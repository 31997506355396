import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-weekly-view-box',
  templateUrl: './weekly-view.component.html',
  styleUrls: ['./weekly-view.component.scss']
})
export class WeeklyViewBoxComponent implements OnInit {
  @Input() notifications;

  @Input() weekDayNames;

  @Input() selectedDate;

  @Input() selectedMonthDates;

  @Output() givePickedDate = new EventEmitter();
  @Output() nextWeek = new EventEmitter();
  @Output() prevWeek = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getCurrentDateClass(date) {
    if (date === this.selectedDate.getDate()) {
      return 'selected';
    }
  }

  pickDate(date) {
    this.givePickedDate.emit(date);
  }

  showNextWeek() {
    this.nextWeek.emit();
  }

  showPrevWeek() {
    this.prevWeek.emit();
  }

  showHideDot(date) {
    const classes = [];
    if (this.notifications) {
      for (const notification of this.notifications) {
        if (
          (notification.eventDate as Date).getTime() === new Date(
            this.selectedDate.getFullYear(), this.selectedDate.getMonth(), date
          ).getTime()
        ) {
          if (! classes.includes('type' + notification.eventTypeId)) {
            classes.push('type' + notification.eventTypeId);
          }
        }
      }
    }
    const classesString = classes.toString().replace(/,/g, ' ');
    return classesString;
  }

}
