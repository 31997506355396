import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[usernamePattern]",
})
export class UsernamePatternDirective {
  private navigationKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Escape",
    "Enter",
    "Home",
    "End",
    "ArrowLeft",
    "ArrowRight",
    "Clear",
    "Copy",
    "Paste",
  ];
  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.code === "KeyA" && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.code === "KeyC" && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.code === "KeyV" && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.code === "KeyX" && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.code === "KeyA" && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.code === "KeyC" && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.code === "KeyX" && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.code === "KeyX" && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (/[^a-zA-Z0-9]/g.test(e.key)) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData("text/plain")
      .replace(/[^a-zA-Z0-9]/g, ""); // get a digit-only string
    document.execCommand("insertText", false, pastedInput);
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer
      .getData("text")
      .replace(/[^a-zA-Z0-9]/g, "");
    this.inputElement.focus();
    document.execCommand("insertText", false, textData);
  }

  @HostListener("input", ["$event"])
  onInput(event) {
    const regex = RegExp(/[^a-zA-Z0-9]/g);
    if (regex.test(event.data)) {
      const target = event.target as HTMLTextAreaElement;
      target.value = target.value.replace(event.data, "");
    }
  }
}
