import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";
import { Subscription, BehaviorSubject } from "rxjs";
import {
  customerInfo,
  customerInfoResponse,
  generalBalanceInfo,
  generalBalanceInfoResponse,
} from "../../shared/models/models";
import { Console } from "console";

@Injectable({
  providedIn: "root",
})
export class CustomerInfoService {
  // Custmer Info variables
  customerInfoSub: Subscription;
  customerInfoLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  customerInfoErrorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  customerInfo: BehaviorSubject<customerInfo> = new BehaviorSubject<customerInfo>(
    undefined
  );

  // General Balance Info variables
  generalBalanceInfoSub: Subscription;
  generalBalanceInfoLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  generalBalanceInfoErrorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  generalBalanceInfo: BehaviorSubject<generalBalanceInfo> = new BehaviorSubject<generalBalanceInfo>(
    undefined
  );

  constructor(private graphQL: GraphQLRequestService) {

  }

  customerInfoQuery() {
    return this.graphQL.request(gql`
      {
        customer {
          user
          email
          mobile
          lastName
          personalN
          birthDate
          firstName
          fullAddress
          lastNameLat
          firstNameLat
          hasTempPassword
          fullLegalAddress
          companyName
        }
      }
    `);
  }

  fetchCustomerInfo() {
    this.customerInfoLoading.next(true);

    this.customerInfoSub = this.customerInfoQuery().subscribe(
      (res: customerInfoResponse): any => {
        this.customerInfoLoading.next(false);
        if (res.errors) {
          this.customerInfoErrorMessage.next(res.errors[0].extensions.code);
        } else {
          this.customerInfoErrorMessage.next("");
          this.customerInfo.next(res.data.customer);

          let fullname = res.data.customer.companyName?? res.data.customer.firstName+" "+res.data.customer.lastName
          localStorage.setItem('fullname', fullname.toLowerCase());


        }
      }
    );
  }

  generalBalanceInfoQuery() {
    return this.graphQL.request(gql`
      {
        customer {
          totalDebt
          totalBalance
          totalBalanceZMW
          totalBalanceEUR
          totalBalanceUSD
        }
      }
    `);
  }









  PendingTransfers(
    sendPerson
  ) {
    return this.graphQL.mutate(
      gql`
        mutation pendingTransfer($senderPerson: String!) {
          pendingTransfer(senderPerson: $senderPerson) {

            id
            userId
            externalId
            amount
            currency
            senderAccountNumber
            receiverAccountNumber
            transmissionDateTime
            provider
            comment
            fee
            receiverBankSwift
            receiverPerson
            transferType
            channelCode
            ownersCount
            voucherNumber
            status
            rejectReason
            rejectCode
            receiverBank
            senderPerson
            branch

          }
        }
      `,
      {
        senderPerson: sendPerson
        // senderPerson: "michael chitembo"
      }
    );
  }





getPendingTransfers(){
  var sendPerson = localStorage.getItem('fullname');
   this.PendingTransfers(sendPerson).subscribe(
    (res): any => {

      if (res.errors) {
       console.log(res);


      } else {
        console.log(res.data['pendingTransfer']);
      }
    }
  );
}











  fetchGeneralBalanceInfo() {
    this.generalBalanceInfoLoading.next(true);

    this.generalBalanceInfoSub = this.generalBalanceInfoQuery().subscribe(
      (res: generalBalanceInfoResponse): any => {
        this.generalBalanceInfoLoading.next(false);
        if (res.errors) {
          this.generalBalanceInfoErrorMessage.next(
            res.errors[0].extensions.code
          );
        } else {
          this.generalBalanceInfoErrorMessage.next("");
          this.generalBalanceInfo.next(res.data.customer);
        }
      }
    );
  }

  clearCustomerInfo() {
    if (this.customerInfo) {
      this.customerInfo = null;
      this.customerInfo = new BehaviorSubject<customerInfo>(undefined);
    }
    if (this.generalBalanceInfo) {
      this.generalBalanceInfo = null;
      this.generalBalanceInfo = new BehaviorSubject<generalBalanceInfo>(
        undefined
      );
    }
    if(this.customerInfoErrorMessage){
      this.customerInfoErrorMessage.next("");
    }
    if(this.generalBalanceInfoErrorMessage){
      this.generalBalanceInfoErrorMessage.next("");
    }
  }
}
