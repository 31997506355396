import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CalendarDataService } from '../../../services/calendar/calendarData.service'

@Component({
  selector: 'app-pick-date',
  templateUrl: './pick-date.component.html',
  styleUrls: ['./pick-date.component.scss']
})
export class PickDateComponent implements OnInit {

  @Input() pickedDate;
  @Input() minDate: Date;
  @Input() maxDate: Date;

  paddingDaysList = [];

  daysList = [];
  daysList2 = [];

  @Output() giveDate = new EventEmitter<any>();

  constructor(
    private calendarDataService: CalendarDataService
  ) {}

  ngOnInit() {
    this.generateDates(this.pickedDate);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  generateDates(parentPickedDate?: Date) {
    this.pickedDate = parentPickedDate;
    this.paddingDaysList = this.calendarDataService.getPadding(
      this.pickedDate.getFullYear(),
      this.pickedDate.getMonth()
    );
    this.daysList = this.calendarDataService.getDatesArray(
      this.pickedDate.getFullYear(),
      this.pickedDate.getMonth()
    );

    this.daysList2 = [];
    let counter = 0;
    while(this.daysList.length != this.daysList2.length){
      this.daysList2[counter] = {valid: true, value: this.daysList[counter]};
      counter++;
    }
    counter = 0;

    this.checkdate()
  }

  checkdate(){
    let testDate: Date;
    testDate = this.pickedDate;
    this.daysList2.forEach((temp) => {
      this.daysList2[
        this.daysList2.indexOf(temp)
      ].valid = this.calendarDataService.checkDateValidation(
        this.minDate,
        this.maxDate,
        testDate.getFullYear(),
        testDate.getMonth().toString(),
        temp.value
      );
    });
  }

  pickDate(date) {
    if(date.valid){
      this.giveDate.emit(date.value);
    }
  }

  giveClassAndCheck(day) {
    let className = "";

    if (day.value === this.pickedDate.getDate() && day.valid) {
      className = "active";
    }else if(!day.valid){
      className = "disabled";
    }
    return className;
  }
}
