import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class RegisterService {
  constructor(private graphQL: GraphQLRequestService) {}

  checkPerson(
    isLegalEntity: boolean,
    personalN: string,
    birthDate: string,
    mobile: string,
    fiscalNumber?: string,
    // statNumber?: string,
    companyName?: string
  ) {
    // personalN
    // firstName
    // lastName
    // address
    // birthDate

    // branch
    // mobile
    // username
    // founded
    // fiscalNumber
    // statNumber
    // companyName
    // companyPhone
    // companyAddress
    // companyCustomerId
    // companyTaxtNumber
    // companyEmailAddress
    // companyBranch
    // __typename
    // statNumber: "${statNumber}",

    // NRC: 795804/11/1
    // Mobile: 0977307314
    // Birth Date: 12/08/1982

    // NRC for authorized signatory: 934761/11/1
    // TPN: 1002230632
    // Mobile: 0968449638
    // Birth Date for authorized signatory: 02/01/1986
    // company name: ROVERLINE MARKETING & LOGISTICS LIMITED

    return this.graphQL.request(gql`{
      registrationCheckCustomer(
        isLegalEntity: ${isLegalEntity},
        personalN: "${encodeURIComponent(personalN)}",
        birthDate: "${birthDate}",
        mobile: "${mobile}",
        fiscalNumber: "${fiscalNumber}",
        companyName: "${companyName}") {
          firstName
          lastName
          isLegalEntity
          personalN
          birthDate
          mobile
          address
      }
    }`);
  }

  generateRegistrationOTP(data) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: RegisterCustomerGenerateOTPInput!) {
          generateRegistrationOTP(data: $data)
        }
      `,
      data
    );
  }

  validateRegistrationOTP(data) {
    return this.graphQL.request(gql`{
      validateRegistrationOTP(
        personalN: "${encodeURIComponent(data.data.personalN)}"
        birthDate: "${data.data.birthDate}"
        mobile: "${data.data.mobile}"
        id: "${data.data.sessionId}"
        otpCode: "${data.data.otpCode}"
        fiscalNumber: "${data.data.fiscalNumber}"
        companyName: "${data.data.companyName}"
        isLegalEntity: ${data.data.isLegalEntity}
        )
    }`);
  }

  checkExistingUsername(username: string) {
    return this.graphQL.request(gql`{
      validateUsername(username:"${username}")
    }`);
  }

  registerCustomer(data) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: RegisterCustomerModel!) {
          registerCustomer(data: $data)
        }
      `,
      data
    );
  }
}
