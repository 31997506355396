<form [formGroup]="accountTemplateForm" class="preloader-parent">
  <div class="card-block-header">
    <h4 class="card-block-title-sm">
      {{ "TEMPLATES_C.ADD_ACC_NUM_TEMP" | translate }}
    </h4>
  </div>

  <div class="card-block">
    <div class="fieldset">
      <div class="row-sm">
        <div class="col-12 col-md-4 col-xl-5 form-group">
          <input
            class="form-input"
            type="text"
            [placeholder]="'TEMPLATES_C.ACC_NUM' | translate"
            maxlength="23"
            style="text-transform: uppercase"
            formControlName="accountNumber"
            [ngClass]="giveInputClasses('accountNumber')"
          />
        </div>
        <div class="col-12 col-md-4 col-xl-3 form-group">
          <input
            class="form-input"
            type="text"
            [placeholder]="'TEMPLATES_C.NAME' | translate"
            formControlName="name"
            [ngClass]="giveInputClasses('name')"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card-block-header">
    <h4 class="card-block-title-sm">
      {{ "TEMPLATES_C.CHOOSE_BANK" | translate }}
    </h4>
  </div>
  <div class="card-block">
    <app-bank-select-for-transfers
      (chooseIndustryNumber)="getEmittedIndustryNumber($event)"
    ></app-bank-select-for-transfers>
  </div>

  <h4 style="padding: 0px 40px 20px 40px; color: #ea4c36" *ngIf="errorMessage">
    {{ "ERROR_CODES." + errorMessage | translate }}
  </h4>

  <div class="card-footer">
    <div class="row-sm btn-row">
      <div class="col-12 col-md-auto">
        <button
          class="btn btn-secondary"
          type="submit"
          [ngClass]="{ 'no-hover': accountTemplateForm.invalid }"
          (click)="submitForm()"
        >
          {{ "TEMPLATES_C.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="preloader full-screen double-circles" *ngIf="loading"></div>
</form>
