<div class="nav-secondary">
    <ul class="table">
        <li class="pointer">
            <a [ngClass]="{ active: activeTab === 'accounts' }" [routerLink]="['/products/accounts']">{{ "PRODUCTS.ACCOUNTS" | translate }}</a
      >
    </li>
    <li class="pointer">
      <a
        [ngClass]="{ active: activeTab === 'loans' }"
        [routerLink]="['/products/loans']"
        >{{ "PRODUCTS.LOANS" | translate }}</a
      >
    </li>
    <li class="pointer">
      <a
        [ngClass]="{ active: activeTab === 'deposits' }"
        [routerLink]="['/products/deposits']"
        >{{ "PRODUCTS.DEPOSITS.DEPOSITS" | translate }}</a
      >
    </li>
  </ul>
</div>