import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { CustomerInfoService } from "src/app/shared/services/customer-info.service";
import { customerInfo } from "../../shared/models/models";
import { Router } from "@angular/router";
import { CoreService } from "src/app/shared/services/core.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Input() menuIsOpen: boolean;
  @Output() toggleMenu = new EventEmitter<boolean>();

  customerInfoSub: Subscription;
  customerInfo: customerInfo;
  customerInfoLoadingSub: Subscription;
  customerInfoLoading: boolean;

  constructor(
    private customerInfoService: CustomerInfoService,
    private router: Router,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.subscribeToCustomerInfoData();
  }

  subscribeToCustomerInfoData() {
    this.customerInfoSub = this.customerInfoService.customerInfo.subscribe(
      (res: customerInfo): any => {
        this.customerInfo = res;
      }
    );
    this.customerInfoLoadingSub = this.customerInfoService.customerInfoLoading.subscribe(
      (res: boolean): any => {
        this.customerInfoLoading = res;
      }
    );
  }

  giveClasses(menuItem, limited) {
    const url = this.router.url.split("/");
    let classes = "";
    if (url.includes(menuItem)) {
      classes = classes + " active";
    }


    return classes;
  }
  logOut() {
    this.coreService.logOut();
  }
  toggleMenuState() {
    this.toggleMenu.emit(!this.menuIsOpen);
  }

  closeMenu() {
    this.toggleMenu.emit(false);
  }
}
