import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";

@Injectable({
  providedIn: "root",
})
export class TakeALoanService {
  constructor(private graphQL: GraphQLRequestService) {}

  calculateLoan(
    amount: number,
    rate: number,
    duration: number,
    aplicationFee: number
  ) {
    if (aplicationFee) {
      aplicationFee /= 100;
      amount += amount * aplicationFee;
    }

    let t = this.mathRound(rate / 100 / 12, 4);
    let numerator = t * amount;
    let monthlyRate = 1 + t;
    monthlyRate = this.mathRound(monthlyRate ** (-1 * duration), 4);
    let denominator = this.mathRound(1 - monthlyRate, 4);
    let monthly = Math.floor(numerator / denominator);

    return monthly;
  }

  mathRound(number, dig) {
    return Math.round(number * Math.pow(10, dig)) / Math.pow(10, dig);
  }

  cardAgreement(cardAgreement) {
    return this.graphQL.mutate(
      gql`
        mutation ($cardAgreement: NewCardGType!) {
          getCardAgreement(cardAgreement: $cardAgreement)
        }
      `,
      cardAgreement
    );
  }

  sendMail(loanCalculatedData) {
    const { amount, fullName, period, personalNumber, phoneNumber, to } =
      loanCalculatedData;
    return this.graphQL.mutate(
      gql`
        mutation ($loanCalculatedData: LoanCalculatedDataInputGType!) {
          sendLoanCalculatedData(loanCalculatedData: $loanCalculatedData)
        }
      `,
      {
        loanCalculatedData: {
          amount,
          fullName,
          period,
          personalNumber,
          phoneNumber,
          to,
        },
      }
    );
  }
}
