<div class="years">
    <div
      class="year"
      *ngFor="let year of yearsList"
      (click)="pickYear(year)"
      [ngClass]="giveClassAndCheck(year)"
    >
      {{ year.value }}
    </div>
  </div>
  