import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "mycredo-front-abz";

  constructor(translate: TranslateService) {
    translate.addLangs(["en", "fr"]);

    if (localStorage.getItem("choosenLanguage")) {
      translate.setDefaultLang(localStorage.getItem("choosenLanguage"));
      translate.use(localStorage.getItem("choosenLanguage"));
    } else {
      translate.setDefaultLang("en");
      translate.use("en");
    }
  }
}
