import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageModel } from "../../models/models";

@Component({
  selector: "app-language-switcher",
  templateUrl: "./language-switcher.component.html",
  styleUrls: ["./language-switcher.component.scss"],
})
export class LanguageSwitcherComponent implements OnInit {
  //#region Variables

  //language chooser dropdown condition
  public dropdown: boolean = false;

  // list of languages of application with codes and images
  public languageList: Array<LanguageModel> = [
    {
      name: "English",
      code: "en",
      image: "../../../assets/images/icons/flags/uk.svg",
    },
    {
      name: "French",
      code: "fr",
      image: "../../../assets/images/icons/flags/fr.svg",
    }
  ];

  // here we store and show choosen language
  public currentLanguage: LanguageModel;
  //#endregion

  //#region Lifecycle Hook

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.detectPickedLanguage();
  }

  //#endregion

  //#region Methods

  public toggle(): void {
    if (this.dropdown) {
      this.dropdown = false;
    } else {
      this.dropdown = true;
    }
  }

  public selectLanguage(item: LanguageModel): void {
    this.currentLanguage = item;
    switch (item.code) {
      case "en":
        this.translate.use("en");
        localStorage.setItem("choosenLanguage", "en");
        break;
      case "fr":
        this.translate.use("fr");
        localStorage.setItem("choosenLanguage", "fr");
        break;
      default:
        this.translate.use("en");
        break;
    }
    this.toggle();
  }

  private detectPickedLanguage(): void {
    const currentLang = this.translate.currentLang;
    for (let i = 0; i < this.languageList.length; i++) {
      if (this.languageList[i].code == currentLang) {
        this.currentLanguage = this.languageList[i];
      }
    }
  }
  //#endregion
}
