import { Injectable } from "@angular/core";
import { GraphQLRequestService } from 'src/app/graph-ql-request.service';
import { HttpClient } from '@angular/common/http';
import gql from 'graphql-tag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class TemplatesService {
  constructor(
    private graphQL: GraphQLRequestService,
    private http: HttpClient
  ) {}

  fetchAccountTemplates() {
    return this.graphQL.request(gql`
      {
        template {
          transfer {
            id
            bankBicCode
            bankName
            beneficiaryAccountNumber
            imageName
            isFavorite
            name
            type
          }
        }
      }
    `);
  }

  fetchMobileTemplates() {
    return this.graphQL.request(gql`
      {
        template {
          mobile {
            id
            identifiers {
              fieldId
              fieldValue
            }
            isFavorite
            name
            serviceId
          }
        }
      }
    `);
  }

  fetchDocumentTemplates() {
    return this.graphQL.request(gql`
      {
        template {
          document {
            documentNumber
            id
            isFavorite
            name
            type
          }
        }
      }
    `);
  }

  fetchTemplates(showBankInfo?: boolean) {
    return this.graphQL.request(gql`
      {
        template {
          transfer {
            bankBicCode
            bankName
            beneficiaryAccountNumber
            id
            imageName
            isFavorite
            name
            type
          }
          document {
            documentNumber
            id
            isFavorite
            name
          }
        }
      }
    `);
  }

  // fetchUtilityTemplates() {
  //   return this.graphQL.request(gql`
  //     {
  //       template {
  //         utility {
  //           id
  //           identifiers {
  //             fieldId
  //             fieldValue
  //           }
  //           isFavorite
  //           name
  //           serviceId
  //         }
  //       }
  //     }
  //   `);
  // }



  // addUtilityTemplate(template) {
  //   return this.graphQL.mutate(
  //     gql`
  //       mutation($template: TemplateUtilityInput!) {
  //         createUtilityTemplate(template: $template) {
  //           id
  //           isFavorite
  //           name
  //           type
  //         }
  //       }
  //     `,
  //     template
  //   );
  // }

  // updateUtilityTemplate(template) {
  //   return this.graphQL.mutate(
  //     gql`
  //       mutation($template: TemplateUtilityInput!) {
  //         updateUtilityTemplate(template: $template) {
  //           id
  //           isFavorite
  //           name
  //           type
  //         }
  //       }
  //     `,
  //     template
  //   );
  // }

  addTemplate(template) {
    return this.graphQL.mutate(
      gql`
        mutation($template: TemplateInput!) {
          createTemplate(template: $template) {
            identifier
            isFavorite
            name
            type
          }
        }
      `,
      template
    );
  }

  addTransferTemplate(template) {
    return this.graphQL.mutate(
      gql`
        mutation($template: TemplateTransferInput!) {
          createTransferTemplate(template: $template) {
            id
            isFavorite
            name
            type
          }
        }
      `,
      template
    );
  }

  updateTemplate(template) {
    return this.graphQL.mutate(
      gql`
        mutation($template: TemplateInput!) {
          updateTemplate(template: $template) {
            identifier
            isFavorite
            name
            type
            id
          }
        }
      `,
      template
    );
  }

  updateAccountTemplate(template) {
    return this.graphQL.mutate(
      gql`
        mutation($template: TemplateTransferInput!) {
          updateTransferTemplate(template: $template) {
            isFavorite
            name
            id
            type
          }
        }
      `,
      template
    );
  }

  deleteTemplate(template) {
    return this.graphQL.mutate(
      gql`
        mutation($templateId: Int!) {
          deleteTemplate(templateId: $templateId)
        }
      `,
      template
    );
  }

  checkAccountNumberInfo(accountNumber) {
    return this.graphQL.request(gql`
    {
      customer(accountNumber: "${accountNumber}") {
        firstNameLat
        lastNameLat
      }
    }
  `);
  }

  checkPhoneNumberProvider(phoneNumber) {
    return this.graphQL.request(gql`{
      utilityPayment{
        recognizeMobileProvider(mobileNumber:"${phoneNumber}")
        {
          id,
          nameEn,
          name,
          category,
          categoryId,
          email
        }
      }
    }`);
  }

  checkMobilePhoneValidity(phoneNumber) {
    return this.graphQL.request(gql`
    {
      validateMobile(mobile:"${phoneNumber}") {
        code,
        isValid
      }
    }
    `);
  }

  addMobileOrPnTemplate(template) {
    template.template.documentNumber = encodeURIComponent(template.template.documentNumber);
    return this.graphQL.mutate(
      gql`
        mutation($template: TemplateDocumentInput!) {
          createDocumentTemplate(template: $template) {
            name
          }
        }
      `,
      template
    );
  }

  updateMobileOrPnTemplate(template) {
    return this.graphQL.mutate(
      gql`
        mutation($template: TemplateDocumentInput!) {
          updateDocumentTemplate(template: $template) {
            id
            isFavorite
            name
            type
          }
        }
      `,
      template
    );
  }
}
