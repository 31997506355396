import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddEventComponent } from "./add-event/add-event.component";
import { EditEventComponent } from "./edit-event/edit-event.component";
import { MonthlyViewComponent } from "./monthly-view/monthly-view.component";
import { WeeklyViewComponent } from "./weekly-view/weekly-view.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CalendarService } from "./calendar.service";
import { CalendarComponent } from "./calendar.component";
import { TranslateModule } from "@ngx-translate/core";
import { MoneyFormatPipe } from "src/app/shared/pipes/money-format.pipe";

@NgModule({
  declarations: [
    CalendarComponent,
    AddEventComponent,
    EditEventComponent,
    MonthlyViewComponent,
    WeeklyViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [CalendarService],
})
export class CalendarModule {}
