import { Subscription } from "rxjs";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";
import { Bank, Branch } from "src/app/shared/models/models";
import { DictionaryService } from "src/app/shared/services/dictionary.service";

export class TransferBase extends accountsInfoBase {

  prepareTransferLoading: boolean = true;
  loading: boolean = false;
  prepareTransfer: any;
  prepareTransferSub: Subscription;
  prepareTransferError: any;
  errorMessage: string;

  prepareForTransfer(
    transfersService,
    amount: number,
    currency: string,
    otpGuid: string,
    type: string,
    nextStep
  ) {
    this.prepareTransferLoading = true;17
    this.unsubscribePrepareTransfer();
    this.prepareTransferSub = transfersService
      .prepareTransfer(+amount, currency, type, otpGuid)
      .subscribe((res) => {
        this.prepareTransferLoading = false;
        this.prepareTransferError = res["errors"];
        if (!this.prepareTransferError) {
          nextStep();
        } else {
          this.errorMessage = res.errors[0].extensions.code;
        }
        this.prepareTransfer = res["data"] && res["data"]["prepareTransfer"];
      });
  }

  unsubscribePrepareTransfer() {
    if (this.prepareTransferSub) {
      this.prepareTransferSub.unsubscribe();
    }
  }
}
