import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class LoanInfoService {
  constructor(private graphQL: GraphQLRequestService) {}

  loanInfoQuery() {
    return this.graphQL.request(gql`
      {
        loans {
          approvedAmount
          outstandingPrincipal
          branchId
          contractN
          currency
          currencyId
          id
          isActive
          loanBalance
          loanBalanceEqu
          loanEndDate
          loanSize
          loanValueDate
          loanYearPercent
          nextPaymentAmount
          nextPaymentDate
          nextPaymentFee
          nextPaymentInterest
          nextPaymentPrincipal
          product
          productId
          relatedAccount
        }
      }
    `);
  }

  loanScheduleInfoQuery(contractN: string) {
    return this.graphQL.request(gql`
    {
      loans (contractN: "${contractN}"){
        schedule{
          balance
          currency
          date
          fee
          interest
          overdueInterest
          overduePrincipal
          penalty
          principal
          totalAmount
        }
      }
    }`);
  }

  loanDetailsInfoQuery(contractN: string) {
    return this.graphQL.request(gql`
    {
      loans (contractN: "${contractN}"){
        nextPaymentFee
        nextPaymentDate
        nextPaymentAmount
        nextPaymentInterest
        nextPaymentPrincipal
        statement{
          balance
          date
          fee
          interest
          penalty
          principal
        }
      }
    }`);
  }
}
