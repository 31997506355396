import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";
import {
  accountsInfo,
  customerInfo,
  generalBalanceInfo,
  selectType,
} from "src/app/shared/models/models";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import html2canvas from "html2canvas";
import * as jspdf from "jspdf";
import { TransactionListService } from "src/app/shared/services/transaction-list.service";
import { CustomerInfoService } from "src/app/shared/services/customer-info.service";
import { MoneyFormatPipe } from "src/app/shared/pipes/money-format.pipe";
import { DatePipe } from "@angular/common";
import { TransactionsService } from "./transactions.service";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  template: "<statement-template></statement-template>",
  styleUrls: ["./transactions.component.scss"],
})
export class TransactionsComponent extends accountsInfoBase implements OnInit {
  //Component view
  view = "default";

  downloadPdfLoading: boolean = false;

  //Inputs for transaction List
  startDate;
  endDate;
  accountNumber;

  //For Account Select
  accounts: Array<selectType>;
  account: selectType;
  accountsLoading: boolean;

  errorMessage: string;

  //For Period Select
  periodOptions = [
    {
      id: "1",
      mainTitle: this.translateService.instant("TRANSACTIONS.LAST_3_MONTH"),
    },
    {
      id: "2",
      mainTitle: this.translateService.instant("TRANSACTIONS.LAST_6_MONTH"),
    },
    {
      id: "3",
      mainTitle: this.translateService.instant("TRANSACTIONS.CUSTOM"),
    },
  ];
  periodArray: selectType;
  selectedPeriod;
  customPeriod: boolean = false;
  pdfLink;

  //Inputs for datepicker
  minDate = new Date(1950, 0, 1);
  todaysDate = new Date();

  advancedSearchForm: FormGroup;

  constructor(
    private accountsInfoService: AccountsInfoService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private transactionListService: TransactionListService,
    private modalService: ModalWithTextService,
    private customerInfoService: CustomerInfoService,
    private moneyFormat: MoneyFormatPipe,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private transactionService: TransactionsService
  ) {
    super();
  }

  private initadvancedSearchForm(): void {
    this.advancedSearchForm = this.formBuilder.group({
      accountNumber: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });
  }
  ngOnInit(): void {
    this.account = null;
    setTimeout(() => {
      this.accountsInfoService.fetchAccountsInfo();
    });
    super.subscribeToAccountsInfo(this.accountsInfoService);
    this.preparePeriodForSelect();
    this.accountsInfoService.accountsInfoErrorMessage.subscribe((res: any) => {
      if (res) {
        this.errorMessage = res;
      }
    });
    this.accountsInfoService.accountsInfo.subscribe((res: any) => {
      this.accountsLoading = false;
      if (res) {
        this.prepareAccountsForFromSelect();
      }
    });
    this.initadvancedSearchForm();
  }
  //#region ExportStatement
  generateData(transactions) {
    let dates = transactions.map((item) => {
      return this.datePipe.transform(
        item.transactionExecutionTime,
        "yyyy-MMM-dd"
      );
    });
    let dateValues = transactions.map((item) => {
      return this.datePipe.transform(item.transactionValueDate, "yyyy-MMM-dd");
    });
    let description = transactions.map((item) => {
      return item.description1;
    });
    let credits = transactions.map((item) => {
      return item.amount > 0
        ? this.moneyFormat.transform(item.amount).toString()
        : "0";
    });
    let debits = transactions.map((item) => {
      return item.amount < 0
        ? this.moneyFormat.transform(item.amount).toString()
        : "0";
    });
    let balance = transactions.map((item) => {
      return this.moneyFormat.transform(item.balance).toString();
    });

    var result = [];
    for (var i = 0; i < transactions.length; i += 1) {
      const temp = {
        Date: dates[i],
        DateValue: dateValues[i],
        Description: description[i],
        Credit: credits[i],
        Debit: debits[i],
        Balance: balance[i],
      };
      temp["id"] = (i + 1).toString();
      result.push(Object.assign({}, temp));
    }
    return result;
  }

  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0,
      });
    }
    return result;
  }
  exportStatementToPdf(): void {
    if (this.advancedSearchForm.valid) {
      this.downloadPdfLoading = true;
      let headers = this.createHeaders([
        "Date",
        "DateValue",
        "Description",
        "Credit",
        "Debit",
        "Balance",
      ]);
      let doc = new jspdf.jsPDF("l", "mm", "a4", true);
      this.transactionListService
        .fetchTransactions(
          this.returnDateString(
            this.advancedSearchForm.controls.startDate.value
          ),
          this.returnDateString(this.advancedSearchForm.controls.endDate.value),
          this.advancedSearchForm.controls.accountNumber.value
        )
        .subscribe((res: any) => {
          let x = 20;
          let startingY = 20;
          const imgData = "../../../assets/images/logo.png";
          doc?.addImage(imgData, "PNG", x + 72, 10, 110, 25, "", "FAST");
          doc?.setFontSize(22);
          doc?.text("Breakdown of transactions", x + 85, startingY + 30);
          this.customerInfoService.customerInfo.subscribe(
            (res: customerInfo) => {
              doc?.setFontSize(14);
              doc?.text(
                `${
                  res.companyName
                    ? res.companyName
                    : res.firstNameLat + " " + res.lastNameLat
                }`,
                x,
                startingY + 50
              );
              doc?.text(
                `${res.companyName ? res.fullLegalAddress : res.fullAddress}`,
                x,
                startingY + 60
              );
            }
          );
          let accountsInfo: any = this.accountsInfo;
          let choosenAccount = accountsInfo.filter((item) => {
            if (
              item.accountNumber ==
              this.advancedSearchForm.controls.accountNumber.value
            ) {
              return item;
            }
          });
          doc?.text(
            `Balance: ${this.moneyFormat.transform(choosenAccount[0].balance)}`,
            x,
            startingY + 70
          );
          doc?.text(
            `Available balance: ${this.moneyFormat.transform(
              choosenAccount[0].availableBalance
            )}`,
            x,
            startingY + 80
          );
          doc?.text(
            `Account number: ${this.advancedSearchForm.controls.accountNumber.value}`,
            x,
            startingY + 90
          );
          doc?.text(
            `${this.datePipe.transform(
              this.advancedSearchForm.controls.startDate.value,
              "yyyy-MMM-dd"
            )}/${this.datePipe.transform(
              this.advancedSearchForm.controls.endDate.value,
              "yyyy-MMM-dd"
            )}`,
            x,
            startingY + 100
          );
          doc?.table(
            2,
            startingY + 130,
            this.generateData(res.data.customer.transactions),
            headers,
            { autoSize: false }
          );
          doc?.save(
            `${this.returnDateString(
              this.advancedSearchForm.controls.startDate.value
            )}/${this.returnDateString(
              this.advancedSearchForm.controls.endDate.value
            )}-Transactions.pdf`
          );
          this.downloadPdfLoading = false;
        });
    }
  }

  //#endregion

  public setFilterDateFrom(date): void {
    this.advancedSearchForm.controls.startDate.setValue(date);
  }
  public setFilterDateTo(date): void {
    this.advancedSearchForm.controls.endDate.setValue(date);
  }

  //Switch view 'default' or 'advanced'
  public switchView(viewType: string): void {
    this.view = viewType;
  }
  public selectAccount(account: selectType): void {
    this.account = account;
    this.advancedSearchForm.controls.accountNumber.setValue(this.account.id);
  }
  public selectPeriod(period: selectType): void {
    this.selectedPeriod = period;
    var currentDate = new Date();
    switch (this.selectedPeriod.id) {
      case "1":
        currentDate.setMonth(currentDate.getMonth() - 3);
        this.advancedSearchForm.controls.startDate.setValue(currentDate);
        this.advancedSearchForm.controls.endDate.setValue(this.todaysDate);
        this.customPeriod = false;
        break;
      case "2":
        currentDate.setMonth(currentDate.getMonth() - 6);
        this.advancedSearchForm.controls.startDate.setValue(currentDate);
        this.advancedSearchForm.controls.endDate.setValue(this.todaysDate);
        this.customPeriod = false;
        break;
      case "3":
        this.customPeriod = true;
        break;
    }
  }
  public filterTransactions(): void {
    if (this.advancedSearchForm.valid) {
      this.startDate = this.returnDateString(
        this.advancedSearchForm.controls.startDate.value
      );
      this.endDate = this.returnDateString(
        this.advancedSearchForm.controls.endDate.value
      );
      this.accountNumber = this.advancedSearchForm.controls.accountNumber.value;
    }
  }
  private returnDateString(date: Date): string {
    const formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    return formattedDate;
  }
  private prepareAccountsForFromSelect() {
    let temp: any = this.accountsInfo;

    if (temp) {
      this.accounts = temp.map((x: accountsInfo) => ({
        id: x.accountId,
        mainTitle: x.accountNumber,
        aboveTitle: x.category,
        underTitle: x.balance.toString() + " " + x.currency,
      }));
    }
  }
  private preparePeriodForSelect(): void {
    let temp: any = this.periodOptions;

    this.periodArray = temp.map((x: any) => ({
      id: x.id,
      mainTitle: x.mainTitle,
    }));
  }
}
