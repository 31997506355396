<div class="credo-page">
  <main>
    <div class="card accounts-card">
      <div class="card-header">
        <h1 class="card-title">{{ "PRODUCTS.MY_PRODUCTS" | translate }}</h1>
      </div>
      <app-product-menu></app-product-menu>

      <div [ngSwitch]="activeTab" class="preloader-parent">
        <ng-container *ngSwitchCase="'accounts'">
          <app-product-list-accounts
            [accounts]="accountsInfo"
            [currentItem]="currentItem"
            [accountsLoading]="accountsInfoLoading"
          ></app-product-list-accounts>
        </ng-container>

        <ng-container *ngSwitchCase="'loans'">
          <app-product-list-loans
            [loans]="loans"
            [currentItem]="currentItem"
            [loading]="loading"
          >
          </app-product-list-loans>
        </ng-container>

        <ng-container *ngSwitchCase="'deposits'">
          <app-product-list-deposits></app-product-list-deposits>
        </ng-container>

        <div class="three-dot-loader" *ngIf="loading"></div>
      </div>
    </div>
  </main>
</div>
