<ul class="week-days">
    <li>{{ "CALENDAR.DAYS.MON_SHORT" | translate }}</li>
    <li>{{ "CALENDAR.DAYS.TUE_SHORT" | translate }}</li>
    <li>{{ "CALENDAR.DAYS.WED_SHORT" | translate }}</li>
    <li>{{ "CALENDAR.DAYS.THU_SHORT" | translate }}</li>
    <li>{{ "CALENDAR.DAYS.FRI_SHORT" | translate }}</li>
    <li>{{ "CALENDAR.DAYS.SAT_SHORT" | translate }}</li>
    <li>{{ "CALENDAR.DAYS.SUN_SHORT" | translate }}</li>
  </ul>
  
  <div class="days">
    <div class="padding" *ngFor="let paddingDay of paddingDaysList"></div>
    <div
      class="day"
      *ngFor="let day of daysList2"
      (click)="pickDate(day)"
      [ngClass]="giveClassAndCheck(day)"
    >
      {{ day.value }}
    </div>
  </div>
  