<div class="accounts-list">
  <ul class="table">
    <li class="t-row t-head">
      <div class="t-cell card-cell">{{ "PRODUCTS.LOAN_TYPE" | translate }}</div>
      <div class="t-cell">
        <span>{{ "PRODUCTS.CONTRACT_NUMBER" | translate }}</span>
      </div>
      <div class="t-cell">
        <span>{{ "PRODUCTS.END_DATE" | translate }}</span>
      </div>
      <div class="t-cell amount-cell">
        <span>{{ "PRODUCTS.REMAINING_PRINCIPLE" | translate }}</span>
      </div>
      <div class="t-cell arrow-cell">&nbsp;</div>
    </li>
    <div
      class="t-row"
      *ngFor="let loan of loans"
      [ngClass]="{ active: isActive(loan) }"
    >
      <li class="pointer" (click)="clickLoan(loan)">
        <div class="t-cell card-cell">
          <div>
            <div>
              <span class="description">{{ loan.product }}</span>
            </div>
          </div>
        </div>
        <div class="t-cell">
          <div class="cell-name">
            {{ "PRODUCTS.CONTRACT_NUMBER" | translate }}
          </div>
          <span>{{ loan.contractN }}</span>
        </div>
        <div class="t-cell">
          <div class="cell-name">{{ "PRODUCTS.END_DATE" | translate }}</div>
          <span>{{ getDate(loan?.loanEndDate) | date: "dd.MM.yyyy" }}</span>
        </div>

        <div class="t-cell amount-cell">
          <div class="cell-name">
            {{ "PRODUCTS.REMAINING_PRINCIPLE" | translate }}
          </div>
          <span class="amount"
            >{{ loan?.outstandingPrincipal ? loan?.outstandingPrincipal : 0 }}
            {{ loan?.currency }}</span
          >
        </div>

        <div class="t-cell arrow-cell">
          <i class="icon i-arrow--blue"></i>
        </div>
      </li>
      <li *ngIf="currentLoan">
        <!-- Current loan details -->
        <div class="card" *ngIf="currentLoan.id === loan.id">
          <div class="nav-secondary">
            <ul class="table loan-schedule">
              <li class="pointer">
                <a
                  (click)="setStatus(SCHEDULE)"
                  [ngClass]="{ active: activeTab === SCHEDULE }"
                  >{{ "PRODUCTS.SCHEDULE" | translate }}</a
                >
              </li>
              <li class="pointer">
                <a
                  (click)="setStatus(HISTORY)"
                  [ngClass]="{ active: activeTab === HISTORY }"
                  >{{ "PRODUCTS.HISTORY" | translate }}</a
                >
              </li>
              <li class="pointer">
                <a
                  (click)="setStatus(DETAILS)"
                  [ngClass]="{ active: activeTab === DETAILS }"
                  >{{ "PRODUCTS.DETAILS" | translate }}</a
                >
              </li>
            </ul>
          </div>

          <ng-container [ngSwitch]="activeTab">
            <div class="card-body">
              <!-- Schedule -->
              <div
                class="loading-block"
                [ngClass]="{ loading: schedulesLoading }"
                *ngSwitchCase="SCHEDULE"
              >
                <ul class="table subtable">
                  <li class="t-row t-head" *ngIf="!schedulesLoading">
                    <div class="t-cell">{{ "PRODUCTS.DATE" | translate }}</div>
                    <div class="t-cell">
                      {{ "PRODUCTS.PRINCIPAL_AMOUNT" | translate }}
                    </div>
                    <div class="t-cell">
                      {{ "PRODUCTS.INTEREST" | translate }}
                    </div>
                    <div class="t-cell">
                      {{ "PRODUCTS.COMMISSION_FEE" | translate }}
                    </div>
                    <div class="t-cell">
                      {{ "PRODUCTS.AMOUNT_TO_PAY" | translate }}
                    </div>
                  </li>

                  <li class="t-row" *ngFor="let schedule of schedules">
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.DATE" | translate }}
                      </div>
                      <span>{{
                        getDate(schedule.date) | date: "dd.MM.yyyy"
                      }}</span>
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.PRINCIPAL_AMOUNT" | translate }}
                      </div>
                      <span
                        >{{ schedule.principal }} {{ schedule.currency }}</span
                      >
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.INTEREST" | translate }}
                      </div>
                      <span>{{ schedule.interest }}</span>
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.COMMISSION_FEE" | translate }}
                      </div>
                      <span>{{ schedule.fee }} {{ schedule.currency }}</span>
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.AMOUNT_TO_PAY" | translate }}
                      </div>
                      <span
                        >{{ schedule.totalAmount }}
                        {{ schedule.currency }}</span
                      >
                    </div>
                  </li>
                </ul>
                <h2
                  class="no-data"
                  *ngIf="schedules.length === 0 && !schedulesLoading"
                >
                  {{ "GENERAL.NO_DATA" | translate }}
                </h2>
              </div>

              <!-- Payment History -->
              <div
                *ngSwitchCase="HISTORY"
                class="loading-block"
                [ngClass]="{ loading: detailsLoading }"
              >
                <ul class="table subtable" *ngIf="!detailsLoading">
                  <li class="t-row t-head">
                    <div class="t-cell">{{ "PRODUCTS.DATE" | translate }}</div>
                    <div class="t-cell">
                      {{ "PRODUCTS.PRINCIPAL_AMOUNT" | translate }}
                    </div>
                    <div class="t-cell">
                      {{ "PRODUCTS.INTEREST" | translate }}
                    </div>
                    <div class="t-cell">
                      {{ "PRODUCTS.COMMISSION_FEE" | translate }}
                    </div>
                    <div class="t-cell">
                      {{ "PRODUCTS.PENALTY" | translate }}
                    </div>
                  </li>

                  <li class="t-row" *ngFor="let history of statements">
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.DATE" | translate }
                      </div>
                      <span>{{
                        getDate(history?.date) | date: "MMM dd / yyyy"
                      }}</span>
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.PRINCIPAL_AMOUNT" | translate }
                      </div>
                      <span
                        >{{ history?.principal }}
                        {{ currentLoan.currency }}</span
                      >
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.INTEREST" | translate }}
                      </div>
                      <span
                        >{{ history?.interest }}
                        {{ currentLoan.currency }}</span
                      >
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.COMMISSION_FEE" | translate }}
                      </div>
                      <span>{{ history?.fee }} {{ currentLoan.currency }}</span>
                    </div>
                    <div class="t-cell">
                      <div class="cell-name">
                        {{ "PRODUCTS.PENALTY" | translate }}
                      </div>
                      <span
                        >{{ history.penalty }} {{ currentLoan.currency }}</span
                      >
                    </div>
                  </li>
                </ul>
                <h2
                  class="no-data"
                  *ngIf="statements.length === 0 && !detailsLoading"
                >
                  {{ "GENERAL.NO_DATA" | translate }}
                </h2>
              </div>

              <!-- Details -->
              <div *ngIf="loans.length > 0">
                <div *ngSwitchCase="DETAILS">
                  <div
                    class="loan-details"
                    *ngIf="statements.length > 0 || detailsLoading"
                  >
                    <div>
                      <span>{{ "PRODUCTS.LOAN_TYPE" | translate }}</span>
                      <b>{{ currentLoan.product }}</b>
                    </div>
                    <div>
                      <span>{{ "PRODUCTS.INTEREST_RATE" | translate }}</span>
                      <b>{{ currentLoan.loanYearPercent }} %</b>
                    </div>
                    <div>
                      <span>{{ "PRODUCTS.NEAREST_PAY" | translate }}</span>
                      <b>{{
                        getDate(details.nextPaymentDate) | date: "dd.MM.yyyy"
                      }}</b>
                    </div>
                    <div>
                      <span>{{ "PRODUCTS.LOAN_AMOUNT" | translate }}</span>
                      <b class="white-space-wrap"
                        >{{ currentLoan.loanSize }}
                        {{ currentLoan.currency }}</b
                      >
                    </div>
                    <div>
                      <span>{{
                        "PRODUCTS.REMAINING_PRINCIPLE" | translate
                      }}</span>
                      <b class="white-space-wrap"
                        >{{
                          currentLoan.outstandingPrincipal
                            ? currentLoan.outstandingPrincipal
                            : 0
                        }}
                        {{ currentLoan.currency }}</b
                      >
                    </div>
                    <div>
                      <span>{{ "PRODUCTS.ISSUE_DATE" | translate }}</span>
                      <b>{{
                        getDate(currentLoan.loanValueDate) | date: "dd.MM.yyyy"
                      }}</b>
                    </div>
                    <div>
                      <span>{{ "PRODUCTS.MATURITY_DATE" | translate }}</span>
                      <b>{{
                        getDate(currentLoan.loanEndDate) | date: "dd.MM.yyyy"
                      }}</b>
                    </div>
                  </div>
                  <h2
                    class="no-data"
                    *ngIf="statements.length === 0 && !detailsLoading"
                  >
                    {{ "GENERAL.NO_DATA" | translate }}
                  </h2>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </li>
    </div>
  </ul>

  <h2 class="no-data" *ngIf="!loading && !localLoans">
    {{ "GENERAL.NO_DATA" | translate }}
  </h2>
</div>
