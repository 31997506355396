<!-- <p>
  Legal Entity Company Name: LYCEE MIXTE NASSOR SAMDJEE (LMNS) FiscalNumber:
  5000778186 StatNumber: 80212542012000088 Representative Personal Number:
  026000052352 BirthDate: 22051989 Phone: 0327050853
</p> -->

<section class="card" style="position: relative">
  <div class="preloader full-screen double-circles" *ngIf="loading"></div>
  <form class="card" [formGroup]="registrationForm">
    <div class="card-block">
      <h2 class="card-title">{{ "LANDING.REGISTER.REGISTER" | translate }}</h2>

      <div class="container tabs">
        <!-- choosing client type -->
        <div class="conatiner buttons" *ngIf="!clientType">
          <button
            type="button"
            class="btn btn-primary"
            (click)="pickClientType(clientTypeEnum.Retail)"
          >
            <span>{{ "LANDING.REGISTER.RETAIL_C" | translate }}</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="pickClientType(clientTypeEnum.Entity)"
          >
            <span>{{ "LANDING.REGISTER.ENTITY_C" | translate }}</span>
          </button>
        </div>

        <!-- registration form -->
        <div class="container fieldset" *ngIf="clientType">
          <div
            class="row-sm fieldset"
            *ngIf="registrationStep === 1 || registrationStep === 2"
          >
            <!-- birthdate input -->
            <div class="col-12 col-md-6 form-group">
              <div class="custom-input">
                <app-full-date-picker
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [placeholder]="helper.returnDateOfBirthPlaceHolder(clientType,registrationForm.controls.birthDate)|translate"
                  [valid]="
                    this.registrationForm.controls.birthDate.invalid &&
                    this.registrationForm.controls.birthDate.touched
                      ? false
                      : true
                  "
                  [pickedDate]="pickedDate"
                  [disabled]="registrationStep === 2"
                  (giveDate)="pickDate($event)"
                >
                </app-full-date-picker>
              </div>
            </div>

            <!-- id input -->
            <div class="col-12 col-md-6 form-group has-error">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.personalN)
                "
              >
                <i class="icon i-lg i-user"></i>
                <input
                  type="text"
                  class="id"
                  formControlName="personalN"
                />
                <div class="input-messages">
                  <span>{{helper.returnNRCPlaceHolder(clientType,registrationForm.controls.personalN) | translate}}</span>
                </div>
              </div>
            </div>

            <!-- phone number input -->
            <div class="col-12 col-md-6 form-group has-error">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.mobile)
                "
              >
                <i class="icon i-lg i-mobile"></i>
                <input
                  type="text"
                  digitOnly
                  class="phone-number"
                  formControlName="mobile"
                />
                <div class="input-messages">
                  <span>{{
                    registrationForm.controls.mobile.invalid &&
                    registrationForm.controls.mobile.touched
                      ? ("LANDING.MOBILE_INV" | translate)
                      : ("LANDING.MOBILE" | translate)
                  }}</span>
                </div>
              </div>
            </div>

            <!-- if client Type is Entity -->
            <ng-container *ngIf="clientType === clientTypeEnum.Entity">
              <!-- fiscalNumber input -->
              <div class="col-12 col-md-6 form-group has-error">
                <div
                  class="custom-input"
                  [ngClass]="
                    giveClassesToCustomInputs(
                      registrationForm.controls.fiscalNumber
                    )
                  "
                >
                  <i class="icon i-lg i-docs"></i>
                  <input
                    type="text"
                    class="phone-number"
                    formControlName="fiscalNumber"
                    digitOnly
                  />
                  <div class="input-messages">
                    <span>{{
                      registrationForm.controls.fiscalNumber.invalid &&
                      registrationForm.controls.fiscalNumber.touched
                        ? ("LANDING.FISC_NUM_INV" | translate)
                        : ("LANDING.FISC_NUM" | translate)
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- companyName input -->
              <div class="col-12 col-md-6 form-group has-error">
                <div
                  class="custom-input"
                  [ngClass]="
                    giveClassesToCustomInputs(
                      registrationForm.controls.companyName
                    )
                  "
                >
                  <i class="icon i-lg i-group"></i>
                  <input
                    type="text"
                    class="phone-number"
                    formControlName="companyName"
                  />
                  <div class="input-messages">
                    <span>{{
                      registrationForm.controls.companyName.invalid &&
                      registrationForm.controls.companyName.touched
                        ? ("LANDING.COMPANY_NAME_INV" | translate)
                        : ("LANDING.COMPANY_NAME" | translate)
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- statNumber input -->
              <!-- <div class="col-12 col-md-6 form-group has-error">
                <div
                  class="custom-input"
                  [ngClass]="
                    giveClassesToCustomInputs(
                      registrationForm.controls.statNumber
                    )
                  "
                >
                  <i class="icon i-lg i-stat"></i>
                  <input
                    type="text"
                    class="phone-number"
                    formControlName="statNumber"
                    digitOnly
                  />
                  <div class="input-messages">
                    <span>{{
                      registrationForm.controls.statNumber.invalid &&
                      registrationForm.controls.statNumber.touched
                        ? ("LANDING.STAT_NUM_INV" | translate)
                        : ("LANDING.STAT_NUM" | translate)
                    }}</span>
                  </div>
                </div>
              </div> -->
            </ng-container>


          </div>

          <!-- otp input -->
          <div class="col-12 form-group" *ngIf="registrationStep === 2">
            <app-sms-request
              [valid]="
                registrationForm.controls.smsCode.invalid &&
                registrationForm.controls.smsCode.touched
                  ? false
                  : true
              "
              [disabled]="otpLoading"
              [resendBtnHovered]="resendBtnHovered"
              [loading]="loading"
              (giveCode)="getSmsCode($event)"
              (resend)="resendSMSCode()"
            >
            </app-sms-request>
          </div>

          <!-- second step -->
          <div class="row-sm fieldset" *ngIf="registrationStep === 3">
            <!-- Username input -->
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.username)
                "
              >
                <i class="icon i-lg i-user"></i>
                <input
                  type="text"
                  class="username"
                  [ngClass]="{ loading: checkingUsername }"
                  formControlName="username"
                  style="text-transform: uppercase"
                  usernamePattern
                  maxlength="25"
                  (blur)="checkUsernameValidity()"
                />
                <div class="input-messages">
                  <span>{{
                    registrationForm.controls.username.invalid &&
                    registrationForm.controls.username.touched
                      ? ("LANDING.USERNAME_INV" | translate)
                      : ("LANDING.USERNAME" | translate)
                  }}</span>
                </div>
              </div>
            </div>
            <!-- Password input -->
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.password)
                "
              >
                <i class="icon i-lg i-lock"></i>
                <input
                  type="password"
                  class="password"
                  formControlName="password"
                  (blur)="checkPasswordValidity()"
                />
                <div class="input-messages">
                  <span>{{
                    registrationForm.controls.password.invalid &&
                    registrationForm.controls.password.touched
                      ? ("LANDING.PASSWORD_INV" | translate)
                      : ("LANDING.PASSWORD" | translate)
                  }}</span>
                </div>
              </div>
            </div>
            <!-- name input -->
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.name)
                "
              >
                <i class="icon i-lg i-user"></i>
                <input
                  type="text"
                  class="name"
                  formControlName="name"
                  [ngClass]="checkInputToGiveClass('name')"
                />
                <div class="input-messages">
                  <span>{{
                    registrationForm.controls.name.invalid &&
                    registrationForm.controls.name.touched
                      ? ("LANDING.FULL_NAME" | translate)
                      : ("LANDING.FULL_NAME" | translate)
                  }}</span>
                </div>
              </div>
            </div>
            <!-- birthdate input -->
            <div
              class="col-12 col-md-6 form-group"
              *ngIf="clientType === clientTypeEnum.Entity"
            >
              <div class="custom-input">
                <app-full-date-picker
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [placeholder]="'GENERAL.DATE_OFsssBIRTH'|translate"
                  [valid]="
                    this.registrationForm.controls.birthDate.invalid &&
                    this.registrationForm.controls.birthDate.touched
                      ? false
                      : true
                  "
                  [disabled]="
                    this.registrationForm.get('birthDate').value ? true : false
                  "
                  [pickedDate]="pickedDate"
                  (giveDate)="pickDate($event)"
                >
                </app-full-date-picker>
              </div>
            </div>
            <!-- address input -->
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.address)
                "
              >
                <i class="icon i-lg i-home"></i>
                <input
                  type="text"
                  class="address"
                  formControlName="address"
                  [ngClass]="checkInputToGiveClass('address')"
                />
                <div class="input-messages">
                  <span>{{
                    registrationForm.controls.address.invalid &&
                    registrationForm.controls.address.touched
                      ? (returnAddressSpan() | translate)
                      : (returnAddressSpan() | translate)
                  }}</span>
                </div>
              </div>
            </div>
            <!-- email input -->
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.email)
                "
              >
                <i class="icon i-lg i-mail"></i>
                <input
                  type="text"
                  class="email"
                  formControlName="email"
                  [ngClass]="checkInputToGiveClass('email')"
                />
                <div class="input-messages">
                  <span>{{
                    registrationForm.controls.email.invalid &&
                    registrationForm.controls.email.touched
                      ? ("LANDING.EMAIL" | translate)
                      : ("LANDING.EMAIL" | translate)
                  }}</span>
                </div>
              </div>
            </div>
            <!-- terms and conditions tick -->
            <div class="col-12 form-group checkbox-container">
              <label
                class="checkbox pointer"
                [ngClass]="
                  giveClassesToCustomInputs(registrationForm.controls.agreement)
                "
              >
                <input type="checkbox" formControlName="agreement" />
                <span
                  (click)="checkAgreementBox($event)"
                  [ngClass]="{ invalid: invalidAgreement }"
                >
                </span>
              </label>
              <a (click)="openAgreementOnNewTab()">{{
                "GENERAL.AGREE_TO_TERMS" | translate
              }}</a>
            </div>
          </div>

          <h4 style="color: #ea4c36" *ngIf="errorMessage">
            {{ "ERROR_CODES." + errorMessage | translate }}
          </h4>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="container">
        <div class="row">
          <div class="col-auto">
            <a class="icon-btn back-btn" (click)="goBack()"
              ><i class="icon i-arrow--white"></i
              ><span>{{ "GENERAL.BACK" | translate }}</span></a
            >
          </div>
          <div class="col" *ngIf="clientType">
            <button
              type="submit"
              class="btn btn-lg submit-btn"
              (click)="nextStep()"
              *ngIf="registrationStep !== 3"
            >
              {{ "GENERAL.NEXT" | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-lg submit-btn"
              (click)="registerNewUser()"
              *ngIf="registrationStep === 3"
            >
              {{ "GENERAL.REGISTER" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>

