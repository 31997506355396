import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
} from "@angular/core";

@Component({
  selector: "app-sms-request",
  templateUrl: "./sms-request.component.html",
  styleUrls: ["./sms-request.component.scss"],
})
export class SmsRequestComponent implements OnInit {

  @Input() time: number;
  @Input() valid = false;
  @Input() disabled = false;
  @Input() resendBtnHovered = false;

  @Input() loading;

  Math: any;

  inputNumbers = {
    1: null,
    2: null,
    3: null,
    4: null,
  };

  interval;



  smsCode = "";

  @Output() giveCode = new EventEmitter<any>();
  @Output() resend = new EventEmitter<any>();

  constructor() {
    this.Math = Math;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabled: SimpleChange = changes.disabled;
    if (disabled) {
      if (
        (disabled.previousValue === undefined ||
          disabled.previousValue === true) &&
        disabled.currentValue === false
      ) {
        this.time = 300;
        this.timerFunction();
      }
    }
  }

  smsOnInput() {
    this.giveCode.emit(this.smsCode);
  }

  disableSelect(e) {
    e.target.selectionStart = e.target.selectionEnd;
  }

  disableArrows(e) {
    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
      e.preventDefault();
    }
  }

  onResendHover() {
    this.resendBtnHovered = true;
  }

  onResendBlur() {
    this.resendBtnHovered = false;
  }

  resetOnTimePassed() {
    clearInterval(this.interval);
    this.smsCode = "";
    this.giveCode.emit(this.smsCode);
  }

  resendCode() {
    this.resetOnTimePassed();
    this.resend.emit();
    this.disabled = false;
    this.time = 300;
    this.timerFunction();
  }

  timerFunction() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      this.time = this.time - 1;
      if (this.time === 0) {
        clearInterval(this.interval);
        this.resetOnTimePassed();
        this.disabled = true;
      }
    }, 1000);
  }
}
