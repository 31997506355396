<h2 class="no-data" *ngIf="!loading && !transaction">
  {{ "GENERAL.NO_DATA" | translate }}
</h2>

<ul class="table transactions-list" [ngClass]="{ loading: loading }">
  <ng-container *ngIf="!loading && transaction">
    <li
      class="t-row"
      (click)="exportTransactionDetailsToPdf(item)"
      *ngFor="let item of transaction"
    >
      <div class="t-cell fluid-cell">
        <app-tooltip
          class="tooltip-box"
          [tooltipText]="'TRANSACTIONS.DOWNLOAD_PDF' | translate"
        >
        </app-tooltip>
        <div class="table">
          <div class="t-row">
            <div class="t-cell date-cell">
              <span class="transaction-date"
                >{{ item.transactionValueDate | date: "dd-MM-yyyy" }}
              </span>
            </div>
            <div class="t-cell description-cell">
              <span class="transaction-description">{{
                item.description1
              }}</span>
            </div>
            <div class="t-cell type-cell">
              <span class="transaction-category">{{
                item.transactionType
              }}</span>
            </div>

            <div class="t-cell amount-cell">
              <span
                class="transaction-amount"
                [ngClass]="{
                  green: item.amount > 0,
                  red: !(item.amount > 0)
                }"
                >{{ item.amount > 0 ? "+" : "" }}{{ item.amount | moneyFormat }}
              </span>
            </div>
            <div class="t-cell amount-cell">
              <span class="transaction-amount">
                {{ item.balance | moneyFormat }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
