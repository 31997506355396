<form [formGroup]="form" class="preloader-parent">
    <div class="card-block-header">
      <h4 class="card-block-title-sm">
        {{ "TEMPLATES_C.ADD_PN_TEMP" | translate }}
      </h4>
    </div>

    <div class="card-block">
      <div class="fieldset">
        <div class="row-sm">
          <div class="col-12 col-md-4 col-xl-5 form-group">
            <input
              class="form-input"
              type="text"
              formControlName="mobileOrPn"
              maxlength="22"
              [placeholder]="'TEMPLATES_C.ID_NUM' | translate"
              [ngClass]="giveInputClasses('mobileOrPn')"
            />
          </div>
          <div class="col-12 col-md-4 col-xl-3 form-group" *ngIf="step === 2">
            <input
              class="form-input"
              type="text"
              [placeholder]="'TEMPLATES_C.NAME' | translate"
              formControlName="name"
              [ngClass]="giveInputClasses('name')"
            />
          </div>
        </div>
      </div>
    </div>

    <h4 style="padding: 0px 40px 20px 40px; color: #ea4c36;" *ngIf="errorMessage">
      {{ "ERROR_CODES."+errorMessage | translate }}
    </h4>

    <div class="card-footer">
      <div class="row-sm btn-row">
        <div class="col-12 col-md-auto" *ngIf="step === 1">
          <button
            class="btn btn-secondary"
            type="submit"
            (click)="checkIdentifier()"
          >
            {{"GENERAL.CHECK" | translate}}
          </button>
        </div>
        <div class="col-12 col-md-auto" *ngIf="step === 2">
          <button
            class="btn btn-secondary"
            type="submit"
            [ngClass]="{ 'no-hover': form.invalid }"
            (click)="submitForm()"
          >
            {{"TEMPLATES_C.SAVE" | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="preloader full-screen double-circles" *ngIf="loading"></div>
  </form>
