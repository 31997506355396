import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import { Deposit } from "../models/models";

@Injectable({
  providedIn: "root",
})
export class DepositsService {
  constructor(private graphQL: GraphQLRequestService) {}

  public depositsLoading = new BehaviorSubject<boolean>(false);
  public depositsError = new BehaviorSubject<string>("");
  public deposits = new BehaviorSubject<Array<Deposit>>([]);

  fetchDepositsQuery() {
    return this.graphQL.request(gql`
      {
        customer {
          deposits {
            accountId
            account
            accountNumber
            currency
            categoryId
            category
            hasCard
            status
            statusId
            balance
            availableBalance
            blockedAmount
            currencyPriority
            availableBalanceEqu
            minimumBalance
          }
        }
      }
    `);
  }

  fetchDeposits() {
    this.depositsLoading.next(true);
    this.fetchDepositsQuery().subscribe((res: any): any => {
      this.depositsLoading.next(false);
      if (res.errors) {
        this.depositsError.next(res.errors[0].extensions.code);
        return;
      }
      if (res.data.customer.deposits.length === 0) {
        this.depositsError.next("NO_DEPOSITS");
        return;
      }
      this.depositsError.next("");
      this.deposits.next(res.data.customer.deposits);
    });
  }
}
