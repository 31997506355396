import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { LoanInfoService } from "src/app/shared/services/loan-info.service";
import { loanInfoResponse, loanInfo } from "src/app/shared/models/models";

@Component({
  selector: "app-loan-list-mini",
  templateUrl: "./loan-list-mini.component.html",
  styleUrls: ["./loan-list-mini.component.scss"],
})
export class LoanListMiniComponent implements OnInit {
  loanSub: Subscription;

  loanErrorMessage = "";
  loanNoDataMessage = "";

  loans: Array<loanInfo>;

  loanLoading = true;

  constructor(public loanInfoservic: LoanInfoService) {}

  ngOnInit(): void {
    this.fetchLoanInfo();
  }

  fetchLoanInfo() {
    this.loanSub = this.loanInfoservic
      .loanInfoQuery()
      .subscribe((res: loanInfoResponse) => {
        if (res.errors) {
          this.loanErrorMessage = res.errors[0].extensions.code;
        }
        if (res.data) {
          let temp: any = res.data.loans;
          this.loans = temp;
          if(this.loans?.length === 0){
            this.loanNoDataMessage = "NO_ACTIVE_LOANS";
          }
        }

        this.loanLoading = false;
      });
  }

}
