import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { Guid } from "guid-typescript";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import { OtpService } from "src/app/shared/services/otp.service";

@Injectable({
  providedIn: "root",
})
export class TwoFaService {
  private guid: Guid;
  private mobile;
  private code: string;

  constructor(
    private otpService: OtpService,
    private graphQL: GraphQLRequestService,
    private apollo: Apollo
  ) {}

  getCustomerMobile(userName,password) {

    console.log('getCustomerMobile is being called');
    return this.graphQL.mutate(
      gql`
        mutation ($userName: String!, $password: String!) {
          customerMobile(userName: $userName, password: $password)
        }
      `,
      { userName,password} 
    );
  }


}
