import { Injectable } from '@angular/core';
import { GraphQLRequestService } from '../../graph-ql-request.service';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private graphQL: GraphQLRequestService
  ) { }

  resetCheckUser(userName, mobile){
    return this.graphQL.mutate(
      gql`
      mutation($userName: String!, $mobile: String!){
        restorePasswordCheckUser(
          userName: $userName,
          mobile: $mobile)
      }`,
      {userName,
      mobile}
    );
  }

  generateResetPasswordOtp(data){
    return this.graphQL.mutate(
      gql`
        mutation($data: OtpInput!) {
          generateRestorePasswordOtp(data: $data)
        }
      `,
      data
    );
  }

  validateResetPasswordOtp(data){
    return this.graphQL.mutate(
      gql`
        mutation($data: OtpInput!) {
          validateRestorePasswordOtp(data: $data)
        }
      `,
      data
    );
  }

  resetPassword(userName, mobile, password){
    return this.graphQL.mutate(
      gql`
        mutation($userName: String!,
          $mobile: String!,
          $password: String!) {
          restorePassword(
            userName:$userName,
            mobile: $mobile,
            password: $password
          )
        }
      `,
      {userName,
        mobile,
        password
      }
    );
  }

  changePassword(data){
    return this.graphQL.mutate(
      gql`
        mutation($data: ChangePasswordDetailsInput!) {
          changePassword(data: $data)
      }`,
      data
    );
  }
}
