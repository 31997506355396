import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LandingService {
  constructor(private httpClient: HttpClient) {}

  showPopupOnLanding: boolean = false;

  authorizeUser(username, password, sessionId?, code?) {
    return this.httpClient.post(`${environment.loginApiUrl}`, {
      userName: username,
      password: password,
      sessionId: sessionId,
      code: code,
    });
  }

  setToken(token) {
    sessionStorage.setItem("token", `${token}`);
  }

  rememberUsername(username) {
    localStorage.setItem("username", `${username}`);
  }
}
