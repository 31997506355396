import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";

@Component({
  selector: "app-transactions-box",
  templateUrl: "./transactions-box.component.html",
  styleUrls: ["./transactions-box.component.scss"],
})
export class TransactionsBoxComponent implements OnInit {
  public accountNumber: string;
  public startDate;
  public endDate;

  private subs = new Subscription();

  constructor(
    private accountsInfoService: AccountsInfoService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.setDate();
    this.subToFirstAccountNumber();
  }
  private setDate(): void {
    let tempStartDate = new Date();
    let tempEndDate = new Date();
    tempStartDate.setMonth(tempEndDate.getMonth() - 3);

    this.startDate = this.datePipe.transform(tempStartDate, "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(tempEndDate, "yyyy-MM-dd");
  }

  private subToFirstAccountNumber(): void {
    this.subs.add(
      this.accountsInfoService.accountsInfo.subscribe((accountsInfo:any) => {
        if(accountsInfo && accountsInfo.length){
        this.accountNumber = accountsInfo[0].accountNumber;
        }
      })
    );
  }
}
