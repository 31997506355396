import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddTemplateComponent } from "./add-template/add-template.component";
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "src/app/shared/shared.module";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TemplatesService } from "./templates.service";
import { TemplatesComponent } from "./templates.component";
import { EditPhoneNumberTemplateComponent } from './edit-templates/edit-phone-number-template/edit-phone-number-template.component';
import { EditAccountNumberTemplateComponent } from './edit-templates/edit-account-number-template/edit-account-number-template.component';
import { EditMobileOrPnTemplateComponent } from './edit-templates/edit-mobile-or-pn-template/edit-mobile-or-pn-template.component';
import { AddAccountNumberTemplateComponent } from './add-template/add-account-number-template/add-account-number-template.component';
import { AddMobileOrPnTemplateComponent } from './add-template/add-mobile-or-pn-template/add-mobile-or-pn-template.component';
import { AddPhoneNumberTemplateComponent } from './add-template/add-phone-number-template/add-phone-number-template.component';

@NgModule({
  declarations: [TemplatesComponent, AddTemplateComponent, EditPhoneNumberTemplateComponent, EditAccountNumberTemplateComponent, EditMobileOrPnTemplateComponent, AddAccountNumberTemplateComponent, AddMobileOrPnTemplateComponent, AddPhoneNumberTemplateComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [TemplatesService],
})
export class TemplatesModule {}
