import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarDataService } from 'src/app/shared/services/calendar/calendarData.service';

@Component({
  selector: 'app-pick-month',
  templateUrl: './pick-month.component.html',
  styleUrls: ['./pick-month.component.scss']
})
export class PickMonthComponent implements OnInit {

  @Input() pickedDate: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;

  monthsList = [];
  counter = 0;

  @Output() giveMonth = new EventEmitter<any>();

  constructor(
    public translate: TranslateService,
    private calendarDataService: CalendarDataService
  ) {}

  ngOnInit() {
    this.generateMonths(this.pickedDate);
  }

  generateMonths(monthPickedDate?: Date) {
    this.pickedDate = monthPickedDate;

    if (this.monthsList.length == 0) {
      this.counter = 0;
      for (let i = 1; i <= 12; i++) {
        this.monthsList[this.counter] = {
          valid: true,
          value: i + "/1" + "/2019",
        };
        this.counter++;
      }
      this.counter = 0;
    }
    this.checkdate();
  }

  checkdate() {
    let testDate: Date;
    testDate = this.pickedDate;
    this.monthsList.forEach((temp) => {
      let month = Number(temp.value.split("/")[0]) - 1;
      this.monthsList[
        this.monthsList.indexOf(temp)
      ].valid = this.calendarDataService.checkDateValidation(
        this.minDate,
        this.maxDate,
        testDate.getFullYear(),
        month.toString()
      );
    });
  }

  pickMonth(date) {
    const month = Number(date.value.split("/")[0]) - 1;
    if (date.valid) {
      this.giveMonth.emit(month);
    }
  }

  giveClassAndCheck(date) {
    let month = Number(date.value.split("/")[0]) - 1;
    let className = "";

    if (this.pickedDate.getMonth() === month && date.valid) {
      className = "active";
    } else if (!date.valid) {
      className = "disabled";
    }

    return className;
  }
}
