import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Guid } from "guid-typescript";
import { Subscription } from "rxjs";
import {
  AccountsByMobileOrPnResponse,
  selectType,
  accountsInfo,
  TemplateDocumentInfo,
} from "src/app/shared/models/models";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { TemplatesService } from "../../templates/templates.service";
import { TransferBase } from "../transfers-base";
import { TransfersService } from "../transfers.service";

@Component({
  selector: "app-transfer-with-mobile-or-pn",
  templateUrl: "./transfer-with-mobile-or-pn.component.html",
  styleUrls: ["./transfer-with-mobile-or-pn.component.scss"],
})
export class TransferWithMobileOrPnComponent
  extends TransferBase
  implements OnInit, OnDestroy
{
  step: number = 1;

  guid: Guid;

  otpCode: string = "";

  mobileOrPnform: FormGroup;

  accountsToSub: Subscription;
  accountsToLoading: boolean = false;

  defaultAccount: selectType;
  accountsFrom: selectType;
  accountsTo: selectType;
  selectedTemplateTo?: selectType;
  selectedAccountFrom?: selectType;
  selectedAccountTo?: selectType;


  senderPerson: string = "";
  ownersCount:number=0;


  templatesLoading: boolean = true;
  showAddTemplateBlock: boolean = false;
  prepareTransferSub: Subscription;
  prepareTransferData;

  addTemplateSub: Subscription;
  templates: TemplateDocumentInfo;
  selectedTemplate: TemplateDocumentInfo;
  templateArr: selectType;

  makeTransferSub: Subscription;

  loading: boolean = false;
  errorMessage: string = "";
  accountErrorMessage: string = "";

  makeTemplateFavourite: boolean = false;

  constructor(
    private transfersService: TransfersService,
    private templateService: TemplatesService,
    private accountsInfoService: AccountsInfoService,
    private router: Router,
    private modalService: ModalWithTextService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedAccountFrom = null;
    this.selectedTemplateTo = null;
    this.selectedAccountTo = null;
    this.errorMessage = null;
    this.initializeForm();
    setTimeout(() => {
      this.accountsInfoService.fetchAccountsInfo();
    });
    this.modalService.modalClosed.subscribe((res: boolean) => {
      if (res === true) {
        this.onPopupClosed();
      }
    });
    super.subscribeToAccountsInfo(this.accountsInfoService);
    this.accountsInfoService.accountsInfoErrorMessage.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = true;
        this.errorMessage = res;
      }
    });
    this.accountsInfoService.accountsInfo.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = false;
        this.prepareAccountsForFromSelect();
      }
    });

    this.fetchTemplates();
    this.getfullName();
  }

  initializeForm() {
    this.mobileOrPnform = new FormGroup({
      accountFrom: new FormControl("", Validators.required),
      template: new FormControl("", Validators.required),
      amount: new FormControl("", [
        Validators.required,
        Validators.max(100000),
      ]),
      description: new FormControl("", Validators.required),
      name: new FormControl("", Validators.required),
      accountTo: new FormControl("", Validators.required),
      mobileOrPn: new FormControl("", [Validators.required]),
      currency: new FormControl(""),
      addToFavourites: new FormControl(false),
      otpCode: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });
  }

  mobileOrPnInputValidator(control: AbstractControl) {
    if (control.value.length !== 9 && control.value.length !== 11) {
      return { validUrl: true };
    }
    return null;
  }

  giveInputClasses(input) {
    if (
      this.mobileOrPnform.get(input).invalid &&
      this.mobileOrPnform.get(input).touched
    ) {
      return "has-error";
    }
  }
  checkIfSavingAccount(accNumber) {
    return accNumber.substring(10, 12) === "21";
  }

  prepareAccountsForFromSelect() {
    let temp: any = this.accountsInfo;
    let accsWithoutSaving = temp.filter((element) => {
      let isSavingAccount = this.checkIfSavingAccount(element.accountNumber);
      if (!isSavingAccount) {
        return element;
      }
    });
    if (accsWithoutSaving) {
      this.accountsFrom = accsWithoutSaving.map((x: accountsInfo) => ({
        id: x.accountId,
        mainTitle: x.accountNumber,
        aboveTitle: x.category,
        rightInfo: x.balance.toString() + " " + x.currency,
      }));
    }

    let defTemp: any = this.defaultAccountInfo;
    if (defTemp) {
      if (defTemp.length !== 0) {
        this.defaultAccount = {
          id: this.defaultAccountInfo.accountId,
          imgId: null,
          imgName: "",
          mainTitle: this.defaultAccountInfo.accountNumber,
          aboveTitle: "",
          underTitle: "",
          rightInfo:
            this.defaultAccountInfo.balance.toString() +
            " " +
            this.defaultAccountInfo.currency,
        };
      }
    }
  }

  selectAccountFrom(tempFrom: selectType) {
    this.selectedAccountFrom = tempFrom;
    this.mobileOrPnform.controls.accountFrom.setValue(tempFrom.mainTitle);
    this.mobileOrPnform.controls.currency.setValue(
      tempFrom.rightInfo.substring(tempFrom.rightInfo.length - 3)
    );
    this.setMaxAmountBasedOnChoosenAccount(tempFrom);
  }
  setMaxAmountBasedOnChoosenAccount(account) {
    const accounts: any = this.accountsInfo;
    const choosenAccount = accounts.find(
      (x) => x.accountNumber === account.mainTitle
    );
    let maxAmount = choosenAccount.balance;
    if (maxAmount > 100000) maxAmount = 100000;
    this.mobileOrPnform.controls.amount.setValidators([
      Validators.required,
      Validators.max(maxAmount),
    ]);
  }
  fetchTemplates() {
    this.templatesLoading = true;
    this.templateService.fetchDocumentTemplates().subscribe((res: any) => {
      this.templatesLoading = false;
      this.templates = res.data.template.document;
      this.prepareTemplatesForToSelect();
    });
  }

  prepareTemplatesForToSelect() {
    let temp: any = this.templates;
    this.templateArr = temp.map((x: TemplateDocumentInfo) => ({
      id: x?.id,
      mainTitle: x?.name,
      rightInfo: decodeURIComponent(x?.documentNumber),
    }));
  }

  selectTemplateTo(accTo: selectType) {
    let temp: any = this.templates;
    this.selectedTemplate = temp.filter(
      (v: TemplateDocumentInfo) => v.id.toString() === accTo.id.toString()
    )[0];
    this.selectedTemplateTo = accTo;

    this.mobileOrPnform.controls.template.setValue(
      this.selectedTemplate.documentNumber
    );
    this.mobileOrPnform.controls.mobileOrPn.setValue(
      this.selectedTemplate.documentNumber
    );
    this.mobileOrPnform.controls.name.setValue(this.selectedTemplate.name);

    this.fetchAccountsByMobileOrPn();
  }

  selectAccountTo(accTo: selectType) {
    this.selectedAccountTo = accTo;
    this.mobileOrPnform.controls.accountTo.setValue(accTo.id);
  }

  prepNewTemplate() {
    this.selectedTemplateTo = {
      mainTitle: "Add new template",
      id: null,
      imgId: null,
      imgName: null,
      aboveTitle: null,
      underTitle: null,
      rightInfo: null,
    };
    this.selectedTemplate = null;
    this.mobileOrPnform.controls.template.setValue("new");
    this.mobileOrPnform.controls.name.setValue("");
    this.mobileOrPnform.controls.amount.setValue("");
    this.mobileOrPnform.controls.accountTo.setValue("");
    this.mobileOrPnform.controls.mobileOrPn.setValue("");
    this.mobileOrPnform.controls.otpCode.setValue("");
    this.selectedAccountTo = null;
    this.showAddTemplateBlock = true;
    this.clearErrorMessage();
  }

  checkAccountsOnBlur() {
    if (this.mobileOrPnform.controls.mobileOrPn.valid) {
      this.fetchAccountsByMobileOrPn();
    }
  }

  fetchAccountsByMobileOrPn() {
    this.loading = true;
    this.accountsToLoading = true;
    this.clearErrorMessage();

    let identifier =
      this.mobileOrPnform.controls.template.value === "new"
        ? this.mobileOrPnform.controls.mobileOrPn.value
        : this.mobileOrPnform.controls.template.value;
    this.accountsToSub = this.transfersService
      .fetchAccountsByMobileOrPn(decodeURIComponent(identifier.toString()))
      .subscribe((res: AccountsByMobileOrPnResponse) => {
        this.loading = false;
        this.accountsToLoading = false;
        this.accountErrorMessage = "";

        if (res.errors) {
          this.accountErrorMessage = "NO_ACCOUNTS";
        } else {
          let temp: any = res.data.accounts;
          this.accountsTo = temp.map((account) => ({
            id: account.accountId,
            mainTitle: "******" + account.accountNumber.substring(6),
            rightInfo: account.currency,
          }));
          if (
            this.mobileOrPnform.controls.template.value === "new" &&
            res.data.accounts
          ) {
            this.mobileOrPnform.controls.name.setValue(
              res.data.accounts[0].account
            );
          }
        }
      });
  }

  goToNextStep() {
    if (this.step === 1) {
      this.mobileOrPnform.controls.accountFrom.markAsTouched();
      this.mobileOrPnform.controls.template.markAsTouched();
      this.mobileOrPnform.controls.amount.markAsTouched();
      this.mobileOrPnform.controls.description.markAsTouched();
      this.mobileOrPnform.controls.name.markAsTouched();
      this.mobileOrPnform.controls.accountTo.markAsTouched();

      if (this.showAddTemplateBlock) {
        this.mobileOrPnform.controls.mobileOrPn.markAsTouched();
      }
      if (
        this.mobileOrPnform.controls.amount.value &&
        this.mobileOrPnform.controls.description.value &&
        this.mobileOrPnform.controls.name.value &&
        this.mobileOrPnform.controls.accountTo.value
      ) {
        if (this.mobileOrPnform.controls.template.value === "new") {
          if (this.mobileOrPnform.controls.mobileOrPn.valid) {
            this.prepareTransferMobile();
          }
        } else {
          this.prepareTransferMobile();
        }
      }
    } else if (this.step === 2) {
      this.mobileOrPnform.controls.otpCode.markAsTouched();

      if (this.mobileOrPnform.controls.otpCode.valid) {
        this.makeTransfer(false);
      }
    }
    this.clearErrorMessage();
  }

  goBack() {
    if (this.step !== 1) {
      this.step--;
    }
    this.clearErrorMessage();
  }

  clearErrorMessage() {
    this.errorMessage = "";
  }
  getfullName(){
    var userfullname = localStorage.getItem('fullname');
    this.senderPerson=userfullname

  }
  prepareTransferMobile() {
    this.loading = true;
    this.clearErrorMessage();

    this.guid = Guid.create();
    this.prepareTransferSub = this.transfersService
      .prepareTransfer(
        +this.mobileOrPnform.controls.amount.value,
        this.mobileOrPnform.controls.currency.value,
        "INTERNAL_TRANSFER",
        this.guid.toString()
      )
      .subscribe((res: any) => {
        this.loading = false;
        if (res.errors) {
          this.errorMessage = res.errors[0].extensions.code;
        } else {
          this.prepareTransferData = res.data.prepareTransfer;
          this.step = 2;
        }
      });
  }

  makeTransfer(saveTemplate) {
    this.loading = true;
    this.clearErrorMessage();

    this.makeTransferSub = this.transfersService
      .transferToSomeoneInternalAccountConfirm(
        this.mobileOrPnform.controls.accountFrom.value,
        this.mobileOrPnform.controls.currency.value,
        +this.mobileOrPnform.controls.amount.value,
        this.prepareTransferData.fee,
        this.guid.toString(),
        this.mobileOrPnform.controls.otpCode.value,
        false,
        null,
        this.mobileOrPnform.controls.accountTo.value,
        this.mobileOrPnform.controls.name.value,
        this.mobileOrPnform.controls.description.value,
        this.senderPerson,
        this.ownersCount

      )
      .subscribe((res:any) => {
        if (res.errors) {
          this.loading = false;
          this.goBackToStepOne();
          this.modalService.generateErrorModal(
            "ERROR_CODES." + res.errors[0].extensions.code
          );
        } else {
          if (saveTemplate) {
            this.addTemplate();
          } else {
            this.loading = false;
            // const voucherNumber = res?.data?.addInternalTransfer?.id??res?.data?.addExternalTransfer?.id;
            // this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
            // console.log(res.data.addInternalTransfer.status)

            if( res.data.addInternalTransfer.status === "SUCCESS"
            ){
              this.errorMessage = "";
              const voucherNumber = res?.data?.addInternalTransfer?.id;
              // console.log(voucherNumber)
              this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
            }else if(
               res.data.addInternalTransfer.status === "PENDING"){
              this.errorMessage = "";
              const externalId = res.data.addInternalTransfer.externalId;
              this.modalService.generateModal(`Transaction Initiated  & Pending Approval ! Referance code  : ${externalId}`);
            }

          }
        }
      });
  }

  addTemplate() {
    const template = {
      template: {
        documentNumber:
          this.mobileOrPnform.controls.mobileOrPn.value.toString(),
        isFavorite: this.makeTemplateFavourite,
        name: this.mobileOrPnform.controls.name.value.toString(),
      },
    };

    this.addTemplateSub = this.templateService
      .addMobileOrPnTemplate(template)
      .subscribe((res: any) => {
        this.loading = false;
        const voucherNumber = res?.data?.addInternalTransfer?.id??res?.data?.addExternalTransfer?.id;
        const message = `Successful Transfer! Voucher Number : ${voucherNumber}`;
        this.modalService.generateModal(message??"GENERAL.SUCC_TRANSFER");
      });
  }

  goBackToStepOne() {
    this.step = 1;
    this.selectedAccountFrom = null;
    this.selectedTemplate = null;
    this.selectedTemplateTo = null;
    this.selectedAccountTo = null;
    this.accountsTo = null;
    this.showAddTemplateBlock = null;
    this.mobileOrPnform = null;
    this.initializeForm();
  }

  clickMakeTemplateFavourite() {
    this.makeTemplateFavourite = this.makeTemplateFavourite ? false : true;
    this.mobileOrPnform
      .get("addToFavourites")
      .setValue(this.makeTemplateFavourite);
  }

  makeTransferAndSaveTemplate() {
    this.mobileOrPnform.controls.otpCode.markAsTouched();

    if (this.mobileOrPnform.controls.otpCode.valid) {
      let temp: any = this.templateArr;
      if (
        temp.find(
          (e) => e.rightInfo == this.mobileOrPnform.controls.mobileOrPn.value
        )
      ) {
        this.accountErrorMessage = "ALREADY_EXISTS";
      } else {
        this.makeTransfer(true);
      }
    }
  }

  getSmsCode(event) {
    this.otpCode = event;
    this.mobileOrPnform.controls.otpCode.setValue(event);
  }
  resendSMSCode(event) {
    this.prepareTransferMobile();
  }

  ngOnDestroy(): void {
    if (this.addTemplateSub) {
      this.addTemplateSub.unsubscribe();
    }
    if (this.accountsToSub) {
      this.accountsToSub.unsubscribe();
    }
    if (this.makeTransferSub) {
      this.makeTransferSub.unsubscribe();
    }
    if (this.prepareTransferSub) {
      this.prepareTransferSub.unsubscribe();
    }
  }

  closeClick() {
    this.router.navigateByUrl(
      "/products/accounts/" + this.selectedAccountFrom.mainTitle
    );
  }

  onPopupClosed() {
    this.closeClick();
  }
}
