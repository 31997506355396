import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent {

  public goToaccesBankPage():void{
    const url = "https://www.abbank.co.zm/";
    window.open(url,"_blank");
  }


}
