import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import {
  accountsInfo,
  selectType,
  TemplateTransferInfo,
  TransferInfo,
} from "src/app/shared/models/models";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { TransferBase } from "../transfers-base";
import { TransfersService } from "../transfers.service";
import { TemplatesService } from "../../templates/templates.service";
import { Subscription } from "rxjs";
import { Template } from "@angular/compiler/src/render3/r3_ast";
import { Guid } from "guid-typescript";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-transfer-to-someone",
  templateUrl: "./transfer-to-someone.component.html",
  styleUrls: ["./transfer-to-someone.component.scss"],
})
export class TransferToSomeoneComponent
  extends TransferBase
  implements OnInit, OnDestroy
{
  transferToSomeoneForm: FormGroup;
  formSubscription: Subscription;

  guid: Guid;
  step: number = 1;

  amount = 0;
  description = "";
  otpCode: string = "";
  branchName: string="";
  resendBtnHovered: boolean = false;

  loading: boolean = true;
  prepareLoading: boolean = false;

  loadingFromSelect: boolean = true;
  loadingToSelect: boolean = true;

  // templates
  templates: TemplateTransferInfo;
  templateArr: selectType;
  selectedTemplate: TemplateTransferInfo;
  pickedTemplateId: string;
  showAddTemplateBlock: boolean = false;

  addTemplateSub: Subscription;

  transactionType: string = "EXTERNAL_TRANSFER";

  isBetween: boolean = false;

  showTransferInfoModal = false;

  makeTemplateFavourite = false;

  // variables for select
  defaultAccount: selectType;
  accountsFrom: selectType;
  accountsTo: selectType;
  selectedAccountTo?: selectType;
  selectedAccountFrom?: selectType;

  // transfer
  transferConfirmationSub: Subscription;
  transferConfirmationLoading = false;
  transferConfirmationError: any;
  transferConfirmation: TransferInfo;

  checkAccountSub: Subscription;
  wrongAccountNumbersList = [];
  errorMessage: string = "";

  receiverBank: string = "";
  senderPerson: string = "";
  ownersCount:number=0;
  branches=[];

  isTransferTemplateSelected: boolean;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    private accountsInfoService: AccountsInfoService,
    private functionsService: FunctionsService,
    private transferService: TransfersService,
    private templateService: TemplatesService,
    private modalService: ModalWithTextService,
    private translateService: TranslateService
  ) {
    super();
  }
  ngOnInit(): void {
    this.selectedAccountFrom = null;
    this.selectedAccountTo = null;
    this.errorMessage = null;
    this.initializeForm();
    setTimeout(() => {
      this.accountsInfoService.fetchAccountsInfo();
    });
    this.modalService.modalClosed.subscribe((res: boolean) => {
      if (res === true) {
        this.onPopupClosed();
      }
    });
    super.subscribeToAccountsInfo(this.accountsInfoService);
    this.accountsInfoService.accountsInfoErrorMessage.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = true;
        this.errorMessage = res;
      }
    });
    this.accountsInfoService.accountsInfo.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = false;
        this.prepareAccountsForFromSelect();
      }
    });

    this.fetchTemplates();
    this.checkIfShouldAddAsNewTemplate();
    this.checkIfTemplateIsPicked();
    this.subscribeToForm();
    this.getfullName();
  }

  initializeForm() {
    this.transferToSomeoneForm = this.formBuilder.group({
      accountNumber: new FormControl("", {
        validators: [
          Validators.required,
          // Validators.minLength(23),
          // Validators.maxLength(23),
          this.accountNumberValidation(this.wrongAccountNumbersList),
        ],
      }),
      bank: new FormControl("", [Validators.required]),
      branchName: new FormControl("", [Validators.required]),
      // branch: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      amount: new FormControl(null, [
        Validators.required,
        Validators.max(100000),
      ]),
      description: new FormControl("", [Validators.required]),
      addToFavourites: new FormControl(false),
      otpCode: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });
  }

  subscribeToForm() {
    this.formSubscription = this.transferToSomeoneForm
      .get("accountNumber")
      .valueChanges.subscribe((accNumber) => {
        this.checkAccountInfo(accNumber);
      });
  }

  checkAccountInfo(accNumber) {
    if (
      accNumber.length === 23 &&
      !this.wrongAccountNumbersList.includes(accNumber)
    ) {
      this.checkIfInternalAccount();
      if (this.transactionType === "INTERNAL_TRANSFER") {
        this.loading = true;

        this.checkAccountSub = this.templateService
          .checkAccountNumberInfo(accNumber)
          .subscribe((res: any) => {
            this.loading = false;
            if (
              (res.data["customer"] && res.data["customer"]["firstNameLat"]) ||
              (res.data["customer"] && res.data["customer"]["lastNameLat"])
            ) {
              const name =
                res.data["customer"]["firstNameLat"] +
                " " +
                res.data["customer"]["lastNameLat"];
              this.transferToSomeoneForm.controls.name.setValue(name);
            } else if (res.errors) {
              this.errorMessage = res.errors[0].extensions.code;
              if (!this.wrongAccountNumbersList.includes(accNumber)) {
                this.wrongAccountNumbersList.push(accNumber);
              }
            }
          });
      }
    }
  }




getfullName(){
  var userfullname = localStorage.getItem('fullname');
  this.senderPerson=userfullname

}


  setBankname(){
    const choosenBankCode = this.transferToSomeoneForm.controls.bank.value;
    if(choosenBankCode==='ABBAK'){
      this.receiverBank = "AB BANK ZAMBIA LIMITED"
    }
    else if(choosenBankCode==='BOZBK'){
      this.receiverBank = "BANK OF ZAMBIA"
    }
    else if(choosenBankCode==='ACCES'){
      this.receiverBank = "ACCESS BANK ZAMBIA LIMITED"
    }

    else if(choosenBankCode==='BOCBK'){
      this.receiverBank = "BANK OF CHINA"
    }
    else if(choosenBankCode==='BBZZM'){
      this.receiverBank = "ABSA BANK ZAMBIA PLC"
    }
    else if(choosenBankCode==='CITIB'){
      this.receiverBank = "CITIBANK ZAMBIA LIMITED"
    }
    else if(choosenBankCode==='ECOBK'){
      this.receiverBank = "ECOBANK ZAMBIA LIMITED"
    }
    else if(choosenBankCode==='FABZM'){
      this.receiverBank = "FIRST ALLIANCE BANK ZAMBIA LIMITED"
    }

    else if(choosenBankCode==='INDOZ'){
      this.receiverBank = "INDO-ZAMBIA BANK LIMITED"
    }
    else if(choosenBankCode==='ZICBK'){
      this.receiverBank = "ZAMBIA INDUSTRIAL COMMERCIAL BANK"
    }

    else if(choosenBankCode==='ITBBK'){
      this.receiverBank = "INVESTRUST BANK PLC"
    }
    else if(choosenBankCode==='STBBK'){
      this.receiverBank = "STANBIC BANK ZAMBIA LIMITED"
    }
    else if(choosenBankCode==='SCBZM'){
      this.receiverBank = "STANDARD CHARTERED BANK PLC"
    }
    else if(choosenBankCode==='UBABK'){
      this.receiverBank = "UNITED BANK FOR AFRICA ZAMBIA LIMITED"
    }
    else if(choosenBankCode==='ZNCBK'){
      this.receiverBank = "ZAMBIA NATIONAL COMMERCIAL BANK PLC"
    }
    else if(choosenBankCode==='STBBK'){
      this.receiverBank = "FIRST CAPITAL BANK ZAMBIA LIMITED"
    }
    else if(choosenBankCode==='FNBZM'){
      this.receiverBank = "FIRST NATIONAL BANK"
    }
    else if(choosenBankCode==='ABCBK'){
      this.receiverBank = "Atlas Mara"
    }
    else{
      this.receiverBank = "NO BANK SELECTED"
    }
  }



  checkIfInternalAccount() {
    const choosenBankCode = this.transferToSomeoneForm.controls.bank.value;
    const zambiaBankCode = "ABBAK";
    if (choosenBankCode === zambiaBankCode) {
      this.transactionType = "INTERNAL_TRANSFER";
    } else {
      this.transactionType = "EXTERNAL_TRANSFER";
    }
  }
  checkIfSavingAccount(accNumber) {
    return accNumber.substring(10, 12) === "21";
  }

  prepareAccountsForFromSelect() {
    let temp: any = this.accountsInfo;
    let accsWithoutSaving = temp.filter((element) => {
      let isSavingAccount = this.checkIfSavingAccount(element.accountNumber);
      if (!isSavingAccount) {
        return element;
      }
    });
    if (accsWithoutSaving) {
      this.accountsFrom = accsWithoutSaving.map((x: accountsInfo) => ({
        id: x.accountId,
        mainTitle: x.accountNumber,
        aboveTitle: x.category,
        rightInfo: x.balance.toString() + " " + x.currency,
      }));
    }

    let defTemp: any = this.defaultAccountInfo;
    if (defTemp) {
      if (defTemp.length !== 0) {
        this.defaultAccount = {
          id: this.defaultAccountInfo.accountId,
          imgId: null,
          imgName: "",
          mainTitle: this.defaultAccountInfo.accountNumber,
          aboveTitle: "",
          underTitle: "",
          rightInfo:
            this.defaultAccountInfo.balance.toString() +
            " " +
            this.defaultAccountInfo.currency,
        };
      }
    }
  }

  prepareTemplatesForToSelect() {
    let temp: any = this.templates;
    this.templateArr = temp.map((x: TemplateTransferInfo) => ({
      id: x.id,
      mainTitle: x.name,
      rightInfo: x.beneficiaryAccountNumber,
    }));
    this.prepareTransferLoading = true;
  }

  //templates
  fetchTemplates() {
    this.templateService.fetchAccountTemplates().subscribe((res: any) => {
      this.loading = false;
      this.prepareTransferLoading = false;
      this.templates = res.data.template.transfer;
      this.prepareTemplatesForToSelect();
    });
  }

  checkIfShouldAddAsNewTemplate() {
    if (window.history.state && window.history.state.addNewTemplate) {
      this.prepNewTemplate();
    }
  }
  checkIfTemplateIsPicked() {
    if (window.history.state && window.history.state.pickedTemplateId) {
      this.pickedTemplateId = window.history.state.pickedTemplateId;
    }
  }

  prepNewTemplate() {
    this.selectedAccountTo = {
      mainTitle: "Add new template",
      id: null,
      imgId: null,
      imgName: null,
      aboveTitle: null,
      underTitle: null,
      rightInfo: null,
    };
    this.showAddTemplateBlock = true;
  }

  accountNumberValidation(array: string[]) {
    return (input: FormControl) => {
      const value = input.value;
      return array.includes(value) ? { wrongAccountNumber: true } : null;
    };
  }

  selectAccountFrom(accFrom: selectType) {
    this.selectedAccountFrom = accFrom;
    this.setMaxAmountBasedOnChoosenAccount(this.selectedAccountFrom);
  }
  setMaxAmountBasedOnChoosenAccount(account) {
    const accounts: any = this.accountsInfo;
    const choosenAccount = accounts.find(
      (x) => x.accountNumber === account.mainTitle
    );
    let maxAmount = choosenAccount.balance;
    if (maxAmount > 100000) maxAmount = 100000;
    this.transferToSomeoneForm.controls.amount.setValidators([
      Validators.required,
      Validators.max(maxAmount),
    ]);
  }
  selectAccountTo(accTo: selectType) {
    let temp: any = this.templates;
    this.selectedTemplate = temp.filter(
      (v: TemplateTransferInfo) => v.id.toString() === accTo.id.toString()
    )[0];
    if (this.isTemplateIsSelected(this.selectedTemplate)) {
      this.setTemplateAsSelected(this.selectedTemplate.bankBicCode);
    }
    this.selectedAccountTo = accTo;
    this.showAddTemplateBlock = false;
  }
  isTemplateIsSelected(template): boolean {
    if (
      template &&
      template.beneficiaryAccountNumber &&
      template.bankBicCode &&
      template.type == "TRANSFER"
    ) {
      return true;
    }
    return false;
  }
  setTemplateAsSelected(industryNumber): void {
    this.isTransferTemplateSelected = true;
    this.transferToSomeoneForm.controls.bank.setValue(industryNumber);
  }

  // getEmittedBranchCode(branchCode: string) {
  //   if (branchCode) {
  //     this.transferToSomeoneForm.get("branch").setValue(branchCode);
  //   } else {
  //     this.transferToSomeoneForm.get("branch").setValue(null);
  //   }
  // }
  getEmittedIndustryNumber(bankCode: string) {
    this.transferToSomeoneForm.get("bank").setValue(bankCode);
    // console.log(this.transferToSomeoneForm.controls.bank.value)
    this.getBranches(this.transferToSomeoneForm.controls.bank.value)

  }
getBranches(bankCode){
  this.http.get<any>('assets/jsons/branches.json').subscribe(data => {
    // Populate the numbers array with the data from the JSON file
    // if (data.odd) {
      this.branches = data[bankCode];
    // }
    // console.log(this.branches);
  });
}



  giveInputClasses(input) {
    if (
      this.transferToSomeoneForm.get(input).invalid &&
      this.transferToSomeoneForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  formValidForTransfer() {
    if (
      this.transferToSomeoneForm.controls.amount.valid &&
      this.transferToSomeoneForm.controls.description.valid
    ) {
      return true;
    } else {
      return false;
    }
  }

  formValidForSavingTemplateAndTransfer() {
    if (
      this.transferToSomeoneForm.controls.amount.valid &&
      this.transferToSomeoneForm.controls.description.valid &&
      this.transferToSomeoneForm.controls.name.valid &&
      this.transferToSomeoneForm.controls.accountNumber.valid
    ) {
      return true;
    } else {
      return false;
    }
  }

  prepare() {
    this.checkIfInternalIsBetweenOwn();
    if (this.isBetween) {
      this.transactionType = "TRANSFER_BETWEEN_OWN_ACCOUNTS";
    }

    this.guid = Guid.create();
    super.prepareForTransfer(
      this.transferService,
      +this.transferToSomeoneForm.value.amount,
      this.selectedAccountFrom.rightInfo.substring(
        this.selectedAccountFrom.rightInfo.length - 3
      ),
      this.guid.toString(),
      this.transactionType,
      () => (this.step = 2)
    );
  }

  gotoFirstStep() {
    this.step = 1;
    this.emptySmsCode();
  }

  emptySmsCode() {
    this.transferToSomeoneForm.controls.otpCode.setValue("");
  }

  isDisabledFirstStep() {
    return this.step === 2;
  }

  submitForm(saveTemplate) {
    this.markFormGroupTouched(this.transferToSomeoneForm);
    if (this.selectedTemplate) {
      if (this.step === 1) {
        if (this.formValidForTransfer()) {
          this.checkIfInternalAccount();

          this.prepare();
        }
      } else {
        if (this.transferToSomeoneForm.controls.otpCode.valid) {
          this.transferToSomeoneAccountConfirm();
        }
      }
    } else {
      // save template and then transfer
      if (this.step === 1) {
        if (this.formValidForSavingTemplateAndTransfer()) {
          this.checkIfInternalAccount();

          this.prepare();
        }
      } else {
        if (this.transferToSomeoneForm.controls.otpCode.valid) {
          if (saveTemplate) {
            let temp: any = this.templates;
            let filteredTemp = temp.filter((obj: TemplateTransferInfo) => {
              return (
                obj.beneficiaryAccountNumber ===
                this.transferToSomeoneForm.controls.accountNumber.value
              );
            });
            if (filteredTemp.length > 0) {
              this.loading = false;
              this.errorMessage = "ALREADY_EXISTS";
            } else {
              this.addTemplate();
            }
          } else {
            this.transferToSomeoneAccountConfirm();
          }
        }
      }
    }
  }

  transferToSomeoneAccountConfirm() {
    this.unsubscribeTransferConfirmation();
    this.transferConfirmation = undefined;
    this.errorMessage = "";

    this.transferConfirmationLoading = true;
    this.loading = true;

    if (
      this.transactionType === "INTERNAL_TRANSFER" ||
      this.transactionType === "TRANSFER_BETWEEN_OWN_ACCOUNTS"
    ) {
      this.checkIfInternalIsBetweenOwn();

      this.transferConfirmationSub = this.transferService
        .transferToSomeoneInternalAccountConfirm(
          this.selectedAccountFrom.mainTitle,
          this.selectedAccountFrom.rightInfo.substring(
            this.selectedAccountFrom.rightInfo.length - 3
          ),
          +this.transferToSomeoneForm.value.amount,
          this.prepareTransfer.fee,
          this.guid.toString(),
          this.transferToSomeoneForm.controls.otpCode.value,
          this.isBetween,
          null,
          this.selectedTemplate
            ? this.selectedTemplate.beneficiaryAccountNumber
            : this.transferToSomeoneForm.controls.accountNumber.value,
          this.selectedTemplate
            ? this.selectedTemplate.name
            : this.transferToSomeoneForm.controls.name.value,
          this.transferToSomeoneForm.value.description
        )
        .subscribe((res: any) => {
          this.transferConfirmationLoading = false;
          this.loading = false;

          if (res.data.addInternalTransfer) {
            if (
              res.data.addInternalTransfer.status === "SUCESS" &&
              res.data.addInternalTransfer.id
            ) {
              this.errorMessage = "";
              const voucherNumber = res?.data?.addInternalTransfer?.id;
              this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
            } else if (

              res.data.addInternalTransfer.status === "PENDING" &&
              res.data.addInternalTransfer.externalId
            ){
              this.errorMessage = "";
              const externalId = res?.data?.addExternalTransfer?.externalId;
              this.modalService.generateModal(`Transaction Initiated  & Pending Approval ! Referance code  : ${externalId}`);

            }
            else {
              this.modalService.generateErrorModal("ERROR_CODES.GENERAL_ERROR");
            }
          }
          if (res.errors) {
            this.modalService.generateErrorModal(
              "ERROR_CODES." + res.errors[0].extensions.code
            );
            this.gotoFirstStep();
          }
          this.transferConfirmation =
            res["data"] && res["data"]["addExternalTransfer"]
              ? res["data"]["addExternalTransfer"]
              : {};
        });
    } else if (this.transactionType === "EXTERNAL_TRANSFER") {
      this.setBankname();
      // console.log(this.receiverBank);
      // console.log(this.senderPerson);
      this.transferConfirmationSub = this.transferService
        .transferToSomeoneExternalAccountConfirm(
          this.selectedAccountFrom.mainTitle,
          this.selectedTemplate
            ? this.selectedTemplate.beneficiaryAccountNumber
            : this.transferToSomeoneForm.controls.accountNumber.value,
          this.selectedAccountFrom.rightInfo.substring(
            this.selectedAccountFrom.rightInfo.length - 3
          ),
          +this.transferToSomeoneForm.value.amount,
          this.transferToSomeoneForm.value.description,
          this.prepareTransfer.fee,
          this.selectedTemplate
            ? this.selectedTemplate.name
            : this.transferToSomeoneForm.controls.name.value,
          this.guid.toString(),
          this.transferToSomeoneForm.controls.otpCode.value,
          this.transferToSomeoneForm.controls.bank.value,
          this.transferToSomeoneForm.controls.branchName.value,
          this.senderPerson,
          this.receiverBank,
          this.ownersCount

        )
        .subscribe((res: any) => {

          this.transferConfirmationLoading = false;
          this.loading = false;
          if (res.errors) {
            this.modalService.generateErrorModal(
              "ERROR_CODES." + res.errors[0].extensions.code
            );
            this.gotoFirstStep();
          } else if (res.data) {
            if (
              res.data.addExternalTransfer.status === "SUCCESS" &&
              res.data.addExternalTransfer.id
            ) {
              this.errorMessage = "";
              const voucherNumber = res?.data?.addExternalTransfer?.id;
              this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
            } else if (
              res.data.addExternalTransfer.status === "PENDING" &&
              res.data.addExternalTransfer.externalId

            ){
              this.errorMessage = "";
              const externalId = res?.data?.addExternalTransfer?.externalId;
              this.modalService.generateModal(`Transaction Initiated  & Pending Approval ! Referance code  : ${externalId}`);
            }

            else {
              this.modalService.generateErrorModal("ERROR_CODES.GENERAL_ERROR");
            }
          }
          this.transferConfirmation =
            res["data"] && res["data"]["addExternalTransfer"]
              ? res["data"]["addExternalTransfer"]
              : {};
        });
    }
  }

  checkIfInternalIsBetweenOwn() {
    let temp: any = this.accountsFrom;
    if (this.selectedTemplate?.beneficiaryAccountNumber) {
      temp.forEach((element) => {
        if (element.id === this.selectedTemplate.beneficiaryAccountNumber) {
          this.isBetween = true;
        }
      });
    } else if (this.transferToSomeoneForm.controls.accountNumber.value) {
      temp.forEach((element) => {
        if (
          element.id === this.transferToSomeoneForm.controls.accountNumber.value
        ) {
          this.isBetween = true;
        }
      });
    }
  }

  unsubscribeTransferConfirmation() {
    if (this.transferConfirmationSub) {
      this.transferConfirmationSub.unsubscribe();
    }
  }

  resetTransfer() {
    this.amount = 0;
    this.description = "";
    this.step = 1;
  }

  clickMakeTemplateFavourite() {
    this.makeTemplateFavourite = this.makeTemplateFavourite ? false : true;
    this.transferToSomeoneForm
      .get("addToFavourites")
      .setValue(this.makeTemplateFavourite);
  }

  addTemplate() {
    this.loading = true;
    const template = {
      template: {
        beneficiaryAccountNumber:
          this.transferToSomeoneForm.controls.accountNumber.value,
        name: this.transferToSomeoneForm.controls.name.value,
        isFavorite: this.transferToSomeoneForm.controls.addToFavourites.value,
      },
    };
    this.addTemplateSub = this.templateService
      .addTransferTemplate(template)
      .subscribe((res) => {
        if (!res.errors) {
          this.transferToSomeoneAccountConfirm();
        } else {
          this.loading = false;
          this.errorMessage = res.errors[0]["extensions"]["code"];
          this.gotoFirstStep();
        }
      });
  }

  getSmsCode(event) {
    this.otpCode = event;
    this.transferToSomeoneForm.controls.otpCode.setValue(event);
  }
  resendSMSCode(event) {
    this.prepare();
  }

  checkNotEmpty() {
    if (this.showAddTemplateBlock) {
      if (
        this.transferToSomeoneForm.controls.amount.value &&
        this.transferToSomeoneForm.controls.accountNumber.valid &&
        this.transferToSomeoneForm.controls.name.value &&
        this.transferToSomeoneForm.controls.bank.value
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        this.transferToSomeoneForm.controls.amount.value &&
        this.transferToSomeoneForm.controls.bank.value
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  ngOnDestroy() {
    super.unsubscribeAccountsInfo();
    super.unsubscribePrepareTransfer();
    this.unsubscribeTransferConfirmation();
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.checkAccountSub) {
      this.checkAccountSub.unsubscribe();
    }
    if (this.addTemplateSub) {
      this.addTemplateSub.unsubscribe();
    }
  }

  closeClick() {
    this.router.navigateByUrl(
      "/products/accounts/" + this.selectedAccountFrom.mainTitle
    );
  }

  onPopupClosed() {
    this.closeClick();
  }
}
