import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { TemplatesService } from "../../templates.service";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-edit-account-number-template",
  templateUrl: "./edit-account-number-template.component.html",
  styleUrls: ["./edit-account-number-template.component.scss"],
})
export class EditAccountNumberTemplateComponent implements OnInit, OnDestroy {
  template;

  accountTemplateForm: FormGroup;

  editTemplate$: Subscription;
  toggleFavourite$: Subscription;
  deleteTemplate$: Subscription;

  loading = false;

  errorMessage = "";

  questionText: string = "Are you sure that you want to delete this template?";

  showDeletePopup = false;

  constructor(
    private templateService: TemplatesService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.initializAccountTemplateForm();

    if (window.history.state.template) {
      this.template = window.history.state.template;
      this.loadTemplate();
    } else {
      this.router.navigateByUrl("/templates");
    }
  }

  initializAccountTemplateForm() {
    this.accountTemplateForm = this.formBuilder.group({
      accountNumber: new FormControl(
        { value: "", disabled: true },
        {
          validators: [
            Validators.required,
            Validators.minLength(23),
            Validators.maxLength(23),
          ],
        }
      ),
      name: new FormControl("", { validators: [Validators.required] }),
      isFavorite: new FormControl(""),
    });
  }

  loadTemplate() {
    this.accountTemplateForm.controls.accountNumber.setValue(
      this.template.beneficiaryAccountNumber
    );
    this.accountTemplateForm.controls.name.setValue(this.template.name);
    this.accountTemplateForm.controls.isFavorite.setValue(
      this.template.isFavorite
    );
  }

  submitForm() {
    this.markFormGroupTouched(this.accountTemplateForm);

    if (this.accountTemplateForm.valid) {
      this.editTemplate();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  editTemplate() {
    this.loading = true;

    const templateToGive = {
      template: {
        id: this.template.id,
        beneficiaryAccountNumber: this.template.beneficiaryAccountNumber,
        isFavorite: this.accountTemplateForm.controls.isFavorite.value,
        name: this.accountTemplateForm.controls.name.value,
      },
    };

    this.editTemplate$ = this.templateService
      .updateAccountTemplate(templateToGive)
      .subscribe((res) => {
        this.loading = false;

        if (!res.errors) {
          this.router.navigateByUrl("/templates");
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  toggleFavourite() {
    this.loading = true;

    const templateToGive = {
      template: {
        id: this.template.id,
        beneficiaryAccountNumber: this.template.beneficiaryAccountNumber,
        isFavorite: !this.accountTemplateForm.controls.isFavorite.value,
        name: this.template.name,
      },
    };

    this.toggleFavourite$ = this.templateService
      .updateAccountTemplate(templateToGive)
      .subscribe((res) => {
        this.loading = false;

        if (!res.errors) {
          this.accountTemplateForm.controls.isFavorite.setValue(
            !this.accountTemplateForm.controls.isFavorite.value
          );
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  deleteTemplatePrompt() {
    this.showDeletePopup = true;
  }

  deleteTemplate() {
    this.showDeletePopup = false;
    this.loading = true;
    this.deleteTemplate$ = this.templateService
      .deleteTemplate({ templateId: this.template.id })
      .subscribe((res: any) => {
        this.loading = false;

        if (res.data.deleteTemplate) {
          this.router.navigateByUrl("/templates");
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  giveInputClasses(input) {
    if (
      this.accountTemplateForm.get(input).invalid &&
      this.accountTemplateForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  ngOnDestroy(): void {
    if (this.editTemplate$) {
      this.editTemplate$.unsubscribe();
    }
    if (this.toggleFavourite$) {
      this.toggleFavourite$.unsubscribe();
    }
    if (this.deleteTemplate$) {
      this.deleteTemplate$.unsubscribe();
    }
  }
}
