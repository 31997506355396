import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { TransfersService } from "src/app/core/transfers/transfers.service";
import { TemplatesService } from "../../templates.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-mobile-or-pn-template",
  templateUrl: "./add-mobile-or-pn-template.component.html",
  styleUrls: ["./add-mobile-or-pn-template.component.scss"],
})
export class AddMobileOrPnTemplateComponent implements OnInit {
  step = 1;

  form: FormGroup;
  formSub: Subscription;
  fetchAccountsSub: Subscription;
  saveTemplateSub: Subscription;

  loading: boolean = false;
  errorMessage: string = "";

  constructor(
    private transfersService: TransfersService,
    private templateService: TemplatesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.formSub = this.form.controls.mobileOrPn.valueChanges.subscribe(
      (value) => {
        this.backToStepOne();
      }
    );
  }

  initializeForm() {
    this.form = new FormGroup({
      mobileOrPn: new FormControl("", [Validators.required]),
      name: new FormControl("", Validators.required),
    });
  }

  checkIdentifier() {
    this.form.controls.mobileOrPn.markAsTouched();
    if (this.form.controls.mobileOrPn.valid) {
      this.fetchAccountsByMobileOrPn();
    }
  }

  fetchAccountsByMobileOrPn() {
    this.loading = true;
    this.errorMessage = "";
    this.fetchAccountsSub = this.transfersService
      .fetchAccountsByMobileOrPn(this.form.controls.mobileOrPn.value.toString())
      .subscribe((res: any) => {
        this.loading = false;
        if (res.errors) {
          this.errorMessage = res.errors[0].extensions.code;
        } else {
          if (res.data && res.data.accounts && !res.data.accounts.length) {
            this.errorMessage = "NO_ACCOUNTS";
          } else {
            this.step = 2;
            if (res.data.accounts[0].account) {
              this.form.controls.name.setValue(res.data.accounts[0].account);
            }
          }
        }
      });
  }

  backToStepOne() {
    this.step = 1;
    this.form.controls.name.setValue("");
  }

  submitForm() {
    this.markFormGroupTouched(this.form);

    if (this.form.valid) {
      this.addTemplate();
    }
  }

  addTemplate() {
    this.loading = true;
    this.errorMessage = "";
    const template = {
      template: {
        documentNumber: this.form.controls.mobileOrPn.value.toString(),
        isFavorite: false,
        name: this.form.controls.name.value.toString(),
      },
    };

    this.saveTemplateSub = this.templateService
      .addMobileOrPnTemplate(template)
      .subscribe((res: any) => {
        this.loading = false;
        if (res.errors) {
          this.errorMessage = res.errors[0].extensions.code;
        } else {
          this.router.navigateByUrl("/templates");
        }
      });
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  giveInputClasses(input) {
    if (this.form.get(input).invalid && this.form.get(input).touched) {
      return "has-error";
    }
  }

  ngOnDestroy(): void {
    this.formSub.unsubscribe();
    if (this.fetchAccountsSub) {
      this.fetchAccountsSub.unsubscribe();
    }
    if (this.saveTemplateSub) {
      this.saveTemplateSub.unsubscribe();
    }
  }
}
