<div class="credo-page">
    <main>
        <div class="card transfers-card">
            <div class="card-header">
                <h1 class="card-title">{{ "TRANSFERS.TRANSFERS" | translate }}</h1>

                <div class="controls">
                    <div class="row-sm">
                        <div class="col">
                            <button class="btn btn-sm btn-secondary" [routerLink]="['/templates']">
              {{ "TEMPLATES_C.MY_TEMPLATES" | translate }}
              </button>
                        </div>
                        <div class="col">
                            <button class="btn btn-sm btn-primary btn-add" [routerLink]="['/templates/add']">
              {{ "TEMPLATES_C.ADD_NEW_TEMP" | translate }}
              </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="items-grid">
                    <a class="grid-item grid-item-button" [routerLink]="['/transfer/to-own-account']" *ngIf="checkIfAccountsMoreThanOne()">
                        <i _ngcontent-woa-c7="" class="icon-circle i-user"></i>
                        <span class="name">{{ "TRANSFERS.BETWEEN_OWN" | translate }}</span>
                    </a>
                    <a class="grid-item grid-item-button" [routerLink]="['/transfer/by-mobile-or-pn']">
                        <i _ngcontent-woa-c7="" class="icon-circle i-mobile-pn"></i>
                        <span class="name">{{"TRANSFERS.TO_SOMEONE_BY_MOBILE_ID" | translate}}</span
            >
          </a>
          <a
            class="grid-item grid-item-button"
            [routerLink]="['/transfer/to-someones-account']"
          >
            <i _ngcontent-woa-c7="" class="icon-circle i-users"></i>
            <span class="name">{{ "TRANSFERS.TO_SOMEONE" | translate }}</span>
                    </a>

                    <!-- <a
            class="grid-item grid-item-button"
            [routerLink]="['/transfer/utility']"
          >
            <i _ngcontent-woa-c7="" class="icon-circle i-bill-grey"></i>
            <span class="name">{{ "TRANSFERS.UTILITY" | translate }}</span>
          </a> -->

                </div>
            </div>
        </div>
        <p style="color: red;">Please Note That You Can Only Transfer An Amount of Up to ZMW 100,000.00, For All Transfers Above ZMW 100,000.00, and International Transfers, Kindly Visit Your Nearest Branch</p>
    </main>
</div>