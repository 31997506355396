import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-range-picker",
  templateUrl: "./range-picker.component.html",
  styleUrls: ["./range-picker.component.scss"],
})
export class RangePickerComponent implements OnInit {
  @Input() min: number;
  @Input() max: number;
  @Input() value: number;
  @Input() step: number;
  @Input() type: string;

  @Output() getValue = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {
    if(!this.min || !this.max){
      this.min=this.value;
      this.max=this.value;
    }
  }

  valueChange(event) {
    this.getValue.emit(event);
  }
}
