import { Component, OnInit, OnDestroy } from "@angular/core";
import { CustomerInfoService } from "../../shared/services/customer-info.service";
import { CustomerInfoBase } from "src/app/shared/component-bases/customer-info-base";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { Subscription } from "rxjs";
import { CoreService } from "src/app/shared/services/core.service";
import { Router } from "@angular/router";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent
  extends CustomerInfoBase
  implements OnInit, OnDestroy {
  errorMessage: string = "";
  loading: boolean = false;
  changePasswordSub: Subscription;

  //#region Variables
  public changePasswordOpen: boolean = false;
  public changePasswordForm: FormGroup;

  //#endregion

  //#region LifeCycle hook

  constructor(
    private customerInfoService: CustomerInfoService,
    private changePasswordFormBuilder: FormBuilder,
    public sharedFunctions: FunctionsService,
    private coreService: CoreService,
    private router: Router,
    private modalService: ModalWithTextService,
    private datePipe:DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.modalService.modalClosed.subscribe((res: boolean) => {
      if (res === true) {
        this.onPopupClosed();
      }
    });
    this.loadCustomerInfo();
    this.changePasswordFormInit();
    this.giveCustomValidators();
  }

  //#region Methods

  //Show or Hide Dropdown
  public toggleChangePassword(): void {
    if (this.changePasswordOpen) {
      this.changePasswordOpen = false;
    } else {
      this.changePasswordOpen = true;
    }
  }

  // Calling Parent's Method
  protected loadCustomerInfo(): void {
    super.loadCustomerInfo(this.customerInfoService);
  }

  public get customerBirthDate(){
    return this.datePipe.transform(this.customerInfo.birthDate,'dd/MM/yyyy');
  }
  public get NRCNumber(){
    return decodeURIComponent(this.customerInfo.personalN);
  }

  private changePasswordFormInit(): void {
    this.changePasswordForm = this.changePasswordFormBuilder.group({
      oldPassword: ["", Validators.required],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/(?=.*[A-Z])(?=.*\d)(?!.*[ა-ჰ]).{8,}/),
        ],
      ],
      repeatPassword: [""],
    });
  }

  // Costum Validator - check if repeated password dont match
  private matchPassword(repeatPassword: AbstractControl): any {
    return (newPassword: FormControl) => {
      return newPassword.value !== repeatPassword.value
        ? { passwordsDontMatch: true }
        : null;
    };
  }

  // Set Costum Validator for repeat password
  private giveCustomValidators(): void {
    this.changePasswordForm.controls.repeatPassword.setValidators([
      this.matchPassword(this.changePasswordForm.controls.newPassword),
      Validators.required,
    ]);
  }

  public onSubmit(): void {
    this.markFormGroupTouched(this.changePasswordForm);

    if (this.changePasswordForm.valid) {
      this.changePassword();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  changePassword() {
    this.loading = true;
    this.changePasswordForm.disable();

    const passwordsData = {
      data: {
        confirmNewPassword: this.changePasswordForm.controls.repeatPassword
          .value,
        newPassword: this.changePasswordForm.controls.newPassword.value,
        oldPassword: this.changePasswordForm.controls.oldPassword.value,
      },
    };

    this.changePasswordSub = this.coreService
      .changeTemporaryPassword(passwordsData)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.changePasswordForm.enable();
          if (res.data?.changePassword) {
            this.modalService.generateModal(
              "SETTINGS_P.CHANGE_P.PASSWORD_CHANGED"
            );
            this.changePasswordFormInit();
          } else if (res.errors || !res.data?.changePassword) {
            this.modalService.generateErrorModal(
              `ERROR_CODES.${
                res.errors[0].extensions.code
                  ? res.errors[0].extensions.code
                  : "GENERAL_ERROR"
              }`
            );
          }
        },
        (err: any) => {
          this.loading = false;
          this.modalService.generateModal(`ERROR_CODES.GENERAL_ERROR`);
        }
      );
  }

  //#endregion

  ngOnDestroy(): void {
    super.unsubscribe();

    if (this.changePasswordSub) {
      this.changePasswordSub.unsubscribe();
    }
  }

  // popup
  closeClick() {
    this.router.navigateByUrl("/");
  }

  onPopupClosed() {
    this.closeClick();
  }
}
