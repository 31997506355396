<div class="months">
  <div
    class="month"
    *ngFor="let date of monthsList"
    [ngClass]="giveClassAndCheck(date)"
    (click)="pickMonth(date)"
  >
    {{ date.value | date: "MMM":undefined:translate.currentLang }}
  </div>
</div>
