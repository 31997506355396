import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingComponent } from "./landing.component";
import { LandingRoutingModule } from "./landing-routing-module";
import { AuthComponent } from "./auth/auth.component";
import { LandingHeaderComponent } from "./landing-header/landing-header.component";
import { LandingFooterComponent } from "./landing-footer/landing-footer.component";
import { RegisterComponent } from "./register/register.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from '../shared/shared.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TwoFaComponent } from './auth/two-fa/two-fa.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

@NgModule({
  declarations: [
    LandingComponent,
    AuthComponent,
    LandingHeaderComponent,
    LandingFooterComponent,
    RegisterComponent,
    ResetPasswordComponent,
    TwoFaComponent,
    TermsOfUseComponent
  ],
  imports: [CommonModule, LandingRoutingModule, ReactiveFormsModule, SharedModule],
})
export class LandingModule {}
