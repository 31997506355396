import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss']
})
export class LandingFooterComponent {

  today:Date=new Date();

  public openPdf(fileName): void {
    let baseUrl = "../../assets/files/";
    if (fileName === "FAQ") {
      window.open(baseUrl+"FAQ.pdf", '_blank');
    } else if(fileName=="TERMS") {
      window.open(baseUrl+"Terms and Conditions.pdf",'_blank');
    }
  }
}
