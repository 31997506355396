<h2 class="no-data" *ngIf="!overdraft ">
  {{ "GENERAL.NO_DATA" | translate }}
</h2>
<!-- && !loading -->
<!-- [ngClass]="{ loading: loading }" -->
<ul  class="table">
  <!-- && !loading -->
  <ng-container *ngIf="overdraft">
    <li class="t-row t-head">
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.OVERDRAFT.AMOUNT_GRANTED" | translate }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.OVERDRAFT.AMOUNT_USED" | translate }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.OVERDRAFT.AMOUNT_LEFT" | translate }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.OVERDRAFT.CURRENCY" | translate }}</span>
      </div>
    </li>
    <li class="t-row">
      <div class="t-cell card-cell">
        <div class="cell-name">
          <span>{{ "PRODUCTS.OVERDRAFT.AMOUNT_GRANTED" | translate }}</span>
        </div>
        <span>{{ overdraft.amountGranted | moneyFormat }}</span>
      </div>
      <div class="t-cell card-cell">
        <div class="cell-name">
          <span>{{ "PRODUCTS.OVERDRAFT.AMOUNT_USED" | translate }}</span>
        </div>
        <span>{{ overdraft.amountUsed | moneyFormat }}</span>
      </div>
      <div class="t-cell card-cell">
        <div class="cell-name">
          <span>{{ "PRODUCTS.OVERDRAFT.AMOUNT_LEFT" | translate }}</span>
        </div>
        <span>{{ (overdraft.amountGranted - overdraft.amountUsed) | moneyFormat }}</span>
      </div>
      <div class="t-cell card-cell">
        <div class="cell-name">
          <span>{{ "PRODUCTS.OVERDRAFT.CURRENCY" | translate }}</span>
        </div>
        <span>{{ overdraft.currency }}</span>
      </div>
    </li>
  </ng-container>
</ul>
