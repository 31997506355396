<app-timer></app-timer>

<app-header
  [menuIsOpen]="menuIsOpen"
  (toggleMenu)="menuIsOpen = $event"
></app-header>

<div class="credo-main-container">
  <app-menu
    [menuIsOpen]="menuIsOpen"
    (toggleMenu)="menuIsOpen = $event"
  ></app-menu>

  <div>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
