import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { clientType } from 'src/app/shared/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  public returnDateOfBirthPlaceHolder(client,control:AbstractControl):string{
    let prefix = "LANDING.";
    let placeHolder = "DATE_OF_BIRTH";
    if(control.invalid && control.touched) placeHolder = `${placeHolder}_INV`;
    if(client == clientType.Entity) placeHolder = `LEGAL_${placeHolder}`;

    return prefix+placeHolder;
  }
  public returnNRCPlaceHolder(client,control:AbstractControl):string{
    let prefix = "LANDING.";
    let placeHolder = "PERS_NUM";
    if(control.invalid && control.touched) placeHolder = `${placeHolder}_INV`;
    if(client == clientType.Entity) placeHolder = `LEGAL_${placeHolder}`;

    return prefix+placeHolder;
  }
}
