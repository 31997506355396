import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  showMe:boolean=false;
  showMe1:boolean=false;
  showMe2:boolean=false;
  showMe3:boolean=false;
  showMe4:boolean=false;
  showMe5:boolean=false;
  showMe6:boolean=false;
  showMe7:boolean=false;
  showMe8:boolean=false;
  showMe9:boolean=false;
  showMe10:boolean=false;
  showMe11:boolean=false;
  showMe12:boolean=false;
  showMe13:boolean=false;
  showMe14:boolean=false;
  showMe15:boolean=false;
  showMe16:boolean=false;
  showMe17:boolean=false;
  showMe18:boolean=false;
  showMe19:boolean=false;
  showMe20:boolean=false;
  showMe21:boolean=false;
  showMe22:boolean=false;
  showMe23:boolean=false;
  showMe24:boolean=false;
  showMe25:boolean=false;
  showMe26:boolean=false;
  showMe27:boolean=false;
  showMe28:boolean=false;
  showMe29:boolean=false;
  showMe30:boolean=false;
  showMe31:boolean=false;
  showMe32:boolean=false;
  showMe33:boolean=false;


  constructor() { }

  ngOnInit(): void {
  }

  modal(){
this.showMe=!this.showMe
  }

  modal1(){
    this.showMe1=!this.showMe1
}
modal2(){
  this.showMe2=!this.showMe2
}
modal3(){
  this.showMe3=!this.showMe3
}

modal4(){
  this.showMe4=!this.showMe4
}

modal5(){
  this.showMe5=!this.showMe5
}
modal6(){
  this.showMe6=!this.showMe6
}

modal7(){
  this.showMe7=!this.showMe7
}

modal8(){
  this.showMe8=!this.showMe8
}

modal9(){
  this.showMe9=!this.showMe9
}

modal10(){
  this.showMe10=!this.showMe10
}

modal11(){
  this.showMe11=!this.showMe11
}

modal12(){
  this.showMe12=!this.showMe12
}

modal13(){
  this.showMe13=!this.showMe13
}

modal14(){
  this.showMe14=!this.showMe14
}

modal15(){
  this.showMe15=!this.showMe15
}

modal16(){
  this.showMe16=!this.showMe16
}

modal17(){
  this.showMe17=!this.showMe17
}

modal18(){
  this.showMe18=!this.showMe18
}

modal19(){
  this.showMe19=!this.showMe19
}

modal20(){
  this.showMe20=!this.showMe20
}

modal21(){
  this.showMe21=!this.showMe21
}
modal22(){
  this.showMe22=!this.showMe22
}

modal23(){
  this.showMe23=!this.showMe23
}

modal24(){
  this.showMe24=!this.showMe24
}

modal25(){
  this.showMe25=!this.showMe25
}

modal26(){
  this.showMe26=!this.showMe26
}

modal27(){
  this.showMe27=!this.showMe27
}

modal28(){
  this.showMe28=!this.showMe28
}

modal29(){
  this.showMe29=!this.showMe29
}

modal30(){
  this.showMe30=!this.showMe30
}

modal31(){
  this.showMe31=!this.showMe31
}

modal32(){
  this.showMe32=!this.showMe32
}

modal33(){
  this.showMe33=!this.showMe33
}


}
