import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, BehaviorSubject } from "rxjs";
import {
  loanInfo,
  loanInfoResponse,
  accountsInfo,
  accountsInfoResponse,
} from "src/app/shared/models/models";
import { LoanInfoService } from "src/app/shared/services/loan-info.service";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";
import { eventNames } from "process";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent extends accountsInfoBase implements OnInit {
  loading = true;
  activeTab = "";
  currentItem;
  data;

  accountsSub: Subscription;

  // loans
  loanSub: Subscription;
  loanErrorMessage: any = "";
  loans: loanInfo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loanInfoservic: LoanInfoService,
    private accountsInfoService: AccountsInfoService
  ) {
    super();
  }

  routerSub: Subscription;

  ngOnInit(): void {
    this.fetchAccounts()
    this.catchRouteParams();
    this.detectRoute();

    this.onloadTnx();
  }

  fetchCurrentProductList() {
    if (this.activeTab === "accounts") {
      this.fetchAccounts();
    } else if (this.activeTab === "loans" || this.activeTab === "loan") {
      if (!this.loans) {
        this.fetchLoans();
      } else {
        this.loading = false;
      }
    }
  }

  fetchAccounts() {
    if (!this.accountsInfo) {
      this.accountsInfoService.fetchAccountsInfo();
      super.subscribeToAccountsInfo(this.accountsInfoService);
    }
    this.accountsSub = this.accountsInfoService.accountsInfoLoading.subscribe(
      (res) => {
        if (!res) {
          this.loading = res;
        }
      }
    );
  }

  fetchLoans() {
    this.loading = true;
    this.loanSub = this.loanInfoservic
      .loanInfoQuery()
      .subscribe((res: loanInfoResponse) => {
        if (res.errors) {
          this.loanErrorMessage = res.errors[0].message;
        }

        if (res.data) {
          this.loans = res.data.loans;
        }
        this.loading = false;
      });
  }


  onloadTnx() {
    // this.activeTab = this.route.snapshot.params.type;
    // this.data =this.accountsInfoService.accountsInfo

    // console.log(this.data)
    // if (this.route.snapshot.params.id) {
    //   this.currentItem = this.route.snapshot.params.id;
    //   console.log(this.currentItem);
    // } else {
    //   this.currentItem = "";
    // }
    // this.fetchCurrentProductList();
  }

  catchRouteParams() {
    this.activeTab = this.route.snapshot.params.type;

    if (this.route.snapshot.params.id) {
      this.currentItem = this.route.snapshot.params.id;

    } else {
      this.currentItem = "";
    }
    this.fetchCurrentProductList();
  }

  detectRoute() {
    this.routerSub = this.router.events.subscribe((val) => {
      this.catchRouteParams();
    });
  }

  ngOnDestroy(): void {
    super.unsubscribeAccountsInfo();
  }
}
