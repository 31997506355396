<div class="card home-products-card">
    <!-- Products box header -->
    <div class="card-header bg-img--cover" style="background-image: url(/assets/images/0/prod_head.png)">
        <div *ngIf="
      generalBalanceInfo &&
      !generalBalanceInfoLoading &&
      !generalBalanceInfoErrorMessage
    ">
            <div>
                <span>{{ "HOME.PRODUCT_BOX.kwacha" | translate }}</span>
                <b>{{ generalBalanceInfo.totalBalanceZMW | moneyFormat }}</b>
            </div>

            <div [hidden]="generalBalanceInfo.totalBalanceUSD==0">
                <span>{{ "HOME.PRODUCT_BOX.Dollar" | translate }}</span>
                <b>{{ generalBalanceInfo.totalBalanceUSD | moneyFormat }}</b>
            </div>

            <div [hidden]="generalBalanceInfo.totalBalanceEUR==0">
                <span>{{ "HOME.PRODUCT_BOX.Euro" | translate }}</span>
                <b>{{ generalBalanceInfo.totalBalanceEUR | moneyFormat }}</b>
            </div>
            <div>
                <span>{{ "HOME.PRODUCT_BOX.MY_LIABILITIES" | translate }}</span>
                <b>{{ generalBalanceInfo.totalDebt | moneyFormat }}</b>
            </div>
        </div>

        <div class="three-dot-loader" *ngIf="generalBalanceInfoLoading"></div>
    </div>

    <!-- List of products appear here -->
    <div class="card-body home-products-list">
        <app-account-list-mini *ngIf="activeTab === activeTabType.Accounts"></app-account-list-mini>
        <app-loan-list-mini *ngIf="activeTab === activeTabType.Loans"></app-loan-list-mini>
        <app-deposits-list-mini *ngIf="activeTab === activeTabType.Deposits">
        </app-deposits-list-mini>
    </div>

    <!-- Tabs to switch between products -->
    <div class="card-footer">
        <button [ngClass]="{ selected: activeTab === activeTabType.Accounts }" (click)="setActiveTab('Accounts')">
    <i class="icon-circle i-cards">
      <app-tooltip
        class="tooltip-box"
        [tooltipText]="'PRODUCTS.ACCOUNTS' | translate"
      >
      </app-tooltip>
    </i>
  </button>
        <button [ngClass]="{ selected: activeTab === activeTabType.Loans }" (click)="setActiveTab('Loans')">
    <i class="icon-circle i-accounts">
      <app-tooltip
        class="tooltip-box"
        [tooltipText]="'PRODUCTS.LOANS' | translate"
      >
      </app-tooltip>
    </i>
  </button>
        <button [ngClass]="{ selected: activeTab === activeTabType.Deposits }" (click)="setActiveTab('Deposits')">
    <i class="icon-circle i-savings">
      <app-tooltip
        class="tooltip-box"
        [tooltipText]="'PRODUCTS.DEPOSITS.DEPOSITS' | translate"
      >
      </app-tooltip>
    </i>
  </button>
    </div>
</div>