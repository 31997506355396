import { Component, OnInit } from "@angular/core";
import { TakeALoanService } from "./take-a-loan.service";
import data from "src/assets/jsons/loan-compilation.json";
import { selectType } from "src/app/shared/models/models";
import { CustomerInfoService } from "src/app/shared/services/customer-info.service";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { CustomerInfoBase } from "src/app/shared/component-bases/customer-info-base";

@Component({
  selector: "app-take-a-loan",
  templateUrl: "./take-a-loan.component.html",
  styleUrls: ["./take-a-loan.component.scss"],
})
export class TakeALoanComponent extends CustomerInfoBase implements OnInit {

  loading: boolean = false;
  selectLoanTypeLoading: boolean = true;

  selectedLoanType: selectType;
  loanTypes;
  loanTypeArray: selectType;
  selectedLoanTypeFiltered;

  amount: number = 1000;
  duration: number = 4;
  minAmount: number;
  maxAmount: number;
  mortgage: boolean = false;
  maxMonth: number;
  aplicationFee: number;
  rate: number;

  monthly: number = 0;

  errorMessage: string = "";
  success: boolean = false;

  constructor(
    private takeALoanService: TakeALoanService,
    private customerInfoService: CustomerInfoService,
    private modalService: ModalWithTextService
  ) {
    super();
  }

  ngOnInit() {
    this.customerInfo = null;
    super.loadCustomerInfo(this.customerInfoService);
    this.loanTypes = JSON.parse(JSON.stringify(data.loanTypes));
  }

  prepMail() {
    this.loading = true;
    this.errorMessage = "";
    if (this.isLoanInfo) {
      const data = {
        amount: +this.amount,
        fullName: this.customerInfo.firstNameLat + " " + this.customerInfo.lastNameLat,
        period: +this.duration,
        personalNumber: decodeURIComponent(this.customerInfo.personalN),
        phoneNumber: this.customerInfo.mobile,
        to: this.customerInfo.email,
      };
      this.sendMail(data);
    } else {
      this.errorMessage = "CUSTOMER_INFO_ERROR";
      this.loading = false;
    }
  }
  isLoanInfo(): boolean {
    if (
      this.customerInfo.firstNameLat &&
      this.customerInfo.lastNameLat &&
      this.duration &&
      this.customerInfo.personalN &&
      this.customerInfo.mobile
    ) {
      return true;
    } else {
      return false;
    }
  }

  sendMail(data) {
    this.takeALoanService.sendMail(data).subscribe((res: any) => {
      if (res.errors) {
        this.errorMessage = res.errors[0].extensions.code;
      } else {
        this.modalService.generateModal("LOANS_P.SUCCESS_SEND");
      }
      this.loading = false;
    });
  }

  setParams() {
    this.aplicationFee = this.selectedLoanTypeFiltered.insuranceFee;
    this.rate = this.selectedLoanTypeFiltered.annualRate;
    this.calculateLoan();
  }

  calculateLoan() {
    this.monthly = this.takeALoanService.calculateLoan(
      this.amount,
      this.rate,
      this.duration,
      this.aplicationFee
    );
  }

  getdisburementFee(): number {
    if (!this.selectedLoanTypeFiltered?.disbursementFee) return 0;
    const fee =
      (this.amount / 100) * this.selectedLoanTypeFiltered.disbursementFee;
    return fee;
  }
  setAmount(amo) {
    this.amount = amo;
    const loanType = this.getLoanTypeBasedOnAmount(this.amount);
    this.selectChoosenLoanType(loanType);
  }
  setPeriod(period) {
    this.duration = period;
    this.setParams();
  }
  selectChoosenLoanType(loanType) {
    this.selectedLoanType = loanType;
    this.selectedLoanTypeFiltered = loanType;
    this.setParams();
  }
  getLoanTypeBasedOnAmount(amount: number) {
    return this.loanTypes.find((x) => x.min <= amount && x.max >= amount);
  }
}
