import { Component, OnInit } from "@angular/core";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";

@Component({
  selector: "app-account-list-mini",
  templateUrl: "./account-list-mini.component.html",
  styleUrls: ["./account-list-mini.component.scss"],
})
export class AccountListMiniComponent extends accountsInfoBase
  implements OnInit {
  constructor(private accountsInfoService: AccountsInfoService) {
    super();
  }

  ngOnInit(): void {
    this.accountsInfoService.fetchAccountsInfo();

    super.subscribeToAccountsInfo(this.accountsInfoService);

  }

  ngOnDestroy(): void {
    super.unsubscribeAccountsInfo();
  }
}
