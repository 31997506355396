<header class="credo-header">
    <a class="credo-logo" routerLink="/" (click)="toggleMenuState()"><img src="assets/images/logo.png" class="Credo logo" /></a>
    <div>
        <a class="user-wrap" *ngIf="customerInfo">
            <div>
                <span>{{ "GENERAL.HELLO" | translate }}</span>
                <b *ngIf="!customerInfo?.companyName">{{
          customerInfo?.user.charAt(0).toUpperCase() +
            customerInfo?.user.slice(1)
        }}</b>
                <b *ngIf="customerInfo?.companyName">{{
          customerInfo?.companyName.charAt(0).toUpperCase() +
            customerInfo?.companyName.slice(1)
        }}</b>
            </div>
        </a>
        <div class="btn-wrap">

            <a [hidden]="arrayCount===0" routerLink="transfer/pending" class="btn btn-circle btn-outline-secondary" [routerLink]="['/transfer/pending']">
                <i class="icon i-bell"></i>
                <p style="color: red;">{{arrayCount}}</p>
            </a>


            <a routerLink="settings" class="btn btn-circle btn-outline-secondary" [routerLink]="['/settings']"><i class="icon i-cog"></i
      ></a>

            <a class="btn btn-circle btn-outline-secondary pointer" id="logoutBtn" (click)="logOut()"><i class="icon i-logout"></i>
      </a>
        </div>

        <div>
            <!-- <app-language-switcher></app-language-switcher> -->
        </div>

        <button class="hamburger" [ngClass]="{ active: menuIsOpen }" (click)="toggleMenuState()">
      <span></span>
    </button>
    </div>
</header>