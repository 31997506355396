import { Component, OnInit, SimpleChanges } from "@angular/core";
import { selectType, accountsInfo } from "src/app/shared/models/models";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";
import { Subscription } from "rxjs";
import { TemplatesService } from '../../templates/templates.service';

@Component({
  selector: "app-transfer-box",
  templateUrl: "./transfer-box.component.html",
  styleUrls: ["./transfer-box.component.scss"],
})
export class TransferBoxComponent extends accountsInfoBase implements OnInit {

  currentTab = "TRANSFER_TO_SOMEONE";

  loading: boolean = true;

  utilityTemplatesSub: Subscription;
  utilityTemplates: Array<any>;
  utilityTemplatesLoading: boolean = false;
  utilityTemplatesError: any;

  templatesLoading: boolean = false;
  templatesSub: Subscription;
  templates;
  templatesErrors;

  constructor(private templateService: TemplatesService) {
    super();
  }


  ngOnInit() {
    this.fetchAccountTemplates(this.templateService);
  }

  fetchAccountTemplates(templateService, pickSelectedtemplate?) {
    this.templatesLoading = true;
    this.templatesSub = templateService
      .fetchAccountTemplates()
      .subscribe((res) => {
        this.templatesLoading = false;
        this.templates =
          res["data"] &&
          res["data"]["template"] &&
          res["data"]["template"]["transfer"]
            ? res["data"]["template"]["transfer"]
            : [];
        this.templatesErrors = res["errors"];

        if (pickSelectedtemplate) {
          pickSelectedtemplate();
        }
      });
  }

  setTab(tab: string) {
    this.currentTab = tab;
  }

  ngOnDestroy(): void {
    this.templatesSub.unsubscribe();
    if (this.utilityTemplatesSub) {
      this.utilityTemplatesSub.unsubscribe();
    }
  }
}
