import { Subscription } from "rxjs";
import { CustomerInfoService } from "../services/customer-info.service";
import { customerInfo } from "../models/models";

export class CustomerInfoBase {

  //#region  Variables
  // Customer Info
  public customerInfoSub: Subscription;
  public customerInfo: customerInfo;
 
  // Customer Info Loading
  public customerInfoLoadingSub: Subscription;
  public customerInfoLoading: boolean;


  // Customer INfo Errors
  public customerInfoErrorSub: Subscription;
  public customerInfoError: string;
 
  //#endregion

  //#region  Methods

  protected loadCustomerInfo(customerInfoService: CustomerInfoService): void {
    this.customerInfoLoadingSub = customerInfoService.customerInfoLoading.subscribe(
      (res: boolean) => {
        this.customerInfoLoading = res;
      }
    );
    this.customerInfoErrorSub = customerInfoService.customerInfoErrorMessage.subscribe(
      (res: string) => {
        this.customerInfoError = res;
      }
    );

    this.customerInfoSub = customerInfoService.customerInfo.subscribe(
      (res: customerInfo) => {
        this.customerInfo = res;
      }
    );
  }

  //UNSUBSCRIBE
  protected unsubscribe(): void {
    if (this.customerInfoLoadingSub) {
      this.customerInfoLoadingSub.unsubscribe();
    }

    if (this.customerInfoErrorSub) {
      this.customerInfoErrorSub.unsubscribe();
    }

    if (this.customerInfoSub) {
      this.customerInfoSub.unsubscribe();
    }
  }
  //#endregion
}
