<div class="monthly-view">
  <ul class="weekdays">
    <li *ngFor="let weekDayName of weekDayNames">{{ ('CALENDAR.DAYS.' + weekDayName | translate).charAt(0) }}</li>
  </ul>

  <div class="month-days">
    <div class="padding" *ngFor="let padding of prevMonthPadding">{{ padding }}</div>
    <div  class="day" 
          *ngFor="let selectedMonthDate of selectedMonthDates" 
          [ngClass]="getCurrentDateClass(selectedMonthDate)"
          (click)="pickDate(selectedMonthDate)">
          {{ selectedMonthDate }}
          <div class="notification-dots" [ngClass]="showHideDot(selectedMonthDate)">
            <div class="dot bills" ></div>
            <div class="dot loan" ></div>
            <div class="dot income" ></div>
            <div class="dot custom" ></div>
          </div>
    </div>
    <div class="padding" *ngFor="let padding of nextMonthPadding">{{ padding }}</div>
  </div>
</div>

