import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  data:any

  constructor() { }

  setData(data){
    this.data=data
    // console.log(this.data)
  }

  getData(){
    return this.data
  }
}
