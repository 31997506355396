import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";
import { start } from "repl";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class TransactionListService {
  constructor(
    private graphQL: GraphQLRequestService,
    private datePipe: DatePipe
  ) {}

  fetchTransactions(startDate?, endDate?, accountNumber?) {
    let params;
    if (startDate && endDate && accountNumber) {
      params = `(startDate: "${startDate}", endDate: "${endDate}", accountNumber: "${accountNumber}")`;
    } else if (!accountNumber) {
      let tempStartDate = new Date();
      let tempEndDate = new Date();
      tempStartDate.setMonth(tempEndDate.getMonth() - 3);
      params = `(startDate: "${this.datePipe.transform(
        tempStartDate,
        "yyyy-MM-dd"
      )}", endDate: "${this.datePipe.transform(tempEndDate, "yyyy-MM-dd")}")`;
    } else {
      params = "";
    }
    return this.graphQL.request(gql`
      {
        customer {
          transactions${params} {
            amount
            balance
            channelCode
            description1
            description2
            externalId
            providerId
            status
            transactionExecutionTime
            transactionType
            transactionValueDate
            voucherNumber
          }
        }
      }
    `);
  }
  exportTransactionDetailsToPdf(voucherNumber) {
    return this.graphQL.request(gql`
      {
          customer {
            transaction(voucherNumber: "${voucherNumber}"){
              printForm
            }
        }
      }
    `);
  }
}
