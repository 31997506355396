<div class="card tranfer-widget">
  <div class="card-header">
    <button
      (click)="setTab('TRANSFER_TO_SOMEONE')"
      [ngClass]="{ selected: currentTab === 'TRANSFER_TO_SOMEONE' }"
    >
      <i class="icon i-lg i-transfer"></i>
      <span>{{"TRANSFERS.TRANSFERS" | translate}}</span>
    </button>
  </div>

  <div class="card-body">
    <ng-container [ngSwitch]="currentTab">
      <!-- TRANSFER_TO_SOMEONE -->
      <div
        class="card-block"
        *ngSwitchCase="'TRANSFER_TO_SOMEONE'"
        data-tab="transfer"
      >
        <app-to-someone-mini
          [templates]="templates"
          [templatesLoading]="templatesLoading"
          [templatesErrors]="templatesErrors"
        ></app-to-someone-mini>
      </div>
    </ng-container>
  </div>
</div>
