import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalWithTextService {
  public modalText: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public modalErrorText:BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public modalClosed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private translateService: TranslateService) {}

  public generateModal(modalText) {
    this.translateService.get(modalText).subscribe((translatedString: any) => {
      this.modalText.next(translatedString ? translatedString : modalText);
      this.modalClosed.next(false);
    });
  }
  public generateErrorModal(modalText){
    this.translateService.get(modalText).subscribe((translatedString:any)=>{
      this.modalErrorText.next(translatedString ? translatedString : modalText);
    })
  }
}
