import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "accountTemplatesFilter",
})
export class AccountTemplatesFilterPipe implements PipeTransform {
  transform(array: any, searchQuerry: any): any {
    if (searchQuerry === undefined) {
      return array;
    }

    return array.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
        item.beneficiaryAccountNumber
          .toLowerCase()
          .includes(searchQuerry.toLowerCase())
    );
  }
}
