<style>
    td,
    th {
        border: 1px solid #777;
        padding: 0.5rem;
        text-align: center;
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
    }
    
    caption {
        font-size: 0.8rem;
    }
</style>

<div class="credo-page">
    <div class=""></div>
    <main>
        <div class="card transfers-card">
            <div class="card-header">
                <h1 class="card-title">
                    Pending Transfers
                    <!-- FEAR ME -->
                </h1>
            </div>

            <div class="card-body preloader-parent">

                <div class="card-block info-wrap loan-counter">



                    <div class="card p-2 mb-5 ">
                        <table class="styled-table">
                            <thead>
                                <tr>

                                    <th scope="col ">Amount</th>
                                    <th scope="col ">Receiver Bank </th>
                                    <th scope="col ">Receiver Acc No </th>
                                    <th scope="col ">Comment</th>


                                    <th scope="col ">View</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transfer of transfers">
                                    <td scope="row ">{{transfer.amount}}</td>
                                    <td scope="row ">{{transfer.receiverBank}}</td>
                                    <td scope="row ">{{transfer.receiverAccountNumber}}</td>

                                    <td scope="row ">{{transfer.comment}}</td>


                                    <td scope="row "><button (click)="getData(
                                      transfer

                                      )" class="btn btn-primary">View</button></td>


                                </tr>






                            </tbody>
                        </table><br/><br/>
                        <p style="color: red; font-size: 20px">Kindly note that you cannot approve an amount bigger than your Account Balance</p>
                    </div>




                </div>
            </div>
        </div>
    </main>
</div>