<div class="credo-page">
  <div class="preloader full-screen double-circles" *ngIf="loading"></div>
  <main>
    <div class="card transfers-card">
      <div class="card-header">
        <h1 class="card-title">
          {{ "LOANS_P.LOAN_CAL" | translate }}
        </h1>
      </div>
      <div class="back-btn-wrap">
        <a [routerLink]="['/']" class="back-btn">
          <span class="icon i-dropdown-arrow"></span>
          {{ "GENERAL.BACK" | translate }}
        </a>
      </div>
      <div class="card-body preloader-parent">
        <!-- <div class="card-block accounts-wrap">
          <div class="row-sm fieldset">
            <div class="col-12 col-md-6">
              <div class="form-label">
                {{ "LOANS_P.CHOOSE_LOAN_TYPE" | translate }}
              </div>
              <app-select
                [loadingtext]="'LOANS_P.LOAN_TYPES' | translate"
                [loading]="selectLoanTypeLoading"
                [ngClass]="{ disabled: selectLoanTypeLoading }"
                [newItem]="false"
                [choseElement]="selectedLoanType"
                [inputArray]="loanTypeArray"
                [showDropIcon]="!selectLoanTypeLoading"
                (selectedItem)="selectLoanType($event)"
              ></app-select>
            </div>
          </div>
        </div> -->
        <div class="card-block info-wrap loan-counter">
          <div class="range-pickers">
            <app-range-picker
              [min]="1000"
              [max]="350000"
              [value]="amount"
              [step]="250"
              (getValue)="setAmount($event)"
              [type]="'ZMW'"
            >
            </app-range-picker>

            <app-range-picker
              [min]="4"
              [max]="60"
              [value]="duration"
              [step]="1"
              (getValue)="setPeriod($event)"
              [type]="'CALENDAR.MONTH' | translate"
            ></app-range-picker>
          </div>
          <!-- <div class="mortgage" *ngIf="amount >= 20000001">
            <label class="radio pointer">
              <input type="checkbox" (click)="checkMortgage()" />
              <span>{{ "LOANS_P.MORTGAGE" | translate }}</span>
            </label>
          </div> -->
          <div class="loan-info">
            <div class="monthly">
              <span
                >{{ "LOANS_P.MONTLY_P" | translate }}:
                {{ monthly | moneyFormat }} ZMW</span
              >
            </div>
            <!-- <div class="monthly">
              <span
                >{{ "LOANS_P.DISBUREMENT_FEE" | translate }}:
                {{ getdisburementFee() | moneyFormat }} ZMW</span
              >
            </div> -->
          </div>
          <!-- <div *ngIf="!selectedLoanType" class="disable"></div> -->
          <div class="col-12 col-md-auto send-mail">
            <button
              class="btn btn-primary btn-link send-mail-btn"
              (click)="prepMail()"
            >
              {{ "LOANS_P.SEND_MAIL" | translate }}
            </button>
            <h3 class="error-text" *ngIf="errorMessage">
              {{ "ERROR_CODES." + errorMessage | translate }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
