<div class="preloader-parent" [formGroup]="form">
    <div class="card-row" [ngClass]="{ disabled: step === 2 }">
        <app-select [loadingtext]="loadingSelectFromText" [loading]="prepareTransferLoading" [choseElement]="defaultAccount" [newItem]="false" [inputArray]="accountsFrom" (selectedItem)="selectAccountFrom($event)"></app-select>
    </div>

    <div class="card-row" [ngClass]="{ disabled: step === 2 }">
        <app-select [loadingtext]="loadingTemplateText" [loading]="waitforTemplates()" [choseElement]="templateTo" [newItem]="true" [inputArray]="templatesToArr" (selectedItem)="selectTemplateTo($event)" (newItemClick)="newItemClick()"></app-select>
    </div>

    <!-- (chooseBranchCode)="getEmittedBranchCode($event)" -->
    <!-- <div class="card-row" [ngClass]="{ disabled: step === 2 }">
    <app-bank-select-for-transfers
      (chooseIndustryNumber)="getEmittedIndustryNumber($event)"
    ></app-bank-select-for-transfers>
  </div> -->

    <div class="card-row" [ngClass]="{ disabled: step === 2 }">
        <div class="amount-wrap">
            <div>
                <input appCurrency class="form-input" [ngClass]="giveInputClasses('amount')" formControlName="amount" type="text" [placeholder]="'GENERAL.AMOUNT' | translate" />
            </div>
        </div>
    </div>

    <div class="card-row" [ngClass]="{ disabled: step === 2 }">
        <input type="text" class="form-input" [ngClass]="giveInputClasses('description')" formControlName="description" [placeholder]="'GENERAL.DESCRIPTION' | translate" />
    </div>

    <div *ngIf="step === 2">
        <h3 class="tax">
            {{ "GENERAL.FEE" | translate }}: {{ prepareTransfer.fee | moneyFormat }}
        </h3>
    </div>

    <div class="row-sm transfer-amount-validation-error">
        <p *ngIf="
        form.controls.amount.dirty &&
        form.controls.amount.invalid &&
        form.controls.amount.errors.max &&
        form.controls.amount.value < 500000
      "> {{ "ERROR_CODES.INSUFFICIENT_FUNDS" | translate }}</p>
        <p *ngIf="
        form.controls.amount.dirty &&
        form.controls.amount.invalid &&
        form.controls.amount.errors.max &&
        form.controls.amount.value >= 100000
      ">
            Max {{ "GENERAL.AMOUNT" | translate }}:{{ 100000 | moneyFormat }} ZMW
        </p>
    </div>

    <div class="card-row" *ngIf="!isDisabledFirstStep()">
        <div class="col">
            <button [ngClass]="{ disabled: !isfirstStepValid() }" (click)="prepare()" class="btn btn-secondary btn-link">
        {{ "GENERAL.NEXT" | translate }}
      </button>
        </div>
    </div>

    <div class="row-sm card-row" *ngIf="prepareTransfer?.authType === 'SMS' && step === 2">
        <div class="request-sms">
            <app-sms-request [valid]="
          form.controls.otpCode.valid || form.controls.otpCode.touched
            ? true
            : false
        " [loading]="loading" [disabled]="loading" [resendBtnHovered]="resendBtnHovered" (giveCode)="getSmsCode($event)" (resend)="resendSms()">
            </app-sms-request>
        </div>
    </div>

    <div class="col-12 col-md-auto">
        <h4 style="color: #ea4c36" *ngIf="errorMessage">
            {{ "ERROR_CODES." + errorMessage | translate }}
        </h4>
    </div>

    <div class="row-sm card-row" *ngIf="step === 2">
        <div class="col">
            <button (click)="gotoFirstStep()" class="btn btn-secondary btn-link">
        {{ "GENERAL.BACK" | translate }}
      </button>
        </div>
        <div class="col">
            <button (click)="transferToSomeoneAccountConfirm()" [ngClass]="{ disabled: form.controls.otpCode.invalid }" class="btn btn-primary" [disabled]="form.controls.otpCode.invalid">
        {{ "GENERAL.TRANSFER" | translate }}
      </button>
        </div>
    </div>

    <div class="preloader full-screen double-circles" *ngIf="loading"></div>
</div>