import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CalendarDataService } from 'src/app/shared/services/calendar/calendarData.service';
import { CalendarService } from './calendar.service';

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, OnDestroy {
  eventsLoading = true;

  notificationsSub: Subscription;
  notifications: Notification[];

  loadingSelectMonthText: string = "Loading"

  formattedNotifications: Notification[];

  viewStyle = "monthly";

  currentDate: Date;

  weekDayNames: string[];

  monthNames: any;

  selectedMonth: any;

  selectedMonthDates: number[];
  prevMonthPadding: number[];
  nextMonthPadding: number[];

  selectedMonthDatesWeekly: {};

  notificationsFilter: string | number = "all";

  showDeleteEventPrompt = false;

  notificationToDelete;
  deleteNotification$;
  deletingNotification = false;

  constructor(
    private calendarDataService: CalendarDataService,
    private calendarService: CalendarService,
    public router: Router
  ) {}

  ngOnInit() {
    this.currentDate = this.calendarDataService.getCurrentDate();
    this.weekDayNames = this.calendarDataService.getWeekDayNames();
    this.getAndPrepMonthNamesForSelect();


    this.pickTodaysDate();
    this.fetchNotifications();

  }

  getAndPrepMonthNamesForSelect(){
    this.monthNames = this.calendarDataService.getMonthNames().map((x: any) => ({
      id: x.value,
      mainTitle: x.label,
    }));
  }

  pickView(viewStyle) {
    this.viewStyle = viewStyle;
  }

  initializeCalendar() {
    this.selectedMonthDates = this.calendarDataService.getDatesArray(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth()
    );
    this.prevMonthPadding = this.calendarDataService.getPrevMonthPadding(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth()
    );
    this.nextMonthPadding = this.calendarDataService.getNextMonthPadding(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth()
    );
  }

  initializeWeeklyCalendar() {
    this.selectedMonthDatesWeekly = this.calendarDataService.getDatesArrayWeekly(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate()
    );
  }

  pickMonth(month) {
    this.currentDate.setMonth(month.id);
    this.initializeCalendar();
    this.initializeWeeklyCalendar();
    this.selectedMonth = this.monthNames[this.currentDate.getMonth()];
  }

  pickDate(data) {
    let currentMonth = this.currentDate.getMonth();

    if (data.month === "previous") {
      if (currentMonth !== 0) {
        currentMonth--;
      }
      this.currentDate.setMonth(currentMonth);
    } else if (data.month === "next") {
      if (currentMonth !== 11) {
        currentMonth++;
      }
      this.currentDate.setMonth(currentMonth);
    }

    this.currentDate.setDate(data.date);

    this.pickMonth(this.monthNames[this.currentDate.getMonth()]);
  }

  pickTodaysDate() {
    const currentDate = new Date();
    this.pickMonth(this.monthNames[currentDate.getMonth()]);
    this.pickDate({
      month: currentDate.getMonth(),
      date: currentDate.getDate(),
    });
  }

  prevButton() {
    if (this.viewStyle === "monthly") {
      let currentMonth = this.currentDate.getMonth();

      if (currentMonth !== 0) {
        currentMonth--;
      }
      this.currentDate.setMonth(currentMonth);
    } else if (this.viewStyle === "weekly") {
      this.currentDate = this.calendarDataService.getPrevWeekDate(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    }

    this.pickMonth(this.monthNames[this.currentDate.getMonth()]);
  }

  nextButton() {
    if (this.viewStyle === "monthly") {
      let currentMonth = this.currentDate.getMonth();

      if (currentMonth !== 11) {
        currentMonth++;
      }
      this.currentDate.setMonth(currentMonth);
    } else if (this.viewStyle === "weekly") {
      this.currentDate = this.calendarDataService.getNextWeekDate(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    }

    this.pickMonth(this.monthNames[this.currentDate.getMonth()]);
  }

  formatDateForNotifications() {
    const notificationsArray = this.notifications;

    if (notificationsArray) {
      this.formattedNotifications = notificationsArray.map((notification: any) => {

        const dateTimeString = notification.eventDate;
        const dateString = dateTimeString.split("T")[0];
        const dateArray = dateString.split('-');
        const year = Number(dateArray[0]);
        const month = Number(dateArray[1]) - 1;
        const day = Number(dateArray[2]);

        const newDate = new Date(year, month, day);

        return {
          ...notification,
          eventDate: newDate,
        };
      });
    }
  }

  setNotificationsFilter(type) {
    this.notificationsFilter = type;
  }

  fetchNotifications() {
    this.notificationsSub = this.calendarDataService
      .fetchNotifications()
      .subscribe((res) => {
        this.notifications = res["data"]["calendarEvents"];
        this.eventsLoading = false;
        this.formatDateForNotifications();
      });
  }

  showHideNotification(notification) {
    const notificationDate: Date = notification.eventDate;
    if (notificationDate.getTime() === this.currentDate.getTime()) {
      if (
        notification.eventTypeId === this.notificationsFilter ||
        this.notificationsFilter === "all"
      ) {
        return true;
      }
    }
  }

  goToEditNotification(notification) {
    console.log(notification);
    this.router.navigateByUrl("/calendar/edit-event", {
      state: { notification },
    });
  }

  goToAddNotificationPage() {
    const date = new Date();
    this.router.navigateByUrl("/calendar/add-event", { state: { date } });
  }

  deleteNotification() {
    const notification = this.notificationToDelete;
    this.deletingNotification = true;
    this.deleteNotification$ = this.calendarService
      .deleteCalendarEvent({ calendarEventId: notification.id })
      .subscribe((res) => {
        this.deletingNotification = false;
        this.showDeleteEventPrompt = false;
        if (res.data["deleteCalendarEvent"]) {
          for (let i = 0; i < this.formattedNotifications.length; i++) {
            if (this.formattedNotifications[i]["id"] === notification.id) {
              this.formattedNotifications.splice(1, i);
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.notificationsSub.unsubscribe();
    if (this.deleteNotification$) {
      this.deleteNotification$.unsubscribe();
    }
  }
}
