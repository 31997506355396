import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TemplatesService } from '../../templates.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-phone-number-template',
  templateUrl: './add-phone-number-template.component.html',
  styleUrls: ['./add-phone-number-template.component.scss']
})
export class AddPhoneNumberTemplateComponent implements OnInit, OnDestroy {
  mobileTemplateForm: FormGroup;

  formSubscription: Subscription;
  checkPhoneNumberSub: Subscription;
  addTemplateSub: Subscription;

  loading = false;

  wrongPhoneNumbers = [];

  errorMessage = "";

  constructor(
    private formBuilder: FormBuilder,
    private templateService: TemplatesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.subscribeToForm();
  }

  initializeForm() {
    this.mobileTemplateForm = this.formBuilder.group({
      phoneNumber: new FormControl("", {
        validators: [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
          this.phoneNumberValidation(this.wrongPhoneNumbers)
        ]
      }),
      name: new FormControl("", { validators: [Validators.required] })
    });
  }

  subscribeToForm() {
    this.formSubscription = this.mobileTemplateForm
      .get("phoneNumber")
      .valueChanges.subscribe(phoneNumber => {
        this.errorMessage = "";
        this.checkPhoneNumber(phoneNumber);
      });
  }

  checkPhoneNumber(phoneNumber) {
    if (
      phoneNumber.length === 9 &&
      !this.wrongPhoneNumbers.includes(phoneNumber)
    ) {
      this.loading = true;

      this.checkPhoneNumberSub = this.templateService
        .checkPhoneNumberProvider(phoneNumber)
        .subscribe(res => {
          this.loading = false;
          this.errorMessage = '';

          if (res.errors) {
            if (!this.wrongPhoneNumbers.includes(phoneNumber)) {
              this.wrongPhoneNumbers.push(phoneNumber);
            }
            this.errorMessage = res.errors[0].extensions.code;
          }
        });
    }
  }

  phoneNumberValidation(array: string[]) {
    return (input: FormControl) => {
      const value = input.value;
      return array.includes(value) ? { wrongPhoneNumber: true } : null;
    };
  }

  giveInputClasses(input) {
    if (
      this.mobileTemplateForm.get(input).invalid &&
      this.mobileTemplateForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  submitForm() {
    this.markFormGroupTouched(this.mobileTemplateForm);

    if (this.mobileTemplateForm.valid && !this.errorMessage) {
      this.addTemplate();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  addTemplate() {
    this.loading = true;

    const template = {
      template: {
        identifiers: [
          {
            fieldId: "0",
            fieldValue: this.mobileTemplateForm.controls.phoneNumber.value.toString()
          }
        ],
        type: "MOBILE",
        isFavorite: false,
        serviceId: "",
        name: this.mobileTemplateForm.controls.name.value
      }
    };

  }

  ngOnDestroy() {
    if(this.formSubscription){
      this.formSubscription.unsubscribe();
    }
    if (this.checkPhoneNumberSub) {
      this.checkPhoneNumberSub.unsubscribe();
    }
    if(this.addTemplateSub){
      this.addTemplateSub.unsubscribe();
    }
  }
}
