<div
  class="full-date-picker-container"
  [ngClass]="{ open: showPicker, disabled: disabled }"
>
  <div
    class="picked-date"
    (click)="showHidePicker()"
    [ngClass]="{ invalid: !valid }"
  >
    <h2 class="placeholder" *ngIf="!dateIsPicked">
      {{ valid ? placeholder : ("GENERAL.ERROR" | translate) }}
    </h2>
    <h2 *ngIf="dateIsPicked">
      {{ pickedDate | date: "d LLLL yyyy" }}
    </h2>
  </div>

  <div class="selectors-container" *ngIf="showPicker">
    <div class="controls">
      <div class="arrow left" (click)="previousClicked()"></div>
      <div class="control-picker" (click)="changeStep()">
        <h2 *ngIf="step === 'date'">
          {{ pickedDate | date: "LLLL, yyyy" }}
        </h2>
        <h2 *ngIf="step === 'month'">
          {{ pickedDate | date: "yyyy" }}
        </h2>
        <h2 *ngIf="step === 'year'">
          {{ "CALENDAR.DATE_PICKER.TODAYS_DATE" | translate }}
        </h2>
      </div>
      <div class="arrow right" (click)="nextClicked()"></div>
    </div>

    <app-pick-year
      #pickYearChild
      [pickedDate]="pickedDate"
      [minDate]="minDate"
      [maxDate]="maxDate"
      *ngIf="step === 'year'"
      (giveYear)="setYear($event)"
    ></app-pick-year>

    <app-pick-month
      #pickMonthChild
      [pickedDate]="pickedDate"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (giveMonth)="setMonth($event)"
      *ngIf="step === 'month'"
    ></app-pick-month>

    <app-pick-date
      #pickDateChild
      [pickedDate]="pickedDate"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (giveDate)="setDate($event)"
      *ngIf="step === 'date'"
    ></app-pick-date>
  </div>
  <div class="overlay" *ngIf="showPicker" (click)="showHidePicker()"></div>
</div>
