import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home.component";
import { AdsComponent } from "./ads/ads.component";
import { AdsRightComponent } from "./ads-right/ads-right.component";
import { CalendarBoxComponent } from "./calendar-box/calendar-box.component";
import { ExchangeBoxComponent } from "./exchange-box/exchange-box.component";
import { ProductsBoxComponent } from "./products-box/products-box.component";
import { TransferBoxComponent } from "./transfer-box/transfer-box.component";
import { TransactionsBoxComponent } from "./transactions-box/transactions-box.component";
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountListMiniComponent } from './products-box/account-list-mini/account-list-mini.component';
import { LoanListMiniComponent } from './products-box/loan-list-mini/loan-list-mini.component';
import { RouterModule } from '@angular/router';
import { ToSomeoneMiniComponent } from './transfer-box/to-someone-mini/to-someone-mini.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WeeklyViewBoxComponent } from './calendar-box/weekly-view/weekly-view.component';
import { MonthlyViewBoxComponent } from './calendar-box/monthly-view/monthly-view.component';
import { UtilityMiniComponent } from './transfer-box/utility-mini/utility-mini.component';
import { DepositsListMiniComponent } from './products-box/deposits-list-mini/deposits-list-mini.component';

@NgModule({
  declarations: [
    HomeComponent,
    AdsComponent,
    AdsRightComponent,
    CalendarBoxComponent,
    WeeklyViewBoxComponent,
    MonthlyViewBoxComponent,
    ExchangeBoxComponent,
    ProductsBoxComponent,
    TransferBoxComponent,
    TransactionsBoxComponent,
    AccountListMiniComponent,
    LoanListMiniComponent,
    ToSomeoneMiniComponent,
    UtilityMiniComponent,
    DepositsListMiniComponent,
  ],
  imports: [
    CommonModule, 
    SharedModule, 
    RouterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule],
})
export class HomeModule {}
