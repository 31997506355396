import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss']
})
export class ProductMenuComponent implements OnInit {

  activeTab = '';

  constructor(private route: ActivatedRoute, private router: Router) { }
  router$: any;

  ngOnInit(): void {
    this.catchRouteParams();
    this.detectRoute();
  }

  detectRoute() {
    this.router$ = this.router.events.subscribe((val) => {
      this.catchRouteParams();
    });
  }

  catchRouteParams() {
    this.activeTab = this.route.snapshot.params.type;
  }

  ngOnDestroy(): void {
    this.router$.unsubscribe();
  }

}
