import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { Bank, Branch, selectType } from "../../models/models";
import { DictionaryService } from "../../services/dictionary.service";

@Component({
  selector: "app-bank-select-for-transfers",
  templateUrl: "./bank-select-for-transfers.component.html",
  styleUrls: ["./bank-select-for-transfers.component.scss"],
})
export class BankSelectForTransfersComponent implements OnInit,OnDestroy {

  @Input() selectWidth: string = "100%";

  public selectBankPlaceHolder = 'GENERAL.SELECT_BANK';
  public choosenBank: selectType;
  public banksForSelect: Array<selectType>;

  public selectBranchPlaceHolder = 'GENERAL.SELECT_BRANCH';
  public choosenBranch: selectType;
  public branchesForSelect: Array<selectType>;

  public branches: Array<Branch>;
  public branchesError: string;
  public branchesLoading: boolean;

  public banks: Array<Bank>;
  public banksError: string;
  public banksLoading: boolean;

  private subs = new Subscription();

  @Output() chooseIndustryNumber = new EventEmitter<string>();
  @Output() chooseBranchCode = new EventEmitter<string>();

  constructor(private dictionaryService: DictionaryService) {}

  ngOnInit(): void {
    this.subscribe();
    this.dictionaryService.fetchBanks();
  }
  ngOnDestroy():void{
    this.subs.unsubscribe();
  }

  public prepareBanksForSelect() {
    let temp: any = this.banks;
    if (temp) {
      this.banksForSelect = temp.map((x: Bank) => ({
        id: x.id,
        mainTitle: x.bankName,
      }));
    }
  }
  public prepareBranchesForSelect() {
    let temp: any = this.branches;
    if (temp) {
      this.branchesForSelect = temp.map((x: Branch) => ({
        id: x.id,
        mainTitle: x.branchName,
      }));
    }
  }

  public selectBank(bank: selectType) {
    this.choosenBank = bank;
    this.emitBankCode(bank.id);
    // this.fetchBranches(bank.id);
    // this.selectBranch(null);
  }
  private emitBankCode(id):void{
    const selectedBank = this.banks.find(bank => bank.id === id);
    this.chooseIndustryNumber.emit(selectedBank.industryNumber);
  }
  // public selectBranch(branch: selectType) {
  //   this.choosenBranch = branch;
  //   this.emitBranchCode(branch?.id);
  // }
  private emitBranchCode(id):void{
    const selectedBranch = this.branches?.find(branch => branch?.id === id);
    this.chooseBranchCode.emit(selectedBranch?.code??null);
  }
  public fetchBranches(bankId): void {
    this.dictionaryService.fetchBranches(bankId);
  }
  public fetchBanks(): void {
    this.dictionaryService.fetchBanks();
  }
  public subscribe(): void {
    // this.subs.add(this.branchesSub);
    // this.subs.add(this.branchesErrorSub);
    // this.subs.add(this.branchesLoadingSub);

    this.subs.add(this.banksSub);
    this.subs.add(this.banksErrorSub);
    this.subs.add(this.banksLoadingSub);
  }
  public unsubscribe(): void {
    this.subs.unsubscribe();
  }
  // private get branchesSub(): Subscription {
  //   return this.dictionaryService.branches.subscribe((branches) => {
  //     if(!branches) return;
  //     this.branches = branches;
  //     this.prepareBranchesForSelect();
  //   });
  // }
  // private get branchesLoadingSub(): Subscription {
  //   return this.dictionaryService.branchesLoading.subscribe((branches) => {
  //     this.branchesLoading = branches;
  //   });
  // }
  // private get branchesErrorSub(): Subscription {
  //   return this.dictionaryService.branchesErrorMessage.subscribe((error) => {
  //     this.branchesError = error;
  //     if(error) this.selectBranchPlaceHolder = 'ERROR_CODES.SELECT_BRANCH_ERROR';
  //   });
  // }
  private get banksSub(): Subscription {
    return this.dictionaryService.banks.subscribe((banks) => {
      if(!banks) return;
      this.banks = banks;
      this.prepareBanksForSelect();

    });
  }
  private get banksLoadingSub(): Subscription {
    return this.dictionaryService.banksLoading.subscribe((banks) => {
      this.banksLoading = banks;
    });
  }
  private get banksErrorSub(): Subscription {
    return this.dictionaryService.banksErrorMessage.subscribe((error) => {
      this.banksError = error;
      if(error) this.selectBankPlaceHolder = 'ERROR_CODES.SELECT_BANK_ERROR';
    });
  }
}
