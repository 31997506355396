<div class="row main-row">
  <div class="col">
    <section class="card">
      <div class="card-block">
        <form [formGroup]="authForm" (submit)="initiateTwoFaAuth()">
          <div class="card-header">
            <h2 class="card-title">{{ "LANDING.LOGIN" | translate }}</h2>
            <p class="error-text" *ngIf="errorMessage">
              {{ "ERROR_CODES." + errorMessage | translate }}
            </p>
          </div>

          <div class="row-sm fieldset">
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input"
                [ngClass]="
                  loadingAuthentication
                    ? 'disabled'
                    : sharedFunctions.giveClassesToCustomInputs(
                        this.authForm.controls.username
                      )
                "
              >
                <i class="icon i-lg i-user"></i>
                <input
                  type="text"
                  name="new-password one"
                  formControlName="username"
                  style="text-transform: uppercase"
                  usernamePattern
                  autocomplete="off"
                  ng-disabled="loadingAuthentication"
                />
                <div class="input-messages">
                  <span>
                    {{ "LANDING.USERNAME" | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 form-group">
              <div
                class="custom-input password"
                [ngClass]="
                  loadingAuthentication
                    ? 'disabled'
                    : sharedFunctions.giveClassesToCustomInputs(
                        this.authForm.controls.password
                      )
                "
              >
                <i class="icon i-lg i-lock"></i>
                <input
                  id="password"
                  [type]="passwordInputType"
                  formControlName="password"
                  autocomplete="off"
                  ng-disabled="loadingAuthentication"
                />
                <div class="input-messages">
                  <span>
                    {{
                      authForm.controls.password.invalid &&
                      authForm.controls.password.touched
                        ? ("LANDING.PASSWORD" | translate)
                        : ("LANDING.PASSWORD" | translate)
                    }}
                  </span>
                </div>
                <div
                  class="show-pass"
                  *ngIf="authForm.controls.password.value"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                ></div>
              </div>
            </div>
          </div>
          <div class="row-sm">
            <app-two-fa
              [twoFaStarted]="isTwoFaStarted.asObservable()"
              [userName]="authForm.controls.username.value"
              [password]="authForm.controls.password.value"
              (otpFilledEmitter)="submitForm($event)"
              (errorEmitter)="getErrorFromTwoFa($event)"
            ></app-two-fa>
          </div>
          <div class="row-sm">
            <div class="col-12 col-sm-auto">
              <label class="checkbox">
                <input type="checkbox" formControlName="remembeUsername" />
                <span>{{ "LANDING.REMEMBER_ME" | translate }}</span>
              </label>
            </div>
            <div class="col-12 col-sm-auto">
              <div class="btn-wrap">
                <button
                  type="button"
                  class="reset-password-btn"
                  routerLink="/landing/reset-password"
                >
                  {{ "LANDING.RESET_PASS" | translate }}
                </button>
                <button
                  class="btn btn-lg btn-primary login-btn"
                  type="submit"
                  [disabled]="loadingAuthentication"
                  [ngClass]="{ loading: loadingAuthentication }"
                >
                  {{
                    loadingAuthentication ? "" : ("LANDING.LOGIN" | translate)
                  }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>

    <div class="ads-slider col-auto">
      <app-ad-slider [sliderContentArray]="sliderData"> </app-ad-slider>
    </div>
  </div>
  <div class="col-auto">
    <div class="aside">
      <div class="card-block sign-box">
        <div>
          <h4>{{ "LANDING.NEW_MEMBER" | translate }}</h4>
          <p>
            {{ "LANDING.CREATE_WALLET" | translate }}
          </p>
        </div>
        <a class="btn btn-lg" routerLink="/landing/register">{{
          "LANDING.REGISTRATION" | translate
        }}</a>
      </div>
      <div class="card-block contact-box">
        <div class="help-row">
          <span>{{ "LANDING.NEED_HELP" | translate }}</span>
        </div>

        <div class="request-call-row">
          <i class="icon-circle i-phone2--blue"></i>
          <a class="tel" href="tel:*888#">888</a>
        </div>
        <div>
          <button class="btn-icon location-btn" (click)="openLocationMap()">
            <i class="icon i-pin--white"></i>
            <span>{{ "GENERAL.LOCATION" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
