import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgModel } from "@angular/forms";
import { Guid } from "guid-typescript";
import { Subject, Subscription } from "rxjs";
import { OtpService } from "src/app/shared/services/otp.service";
import { TwoFaService } from "./two-fa.service";
import * as CryptoJS  from 'crypto-js';

@Component({
  selector: "app-two-fa",
  templateUrl: "./two-fa.component.html",
  styleUrls: ["./two-fa.component.scss"],
})
export class TwoFaComponent implements OnInit, OnDestroy {
  @Input() userName;
  @Input() password;
  @Input() twoFaStarted = new Subject<boolean>();

  @Output() otpFilledEmitter = new EventEmitter<any>();
  @Output() errorEmitter = new EventEmitter<string>();

  @Output() responseReceived = new EventEmitter<boolean>();


  isStarted =  false;

  public showTwoFa = true;

  public mobileNumber;
  public guid: Guid;
  public otpCode: NgModel;

  

  public isOtpLoading;
  public isOtpSent;

  private subs = new Subscription();


  private readonly key = CryptoJS.enc.Utf8.parse('0123456789abcdef0123456789abcdef');
  private readonly iv = CryptoJS.enc.Utf8.parse('MyIV123456789012');




  public encryptedValue: string;
  public decryptedValue: string;

  customerMobileReceived = false;




  constructor(
    private otpService: OtpService,
    private twoFaService: TwoFaService
  ) {}

  ngOnInit(): void {
    this.subToTwoFaStart();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // public getCustomerMobile(sendOtpFunction) {
  //   this.isOtpLoading = true;
  //   this.isStarted = false; // Initially set isStarted to false
  
  //   this.twoFaService.getCustomerMobile(this.userName,this.password).subscribe((res: any) => {
  //     this.isOtpLoading = false;
  //     if (res.errors) {
  //       this.errorEmitter.emit(
  //         res.errors[0].extensions.code ?? "GENERAL_ERROR"
  //       );
  //       this.showTwoFa = false;
  //       return;
  //     } else if (res?.data?.customerMobile == null) {
  //       this.errorEmitter.emit(
  //         "INVALID_USERNAME12"
  //       );
  //       // reload page
  //       this.isStarted = false;
  //       this.showTwoFa = false;
  //       return;
  //     } else {
  //       this.mobileNumber = res?.data?.customerMobile;
  //       sendOtpFunction();
  //       this.sendSMSCode();
  //       this.twoFaStarted.next(true);
  //       this.isStarted = true; // Set isStarted to true after successful execution
  //        // After receiving the response, set customerMobileReceived to true
  //       this.customerMobileReceived = true;
  //     }
  //   });
  // }


  public getCustomerMobile(sendOtpFunction) {
    this.isOtpLoading = true;
    sendOtpFunction();
    this.isOtpLoading = false;
    // this.twoFaService.getCustomerMobile(this.userName).subscribe((res: any) => {
    //   this.isOtpLoading = false;
    //   if (res.errors) {
    //     this.errorEmitter.emit(
    //       res.errors[0].extensions.code ?? "GENERAL_ERROR"
    //     );
    //     return;
    //   }
    //  // this.mobileNumber = res?.data?.customerMobile;
    //   sendOtpFunction();
    // });
  }


  public sendSMSCode() {
    const AES_KEY = '0123456789abcdef0123456789abcdef';
    const encrypted = CryptoJS.AES.encrypt(this.userName, this.key, { iv: this.iv });
    this.encryptedValue = encrypted.toString();
    // console.log("encrypted "+this.encryptedValue)
    this.isOtpLoading = true;
    this.guid = Guid.create();
    this.otpService
      .generateOtp({
      sessionId: this.guid.toString(),
       mobile: this.encryptedValue,
      })
      .subscribe((res: any) => {
        this.isOtpLoading = false;
        // console.log(res.errors)
        if (res.errors) {
          this.errorEmitter.emit(
            "INVALID_USERNAME12"
          );
          return;
        }
        this.isStarted = true;
        this.isOtpSent = true;
      });
  }
  public resendSMSCode() {
    this.sendSMSCode();
  }
  public getSmsCode(code) {
    this.otpCode = code;
    if (code.length == 4) {
      this.otpFilledEmitter.emit({
        sessionId: this.guid.toString(),
        code: code,
      });
    }
  }

  private subToTwoFaStart() {
    this.subs.add(
      this.twoFaStarted.subscribe((isStart) => {
        if (isStart) {

          console.log("isStart "+isStart)
          this.getCustomerMobile(this.sendSMSCode.bind(this));
          this.isStarted = isStart;
        }
      })
    );
  }
}
