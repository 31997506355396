<ng-container *ngIf="!accountsInfoLoading && !accountsInfoErrorMessage">
  <div
    class="home-product-item pointer"
    *ngFor="let account of accountsInfo"
    [routerLink]="['/products/accounts/' + account.accountId]"
  >
    <div>
      <div>
        <span class="name">{{ account.category }}</span>
        <span class="description">{{ account.accountNumber }}</span>
      </div>
    </div>
    <div>

      <span class="amount"
        >{{ account.availableBalance | moneyFormat }} {{ account.currency }}</span
      >
      <i class="icon i-arrow"></i>
    </div>
  </div>
</ng-container>

<div class="three-dot-loader" *ngIf="accountsInfoLoading"></div>

<h2
  class="error-message"
  *ngIf="!accountsInfoLoading && accountsInfoErrorMessage"
>
  {{ "ERROR_CODES." + accountsInfoErrorMessage | translate}}
</h2>
