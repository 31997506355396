import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-monthly-view-box',
  templateUrl: './monthly-view.component.html',
  styleUrls: ['./monthly-view.component.scss']
})
export class MonthlyViewBoxComponent implements OnInit {
  @Input() notifications;

  @Input() weekDayNames;

  @Input() selectedDate;

  @Input() prevMonthPadding;
  @Input() selectedMonthDates;
  @Input() nextMonthPadding;

  @Output() givePickedDate = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getCurrentDateClass(date) {
    if (date === this.selectedDate.getDate()) {
      return 'selected';
    }
  }

  pickDate(date) {
    this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), date);
    this.givePickedDate.emit(date);
  }

  showHideDot(date) {
    const classes = [];
    if (this.notifications) {
      for (const notification of this.notifications) {
        if (
          (notification.eventDate as Date).getTime() === new Date(
            this.selectedDate.getFullYear(), this.selectedDate.getMonth(), date
          ).getTime()
        ) {
          if (! classes.includes('type' + notification.eventTypeId)) {
            classes.push('type' + notification.eventTypeId);
          }
        }
      }
    }
    const classesString = classes.toString().replace(/,/g, ' ');
    return classesString;
  }
}
