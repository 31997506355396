import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { CustomerInfoService } from "src/app/shared/services/customer-info.service";
import { Subscription } from "rxjs";
import { customerInfo } from "../../shared/models/models";
import { CustomerInfoBase } from "src/app/shared/component-bases/customer-info-base";
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends CustomerInfoBase
  implements OnInit, OnDestroy {
  @Input() menuIsOpen: boolean;
  @Output() toggleMenu = new EventEmitter<boolean>();

  constructor(

    private customerInfoService: CustomerInfoService,
    private coreService:CoreService
    ) {

    super();
    this.gettransfers();
  }
  transfers =<any>[];
  arrayCount:number =0

  ngOnInit(): void {
    this.customerInfo = null
    super.loadCustomerInfo(this.customerInfoService);
  }

  logOut() {
    this.coreService.logOut();
  }

  ngOnDestroy() {
    super.unsubscribe();
  }

  toggleMenuState() {
    this.toggleMenu.emit(!this.menuIsOpen);
  }
  gettransfers(){
    var sendPerson = localStorage.getItem('fullname');
    this.customerInfoService.PendingTransfers(sendPerson).subscribe(
      (response)=>{
        // console.log(response.data['pendingTransfer'])
        this.transfers = response.data['pendingTransfer'];
        let count = this.transfers.length;
        this.arrayCount=count

      },
      (error)=>{
        console.log(error);
      }
          );
     }
}
