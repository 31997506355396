<div class="calendar-container">
  <div class="header">
    <div class="header-child">

      <app-select
        class="month-select"
        [loadingtext]="loadingSelectMonthText"
        [loading]="loadingNotifications"
        [newItem]="false"
        [choseElement]="selectedMonth"
        [inputArray]="monthNames"
        [showDropIcon]="true"
        (selectedItem)="pickMonth($event)"
      ></app-select>
    </div>

    <div class="header-child">
      <button
        class="monthly"
        (click)="pickView('monthly')"
        [ngClass]="{ active: viewStyle === 'monthly' }"
      >
      {{ "CALENDAR.MONTHLY" | translate }}
      </button>
      <button
        class="weekly"
        (click)="pickView('weekly')"
        [ngClass]="{ active: viewStyle === 'weekly' }"
      >
      {{ "CALENDAR.WEEKLY" | translate }}
      </button>
    </div>
  </div>

  <div class="preloader-parent">
    <app-monthly-view-box
      *ngIf="viewStyle === 'monthly'"
      [weekDayNames]="weekDayNames"
      [selectedDate]="currentDate"
      [selectedMonthDates]="selectedMonthDates"
      [prevMonthPadding]="prevMonthPadding"
      [nextMonthPadding]="nextMonthPadding"
      (givePickedDate)="pickDate($event)"
      [notifications]="formattedNotifications"
    ></app-monthly-view-box>

    <app-weekly-view-box
      *ngIf="viewStyle === 'weekly'"
      [weekDayNames]="weekDayNames"
      [selectedDate]="currentDate"
      [selectedMonthDates]="selectedMonthDatesWeekly"
      (givePickedDate)="pickDate($event)"
      (nextWeek)="showNextWeek()"
      (prevWeek)="showPrevWeek()"
      [notifications]="formattedNotifications"
    ></app-weekly-view-box>

    <div
      class="preloader full-screen double-circles"
      *ngIf="loadingNotifications"
    ></div>
  </div>

  <ul class="notification-types">
    <!-- <li class="bills">{{ "CALENDAR.UTILITY" | translate }}</li> -->
    <li class="loan">{{ "CALENDAR.LOAN" | translate }}</li>
    <li class="custom">{{ "CALENDAR.CUSTOM" | translate }}</li>
  </ul>

  <div class="notifications">
    <div *ngFor="let notification of formattedNotifications">
      <div class="notification" *ngIf="showHideNotification(notification)" [ngClass]="{
              'bills': notification.eventTypeId === 1, 'loan': notification.eventTypeId === 2, 'income': notification.eventTypeId === 4, 'custom': notification.eventTypeId === 3
            }">
        <h4>{{ notification.eventName }}</h4>
        <h2>{{ notification.eventDate.toDateString() }}</h2>

        <div class="info">
          <div class="wrapper">
            <h4>Title</h4>
            <h5>{{ notification.eventName }}</h5>
          </div>

          <div class="wrapper">
            <h4>Due Date</h4>
            <h5>{{ notification.eventDate.toDateString() }}</h5>
          </div>

          <div class="wrapper">
            <h4>Amount</h4>
            <h5>{{ notification.amount + ' ZMW'  }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <a class="view-calendar-btn btn-primary" [routerLink]="['/calendar']">{{
    "CALENDAR.VIEW_CALENDAR" | translate
  }}</a>
</div>
