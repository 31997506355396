import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TemplatesService } from '../../templates.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-edit-phone-number-template",
  templateUrl: "./edit-phone-number-template.component.html",
  styleUrls: ["./edit-phone-number-template.component.scss"],
})
export class EditPhoneNumberTemplateComponent implements OnInit, OnDestroy {
  template;

  mobileTemplateForm: FormGroup;

  editTemplateSub: Subscription;
  toggleFavouriteSub: Subscription;
  deleteTemplateSub: Subscription;

  loading = false;

  questionText: string = "Are you sure that you want to delete this template?";

  errorMessage = "";

  showDeletePopup = false;

  constructor(
    private templateService: TemplatesService,
    private router: Router

  ) {}

  ngOnInit() {
    this.initializeMobileTemplateForm();

    if (window.history.state.template) {
      this.template = window.history.state.template;
      this.loadTemplate();
    } else {
      this.router.navigateByUrl("/templates");
    }
  }

  initializeMobileTemplateForm() {
    this.mobileTemplateForm = new FormGroup({
      name: new FormControl("", { validators: [Validators.required] }),
      phoneNumber: new FormControl({ value: "", disabled: true }),
      isFavourite: new FormControl(""),
    });
  }

  loadTemplate() {
    this.mobileTemplateForm.controls.isFavourite.setValue(
      this.template.isFavorite
    );
    this.mobileTemplateForm.controls.phoneNumber.setValue(
      this.template.identifiers[0].fieldValue
    );
    this.mobileTemplateForm.controls.name.setValue(this.template.name);
  }

  giveInputClasses(input) {
    if (
      this.mobileTemplateForm.get(input).invalid &&
      this.mobileTemplateForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  submitForm() {
    this.markFormGroupTouched(this.mobileTemplateForm);

    if (this.mobileTemplateForm.valid) {
      this.editTemplate();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  editTemplate() {
    this.loading = true;

    const templateToGive = {
      template: {
        id: this.template.id,
        identifiers: [
          {
            fieldId: this.template.identifiers[0].fieldId,
            fieldValue: this.template.identifiers[0].fieldValue,
          },
        ],
        type: "MOBILE",
        isFavorite: this.template.isFavorite,
        name: this.mobileTemplateForm.controls.name.value,
        serviceId: this.template.serviceId,
      },
    };

  }

  toggleFavourite() {
    this.loading = true;

    const templateToGive = {
      template: {
        id: this.template.id,
        identifiers: [
          {
            fieldId: this.template.identifiers[0].fieldId,
            fieldValue: this.template.identifiers[0].fieldValue,
          },
        ],
        type: "MOBILE",
        isFavorite: !this.mobileTemplateForm.controls.isFavourite.value,
        name: this.template.name,
        serviceId: this.template.serviceId,
      },
    };

  }

  deleteTemplatePrompt() {
    this.showDeletePopup = true;
  }

  deleteTemplate() {
    this.showDeletePopup = false;
    this.loading = true;

    this.deleteTemplateSub = this.templateService
      .deleteTemplate({ templateId: this.template.id })
      .subscribe((res: any) => {
        this.loading = false;

        if (res.data.deleteTemplate) {
          this.router.navigateByUrl("/templates");
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  ngOnDestroy(): void {
    if (this.editTemplateSub) {
      this.editTemplateSub.unsubscribe();
    }
    if (this.toggleFavouriteSub) {
      this.toggleFavouriteSub.unsubscribe();
    }
    if (this.deleteTemplateSub) {
      this.deleteTemplateSub.unsubscribe();
    }
  }
}
