import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-full-date-picker',
  templateUrl: './full-date-picker.component.html',
  styleUrls: ['./full-date-picker.component.scss']
})
export class FullDatePickerComponent implements OnInit {

  @ViewChild("pickYearChild") child;
  @ViewChild("pickMonthChild") childMonth;
  @ViewChild("pickDateChild") childDate;

  todaysDate = new Date();

  @Input() pickedDate: Date;

  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Input() step = "year";

  @Input() placeholder = "Pick a date";

  @Input() valid = true;
  @Input() disabled = false;

  showPicker = false;

  dateIsPicked = false;

  minYearList: any;
  maxYearList: any;

  @Output() giveDate = new EventEmitter<Date>();

  constructor(
    ) {}

  ngOnInit() {
    if(!this.maxDate || !this.minDate){
      this.newMaxMin();
    }
    if (!this.pickedDate) {
      this.pickedDate = this.maxDate;
    } else {
      this.finishPicking();
    }
  }

  newMaxMin(){
    this.minDate = new Date(
        new Date().getFullYear() - 100,
        new Date().getMonth(),
        new Date().getDate()
      );
      this.maxDate = new Date(
        new Date().getFullYear() - 5,
        new Date().getMonth(),
        new Date().getDate()
      );
  }

  showHidePicker() {
    if (!this.disabled) {
      if (this.showPicker === true) {
        this.showPicker = false;
        if (this.dateIsPicked === false) {
          this.valid = false;
          this.pickedDate = this.maxDate;
        } else {
          if (this.pickedDate <= this.maxDate) {
            this.valid = true;
          } else {
            this.pickedDate = this.maxDate;
          }
        }
      } else {
        this.showPicker = true;
        this.valid = true;
      }
    }
  }

  setDate(date) {
    this.pickedDate = new Date(
      this.pickedDate.getFullYear(),
      this.pickedDate.getMonth(),
      date
    );

    this.finishPicking();
  }

  setMonth(month) {
    this.pickedDate = new Date(
      this.pickedDate.getFullYear(),
      month,
      this.pickedDate.getDate()
    );

    this.step = "date";
  }

  setYear(year) {
    this.pickedDate = new Date(
      year,
      this.pickedDate.getMonth(),
      this.pickedDate.getDate()
    );
    this.step = "month";
  }

  changeStep() {
    if (this.step === "year") {

      let dateIsInRange = true;
      if (this.minDate) {
        if (this.minDate.getTime() > new Date().getTime()) {
          dateIsInRange = false;
        }
      }

      if (this.maxDate) {
        if (this.maxDate.getTime() < new Date().getTime()) {
          dateIsInRange = false;
        }
      }

      if (dateIsInRange === true) {
        this.pickedDate = new Date();

        this.finishPicking();
      }
    }
    if (this.step === "month") {
      this.step = "year";
    }
    if (this.step === "date") {
      this.step = "month";
    }
  }

  previousClicked() {
    if (
      this.step === "year" &&
      this.child.minYearList.value >= this.minDate.getFullYear()
    ) {
      this.child.generatePreviousYearList();
    }
    if (
      this.step === "month" &&
      this.pickedDate.getFullYear() > this.minDate.getFullYear()
    ) {
      this.pickedDate = new Date(
        this.pickedDate.getFullYear() - 1,
        this.pickedDate.getMonth(),
        this.pickedDate.getDate()
      );
      this.childMonth.generateMonths(this.pickedDate);
    }
    if (this.step === "date" && this.pickedDate > this.minDate) {
      this.pickedDate = new Date(
        this.pickedDate.getFullYear(),
        this.pickedDate.getMonth() - 1,
        this.pickedDate.getDate()
      );
      this.childDate.generateDates(this.pickedDate);
    }
  }

  nextClicked() {
    if (
      this.step === "year" &&
      this.maxDate.getFullYear() >= this.child.maxYearList.value
    ) {
      this.child.generateNextYearList();
    }

    if (
      this.step === "month" &&
      this.pickedDate.getFullYear() < this.maxDate.getFullYear()
    ) {
      this.pickedDate = new Date(
        this.pickedDate.getFullYear() + 1,
        this.maxDate.getMonth(),
        this.maxDate.getDate()
      );
      this.childMonth.generateMonths(this.pickedDate);
    }
    if (this.step === "date" && this.pickedDate < this.maxDate) {
      this.pickedDate = new Date(
        this.pickedDate.getFullYear(),
        this.pickedDate.getMonth() + 1,
        this.maxDate.getDate()
      );
      this.childDate.generateDates(this.pickedDate);
    }
  }

  finishPicking() {
    this.dateIsPicked = true;
    this.showPicker = false;
    this.valid = true;
    this.giveDate.emit(this.pickedDate);
  }
}
