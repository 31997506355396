import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  constructor(private graphQL: GraphQLRequestService) {}
}
