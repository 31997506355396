import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { TransactionListService } from "../../services/transaction-list.service";
import { transactionResponse } from "../../models/models";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { ModalWithTextService } from "../popups/modal-with-text/modal-with-text.service";

@Component({
  selector: "app-transaction-list",
  templateUrl: "./transaction-list.component.html",
  styleUrls: ["./transaction-list.component.scss"],
})
export class TransactionListComponent implements OnInit, OnChanges {
  constructor(
    public transactionListService: TransactionListService,
    private datePipe: DatePipe,
    private modalService: ModalWithTextService
  ) {}

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() accountNumber;

  transactionSub: Subscription;
  transactionResponse: transactionResponse;

  transactionLoading = false;
  transactionErrorMessage = "";
  transaction: any;

  loading = true;

  ngOnInit(): void {
    // this.setDefaultTransactionDates();
    this.fetchTransactions();
  }

  ngOnChanges(): void {
    this.fetchTransactions();
  }

  private setDefaultTransactionDates(): void {
    this.startDate = this.startDate ?? null;
    this.endDate = this.endDate ?? null;
  }
  // get transactions
  fetchTransactions() {
    this.transaction = "";
    this.loading = true;
    this.transactionSub = this.transactionListService
      .fetchTransactions(this.startDate, this.endDate, this.accountNumber)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.transactionLoading = false;
          if (res?.data?.customer && res?.data?.customer?.transactions) {
            this.transaction = res.data.customer.transactions.length
              ? res.data.customer.transactions
              : null;
          } else {
            this.transaction = null;
          }
        },
        (err: any) => {
          this.loading = false;
        }
      );
  }
  exportTransactionDetailsToPdf(transaction): void {
    this.loading = true;
    this.transactionListService
      .exportTransactionDetailsToPdf(transaction.voucherNumber)
      .subscribe((res: any) => {
        this.loading = false;
        if (res.errors) {
          this.modalService.generateErrorModal(
            `ERROR_CODES.${res.errors[0].extensions.code}`
          );
        } else {
          this.exportBase64ToPdf(
            res?.data?.customer?.transaction?.printForm,
            transaction?.transactionValueDate
          );
        }
      });
  }
  exportBase64ToPdf(base64: string, transactionDate) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = `Transaction Details (${this.datePipe.transform(
      transactionDate,
      "dd/MM/yyyy"
    )}).pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
