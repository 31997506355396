import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";
import { Overdraft } from "src/app/shared/models/models";
import { OverdraftService } from "src/app/shared/services/overdraft.service";

@Component({
  selector: "app-product-list-accounts",
  templateUrl: "./product-list-accounts.component.html",
  styleUrls: ["./product-list-accounts.component.scss"],
})
export class ProductListAccountsComponent implements OnInit {
  @Input() accounts: Array<accountsInfoBase>;
  @Input() currentItem?;
  @Input() accountsLoading;

  isTransactions = true;
  activeTab = "";

  tempStDate;
  tempEnDate;

  currentAccount;

  public overdraft: Overdraft;
  public overdraftLoading:boolean;

  constructor(private overdraftService: OverdraftService) {

  }


  ngOnInit(): void {

    // console.log(this.currentAccount)

    // this.clickAccount(this.currentAccount);

}

  public fetchOverdraft(): void {
    this.overdraftLoading = true;
    this.overdraft = null;
    this.overdraftService
      .fetchAccountOverdraft(this.currentAccount?.accountNumber)
      .subscribe(
        (res: any) => {
          this.overdraftLoading = false;
          this.overdraft = res?.data?.overdrafts[0];
        },
        (err: any) => {
          this.overdraftLoading = false;
        }
      );
  }

  ngOnChanges(changes) {
    if (changes && this.currentItem && this.accounts) {
      let tempAcc: any = this.accounts;
      this.currentAccount = tempAcc.filter(
        (acc) => acc.accountId === this.currentItem
      )[0];
    }
  }

  onClickMenu(isTransactions) {
    this.isTransactions = isTransactions;
  }

  clickAccount(acc) {

    this.currentAccount = acc;
    this.fetchOverdraft();
  }

  isActive(acc: any) {
    if (this.currentAccount) {
      if (acc.accountId === this.currentAccount.accountId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
