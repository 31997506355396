import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";
import { HttpClient , HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: "root",
})
export class TransfersService {

  getToken:string="";



  constructor(private graphQL: GraphQLRequestService,private http: HttpClient) {
    this.getToken = sessionStorage.getItem("token");
  }
  // private httpOptions = {

  //   headers: new HttpHeaders({
  //     'Authorization': 'Bearer '+this.getToken,


  //    })
  // };
  prepareTransfer(
    amount: number,
    currency: string,
    type: string,
    otpGuid: string
  ) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: TransferDataBaseInput!) {
          prepareTransfer(data: $data) {
            amount
            authType
            currency
            fee
            type
          }
        }
      `,
      {
        data: {
          amount,
          type,
          currency,
          channelTypeId: 508,
          otpGuid,
        },
      }
    );
  }

  transferToOwnAccountConfirm(
    senderAccountNumber: string,
    receiverAccountNumber: string,
    currency: string,
    amount: number,
    comment: string,
    fee: number,
    otpGuid: string
  ) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: InternalTransferInput!) {
          addInternalTransfer(data: $data) {
            id
            rejectReason
            rejectCode
            status
          }
        }
      `,
      {
        data: {
          amount,
          senderAccountNumber,
          receiverAccountNumber,
          comment,
          currency,
          channelTypeId: 0,
          fee,
          otpCode: null,
          otpGuid,
          isBeetweenOwnAccounts: true,
        },
      }
    );
  }

  fetchAccountsByMobileOrPn(searchValue) {
    searchValue = encodeURIComponent(searchValue);
    return this.graphQL.request(gql`{
      accounts(searchValue: "${searchValue}")
      {
        account,
        accountNumber,
        accountId,
        currency
      }
    }`);
  }

  transferToSomeoneInternalAccountConfirm(
    senderAccountNumber: string,
    currency: string,
    amount: number,
    fee: number,
    otpGuid: string,
    otpCode: string,
    isBeetweenOwnAccounts: boolean,
    receiverAccountId?: number,
    receiverAccountNumber?: string,
    receiverPerson?: string,
    comment?: string,
    senderPerson?:string,
    ownersCount?:number
  ) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: InternalTransferInput!) {
          addInternalTransfer(data: $data) {
            id
            rejectReason
            rejectCode
            status
            externalId
          }
        }
      `,
      {
        data: {
          amount,
          senderAccountNumber,
          receiverAccountNumber,
          comment,
          currency,
          channelTypeId: 508,
          fee,
          receiverPerson,
          receiverAccountId,
          otpCode,
          otpGuid,
          isBeetweenOwnAccounts,
          senderPerson,
          ownersCount

        },
      }
    );
  }

  transferToSomeoneExternalAccountConfirm(
    senderAccountNumber: string,
    receiverAccountNumber: string,
    currency: string,
    amount: number,
    comment: string,
    fee: number,
    receiverPerson: string,
    otpGuid: string,
    otpCode: string,
    receiverBankSwift: string,
    branch:string,
    senderPerson:string,
    receiverBank:string,
    ownersCount:number
  ) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: ExternalTransferInput!) {
          addExternalTransfer(data: $data) {
            id
            rejectReason
            rejectCode
            status
            externalId
          }
        }
      `,
      {
        data: {
          amount,
          senderAccountNumber,
          receiverAccountNumber,
          comment,
          currency,
          channelTypeId: 508,
          fee,
          receiverPerson,
          otpCode,
          otpGuid,
          receiverBankSwift,
          branch,
          senderPerson,
          receiverBank,
          ownersCount

        },
      }

    );

  }

  sendAprove(tnxId){
    // console.log(this.getToken);

    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken)
    .set('Content-Type', 'application/json')
    .set('InstanceName', 'ORISSA');
    return this.http.put('http://172.17.11.166:8052/api/v1/transfer/UpdateTransfer/'+tnxId+'/123/Approved',{}, { headers: headers });
  }


  reject(tnxId){
    // console.log(this.getToken);

    let headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.getToken)
    .set('Content-Type', 'application/json')
    .set('InstanceName', 'ORISSA');
    return this.http.put('http://172.17.11.166:8052/api/v1/transfer/RejectTransfer/'+tnxId+'/123/Reject',{}, { headers: headers });
  }
  checkJiramaBill(compteEnc: number, numeroFacture: number, otpGuid: any) {
    return this.graphQL.request(gql`{
      checkJiramaBill(
      checkInvoiceRequest:{
        compteEnc: "${compteEnc}",
        numeroFacture: "${numeroFacture}",
        otpGuid: "${otpGuid}"
      }){
        code
        message
        moisFacture
        montant
        nomClient
        numeroClient
        numeroFacture
        numeroSession
        refPortefeuille
      }
    }
    `);
  }

  payJiramaBill(
    compteClient: string,
    compteEnc: string,
    numeroFacture: string,
    otpGuid: string,
    code: string
  ) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: PayInvoiceRequestGType!) {
          payJiramaBill(payInvoiceRequest: $data) {
            code
            message
          }
        }
      `,
      {
        data: {
          compteClient,
          compteEnc,
          numeroFacture,
          otpGuid,
          code,
        },
      }
    );
  }
}
