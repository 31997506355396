import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import { Bank, Branch } from "../models/models";

@Injectable({
  providedIn: "root",
})
export class DictionaryService {
  public branches = new BehaviorSubject<Array<Branch>>(null);
  public branchesLoading = new BehaviorSubject<boolean>(false);
  public branchesErrorMessage = new BehaviorSubject<string>("");

  public banks = new BehaviorSubject<Array<Bank>>(null);
  public banksLoading = new BehaviorSubject<boolean>(null);
  public banksErrorMessage = new BehaviorSubject<string>("");

  constructor(private graphQl: GraphQLRequestService) {}

  branchesQuery(bankId) {
    return this.graphQl.request(gql`
      {
        dictionary {
          branches(bankId: ${bankId}) {
            id
            bankId
            branchName
            code
          }
        }
      }
    `);
  }
  banksQuery() {
    return this.graphQl.request(gql`
      {
        dictionary {
          banks {
            id
            bankCode
            bankName
            industryNumber
          }
        }
      }
    `);
  }

  fetchBranches(bankId) {
    this.branchesLoading.next(true);
    this.branchesErrorMessage.next("");
    this.branchesQuery(bankId).subscribe((res: any) => {
      this.branchesLoading.next(false);
      if (res.errors) {
        this.branchesErrorMessage.next(res?.errors[0]?.message);
      } else {
        this.branches.next(res?.data?.dictionary?.branches);
      }
    });
  }

  fetchBanks() {
    this.banksLoading.next(true);
    this.banksErrorMessage.next("");
    this.banksQuery().subscribe((res: any) => {
      this.banksLoading.next(false);
      if (res.errors) {
        this.banksErrorMessage.next(res?.errors[0]?.message);
      } else {
        this.banks.next(res?.data?.dictionary?.banks);
      }
    });
  }
}
