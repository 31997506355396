import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CalendarService } from "../calendar.service";

@Component({
  selector: "app-edit-event",
  templateUrl: "./edit-event.component.html",
  styleUrls: ["./edit-event.component.scss"],
})
export class EditEventComponent implements OnInit, OnDestroy {
  eventType: string = "";

  repeatEvent: boolean = false;

  currentDate: Date;

  pickedRepeatfrequency: number = 1;

  eventInfo;

  editEventForm: FormGroup;

  editEventSub: Subscription;

  loading: boolean = false;

  minDate: Date;
  maxDate: Date;

  errorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private calendarService: CalendarService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.newMaxMin();
    this.handleDateIfPassed();
  }
  handleDateIfPassed() {
    if (window.history.state.notification) {
      const notification = window.history.state.notification;
      this.eventInfo = notification;
      this.editEventForm.setValue({
        eventName: notification.eventName,
        amount: notification.amount,
        eventType: notification.eventTypeId,
      });
      this.currentDate = new Date(
        notification.eventDate.getFullYear(),
        notification.eventDate.getMonth(),
        notification.eventDate.getDate()
      );
      this.eventType = notification.eventTypeId;
      this.repeatEvent = notification.repeatType > 1 ? true : false;
    } else {
      this.router.navigate(["/calendar"]);
    }
  }
  initializeForm() {
    this.editEventForm = this.formBuilder.group({
      eventName: new FormControl("", [Validators.required]),
      amount: new FormControl("", [Validators.required]),
      eventType: new FormControl("", [Validators.required]),
    });
  }

  newMaxMin() {
    this.minDate = new Date();
    this.maxDate = new Date(
      new Date().getFullYear() + 5,
      new Date().getMonth(),
      new Date().getDate()
    );
  }

  pickEventType(type) {
    this.editEventForm.controls.eventType.setValue(type);
  }

  eventRepeatType(type) {
    this.repeatEvent = type;
  }

  pickRepeatFrequency(frequency) {
    this.pickedRepeatfrequency = frequency;
  }

  pickDate(date) {
    this.currentDate = date;
  }

  onSubmit() {
    this.markFormGroupTouched(this.editEventForm);

    if (this.editEventForm.valid) {
      this.editEvent();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  editEvent() {
    this.loading = true;

    const id = this.eventInfo.id;
    const eventName = this.editEventForm.controls.eventName.value;
    const amount = this.editEventForm.controls.amount.value;
    const eventDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      12
    );
    const currency = this.eventInfo.currency;
    const eventTypeId = this.editEventForm.controls.eventType.value ?? 4;
    const eventTriggeringQuantity = 1;
    const identifier = this.eventInfo.identifier;
    const label = this.eventInfo.label;
    const loanId = this.eventInfo.loanId;
    const providerId = this.eventInfo.providerId;
    const repeatType = this.eventInfo.repeatType;

    const editEvent = {
      updateEvent: {
        id,
        currency,
        eventName,
        amount,
        eventDate,
        identifier: identifier === null ? undefined : identifier,
        label: label === null ? undefined : label,
        loanId: loanId === null ? undefined : loanId,
        providerId: providerId === null ? undefined : providerId,
        repeatType: repeatType === null ? undefined : repeatType,
        eventTypeId,
        eventTriggeringQuantity,
      },
    };
    this.editEventSub = this.calendarService
      .editCalendarEvent(editEvent)
      .subscribe((res) => {
        this.loading = false;

        if (!res.errors) {
          this.router.navigate(["/calendar"]);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.editEventSub) {
      this.editEventSub.unsubscribe();
    }
  }
}
