import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let formatedDate: string;
    let day: string;
    let month: string;
    let year: string;
    if (value.getDate() < 10) {
      day = "0" + value.getDate().toString();
    } else {
      day = value.getDate().toString();
    }
    // transform month from programing to human language
    let tempMonth = value.getMonth() + 1;
    if (value.getMonth() < 9) {
      month = "0" + tempMonth.toString();
    } else {
      month = tempMonth.toString();
    }
    year = value.getFullYear().toString();

    formatedDate = day + month + year;
    return formatedDate;
  }

}

