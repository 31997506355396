import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class OtpService {
  constructor(private graphQL: GraphQLRequestService) {}

  generateOtp(data) {
    // console.log(data);
    return this.graphQL.mutate(
      gql`
        mutation ($data: OtpInput!) {
          generateOtp(data: $data)
        }
      `,
      {data}
    );
  }

  validateOtp(data) {
    return this.graphQL.mutate(
      gql`
        mutation ($data: OtpInput!) {
          validateOtp(data: $data)
        }
      `,
      data
    );
  }
}
