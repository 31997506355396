import { Component, OnInit } from "@angular/core";
import { clientType } from "../../shared/enums/enums";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { Guid } from "guid-typescript";
import { Router } from "@angular/router";
import { FunctionsService } from "../../shared/functions/functions.service";
import { RegisterService } from "../register/register.service";
import {
  registrationCheckCustomerResponse,
  validateRegistrationOTPResponse,
} from "../../shared/models/models";
import { OtpService } from "../../shared/services/otp.service";
import { ResetPasswordService } from "./reset-password.service";
import { DateFormatPipe } from "src/app/shared/pipes/date-format.pipe";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  clientTypeEnum = clientType;
  clientType: string = "";

  resetPasswordStep: number = 1;

  resetPasswordForm: FormGroup;

  disabledClass: boolean = false;

  // for datepicker
  minDate: Date;
  maxDate: Date;
  pickedDate: Date;

  resetPasswordCheckCustomerSub: Subscription;

  errorMessage: string = "";
  loading: boolean = false;

  otpLoading: boolean = false;
  resendBtnHovered: boolean = false;
  generateOtpSub: Subscription;
  validateOtpSub: Subscription;
  otpCode: string = "";
  guid: Guid;

  resetPasswordSub: Subscription;

  isModalOpen: boolean = false;

  constructor(
    private router: Router,
    private functionsService: FunctionsService,
    private resetPasswordService: ResetPasswordService,
    private dateFormat: DateFormatPipe,
    private modalService: ModalWithTextService
  ) {}

  ngOnInit(): void {
    this.modalService.modalClosed.subscribe((res: boolean) => {
      if (res === true) {
        this.onPopupClose();
      }
    });
    this.initParametersForDatePicker();
    this.initializeForm();
    this.giveCustomValidator();
  }

  pickClientType(type: clientType) {
    this.clientType = type;
  }

  goBack() {
    if (this.resetPasswordStep === 1) {
      this.router.navigateByUrl("/landing");
    } else if (this.resetPasswordStep === 2) {
      this.initializeForm();
      this.otpLoading = false;
      this.resendBtnHovered = false;
      this.otpCode = "";
      this.resetPasswordStep = 1;
    } else if (this.resetPasswordStep === 3) {
      this.resetPasswordStep = 1;
      this.initializeForm();
    }
  }

  nextStep() {
    if (this.resetPasswordStep === 1) {
      this.submitForm();
    } else if (this.resetPasswordStep === 2) {
      this.resetPasswordForm.get("smsCode").markAsTouched();
      this.validateOtp();
    }
  }

  initializeForm() {
    this.resetPasswordForm = new FormGroup({
      mobile: new FormControl("", [
        Validators.required,
        // Validators.pattern("[0-9]*"),

      ]),
      username: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(32),
        Validators.pattern("^[A-Za-z0-9]*$"),
      ]),
      smsCode: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]*"),
        Validators.maxLength(4),
        Validators.minLength(4),
      ]),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "^(?=.*[A-Z])(?=.*[!@#^$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
        ),
      ]),
      repeatNewPassword: new FormControl(""),
      tempPassword: new FormControl("", Validators.required),
    });
    this.errorMessage = "";
  }

  giveCustomValidator() {
    this.resetPasswordForm.controls.repeatNewPassword.setValidators([
      this.passwordsMatchValidation(
        this.resetPasswordForm.controls.newPassword
      ),
      Validators.required,
    ]);
  }

  passwordsMatchValidation(password: AbstractControl) {
    return (input: FormControl) => {
      const value = input.value;
      const passwordValue = password.value;
      return passwordValue !== value ? { passwordsDonotMatch: true } : null;
    };
  }

  initParametersForDatePicker() {
    this.minDate = new Date(
      new Date().getFullYear() - 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    this.maxDate = new Date(
      new Date().getFullYear() - 5,
      new Date().getMonth(),
      new Date().getDate()
    );
  }

  pickDate(date: Date) {
    this.resetPasswordForm
      .get("birthDate")
      .setValue(this.dateFormat.transform(date));
  }

  giveClassesToCustomInputs(input: AbstractControl) {
    if (this.resetPasswordStep === 2) {
      return "disabled";
    } else {
      return this.functionsService.giveClassesToCustomInputs(input);
    }
  }

  checkInputToGiveClass(input) {
    if (this.resetPasswordForm.controls[input].value) {
      return "disabled";
    }
  }

  submitForm() {
    this.markFormGroupAsTouched();
    this.resetPasswordCheckCustomer();
  }

  markFormGroupAsTouched() {
    this.resetPasswordForm.get("mobile").markAsTouched();
    this.resetPasswordForm.get("username").markAsTouched();
  }

  resetPasswordCheckCustomer() {
    if (this.formValidForFirstStep()) {
      this.errorMessage = "";
      this.loading = true;

      const data = {
        userName: this.resetPasswordForm.get("username").value,
        mobile: this.resetPasswordForm.get("mobile").value,
      };
      this.resetPasswordCheckCustomerSub = this.resetPasswordService
        .resetCheckUser(
          this.resetPasswordForm.get("username").value,
          this.resetPasswordForm.get("mobile").value
        )
        .subscribe((res: any) => {
          this.loading = false;
          if (res.errors) {
            this.errorMessage = res.errors[0].extensions.code
              ? res.errors[0].extensions.code
              : "GENERAL_ERROR";
          } else if (res.data.restorePasswordCheckUser) {
            this.resetPasswordStep = 2;
            this.generateOtp();
          } else {
            this.errorMessage = "NOT_FOUND_RESET_USER";
          }
        });
    } else {
      this.errorMessage = "INVALID_INPUT";
    }
  }

  disableInputs() {
    this.resetPasswordForm.get("birthDate").invalid;
  }

  getSmsCode(code) {
    this.resetPasswordForm.get("smsCode").setValue(code);
  }

  generateGUID() {
    this.guid = Guid.create();
  }

  generateOtp() {
    this.errorMessage = "";
    this.generateGUID();
    this.loading = true;
    this.otpLoading = false;

    const data = {
      mobile: this.resetPasswordForm.get("mobile").value,
      sessionId: this.guid.toString(),
      code: "",
    };

    this.generateOtpSub = this.resetPasswordService
      .generateResetPasswordOtp({ data })
      .subscribe((res: any) => {
        this.loading = false;
        this.otpLoading = false;

        if (res.errors) {
          this.errorMessage = "RESEND_CODE";
          this.otpLoading = true;
          this.resendBtnHovered = true;
          this.resetPasswordForm.controls.smsCode.markAsUntouched();
        }
        this.resetPasswordStep = 2;
      });
  }

  validateOtp() {
    if (this.formValidForSecondStep()) {
      this.errorMessage = "";
      this.loading = true;
      this.otpLoading = true;

      const data = {
        mobile: this.resetPasswordForm.get("mobile").value,
        sessionId: this.guid.toString(),
        code: this.resetPasswordForm.get("smsCode").value,
      };

      this.validateOtpSub = this.resetPasswordService
        .validateResetPasswordOtp({ data })
        .subscribe((res: any) => {
          this.loading = false;
          if (res.errors) {
            this.otpLoading = true;
            this.resendBtnHovered = true;
            this.resetPasswordForm.controls.smsCode.markAsUntouched();
            this.errorMessage = res.errors[0].extensions.code
              ? res.errors[0].extensions.code
              : "GENERAL_ERROR";
          } else {
            this.resetPasswordStep = 3;
          }
        });
    }
  }

  resetPassword() {
    if (this.formValidForThirdStep()) {
      this.loading = true;
  
      this.resetPasswordSub = this.resetPasswordService
        .resetPassword(
          this.resetPasswordForm.get("username").value,
          this.resetPasswordForm.get("mobile").value,
          this.resetPasswordForm.get("newPassword").value
        )
        .subscribe((res: any) => {
          this.loading = false;
  
          if (res.errors) {
            if (res.errors[0].message === 'GraphQL.ExecutionError: YOUR_USERNAME_IS_BLOCKED,PLEASE_VISIT_THE_SERVICE_CENTER ') {
              this.errorMessage = 'USER_BLOCKED';
            } else {
              this.errorMessage = res.errors[0].extensions.code
                ? res.errors[0].extensions.code
                : 'GENERAL_ERROR';
            }
          } else {
            this.modalService.generateModal('LANDING.PASS_CHANGED');
          }
        });
    }
  }
  

  resendSMSCode() {
    this.resendBtnHovered = false;
    this.generateOtp();
  }

  formValidForFirstStep() {
    if (
      this.resetPasswordForm.get("mobile").valid &&
      this.resetPasswordForm.get("username").valid &&
      !this.loading
    ) {
      return true;
    }
  }

  formValidForSecondStep() {
    if (
      this.resetPasswordForm.get("mobile").valid &&
      this.resetPasswordForm.get("username").valid &&
      this.resetPasswordForm.get("smsCode").valid &&
      !this.loading
    ) {
      return true;
    } else {
      return false;
    }
  }

  formValidForThirdStep() {
    if (
      this.resetPasswordForm.get("mobile").valid &&
      this.resetPasswordForm.get("username").valid &&
      this.resetPasswordForm.get("newPassword").valid &&
      !this.loading
    ) {
      return true;
    } else {
      return false;
    }
  }

  passwordValidatorLength() {
    return this.resetPasswordForm.get("newPassword").value.length < 8 &&
      this.resetPasswordForm.get("newPassword").touched
      ? true
      : false;
  }

  passwordValidatorLowercaseLetter() {
    const regex = new RegExp(/[a-z]/g);
    return (
      !regex.test(this.resetPasswordForm.get("newPassword").value) &&
      this.resetPasswordForm.get("newPassword").touched
    );
  }

  passwordValidatorUppercaseLetter() {
    const regex = new RegExp(/[A-Z]/g);
    return (
      !regex.test(this.resetPasswordForm.get("newPassword").value) &&
      this.resetPasswordForm.get("newPassword").touched
    );
  }

  passwordValidatorNumber() {
    const regex = new RegExp(/[0-9]/g);
    return (
      !regex.test(this.resetPasswordForm.get("newPassword").value) &&
      this.resetPasswordForm.get("newPassword").touched
    );
  }

  passwordValidatorSymbol() {
    const regex = new RegExp(/[$@$!%*#?&]/g);
    return (
      !regex.test(this.resetPasswordForm.get("newPassword").value) &&
      this.resetPasswordForm.get("newPassword").touched
    );
  }

  // popup
  closeClick() {
    this.router.navigateByUrl("/");
  }

  onPopupClose() {
    this.closeClick();
  }

  ngOnDestroy(): void {
    if (this.resetPasswordCheckCustomerSub) {
      this.resetPasswordCheckCustomerSub.unsubscribe();
    }
    if (this.generateOtpSub) {
      this.generateOtpSub.unsubscribe();
    }
    if (this.validateOtpSub) {
      this.validateOtpSub.unsubscribe();
    }
    if (this.resetPasswordSub) {
      this.resetPasswordSub.unsubscribe();
    }
  }
}
