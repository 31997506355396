<div class="credo-page">
    <main>
        <div class="card transfers-card" [formGroup]="transferToSomeoneForm">
            <div class="card-header">
                <h1 class="card-title">
                    {{ "TRANSFERS.TO_SOMEONE_ELSE" | translate }}
                </h1>
            </div>
            <div class="back-btn-wrap">
                <a [routerLink]="['/transfer']" class="back-btn">
                    <span class="icon i-dropdown-arrow"></span> {{ "GENERAL.BACK" | translate }}
                </a>
            </div>

            <div class="card-body preloader-parent">
                <div class="card-block accounts-wrap">
                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-6">
                            <div class="form-label">
                                {{ "TRANSFERS.FROM_ACC" | translate }}
                            </div>
                            <app-select [loadingtext]="'GENERAL.SELECT_ACC' | translate" [loading]="prepareTransferLoading || step !== 1" [newItem]="false" [choseElement]="selectedAccountFrom" [inputArray]="accountsFrom" (selectedItem)="selectAccountFrom($event)"></app-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-label">
                                {{ "TRANSFERS.TO_ACC" | translate }}
                            </div>
                            <app-select [loadingtext]="'GENERAL.SELECT_ADD_BENEF' | translate" [loading]="prepareTransferLoading || step !== 1" [newItem]="true" [choseElement]="selectedAccountTo" [inputArray]="templateArr" (selectedItem)="selectAccountTo($event)" (newItemClick)="prepNewTemplate()"></app-select>
                        </div>
                    </div>
                </div>

                <div class="card-block" *ngIf="selectedAccountTo && !isTransferTemplateSelected">
                    <div class="row-sm">
                        <div class="col-12">
                            <div class="form-label">
                                {{ "TRANSFERS.CHOOSE_BANK" | translate }}
                            </div>
                            <!-- (chooseBranchCode)="getEmittedBranchCode($event)" -->
                            <app-bank-select-for-transfers (chooseIndustryNumber)="getEmittedIndustryNumber($event)"></app-bank-select-for-transfers>
                        </div>
                    </div>
                </div>

                <div class="card-block info-wrap" *ngIf="
            selectedAccountFrom && selectedAccountTo && !showAddTemplateBlock
          ">
                    <div class="row-lg">
                        <div class="col-auto">
                            <span class="name">{{ "GENERAL.NAME" | translate }}</span>
                            <span class="description">{{ selectedTemplate.name }}</span>
                        </div>
                        <!-- <div class="col-auto">
              <span class="name">{{ "GENERAL.BANK_NAME" | translate }}</span>
              <span class="description">{{ selectedTemplate.bankName }}</span>
            </div>
            <div class="col-auto">
              <span class="name">{{ "GENERAL.BANK_CODE" | translate }}</span>
              <span class="description">{{
                selectedTemplate.bankBicCode
              }}</span>
            </div> -->
                    </div>
                </div>

                <div class="card-block details-wrap" *ngIf="
            (selectedAccountFrom && selectedTemplate) ||
            (selectedAccountFrom && showAddTemplateBlock)
          ">
                    <h4 class="card-block-title">{{ "GENERAL.DETAILS" | translate }}</h4>

                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-6 col-xl-auto" [ngClass]="{ disabled: step !== 1 }">
                            <input class="form-input" appCurrency type="text" [placeholder]="'GENERAL.AMOUNT' | translate" formControlName="amount" [ngClass]="giveInputClasses('amount')" />
                        </div>
                        <div class="col-12 col-md-6" [ngClass]="{ disabled: step !== 1 }">
                            <input class="form-input" type="text" [placeholder]="'GENERAL.DESCRIPTION' | translate" formControlName="description" [ngClass]="giveInputClasses('description')" />
                        </div>
                    </div>
                    <div class="row-sm transfer-amount-validation-error">
                        <p *ngIf="
                transferToSomeoneForm.controls.amount.dirty &&
                transferToSomeoneForm.controls.amount.invalid &&
                transferToSomeoneForm.controls.amount.errors.max &&
                transferToSomeoneForm.controls.amount.value < 500000
              ">
                            {{ "ERROR_CODES.INSUFFICIENT_FUNDS" | translate }}
                        </p>
                        <p *ngIf="
                transferToSomeoneForm.controls.amount.dirty &&
                transferToSomeoneForm.controls.amount.invalid &&
                transferToSomeoneForm.controls.amount.errors.max &&
                transferToSomeoneForm.controls.amount.value >= 100000
              ">
                            Please Note That You Can Only Transfer An Amount of Up to ZMW {{ "GENERAL.AMOUNT" | translate }}:{{ 100000 | moneyFormat }} , For All Transfers Above ZMW 100,000.00, and International Transfers, Kindly Visit Your Nearest Branch
                        </p>
                    </div>
                </div>

                <div class="card-block user-wrap" *ngIf="selectedAccountFrom && showAddTemplateBlock">
                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-auto form-group" [ngClass]="{ disabled: step !== 1 }">
                            <input class="form-input" type="text" [placeholder]="'TEMPLATES_C.ACC_NUM' | translate" formControlName="accountNumber" minlength="13" maxlength="13" style="text-transform: uppercase" [ngClass]="giveInputClasses('accountNumber')" />
                        </div>
                        <div class="col-12 col-md-auto form-group" [ngClass]="{ disabled: step !== 1 }">
                            <input class="form-input" type="text" [placeholder]="'TEMPLATES_C.NAME' | translate" formControlName="name" [ngClass]="giveInputClasses('name')" />
                        </div>
                        <div class="col-12 col-md-auto form-group">
                            <button class="btn-icon btn-heart" [ngClass]="{ selected: makeTemplateFavourite }" (click)="clickMakeTemplateFavourite()">
                <i class="icon i-heart"></i>
                <span>{{ "TEMPLATES_C.ADD_TO_FAV" | translate }}</span>
              </button>
                        </div>
                    </div>
                </div>



                <div class="card-block user-wrap" *ngIf="selectedAccountFrom && showAddTemplateBlock">
                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-auto form-group" [ngClass]="{ disabled: step !== 1 }">
                            <select class="form-input" formControlName="branchName" [ngClass]="giveInputClasses('branchName')">
                            <option value="" selected disabled>Select Branch</option>
                            <option *ngFor="let branch1 of branches" [value]="branch1">{{branch1}}</option>
                          </select>
                            <!-- <input class="form-input" type="text" placeholder="Branch" formControlName="branchName" maxlength="30" style="text-transform: uppercase" [ngClass]="giveInputClasses('branchName')" /> -->
                        </div>


                    </div>
                </div>



                <ng-container *ngIf="
            (selectedAccountFrom && selectedAccountTo) || showAddTemplateBlock
          ">
                    <div class="card-block" *ngIf="isDisabledFirstStep()">
                        <p class="tax-wrap">
                            {{ "GENERAL.FEE" | translate }}
                            <span>{{ prepareTransfer?.fee | moneyFormat }}</span>
                        </p>
                    </div>
                    <div class="card-block user-wrap" *ngIf="isDisabledFirstStep()">
                        <div class="row-sm fieldset" *ngIf="prepareTransfer?.authType === 'SMS' && step === 2">
                            <div class="col-12 col-md-6 form-group">
                                <div class="sms-wrap">
                                    <app-sms-request [valid]="
                      transferToSomeoneForm.controls.otpCode.valid ||
                      transferToSomeoneForm.controls.otpCode.touched
                        ? true
                        : false
                    " [disabled]="loading" [resendBtnHovered]="resendBtnHovered" [loading]="loading" (giveCode)="getSmsCode($event)" (resend)="resendSMSCode($event)">
                                    </app-sms-request>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="preloader three-dot-loader" *ngIf="prepareTransferLoading"></div>
                </ng-container>

                <div class="preloader full-screen double-circles" *ngIf="loading"></div>
            </div>

            <div class="card-footer">
                <div class="row-sm btn-row">
                    <div class="col-12 col-md-auto" *ngIf="step === 2">
                        <button class="btn btn-primary" (click)="gotoFirstStep()" [disabled]="transferConfirmationLoading">
              {{ "GENERAL.BACK" | translate }}
            </button>
                    </div>
                    <div class="col-12 col-md-auto">
                        <button class="btn btn-primary btn-link" [ngClass]="{disabled: checkNotEmpty()}" (click)="submitForm(false)" [disabled]="transferConfirmationLoading">
              <ng-container *ngIf="step == 1">{{
                "GENERAL.NEXT" | translate
              }}</ng-container>
              <ng-container *ngIf="step == 2">{{
                "TRANSFERS.TRANSFER" | translate
              }}</ng-container>
            </button>
                    </div>
                    <div class="col-12 col-md-auto" *ngIf="step === 2">
                        <button class="btn btn-secondary btn-link" *ngIf="showAddTemplateBlock" [disabled]="transferConfirmationLoading" [ngClass]="{ disabled: checkNotEmpty() }" (click)="submitForm(true)">
              {{ "TRANSFERS.SAVE_AND_TRANSFER" | translate }}
            </button>
                    </div>
                    <div class="col-12 col-md-auto">
                        <h4 style="color: #ea4c36" *ngIf="errorMessage">
                            {{ "ERROR_CODES." + errorMessage | translate }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>