import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { selectType } from "../../models/models";
import { MoneyFormatPipe } from "../../pipes/money-format.pipe";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit {
  selectState = "closed";
  invalid = false;

  @Input() loadingtext: string;
  @Input() loading: boolean = true;
  @Input() choseElement: selectType;
  @Input() inputArray: Array<selectType>;
  @Input() newItem: boolean;
  @Input() showDropIcon: boolean = false;

  @Output() selectedItem = new EventEmitter<selectType>();
  @Output() newItemClick = new EventEmitter<boolean>();

  displayItem: selectType; 

  dropArrow: boolean = true;


  constructor(private moneyFormat: MoneyFormatPipe) {}

  ngOnInit() {
  }
  formatMoney(): void {
    this.inputArray?.forEach((element) => {
      for (const [key, value] of Object.entries(element)) {
        if (value?.toString().includes("MGA") && !value.toString().includes(",")) {
          let amount = element[key].replace("MGA", "").trim();
          if (amount != "") {
            amount = parseFloat(amount);
            let transformedAmount = this.moneyFormat.transform(amount);
            element[key] = `${transformedAmount} MGA`;
          }
        }
      }
    });
    this.displayItem = null;
  }
  ngOnChanges(changes): void {
    if (this.inputArray) {
      if (this.choseElement) {
        this.displayItem = this.choseElement;
      }else{
        this.formatMoney();
      }
    }
  }
  close() {
    this.selectState = "closed";
  }

  toggleState() {
    if (this.selectState === "open") {
      this.selectState = "closed";
    } else {
      this.selectState = "open";
    }
  }

  select(account) {
    this.selectedItem.emit(account);
    this.displayItem = account;
  }

  disableSelect() {
    if (this.loading) {
      return true;
    } else if (this.inputArray) {
      return false;
    } else {
      return true;
    }
  }
}
