import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { selectType } from "src/app/shared/models/models";
import { CalendarService } from "../calendar.service";

@Component({
  selector: "app-add-event",
  templateUrl: "./add-event.component.html",
  styleUrls: ["./add-event.component.scss"],
})
export class AddEventComponent implements OnInit, OnDestroy {
  eventType = "custom";

  repeatEvent = false;

  currentDate: Date;

  eventName = "";
  amount: number;

  repeatArray: Array<selectType> = [];
  pickedRepeatfrequency: selectType;
  loadingSelectRepeatFrequencyText: string = "Select currency";
  newEventForm: FormGroup;

  addEventSub: Subscription;

  loading = false;
  minDate: Date;
  maxDate: Date;

  errorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private calendarService: CalendarService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.newMaxMin();
    this.currentDate = window.history.state.date??new Date();
    this.generateRepeatArray();
  }

  initializeForm() {
    this.newEventForm = this.formBuilder.group({
      eventName: new FormControl("", [Validators.required]),
      amount: new FormControl("", [Validators.required]),
      currency: new FormControl("MGA", [Validators.required]),
      eventType: new FormControl("", [Validators.required]),
      repeatEvent: new FormControl(false),
    });
  }

  newMaxMin() {
    this.minDate = new Date(
      new Date().getFullYear() - 5,
      new Date().getMonth(),
      new Date().getDate()
    );
    this.maxDate = new Date(
      new Date().getFullYear() + 5,
      new Date().getMonth(),
      new Date().getDate()
    );
  }

  pickEventType(type) {
    this.newEventForm.controls.eventType.setValue(type);
  }

  eventRepeatType(type) {
    this.repeatEvent = type;
  }

  pickRepeatFrequency(frequency) {
    this.pickedRepeatfrequency = frequency;
  }

  pickDate(date: Date) {
    this.currentDate = date;
    this.generateRepeatArray();
  }

  generateRepeatArray() {
    const count = 12 - this.currentDate.getMonth();
    const array = [];
    for (let i = 2; i <= count; i++) {
      array.push({
        mainTitle: "for " + i + " months",
        id: i,
      });
    }

    this.repeatArray = array;

    this.pickedRepeatfrequency = array.length ? array[0] : undefined;
  }

  onSubmit() {
    this.markFormGroupTouched(this.newEventForm);

    if (this.newEventForm.valid) {
      this.addEvent();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  addEvent() {
    this.loading = true;
    const eventName = this.newEventForm.controls.eventName.value;
    const amount = +this.newEventForm.controls.amount.value;
    const eventDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      12
    );
    const currency = "ZMW";
    const eventTypeId = this.newEventForm.controls.eventType.value??4;
    const eventTriggeringQuantity = this.newEventForm.controls.repeatEvent.value
      ? this.pickedRepeatfrequency.id
      : 1;
    const newEvent = {
      calendarEvent: {
        eventName,
        amount,
        eventDate,
        currency,
        eventTypeId,
        eventTriggeringQuantity,
      },
    };
    this.addEventSub = this.calendarService
      .createCalendarEvent(newEvent)
      .subscribe((res) => {
        this.loading = false;
        if (!res.errors) {
          this.router.navigate(["/calendar"]);
        } else {
          this.errorMessage = res.errors[0].extensions.code;
        }
      });
  }

  giveInputClasses(input) {
    if (
      this.newEventForm.get(input).invalid &&
      this.newEventForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  ngOnDestroy(): void {
    if (this.addEventSub) {
      this.addEventSub.unsubscribe();
    }
  }
}
