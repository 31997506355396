import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mobileTemplatesFilter",
})
export class MobileTemplatesFilterPipe implements PipeTransform {
  transform(array: any, searchQuerry: any): any {
    if (searchQuerry === undefined) {
      return array;
    }

    return array.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
        item.identifiers[0].fieldValue
          .toLowerCase()
          .includes(searchQuerry.toLowerCase())
    );
  }
}
