import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";

@Injectable({
  providedIn: "root",
})
export class OverdraftService {

  constructor(private graphQL: GraphQLRequestService) {}

  public fetchAccountOverdraft(accountNumber) {
    return this.graphQL.request(gql`
    {
      overdrafts(accountNumber:"${accountNumber}") {
        amountGranted
        amountUsed
        currency
        customerName
        customerNumber
        productType
        overdraftStatus
        loanOfficer
        productType
        expiryDate
      }
    }
  `);
  }
}
