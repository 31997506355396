<div class="credo-page">
    <main>
        <div class="card transfers-card preloader-parent">
            <div class="card-header">
                <h1 class="card-title">{{ "TRANSFERS.BETWEEN_OWN" | translate }}</h1>
            </div>
            <div class="back-btn-wrap">
                <a [routerLink]="['/transfer']" class="back-btn">
                    <span class="icon i-dropdown-arrow"></span> {{ "GENERAL.BACK" | translate }}
                </a>
            </div>

            <div class="card-body" [formGroup]="form">
                <div class="card-block accounts-wrap">
                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-6">
                            <div class="form-label">
                                {{ "TRANSFERS.FROM_ACC" | translate }}
                            </div>
                            <app-select [loadingtext]="accountFromLoadingText" [loading]="prepareTransferLoading" [newItem]="false" [choseElement]="selectedAccountFrom" [inputArray]="accountsFrom" (selectedItem)="selectAccountFrom($event)"></app-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-label">
                                {{ "TRANSFERS.TO_ACC" | translate }}
                            </div>
                            <app-select [loadingtext]="accountToLoadingText" [loading]="prepareTransferLoading" [newItem]="false" [choseElement]="selectedAccountTo" [inputArray]="accountsTo" (selectedItem)="selectAccountTo($event)"></app-select>
                        </div>
                    </div>
                    <h3 class="error-text" *ngIf="errorMessage">
                        {{ "ERROR_CODES." + errorMessage | translate }}
                    </h3>
                </div>

                <div class="card-block details-wrap" *ngIf="selectedAccountFrom && selectedAccountTo && step !== 1">
                    <h4 class="card-block-title">{{ "GENERAL.DETAILS" | translate }}</h4>

                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-6 col-xl-auto">
                            <input appCurrency [placeholder]="'GENERAL.AMOUNT' | translate" class="form-input" type="text" formControlName="amount" [ngClass]="giveInputClasses(form.controls.amount)" />
                        </div>
                        <div class="col-12 col-md-6">
                            <input [placeholder]="'GENERAL.DESCRIPTION' | translate" class="form-input" type="text" formControlName="description" [ngClass]="giveInputClasses(form.controls.description)" />
                        </div>
                    </div>
                    <div class="row-sm transfer-amount-validation-error">
                        <p *ngIf="
                form.controls.amount.dirty &&
                form.controls.amount.invalid &&
                form.controls.amount.errors.max &&
                form.controls.amount.value < 500000
              ">
                            {{ "ERROR_CODES.INSUFFICIENT_FUNDS" | translate }}
                        </p>
                        <p *ngIf="
                form.controls.amount.dirty &&
                form.controls.amount.invalid &&
                form.controls.amount.errors.max &&
                form.controls.amount.value >= 100000
              ">
                            Please Note That You Can Only Transfer An Amount of Up to ZMW {{ "GENERAL.AMOUNT" | translate }}:{{ 100000 | moneyFormat }} , For All Transfers Above ZMW 100,000.00, and International Transfers, Kindly Visit Your Nearest Branch
                        </p>
                    </div>

                    <p class="tax-wrap" *ngIf="isDisabledFirstStep()">
                        {{ "GENERAL.FEE" | translate }}:
                        <span>{{ prepareTransfer?.fee | moneyFormat }}
              {{ prepareTransfer?.currency }}</span
            >
          </p>
        </div>

        <div class="card-block btn-wrap">
          <div class="row-sm btn-row">
            <div class="col-12 col-md-auto" *ngIf="!isDisabledFirstStep()">
              <button
                class="btn btn-primary btn-link"
                [ngClass]="{ disabled: form.invalid }"
                [disabled]="form.invalid"
                (click)="prepareForTransfer()"
              >
                {{ "GENERAL.NEXT" | translate }}
              </button>
            </div>
            <div class="col-12 col-md-auto">
              <button
                (click)="gotoFirstStep()"
                class="btn btn-primary"
                [ngClass]="{ disabled: transferConfirmationLoading }"
                [disabled]="transferConfirmationLoading"
                *ngIf="isDisabledFirstStep()"
              >
                {{ "GENERAL.BACK" | translate }}
              </button>
            </div>
            <div class="col-12 col-md-auto">
              <button
                (click)="transferToOwnAccountConfirm()"
                class="btn btn-primary btn-link"
                [ngClass]="{ disabled: transferConfirmationLoading }"
                [disabled]="transferConfirmationLoading"
                *ngIf="isDisabledFirstStep()"
              >
                {{ "GENERAL.TRANSFER" | translate }}
              </button>
            </div>
          </div>
          <h3 class="error-text" *ngIf="transferConfirmationError">
            {{ transferConfirmationError }}
          </h3>
        </div>
      </div>

      <div class="preloader full-screen double-circles" *ngIf="loading || prepareTransferLoading"></div>
    </div>
  </main>
</div>