import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalendarDataService } from 'src/app/shared/services/calendar/calendarData.service';

@Component({
  selector: 'app-pick-year',
  templateUrl: './pick-year.component.html',
  styleUrls: ['./pick-year.component.scss']
})
export class PickYearComponent implements OnInit {

  @Input() pickedDate: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Output() giveYear = new EventEmitter<any>();
  maxYearList;
  minYearList;

  yearsList = [];
  counter = 0;

  constructor(private calendarDataService: CalendarDataService) {}

  ngOnInit() {
    this.generateYears();
  }

  generateYears() {
    const currentYear = this.pickedDate.getFullYear();
    this.counter = 0;
    for (let i = currentYear - 12; i < currentYear; i++) {
      this.yearsList[this.counter] = { valid: true, value: i };
      this.counter++;
    }
    for (let i = currentYear; i <= currentYear + 12; i++) {
      this.yearsList[this.counter] = { valid: true, value: i };
      this.counter++;
    }
    this.minYearList = this.yearsList[0];
    this.maxYearList = this.yearsList[24];
    this.counter = 0;

    this.checkdate();
  }

  generatePreviousYearList() {
    const firstYear = this.yearsList[0].value;

    this.yearsList = [];
    this.counter = 0;

    for (let i = firstYear - 25; i < firstYear; i++) {
      this.yearsList[this.counter] = { valid: true, value: i };
      this.counter++;
    }
    this.counter = 0;
    this.minYearList = this.yearsList[0];
    this.maxYearList = this.yearsList[24];

    this.checkdate();
  }

  generateNextYearList() {
    const lastYear = this.yearsList[24].value;

    this.yearsList = [];
    this.counter = 0;

    for (let i = lastYear + 1; i <= lastYear + 25; i++) {
      this.yearsList[this.counter] = { valid: true, value: i };
      this.counter++;
    }
    this.counter = 0;
    this.minYearList = this.yearsList[0];
    this.maxYearList = this.yearsList[24];

    this.checkdate();
  }

  checkdate() {
    let testDate: Date;
    testDate = this.pickedDate;
    this.yearsList.forEach((temp) => {
      this.yearsList[
        this.yearsList.indexOf(temp)
      ].valid = this.calendarDataService.checkDateValidation(
        this.minDate,
        this.maxDate,
        temp.value
      );
    });
  }

  pickYear(year) {
    if (year.valid) {
      this.giveYear.emit(year.value);
    }
  }

  giveClassAndCheck(year) {
    let className = "";

    if (this.pickedDate.getFullYear() === year.value) {
      className = "active";
    } else if (!year.valid) {
      className = "disabled";
    }

    return className;
  }
}
