<footer class="landing-footer">
    <div class="landing-container">
        <ul class="links">
            <li>
                <a (click)="openPdf('FAQ')">{{ "GENERAL.FAQ" | translate }}</a>
            </li>
            <li>

                <a href="landing/terms-of-use" target="_self" class="">Terms of use

        </a>
                <!-- <a (click)="openPdf('TERMS')">{{
          "GENERAL.TERMS_CONDITIONS" | translate
        }}</a> -->
            </li>
        </ul>

        <p>AB Bank Zambia © {{ today.getFullYear() }}</p>

        <div class="social-wrap">
            <a href="https://www.facebook.com/abbankzm" target="_blank" class="btn btn-sm btn-circle btn-outline-secondary"><i class="icon i-facebook"></i
      ></a>

            <a href="https://www.linkedin.com/company/abbankzm/" target="_blank" class="btn btn-sm btn-circle btn-outline-secondary"><i class="icon i-linkedin"></i
      ></a>
        </div>
    </div>
</footer>
