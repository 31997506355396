<div class="credo-page">
  <main>
    <div class="card templates-card templates-grid-card preloader-parent">
      <div class="card-header">
        <h1 class="card-title">{{ "TEMPLATES_C.TEMPLATES" | translate }}</h1>

        <div class="controls">
          <div class="row-sm">
            <div class="col-12 col-sm-6 col-xl-auto">
              <input
                class="form-input input-sm search-input"
                [placeholder]="'GENERAL.SEARCH' | translate"
                type="text"
                [(ngModel)]="filterInput"
              />
            </div>
            <div class="col-12 col-sm-6 col-xl-auto">
              <label class="radio pointer">
                <input
                  type="checkbox"
                  [(ngModel)]="showFavouritesOnly"
                  (ngModelChange)="categorizeTemplates()"
                />
                <span>{{ "TEMPLATES_C.FAVOURITES_ONLY" | translate }}</span>
              </label>
            </div>
            <div class="col-12 col-sm-6 col-xl-auto">
              <button
                class="btn btn-sm btn-primary btn-add"
                [routerLink]="['/templates/add']"
              >
                {{ "TEMPLATES_C.ADD_NEW_TEMP" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="card-block-header">
          <h4 class="card-block-title-sm">
            {{ "TEMPLATES_C.ACCOUNT_NUMBERS" | translate }}
          </h4>
        </div>
        <div class="items-grid">
          <div
            class="grid-item"
            *ngFor="
              let template of accountTemplates
                | accountTemplatesFilter: filterInput
            "
          >
            <div class="action-buttons">
              <button class="btn-icon" (click)="deleteTemplatePrompt(template)">
                <i class="icon i-trash"></i>
              </button>
              <button
                class="btn-icon"
                (click)="goToEditTemplatePage(template, 'account')"
              >
                <i class="icon i-pencil2"></i>
              </button>
              <button
                class="btn-icon btn-heart"
                [ngClass]="{ selected: template.isFavorite }"
                (click)="toggleFavourite(template, 'account')"
              >
                <i class="icon i-heart"></i>
              </button>
            </div>
            <div class="wrap">
              <div class="avatar">{{ generateAvatarInitials(template) }}</div>
              <div>
                <span class="name">{{ template.name }}</span>
                <span class="description">{{
                  template.beneficiaryAccountNumber
                }}</span>
                <button
                  class="btn btn-sm btn-primary"
                  (click)="
                    goToTransferPageAndPassTemplateId(
                      template,
                      '/transfer/to-someones-account'
                    )
                  "
                >
                  {{ "TEMPLATES_C.TRANSFER" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-block-header">
          <h4 class="card-block-title-sm">
            {{ "TEMPLATES_C.PNS" | translate }}
          </h4>
        </div>
        <div class="items-grid">
          <div
            class="grid-item"
            *ngFor="
              let template of mobileOrPnTemplates
                | mobileOrPnTemplatesFilter: filterInput
            "
          >
            <div class="action-buttons">
              <button class="btn-icon" (click)="deleteTemplatePrompt(template)">
                <i class="icon i-trash"></i>
              </button>
              <button
                class="btn-icon"
                (click)="goToEditTemplatePage(template, 'mobile-or-pn')"
              >
                <i class="icon i-pencil2"></i>
              </button>
              <button
                class="btn-icon btn-heart"
                [ngClass]="{ selected: template?.isFavorite }"
                (click)="toggleFavourite(template, 'mobileOrPn')"
              >
                <i class="icon i-heart"></i>
              </button>
            </div>
            <div class="wrap">
              <div class="avatar">{{ generateAvatarInitials(template) }}</div>
              <div>
                <span class="name">{{ template?.name }}</span>
                <span class="description">{{ getDecodedDocumentNumber(template?.documentNumber)}}</span>
                <button
                  class="btn btn-sm btn-primary"
                  (click)="
                    goToTransferPageAndPassTemplateId(
                      template,
                      '/transfer/by-mobile-or-pn'
                    )
                  "
                >
                  {{ "TEMPLATES_C.TRANSFER" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="preloader full-screen double-circles" *ngIf="loading"></div>
    </div>
  </main>

  <h4 *ngIf="errorMessage">{{ errorMessage }}</h4>
</div>

<app-are-you-sure
  [questionText]="questionText"
  *ngIf="showDeletePopup"
  (cancel)="showDeletePopup = false"
  (confirm)="deleteTemplate()"
  [loading]="false"
></app-are-you-sure>
