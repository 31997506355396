<div class="credo-page">
  <main>
    <div class="card settings-card">
      <div class="card-header">
        <h1 class="card-title">
          {{ "SETTINGS_P.SETTINGS" | translate }}
        </h1>
      </div>
      <div class="card-body">
        <div class="card-block-header">
          <h2 class="card-title">
            {{ "SETTINGS_P.PERSONAL_INFO" | translate }}
          </h2>
        </div>
        <div class="card-block fieldset" *ngIf="customerInfo">
          <div class="row-sm">
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.NAME" | translate
                }}</span>
                <input type="text" value="{{ customerInfo.firstNameLat }}" />
              </label>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.SURNAME" | translate
                }}</span>
                <input type="text" value="{{ customerInfo.lastNameLat }}" />
              </label>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.BIRTHDATE" | translate
                }}</span>
                <input type="text" value="{{ customerBirthDate }}" />
              </label>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.ID" | translate
                }}</span>
                <input type="text" value="{{ NRCNumber }}" />
              </label>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.MOBILE" | translate
                }}</span>
                <input type="text" value="{{ customerInfo.mobile }}" />
              </label>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.ADDRESS" | translate
                }}</span>
                <input type="text" value="{{ customerInfo.fullAddress }}" />
              </label>
            </div>
            <div class="col-12 col-md-6 col-xl-3 form-group">
              <label class="form-input-with-label disabled">
                <span class="form-input-label">{{
                  "FORM_INPUTS.EMAIL" | translate
                }}</span>
                <input type="text" value="{{ customerInfo.email }}" />
              </label>
            </div>
          </div>

          <div class="row-sm">
            <h2 class="no-data" *ngIf="errorMessage">
              {{ "ERROR_CODES." + errorMessage | translate }}
            </h2>
          </div>
        </div>

        <div class="pass-container">
          <div
            class="card-block-header toggler"
            (click)="toggleChangePassword()"
          >
            <h2 class="card-title">
              {{ "SETTINGS_P.CHANGE_P.CHANGE_P" | translate }}
            </h2>

            <i class="icon i-arrow"></i>
          </div>
          <div
            class="card-block fieldset"
            [formGroup]="changePasswordForm"
            *ngIf="changePasswordOpen"
          >
            <div class="row-sm">
              <!-- Old Password -->
              <div class="col-12 col-md-6 col-xl-3 form-group">
                <div
                  class="custom-input"
                  [ngClass]="
                    sharedFunctions.giveClassesToCustomInputs(
                      this.changePasswordForm.controls.oldPassword
                    )
                  "
                >
                  <i class="icon i-lg i-lock"></i>
                  <input
                    id="oldPassword"
                    type="password"
                    formControlName="oldPassword"
                    autocomplete="off"
                  />

                  <div class="input-messages">
                    <span>
                      {{
                        sharedFunctions.giveValidationMessagesToCustomInputs(
                          this.changePasswordForm.controls.oldPassword
                        )
                          ? sharedFunctions.giveValidationMessagesToCustomInputs(
                              this.changePasswordForm.controls.oldPassword
                            )
                          : ("SETTINGS_P.CHANGE_P.OLD_PASS" | translate)
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- Old Password -->

              <!-- New Password -->
              <div class="col-12 col-md-6 col-xl-3 form-group">
                <div
                  class="custom-input"
                  [ngClass]="
                    sharedFunctions.giveClassesToCustomInputs(
                      this.changePasswordForm.controls.newPassword
                    )
                  "
                >
                  <i class="icon i-lg i-lock"></i>
                  <input
                    id="newPassword"
                    type="password"
                    formControlName="newPassword"
                    autocomplete="off"
                  />

                  <div class="input-messages">
                    <span>
                      {{
                        sharedFunctions.giveValidationMessagesToCustomInputs(
                          this.changePasswordForm.controls.newPassword
                        )
                          ? sharedFunctions.giveValidationMessagesToCustomInputs(
                              this.changePasswordForm.controls.newPassword
                            )
                          : ("SETTINGS_P.CHANGE_P.NEW_PASS" | translate)
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- New Password -->

              <!-- Repeat Password -->

              <div class="col-12 col-md-6 col-xl-3 form-group">
                <div
                  class="custom-input"
                  [ngClass]="
                    sharedFunctions.giveClassesToCustomInputs(
                      this.changePasswordForm.controls.repeatPassword
                    )
                  "
                >
                  <i class="icon i-lg i-lock"></i>
                  <input
                    id="repeatPassword"
                    type="password"
                    formControlName="repeatPassword"
                    autocomplete="off"
                  />

                  <div class="input-messages">
                    <span>
                      {{
                        sharedFunctions.giveValidationMessagesToCustomInputs(
                          this.changePasswordForm.controls.repeatPassword
                        )
                          ? sharedFunctions.giveValidationMessagesToCustomInputs(
                              this.changePasswordForm.controls.repeatPassword
                            )
                          : ("SETTINGS_P.CHANGE_P.REPEAT_NEW_PASS" | translate)
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Repeat Password -->
            </div>

            <div class="row-sm btn-row">
              <div class="col-12 col-md-6 col-xl-3">
                <button
                  class="btn btn-secondary"
                  (click)="onSubmit()"
                  type="submit"
                >
                  {{ "SETTINGS_P.CHANGE_P.CHANGE_P" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="preloader full-screen double-circles" *ngIf="loading"></div>
    </div>
  </main>
</div>
