<section class="card" style="position: relative">
    <div class="preloader full-screen double-circles" *ngIf="loading"></div>
    <div class="card-block">
        <h2 class="card-title">{{ "LANDING.RESET_PASSWORD" | translate }}</h2>

        <div class="container tabs">
            <!-- reset password form -->
            <div class="container fieldset">
                <form class="card" [formGroup]="resetPasswordForm">
                    <div class="card-block min-height">
                        <div class="container fieldset">
                            <div class="row-sm" *ngIf="resetPasswordStep < 3" [ngClass]="{ disabled: loading }">
                                <!-- username input -->
                                <div class="col-12 col-md-6 form-group has-error">
                                    <div class="custom-input" [ngClass]="
                      giveClassesToCustomInputs(
                        resetPasswordForm.controls.username
                      )
                    ">
                                        <i class="icon i-lg i-user"></i>
                                        <input type="text" class="username" formControlName="username" style="text-transform: uppercase" usernamePattern maxlength="25" />
                                        <div class="input-messages">
                                            <span>{{
                        resetPasswordForm.controls.username.invalid &&
                        resetPasswordForm.controls.username.touched
                          ? ("LANDING.USERNAME_INV" | translate)
                          : ("LANDING.USERNAME" | translate)
                      }}</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- phone number input -->
                                <div class="col-12 col-md-6 form-group has-error">
                                    <div class="custom-input" [ngClass]="
                      giveClassesToCustomInputs(
                        resetPasswordForm.controls.mobile
                      )
                    ">
                                        <i class="icon i-lg i-mobile"></i>
                                        <input type="text" digitOnly class="phone-number" formControlName="mobile" minlength="12" maxlength="12" />
                                        <div class="input-messages">
                                            <span>{{
                        resetPasswordForm.controls.mobile.invalid &&
                        resetPasswordForm.controls.mobile.touched
                          ? ("LANDING.MOBILE_INV" | translate)
                          : ("LANDING.MOBILE" | translate)
                      }}</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- otp input -->
                                <div class="col-12 form-group" *ngIf="resetPasswordStep === 2">
                                    <app-sms-request [valid]="
                      resetPasswordForm.controls.smsCode.invalid &&
                      resetPasswordForm.controls.smsCode.touched
                        ? false
                        : true
                    " [disabled]="otpLoading" [resendBtnHovered]="resendBtnHovered" [loading]="loading" (giveCode)="getSmsCode($event)" (resend)="resendSMSCode()">
                                    </app-sms-request>
                                </div>
                            </div>

                            <div class="row-sm" *ngIf="resetPasswordStep === 3" [ngClass]="{ disabled: loading }">
                                <!-- New Password input -->
                                <div class="col-12 col-md-6 form-group">
                                    <div class="custom-input" [ngClass]="
                      giveClassesToCustomInputs(
                        resetPasswordForm.controls.newPassword
                      )
                    ">
                                        <i class="icon i-lg i-lock"></i>
                                        <input type="password" class="password" formControlName="newPassword" passwordPattern autocomplete="off" />
                                        <div class="input-messages">
                                            <span>{{
                        resetPasswordForm.controls.newPassword.invalid &&
                        resetPasswordForm.controls.newPassword.touched
                          ? ("LANDING.NEW_PASS_INV" | translate)
                          : ("LANDING.NEW_PASS" | translate)
                      }}</span>
                                        </div>
                                    </div>

                                    <h4 class="password-strength-promt" *ngIf="passwordValidatorLength()">
                                        {{ "VALIDATION_ERRORS.MIN_8_CHARS" | translate }}
                                    </h4>
                                    <h4 class="password-strength-promt" *ngIf="passwordValidatorUppercaseLetter()">
                                        {{ "VALIDATION_ERRORS.MIN_1_UPPER" | translate }}
                                    </h4>
                                    <h4 class="password-strength-promt" *ngIf="passwordValidatorLowercaseLetter()">
                                        {{ "VALIDATION_ERRORS.MIN_1_LOWER" | translate }}
                                    </h4>
                                    <h4 class="password-strength-promt" *ngIf="passwordValidatorNumber()">
                                        {{ "VALIDATION_ERRORS.MIN_1_NUMBER" | translate }}
                                    </h4>
                                    <h4 class="password-strength-promt" *ngIf="passwordValidatorSymbol()">
                                        {{ "VALIDATION_ERRORS.MIN_1_SYMBOL" | translate }}
                                    </h4>
                                </div>

                                <!-- Repeat New Password input -->
                                <div class="col-12 col-md-6 form-group">
                                    <div class="custom-input" [ngClass]="
                      giveClassesToCustomInputs(
                        resetPasswordForm.controls.repeatNewPassword
                      )
                    ">
                                        <i class="icon i-lg i-lock"></i>
                                        <input type="password" class="password" formControlName="repeatNewPassword" />
                                        <div class="input-messages">
                                            <span>{{
                        resetPasswordForm.controls.repeatNewPassword.invalid &&
                        resetPasswordForm.controls.repeatNewPassword.touched
                          ? ("LANDING.REPEAT_PASS_INV" | translate)
                          : ("LANDING.REPEAT_PASS" | translate)
                      }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h4 style="color: #ea4c36" *ngIf="errorMessage">
                                {{ "ERROR_CODES." + errorMessage | translate }}
                            </h4>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="card-footer">
        <div class="container">
            <div class="row">
                <div class="col-auto">
                    <a class="icon-btn back-btn" (click)="goBack()"><i class="icon i-arrow--white"></i
            ><span>{{ "GENERAL.BACK" | translate }}</span></a
          >
        </div>
        <div class="col">
          <button
            class="btn btn-lg submit-btn"
            (click)="nextStep()"
            *ngIf="resetPasswordStep !== 3"
          >
            {{ loading ? " " : ("GENERAL.NEXT" | translate) }}
          </button>
          <button
            class="btn btn-lg submit-btn"
            (click)="resetPassword()"
            *ngIf="resetPasswordStep === 3"
          >
            {{ loading ? " " : ("LANDING.RESET_PASS" | translate) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
