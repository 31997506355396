<div class="are-you-sure-container" (click)="closePopup($event)">
    <div class="are-you-sure-popup">
      <div class="logo"></div>
  
      <div class="text">
        <h2>{{error ? error : questionText}}</h2>
  
        <div class="buttons">
          <button [disabled]="loading" class="cancel" (click)="closePopup($event)">No</button>
          <button *ngIf="!error" [disabled]="loading" [ngClass]="{'loading': loading}"
            (click)="confirmPopup()">{{ loading ? '' : "Yes" }}</button>
        </div>
      </div>
    </div>
  </div>