<div
  class="preloader full-screen double-circles"
  *ngIf="downloadPdfLoading"
></div>
<div class="credo-page">
  <main>
    <div class="card transactions-card">
      <div class="loader-container card-header">
        <ng-container>
          <h1 class="card-title">
            {{ "TRANSACTIONS.SEARCH" | translate }}
          </h1>

          <div class="controls">
            <div class="row-sm">
              <div class="col">
                <!-- Account Select -->
                <app-select
                  [loadingtext]="'TRANSACTIONS.SELEC_ACC' | translate"
                  [loading]="accountsLoading"
                  [newItem]="false"
                  [choseElement]="account"
                  [inputArray]="accounts"
                  (selectedItem)="selectAccount($event)"
                ></app-select>
                <!-- Account Select -->
              </div>
              <div class="col">
                <!-- Period select -->
                <app-select
                  [loadingtext]="'TRANSACTIONS.SELEC_PERIOD' | translate"
                  [loading]="false"
                  [newItem]="false"
                  [choseElement]="selectedPeriod"
                  [inputArray]="periodArray"
                  (selectedItem)="selectPeriod($event)"
                ></app-select>
                <!-- Period select -->
              </div>

              <!-- Date Pickers -->
              <ng-container *ngIf="customPeriod">
                <div class="col date-picker">
                  <app-full-date-picker
                    [minDate]="minDate"
                    [maxDate]="todaysDate"
                    [pickedDate]="advancedSearchForm.controls.startDate.value"
                    [placeholder]="'TRANSACTIONS.DATE_FROM' | translate"
                    [valid]="true"
                    [disabled]="false"
                    (giveDate)="setFilterDateFrom($event)"
                  >
                  </app-full-date-picker>
                </div>
                <div class="col date-picker">
                  <app-full-date-picker
                    [minDate]="minDate"
                    [maxDate]="todaysDate"
                    [pickedDate]="advancedSearchForm.controls.endDate.value"
                    [placeholder]="'TRANSACTIONS.DATE_TO' | translate"
                    [valid]="true"
                    [disabled]="false"
                    (giveDate)="setFilterDateTo($event)"
                  >
                  </app-full-date-picker>
                </div>
              </ng-container>
              <!-- Date Pickers -->

              <div class="col btn-wrap">
                <button
                  class="btn btn-sm btn-secondary filter-btn"
                  (click)="filterTransactions()"
                  [ngClass]="{ disabled: !advancedSearchForm.valid }"
                >
                  <app-tooltip
                    class="tooltip-box"
                    [tooltipText]="'GENERAL.FILTER' | translate"
                  >
                  </app-tooltip>
                  <span>{{ "TRANSACTIONS.FILTER" | translate }}</span
                  ><i class="icon i-filter"></i>
                </button>
              </div>

              <div
                class="col btn-wrap"
                [ngClass]="{ disabled: !advancedSearchForm.valid }"
              >
                <button
                  class="btn btn-sm btn-secondary"
                  (click)="exportStatementToPdf()"
                >
                  <app-tooltip
                    class="tooltip-box"
                    [tooltipText]="'TRANSACTIONS.DOWNLOAD' | translate"
                  >
                  </app-tooltip>
                  <span>{{ "TRANSACTIONS.DOWNLOAD" | translate }}</span>
                  <i class="icon icon-download"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="card-body" *ngIf="startDate && endDate && accountNumber">
        <div class="card-block-header">
          <h4 class="card-title">
            {{ "TRANSACTIONS.TRANSACTION_HISTORY" | translate }}
          </h4>
        </div>

        <app-transaction-list
          [startDate]="startDate"
          [endDate]="endDate"
          [accountNumber]="accountNumber"
        >
        </app-transaction-list>
      </div>
    </div>
  </main>
</div>
