import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TransfersService } from 'src/app/core/transfers/transfers.service';
import { TemplatesService } from '../../templates.service';

@Component({
  selector: "app-edit-mobile-or-pn-template",
  templateUrl: "./edit-mobile-or-pn-template.component.html",
  styleUrls: ["./edit-mobile-or-pn-template.component.scss"],
})
export class EditMobileOrPnTemplateComponent implements OnInit, OnDestroy {
  step = 2;
  template;

  form: FormGroup;

  formSub: Subscription;
  fetchAccountsSub: Subscription;
  editTemplateSub: Subscription;
  deleteTemplateSub: Subscription;

  showDeletePopup: boolean = false;

  loading: boolean = false;

  questionText: string = "Are you sure that you want to delete this template?";

  errorMessage: string = "";

  constructor(
    private router: Router,
    private transfersService: TransfersService,
    private templateService: TemplatesService
  ) {}

  ngOnInit() {
    this.initializeForm();

    if (window.history.state.template) {
      this.template = window.history.state.template;
      this.loadTemplate();
    } else {
      this.router.navigateByUrl("/templates");
    }

    this.formSub = this.form.controls.mobileOrPn.valueChanges.subscribe(
      (value) => {
        this.backToStepOne();
      }
    );
  }

  initializeForm() {
    this.form = new FormGroup({
      mobileOrPn: new FormControl("", [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(11),
      ]),
      name: new FormControl("", Validators.required),
      isFavorite: new FormControl(""),
    });
  }

  loadTemplate() {
    this.form.controls.mobileOrPn.setValue(decodeURIComponent(this.template.documentNumber));
    this.form.controls.name.setValue(this.template.name);
    this.form.controls.isFavorite.setValue(this.template.isFavorite);
  }

  checkIdentifier() {
    this.form.controls.mobileOrPn.markAsTouched();
    if (this.form.controls.mobileOrPn.valid) {
      this.fetchAccountsByMobileOrPn();
    }
  }

  fetchAccountsByMobileOrPn() {
    this.loading = true;
    this.errorMessage = "";

    this.fetchAccountsSub = this.transfersService
      .fetchAccountsByMobileOrPn(this.form.controls.mobileOrPn.value.toString())
      .subscribe((res: any) => {
        this.loading = false;
        if (res.errors) {
          this.errorMessage = res.errors[0].extensions.code;
        } else {
          if (res.data && res.data.accounts && !res.data.accounts.length) {
            this.errorMessage = "NO_ACCOUNTS";
          } else {
            this.step = 2;
            if (res.data.accounts[0].account) {
              this.form.controls.name.setValue(res.data.accounts[0].account);
            }
          }
        }
      });
  }

  backToStepOne() {
    this.step = 1;
    this.form.controls.name.setValue("");
    this.form.controls.isFavorite.setValue(false);
  }

  submitForm() {
    this.markFormGroupTouched(this.form);

    if (this.form.valid) {
      this.editTemplate();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  editTemplate() {
    const templateToGive = {
      template: {
        id: this.template.id,
        documentNumber: this.form.controls.mobileOrPn.value,
        isFavorite: this.form.controls.isFavorite.value,
        name: this.form.controls.name.value,
      },
    };
    this.loading = true;
    this.editTemplateSub = this.templateService
      .updateMobileOrPnTemplate(templateToGive)
      .subscribe((res) => {
        this.loading = false;
        if (!res.errors) {
          this.router.navigateByUrl("/templates");
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  toggleFavourite() {
    this.form.controls.isFavorite.setValue(
      !this.form.controls.isFavorite.value
    );
  }

  deleteTemplatePrompt() {
    this.showDeletePopup = true;
  }

  deleteTemplate() {
    this.showDeletePopup = false;
    this.loading = true;
    this.deleteTemplateSub = this.templateService
      .deleteTemplate({ templateId: this.template.id })
      .subscribe((res: any) => {
        this.loading = false;
        if (res.data.deleteTemplate) {
          this.router.navigateByUrl("/templates");
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  giveInputClasses(input) {
    if (this.form.get(input).invalid && this.form.get(input).touched) {
      return "has-error";
    }
  }

  ngOnDestroy(): void {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    if (this.deleteTemplateSub) {
      this.deleteTemplateSub.unsubscribe();
    }
    if (this.editTemplateSub) {
      this.editTemplateSub.unsubscribe();
    }
    if (this.fetchAccountsSub) {
      this.fetchAccountsSub.unsubscribe();
    }
  }
}
