<form [formGroup]="mobileTemplateForm" class="preloader-parent">
  <div class="card-block-header">
    <h4 class="card-block-title-sm">{{ "TEMPLATES_C.ADD_PHONE_NUMBER_TEMP" | translate }}</h4>
  </div>

  <div class="card-block">
    <div class="fieldset">
      <div class="row-sm">
        <div class="col-12 col-md-6 col-xl-5 form-group">
          <input
            class="form-input"
            type="text"
            [placeholder]="'LANDING.MOBILE'|translate"
            formControlName="phoneNumber"
            phoneNumber
            digitOnly
            [ngClass]="giveInputClasses('phoneNumber')"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-3 form-group">
          <input
            class="form-input"
            type="text"
            [placeholder]="'GENERAL.NAME'|translate"
            formControlName="name"
            [ngClass]="giveInputClasses('name')"
          />
        </div>
      </div>
    </div>
  </div>

  <h4 style="padding: 0px 40px 20px 40px; color: #ea4c36" *ngIf="errorMessage">
    {{ errorMessage }}
  </h4>

  <div class="card-footer">
    <div class="row-sm btn-row">
      <div class="col-12 col-md-auto">
        <button class="btn btn-secondary" type="submit" (click)="submitForm()">
          Save
        </button>
      </div>
    </div>
  </div>

  <div class="preloader full-screen double-circles" *ngIf="loading"></div>
</form>
