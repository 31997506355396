import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @ViewChild("tref", { read: ElementRef }) tref: ElementRef;

  @Input() tooltipText: string;

  parentHeight: any;
  parentWidth: any;
  parentTop: number;
  parentLeft: number;

  tooltipWidth: number;
  tooltipHeight: number;
  tooltipTop: number;

  tooltipPosTop;
  tooltipPosLeft;
  tooltipArrowPosLeft;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.getParentParameters();
  }

  getParentParameters() {
    this.tref.nativeElement.parentElement.parentElement.onmouseover = () => {
      let parent = this.tref.nativeElement.parentElement.parentElement;
      this.parentHeight = parent.getBoundingClientRect().height;
      this.parentWidth = parent.getBoundingClientRect().width;
      this.parentTop = parent.getBoundingClientRect().top;
      this.parentLeft = parent.getBoundingClientRect().left;

      this.setPosition();
    };
  }

  setPosition() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.tooltipWidth = this.tref.nativeElement.getBoundingClientRect().width;
    this.tooltipHeight = this.tref.nativeElement.getBoundingClientRect().height;
    this.tooltipTop = this.tref.nativeElement.getBoundingClientRect().top;

    this.tooltipPosTop = this.parentTop + this.parentHeight + 5;

    this.tooltipPosLeft =
      this.parentLeft + this.parentWidth / 2 - this.tooltipWidth / 2;

    if (this.tooltipPosLeft < 10) {
      this.tooltipPosLeft = 10;
    }
    if (this.tooltipPosLeft > windowWidth - (this.tooltipWidth + 10)) {
      this.tooltipPosLeft = windowWidth - this.tooltipWidth - 10;
    }
    if (this.tooltipTop + this.tooltipHeight > windowHeight) {
      this.tooltipPosTop = this.parentTop - this.tooltipHeight - 5;
    }
  }

}
