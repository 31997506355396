<div class="credo-page">
  <main>
    <app-products-box></app-products-box>
    <div class="ads-slider col-auto">
      <app-ad-slider [sliderContentArray]="sliderData"> </app-ad-slider>
    </div>
    <div>
      <app-transactions-box></app-transactions-box>
    </div>
  </main>

  <aside>
    <div>
      <app-transfer-box></app-transfer-box>
    </div>

    <div>
      <app-calendar-box></app-calendar-box>
    </div>
  </aside>
</div>
