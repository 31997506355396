<div class="row-sm">
    <div class="col-12">
        <app-select [loadingtext]="selectBankPlaceHolder" [loading]="banksLoading" [choseElement]="choosenBank" [newItem]="false" [inputArray]="banksForSelect" (selectedItem)="selectBank($event)"></app-select>
    </div>
</div>

<!-- <div class="col-12 col-md-6" >
    <app-select
      [loadingtext]="selectBranchPlaceHolder"
      [loading]="branchesLoading"
      [choseElement]="choosenBranch"
      [newItem]="false"
      [inputArray]="branchesForSelect"
      (selectedItem)="selectBranch($event)"
    ></app-select>
  </div> -->