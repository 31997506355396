import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-monthly-view",
  templateUrl: "./monthly-view.component.html",
  styleUrls: ["./monthly-view.component.scss"],
})
export class MonthlyViewComponent implements OnInit {

  @Input() currentDate;

  @Input() selectedMonthDates;
  @Input() prevMonthPadding;
  @Input() nextMonthPadding;

  @Input() notifications;

  @Output() givePickedDate = new EventEmitter();

  constructor(public router: Router) {}

  ngOnInit() {

  }

  pickDate(month, date) {
    const data = {
      month,
      date,
    };
    this.givePickedDate.emit(data);
  }

  pickedDateClass(day) {
    return this.currentDate.getDate() === day ? "picked" : "";
  }

  showHideDot(date) {
    const classes = [];
    if (this.notifications) {
      for (const notification of this.notifications) {
        if (
          (notification.eventDate as Date).getTime() ===
          new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            date
          ).getTime()
        ) {
          if (!classes.includes("type" + notification.eventTypeId)) {
            classes.push("type" + notification.eventTypeId);
          }
        }
      }
    }
    const classesString = classes.toString().replace(/,/g, " ");
    return classesString;
  }

  showFirstNotification(date) {
    if (this.notifications) {
      for (const notification of this.notifications) {
        if (
          (notification.eventDate as Date).getTime() ===
          new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            date
          ).getTime()
        ) {
          return notification.eventName;
        }
      }
    }
  }

  firstNotificationClass(date) {
    if (this.notifications) {
      for (const notification of this.notifications) {
        if (
          (notification.eventDate as Date).getTime() ===
          new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            date
          ).getTime()
        ) {
          return "type" + notification.eventTypeId;
        }
      }
    }
  }

  goToAddNotificationPage() {
    const date = this.currentDate;
    this.router.navigateByUrl("/calendar/add-event", { state: { date } });
  }
}
