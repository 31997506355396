<div
  class="card banner-widget bg-img--cover"
  style="background-image: url(/assets/images/0/right_banner.png);"
>
  <div class="card-block">
    <h5>Fast loan up to 4,000 GEL</h5>
    <p>Check your credit limit & get loan in 15 mins</p>

    <div class="row-sm">
      <div class="col">
        <button class="btn btn-white">Check limit</button>
      </div>
      <div class="col">
        <a class="btn btn-primary btn-link" href="#">Apply</a>
      </div>
    </div>
  </div>
</div>
