<div class="three-dot-loader" *ngIf="loanLoading"></div>

<div class="accounts-list" *ngIf="!loanLoading">
  <ul class="table">


    <li
      class="t-row pointer"
      *ngFor="let loan of loans"
      [routerLink]="['/products/loans/' + loan.id]"
    >
      <div >
        <div class="t-cell card-cell">
          <div>
            <div>
              <span class="description">{{ loan.product }}</span>
            </div>
          </div>
        </div>
        <div class="t-cell">
          <div class="cell-name">{{ "HOME.PRODUCT_BOX.NEAREST_PAY" | translate }}</div>
          <span>{{ loan.nextPaymentDate | moneyFormat }} {{ loan.currency }}</span>
        </div>
        <div class="t-cell">
          <div class="cell-name">{{ "GENERAL.END_DATE" | translate }}</div>
          <span>{{ loan.loanEndDate | date:"dd-MM-yyyy" }}</span>
        </div>

        <div class="t-cell amount-cell">
          <div class="cell-name">{{ "HOME.PRODUCT_BOX.REMAINING_PRINCIPLE" | translate }}</div>
          <span class="amount">{{ loan.outstandingPrincipal?loan.outstandingPrincipal:0 | moneyFormat }} {{ loan.currency }}</span>
        </div>

        <div class="t-cell arrow-cell">
          <i class="icon i-arrow--blue"></i>
        </div>
      </div>
    </li>
  </ul>
  <h2 class="error-message" *ngIf="!loanLoading && loanErrorMessage">
    {{ "ERROR_CODES." + loanErrorMessage | translate}}
  </h2>
  <h2 class="no-data-message" *ngIf="!loanLoading && loanNoDataMessage">
    {{ "ERROR_CODES." + loanNoDataMessage | translate}}
  </h2>
</div>
