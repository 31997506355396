import { Component, OnChanges, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ModalWithTextService } from "./modal-with-text.service";

@Component({
  selector: "app-modal-with-text",
  templateUrl: "./modal-with-text.component.html",
  styleUrls: ["./modal-with-text.component.scss"],
})
export class ModalWithTextComponent implements OnInit {
  public modalTextSub: Subscription;
  public modalText: string = "";

  private modalClosedSub: Subscription;
  private modalClosed: boolean;

  private modalErrorTextSub: Subscription;
  public modalErrorText: string;
  constructor(private modalService: ModalWithTextService) {}

  ngOnInit(): void {
    if (this.modalService.modalClosed.value) {
      this.modalService.modalClosed.next(false);
    }
    this.modalTextSub = this.modalService.modalText.subscribe((res: string) => {
      this.modalText = res;
    });
    this.modalClosedSub = this.modalService.modalClosed.subscribe(
      (res: boolean) => {
        this.modalClosed = res;
      }
    );
    this.modalErrorTextSub = this.modalService.modalErrorText.subscribe(
      (res: string) => {
        this.modalErrorText = res;
      }
    );
  }

  public closeModal(): void {
    if (this.modalErrorText) {
      this.modalService.modalErrorText.next("");
    } else {
      this.modalService.modalText.next("");
      this.modalService.modalClosed.next(true);
      this.modalService.modalClosed.next(false);
    }
  }
}
