import { Component, OnInit, OnDestroy } from "@angular/core";
import { TemplatesService } from "./templates.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  TemplateTypeResponse,
  TemplateType,
  TemplateMobileInfo,
  TemplateTransferInfo,
} from "src/app/shared/models/models";

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.scss"],
})
export class TemplatesComponent implements OnInit, OnDestroy {
  templates;
  favouriteTemplates;
  templatesSub: Subscription;
  templatesErrors: any;

  accountTemplates: TemplateTransferInfo;
  mobilePhoneTemplates: TemplateMobileInfo;
  mobileOrPnTemplates: TemplateMobileInfo;

  showFavouritesOnly: false;

  toggleFavourite$: Subscription;

  filterInput = undefined;

  showDeletePopup = false;
  templateToDelete;

  deleteTemplate$: Subscription;

  loading = false;

  errorMessage = "";

  questionText: string = "Are you sure that you want to delete this template?";

  constructor(
    private templatesService: TemplatesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.fetchTemplates();
  }

  fetchTemplates() {
    this.loading = true;
    this.templatesSub = this.templatesService
      .fetchTemplates()
      .subscribe((res: TemplateTypeResponse) => {
        this.loading = false;
        this.templates = res.data.template;
        this.categorizeTemplates();
      });
  }
  categorizeTemplates() {
    if (this.showFavouritesOnly) {
      // this.mobilePhoneTemplates = this.templates.mobile.filter(
      //   (item) => item.isFavorite === true
      // );
      this.accountTemplates = this.templates.transfer.filter(
        (item) => item.isFavorite === true
      );
      this.mobileOrPnTemplates = this.templates.document.filter(
        (item) => item.isFavorite === true
      );
    } else {
      // this.mobilePhoneTemplates = this.templates.mobile;
      this.accountTemplates = this.templates.transfer;
      this.mobileOrPnTemplates = this.templates.document;
    }
  }

  deleteTemplatePrompt(template) {
    this.templateToDelete = template;
    this.showDeletePopup = true;
  }

  deleteTemplate() {
    this.showDeletePopup = false;
    this.loading = true;
    this.deleteTemplate$ = this.templatesService
      .deleteTemplate({ templateId: this.templateToDelete.id })
      .subscribe((res: any) => {
        this.loading = false;

        if (res.data.deleteTemplate) {
          this.templates.transfer = this.templates.transfer.filter(
            (item) => item.id !== this.templateToDelete.id
          );
          // this.templates.mobile = this.templates.mobile.filter(
          //   (item) => item.id !== this.templateToDelete.id
          // );
          this.templates.document = this.templates.document.filter(
            (item) => item.id !== this.templateToDelete.id
          );
          this.categorizeTemplates();
        }
      });
  }

  toggleFavourite(template, templateType) {
    this.loading = true;

    if (templateType === "account") {
      const templateToGive = {
        template: {
          id: template.id,
          beneficiaryAccountNumber: template.beneficiaryAccountNumber,
          isFavorite: !template.isFavorite,
          name: template.name,
        },
      };

      this.toggleFavourite$ = this.templatesService
        .updateAccountTemplate(templateToGive)
        .subscribe((res) => {
          this.loading = false;

          if (!res.errors) {
            for (const accountTemplate of this.templates.transfer) {
              if (accountTemplate.id === template.id) {
                accountTemplate.isFavorite = !accountTemplate.isFavorite;
              }
            }
            this.categorizeTemplates();
          } else {
            this.errorMessage = res.errors[0].extensions.code;
          }
        });
    }

    // if (templateType === "mobile") {
    //   const templateToGive = {
    //     template: {
    //       id: template.id,
    //       identifiers: [
    //         {
    //           fieldId: template.identifiers[0].fieldId,
    //           fieldValue: template.identifiers[0].fieldValue,
    //         },
    //       ],
    //       type: "MOBILE",
    //       isFavorite: !template.isFavorite,
    //       name: template.name,
    //       serviceId: template.serviceId,
    //     },
    //   };

    //   this.toggleFavourite$ = this.templatesService
    //     .updateUtilityTemplate(templateToGive)
    //     .subscribe((res) => {
    //       this.loading = false;

    //       if (!res.errors) {
    //         for (const mobileTemplate of this.templates.mobile) {
    //           if (mobileTemplate.id === template.id) {
    //             mobileTemplate.isFavorite = !mobileTemplate.isFavorite;
    //           }
    //         }
    //         this.categorizeTemplates();
    //       } else {
    //         this.errorMessage = res.errors[0].extensions.code;
    //       }
    //     });
    // }

    if (templateType === "mobileOrPn") {
      const templateToGive = {
        template: {
          id: template.id,
          documentNumber: template.documentNumber,
          isFavorite: !template.isFavorite,
          name: template.name,
        },
      };

      this.toggleFavourite$ = this.templatesService
        .updateMobileOrPnTemplate(templateToGive)
        .subscribe((res) => {
          this.loading = false;

          if (!res.errors) {
            for (const mobileOrPnTemplate of this.templates.document) {
              if (mobileOrPnTemplate.id === template.id) {
                mobileOrPnTemplate.isFavorite = !mobileOrPnTemplate.isFavorite;
              }
            }
            this.categorizeTemplates();
          } else {
            this.errorMessage = res.errors[0].extensions.code;
          }
        });
    }
  }

  generateAvatarInitials(template) {
    const splitted = template?.name?.split(" ");

    if (splitted) {
      if (splitted?.length > 1) {
        return splitted[0]?.charAt(0) + "." + splitted[1]?.charAt(0);
      } else {
        return splitted[0]?.charAt(0);
      }
    }
  }
  getDecodedDocumentNumber(documentNumber){
    return decodeURIComponent(documentNumber);
  }
  goToEditTemplatePage(template, templateType) {
    this.router.navigateByUrl("/templates/edit-" + templateType + "-template", {
      state: { template },
    });
  }

  goToTransferPageAndPassTemplateId(template, url: string) {
    this.router.navigateByUrl(url, {
      state: { pickedTemplateId: template.id },
    });
  }

  ngOnDestroy(): void {
    this.templatesSub.unsubscribe();
    if (this.toggleFavourite$) {
      this.toggleFavourite$.unsubscribe();
    }
    if (this.deleteTemplate$) {
      this.deleteTemplate$.unsubscribe();
    }
  }
}
