<div class="range-picker">
  <div class="wrapper">
    <div class="r-p-custom-input">
      <input
        type="range"
        [min]="min"
        [max]="max"
        [step]="step"
        [(ngModel)]="value"
        (ngModelChange)="valueChange($event)"
      />
      <div class="custom-input-srange">
        <div
          class="lower"
          [ngStyle]="{ width: ((value - min) * 100) / (max - min) + '%' }"
        ></div>
        <div
          class="thumb"
          [ngStyle]="{
            left: 'calc(' + ((value - min) * 100) / (max - min) + '% - 6px'
          }"
        ></div>
        <div class="upper"></div>
      </div>
    </div>
    <span *ngIf="type === 'Ar'">{{ value | moneyFormat }} {{ type }}</span>
    <span *ngIf="type !== 'Ar'">{{ value + " " + type }}</span>
  </div>
  <div class="wrapper">
    <h4>
      <span *ngIf="type === 'Ar'">{{ min | moneyFormat }} - {{ max  | moneyFormat }}</span>
      <span *ngIf="type !== 'Ar'">{{ min + " - " + max }}</span>
    </h4>
  </div>
</div>
