import { PedingTransferComponent } from './transfers/peding-transfer/peding-transfer.component';
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CoreComponent } from "./core.component";
import { HomeComponent } from "./home/home.component";
import { SettingsComponent } from "./settings/settings.component";
import { ProductsComponent } from "./products/products.component";
import { TransfersComponent } from "./transfers/transfers.component";
import { TransferToOwnComponent } from "./transfers/transfer-to-own/transfer-to-own.component";
import { TransferWithMobileOrPnComponent } from "./transfers/transfer-with-mobile-or-pn/transfer-with-mobile-or-pn.component";
import { TransferToSomeoneComponent } from "./transfers/transfer-to-someone/transfer-to-someone.component";
import { TemplatesComponent } from "./templates/templates.component";
import { AddTemplateComponent } from "./templates/add-template/add-template.component";
import { EditPhoneNumberTemplateComponent } from "./templates/edit-templates/edit-phone-number-template/edit-phone-number-template.component";
import { EditAccountNumberTemplateComponent } from "./templates/edit-templates/edit-account-number-template/edit-account-number-template.component";
import { EditMobileOrPnTemplateComponent } from "./templates/edit-templates/edit-mobile-or-pn-template/edit-mobile-or-pn-template.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { AddEventComponent } from "./calendar/add-event/add-event.component";
import { EditEventComponent } from "./calendar/edit-event/edit-event.component";
import { TakeALoanComponent } from "./take-a-loan/take-a-loan.component";
import { UtilityComponent } from './transfers/utility/utility.component';
import { TransactionsComponent } from "./transactions/transactions.component";
import { AuthGuardService } from "../shared/services/auth-guard.service";
import { ResendTransferComponent } from './transfers/resend-transfer/resend-transfer.component';

const routes: Routes = [
  {
    path: "",
    component: CoreComponent,
    canActivateChild: [AuthGuardService],
    canActivate: [AuthGuardService],
    children: [
      { path: "", component: HomeComponent },
      /* Settings Block */
      { path: "settings", component: SettingsComponent },
      // product box
      { path: "products/:type", component: ProductsComponent },
      { path: "products/:type/:id", component: ProductsComponent },
      // transfer box
      {
        path: "transfer",
        component: TransfersComponent,
      },
      {
        path: "transfer/to-own-account",
        component: TransferToOwnComponent,
      },
      {
        path: "transfer/by-mobile-or-pn",
        component: TransferWithMobileOrPnComponent,
      },
      {
        path: "transfer/to-someones-account",
        component: TransferToSomeoneComponent,
      },
      {
        path: "transfer/approve",
        component: ResendTransferComponent,
      },
      {
        path: "transfer/pending",
        component: PedingTransferComponent,
      },
      {
        path: "transfer/utility",
        component: UtilityComponent,
      },
      // template block
      {
        path: "templates/edit-mobile-template",
        component: EditPhoneNumberTemplateComponent,
      },
      {
        path: "templates/edit-account-template",
        component: EditAccountNumberTemplateComponent,
      },
      {
        path: "templates/edit-mobile-or-pn-template",
        component: EditMobileOrPnTemplateComponent,
      },
      { path: "templates/add/:tab", component: AddTemplateComponent },
      { path: "templates/add", component: AddTemplateComponent },
      { path: "templates", component: TemplatesComponent },
      // calendar block
      { path: "calendar", component: CalendarComponent },
      { path: "calendar/add-event", component: AddEventComponent },
      { path: "calendar/edit-event", component: EditEventComponent },
      // Take a loan
      { path: "take-a-loan", component: TakeALoanComponent },
      // { path: "take-a-loan/loans-list", component: LoansListComponent },
      // {
      //   path: "take-a-loan/unfinished-loans-list",
      //   component: ShopAppsListComponent,
      // },
      {path:"transactions",component:TransactionsComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
