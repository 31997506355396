import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { LandingComponent } from "./landing.component";
import { AuthComponent } from "./auth/auth.component";
import { RegisterComponent } from "./register/register.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: "landing", redirectTo: "landing/auth", pathMatch: "full" },
  {
    path: "landing",
    component: LandingComponent,
    children: [
      { path: "auth", component: AuthComponent },
      { path: "register", component: RegisterComponent },
      { path: "reset-password", component: ResetPasswordComponent },
      { path: "terms-of-use", component: TermsOfUseComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
