<div class="credo-page">
  <main>
    <div class="card event-card">
      <div class="header">
        <div class="date-selectors">
          <div class="date-navigation-arrows">
            <div class="arrow prev" (click)="prevButton()">&#60;</div>
            <div class="arrow next" (click)="nextButton()">&#62;</div>
          </div>
          <button class="today" (click)="pickTodaysDate()">
            {{ "CALENDAR.TODAY" | translate }}
          </button>
          <div class="month-select">
            <app-select
              [loadingtext]="loadingSelectMonthText"
              [loading]="eventsLoading"
              [newItem]="false"
              [choseElement]="selectedMonth"
              [inputArray]="monthNames"
              [showDropIcon]="true"
              (selectedItem)="pickMonth($event)"
            ></app-select>
          </div>
        </div>

        <div class="change-view-buttons">
          <button
            (click)="pickView('monthly')"
            [ngClass]="{ active: viewStyle === 'monthly' }"
          >
            {{ "CALENDAR.MONTHLY" | translate }}
          </button>
          <button
            (click)="pickView('weekly')"
            [ngClass]="{ active: viewStyle === 'weekly' }"
          >
            {{ "CALENDAR.WEEKLY" | translate }}
          </button>
        </div>
      </div>

      <ul class="weekdays">
        <li *ngFor="let weekDayName of weekDayNames">
          {{ ("CALENDAR.DAYS." + weekDayName | translate).charAt(0) }}
        </li>
      </ul>

      <div class="calendar preloader-parent" *ngIf="formattedNotifications">
        <app-monthly-view
          *ngIf="viewStyle === 'monthly'"
          [currentDate]="currentDate"
          [selectedMonthDates]="selectedMonthDates"
          [prevMonthPadding]="prevMonthPadding"
          [nextMonthPadding]="nextMonthPadding"
          (givePickedDate)="pickDate($event)"
          [notifications]="formattedNotifications"
        ></app-monthly-view>

        <app-weekly-view
          *ngIf="viewStyle === 'weekly'"
          [selectedMonthDates]="selectedMonthDatesWeekly"
          [currentDate]="currentDate"
          (givePickedDate)="pickDate($event)"
          [notifications]="formattedNotifications"
        >
        </app-weekly-view>

        <div
          class="preloader full-screen double-circles"
          *ngIf="eventsLoading"
        ></div>
      </div>

      <div class="card-footer calendar-footer">
        <div class="card-block legend">
          <div class="row-sm">
            <!-- <div class="col-auto">
              <span class="legend-label--blue">{{
                "CALENDAR.UTILITY" | translate
              }}</span>
            </div> -->
            <div class="col-auto">
              <span class="legend-label--red loan">{{
                "CALENDAR.LOAN" | translate
              }}</span>
            </div>
            <div class="col-auto">
              <span class="legend-label--green custom">{{
                "CALENDAR.CUSTOM" | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="card-block">
          <button
            class="btn btn-primary btn-add add-event-btn"
            (click)="goToAddNotificationPage()"
          >
            {{ "CALENDAR.ADD_EVENT" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="card event-card event-list-card">
      <div class="card-header">
        <div>
          <h1 class="card-title">{{ "CALENDAR.EVENTS" | translate }}</h1>
        </div>
        <div class="controls">
          <button
            class="btn"
            (click)="setNotificationsFilter('all')"
            [ngClass]="{ selected: notificationsFilter === 'all' }"
          >
            {{ "GENERAL.ALL" | translate }}
          </button>
          <button
            class="btn"
            (click)="setNotificationsFilter(2)"
            [ngClass]="{ selected: notificationsFilter === 2 }"
          >
            {{ "CALENDAR.LOAN" | translate }}
          </button>
          <!-- <button
            class="btn"
            (click)="setNotificationsFilter(1)"
            [ngClass]="{ selected: notificationsFilter === 1 }"
          >
            {{ "CALENDAR.UTILITY" | translate }}
          </button> -->
          <button
            class="btn"
            (click)="setNotificationsFilter(4)"
            [ngClass]="{ selected: notificationsFilter === 4 }"
          >
            {{ "CALENDAR.CUSTOM" | translate }}
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="table">
          <div
            class="t-row"
            *ngFor="let notification of formattedNotifications"
          >
            <ng-container *ngIf="showHideNotification(notification)">
              <div class="t-cell date-cell">
                <div>
                  <i
                    class="legend-icon--blue"
                    *ngIf="notification.eventTypeId === 1"
                  ></i>
                  <i
                    class="legend-icon--outline-blue"
                    *ngIf="notification.eventTypeId === 3"
                  ></i>
                  <i
                    class="legend-icon--green"
                    *ngIf="notification.eventTypeId === 4"
                  ></i>
                  <i
                    class="legend-label--red loan"
                    *ngIf="notification.eventTypeId === 2"
                  ></i>
                  <div>
                    <span class="date">{{
                      notification.eventDate.toDateString()
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="t-cell fluid-cell">
                <span class="cell-name">{{ "GENERAL.NAME" | translate }}</span>
                <b>{{ notification.eventName }}</b>
              </div>
              <div class="t-cell">
                <span class="cell-name">{{ "GENERAL.DATE" | translate }}</span>
                <b>{{ notification.eventDate.toDateString() }}</b>
              </div>
              <div class="t-cell">
                <span class="cell-name">{{
                  "GENERAL.AMOUNT" | translate
                }}</span>
                <b>{{ notification.amount | moneyFormat }} ZMW</b>
              </div>
              <div
                class="t-cell action-btn-cell"
                *ngIf="notification.eventTypeId !== 2"
              >
                <button
                  class="btn btn-circle btn-xs btn-outline-secondary"
                  (click)="goToEditNotification(notification)"
                  [ngClass]="{ disabled: notification.eventTypeId != 4 }"
                >
                  <i class="icon i-pencil2--blue"></i>
                </button>
                <button
                  [ngClass]="{ disabled: notification.eventTypeId != 4 }"
                  class="btn btn-circle btn-xs btn-outline-secondary"
                  (click)="
                    showDeleteEventPrompt = true;
                    notificationToDelete = notification
                  "
                >
                  <i class="icon i-trash--blue"></i>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
