import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { selectType } from 'src/app/shared/models/models';
import { CalendarDataService } from 'src/app/shared/services/calendar/calendarData.service';


@Component({
  selector: 'app-calendar-box',
  templateUrl: './calendar-box.component.html',
  styleUrls: ['./calendar-box.component.scss']
})
export class CalendarBoxComponent implements OnInit, OnDestroy {
  loadingNotifications = false;

  notificationsSub: Subscription;
  notifications: Notification[];

  formattedNotifications: Notification[];

  viewStyle = 'monthly';

  currentDate: Date;

  weekDayNames: string[];

  loadingSelectMonthText: string = "Loading"
  selectedMonth: any;
  monthNames: any;

  yearsList;

  selectedMonthDates: number[];
  prevMonthPadding: number[];
  nextMonthPadding: number[];

  selectedMonthDatesWeekly: {};

  constructor(private calendarDataService: CalendarDataService) { }

  ngOnInit() {
    this.fetchNotifications();
    this.currentDate = this.calendarDataService.getCurrentDate();

    this.weekDayNames = this.calendarDataService.getWeekDayNames();
    this.getAndPrepMonthNamesForSelect();
    this.yearsList = this.calendarDataService.getYears();

    this.initializeCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth());

    this.initializeWeeklyCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
  }

  getAndPrepMonthNamesForSelect(){
    this.monthNames = this.calendarDataService.getMonthNames().map((x: any) => ({
      id: x.value,
      mainTitle: x.label,
    }));
  }

  pickView(viewStyle) {
    this.viewStyle = viewStyle;
  }

  initializeCalendar(year, month) {
    this.selectedMonth = this.monthNames[month];
    this.selectedMonthDates = this.calendarDataService.getDatesArray(year, month);
    this.prevMonthPadding = this.calendarDataService.getPrevMonthPadding(year, month);
    this.nextMonthPadding = this.calendarDataService.getNextMonthPadding(year, month);
  }

  initializeWeeklyCalendar(year, month, date) {
    this.selectedMonthDatesWeekly = this.calendarDataService.getDatesArrayWeekly(year, month, date);
  }

  pickMonth(month) {
    this.currentDate.setMonth(month.id);
    this.initializeCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth());

    this.initializeWeeklyCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
    this.selectedMonth = month;
  }

  pickDate(date) {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.selectedMonth.id, date);
    this.initializeWeeklyCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
  }

  showNextWeek() {
    this.currentDate = this.calendarDataService.getNextWeekDate(
      this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()
    );

    this.selectedMonth = this.monthNames[this.currentDate.getMonth()];
    this.initializeCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth());
    this.initializeWeeklyCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
  }

  showPrevWeek() {
    this.currentDate = this.calendarDataService.getPrevWeekDate(
      this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()
    );

    this.selectedMonth = this.monthNames[this.currentDate.getMonth()];
    this.initializeCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth());
    this.initializeWeeklyCalendar(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
  }

  fetchNotifications() {
    this.loadingNotifications = true;
    this.notificationsSub = this.calendarDataService.fetchNotifications().subscribe(res => {
      this.notifications = res['data']['calendarEvents'];
      this.formatDateForNotifications();
      this.loadingNotifications = false;
    });
  }

  formatDateForNotifications() {
    const notificationsArray = this.notifications;

    if (notificationsArray) {
      this.formattedNotifications = notificationsArray.map((notification: any) => {
        const dateTimeString = notification.eventDate;
        const dateString = dateTimeString.split("T")[0];
        const dateArray = dateString.split('-');
        const year = Number(dateArray[0]);
        const month = Number(dateArray[1]) - 1;
        const day = Number(dateArray[2]);

        const newDate = new Date(year, month, day);
        return {
          ...notification,
          eventDate: newDate
        };
      });
    }
  }

  showHideNotification(notification) {
    const notificationDate: Date = notification.eventDate;
    return notificationDate.getTime() === this.currentDate.getTime() ? true : false;
  }

  ngOnDestroy(): void {
    this.notificationsSub.unsubscribe();
  }
}
