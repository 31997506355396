import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LandingModule } from "./landing/landing.module";
import { CoreModule } from "./core/core.module";
import { GraphQLModule } from "./graphql.module";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HttpClient } from "@angular/common/http";

//Translation
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DateFormatPipe } from "./shared/pipes/date-format.pipe";
import { registerLocaleData } from "@angular/common";
import localeKa from "@angular/common/locales/ka";
import localeFr from "@angular/common/locales/fr";
import localeMg from "@angular/common/locales/mg";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent,],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    LandingModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
  ],
  providers: [DateFormatPipe],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeKa, "ka");
    registerLocaleData(localeFr, "fr");
    registerLocaleData(localeMg, "mg");
  }
}
