<header class="landing-header">
    <div class="landing-container">
        <a class="credo-logo" routerLink="/landing/auth"><img src="assets/images/logo.png" class="Credo logo" /></a>

        <div>
            <a routerLink="/landing/auth">
                <div class="btn-wrap">
                    <button class="btn btn-primary">Home</button>
                </div>
            </a>
            <div class="btn-wrap">
                <button (click)="goToaccesBankPage()" class="btn btn-primary">
          AB Bank Website
        </button>
            </div>
            <div class="language-switcher">
                <!-- <app-language-switcher></app-language-switcher> -->
            </div>
        </div>
    </div>
</header>