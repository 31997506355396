import { Component, OnInit } from "@angular/core";
import { AdSlider } from "src/app/shared/models/models";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {

  sliderData: AdSlider[];
  constructor() {}

  ngOnInit(): void {
    this.fillSliderData();
  }
  private fillSliderData(): void {
    this.sliderData = [
      {
        background: "../../../assets/images/banners/home-1.jpg",
      },
      {
        background: "../../../assets/images/banners/home-2.jpg",
      },
      {
        background: "../../../assets/images/banners/home-3.jpg",
      },
      {
        background: "../../../assets/images/banners/home-4.jpg",
      },
      {
        background: "../../../assets/images/banners/home-5.jpg",
      },
    ];
  }
}
