import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { generalBalanceInfo } from "src/app/shared/models/models";
import { CustomerInfoService } from "src/app/shared/services/customer-info.service";
import { productsBoxActiveTab } from "../../../shared/enums/enums";

@Component({
  selector: "app-products-box",
  templateUrl: "./products-box.component.html",
  styleUrls: ["./products-box.component.scss"],
})
export class ProductsBoxComponent implements OnInit {
  // Main Variables
  activeTabType = productsBoxActiveTab;
  activeTab: string = this.activeTabType.Accounts;

  // General Balance info Variables
  generalBalanceInfoSub: Subscription;
  generalBalanceInfo: generalBalanceInfo;

  generalBalanceInfoLoadingSub: Subscription;
  generalBalanceInfoLoading: boolean;

  generalBalanceInfoErrorMessageSub: Subscription;
  generalBalanceInfoErrorMessage: string;




  constructor(private customerInfoService: CustomerInfoService) {}

  ngOnInit(): void {
    this.customerInfoService.fetchGeneralBalanceInfo();

    this.subscribeToGeneralBalanceInfo();
  }

  subscribeToGeneralBalanceInfo() {
    this.generalBalanceInfoSub = this.customerInfoService.generalBalanceInfo.subscribe(
      (res: generalBalanceInfo) => {
        this.generalBalanceInfo = res;
      }
    );

    this.generalBalanceInfoLoadingSub = this.customerInfoService.generalBalanceInfoLoading.subscribe(
      (res: boolean) => {
        this.generalBalanceInfoLoading = res;
      }
    );

    this.generalBalanceInfoErrorMessageSub = this.customerInfoService.generalBalanceInfoErrorMessage.subscribe(
      (res: string) => {
        this.generalBalanceInfoErrorMessage = res;
      }
    );
  }

  setActiveTab(product) {
    this.activeTab = this.activeTabType[product];
  }
}
