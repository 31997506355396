import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import gql from 'graphql-tag';
import { GraphQLRequestService } from 'src/app/graph-ql-request.service';
import { AccountsInfoService } from './accounts-info.service';
import { CustomerInfoService } from './customer-info.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private router: Router,
    private graphQL: GraphQLRequestService,
    private accountInfoService: AccountsInfoService,
    private customerIndoService: CustomerInfoService
  ) { }

  changeTemporaryPassword(data) {
    return this.graphQL.mutate(
      gql`
        mutation($data: ChangePasswordDetailsInput!) {
          changePassword(data: $data)
        }
      `,
      data
    );
  }

  logOut(){
    this.customerIndoService.clearCustomerInfo();
    sessionStorage.removeItem("token");
    localStorage.removeItem("username");
    this.router.navigate(["/landing/auth"]);
  }
}
