import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Deposit } from "src/app/shared/models/models";
import { DepositsService } from "src/app/shared/services/deposits.service";

@Component({
  selector: "app-deposits-list-mini",
  templateUrl: "./deposits-list-mini.component.html",
  styleUrls: ["./deposits-list-mini.component.scss"],
})
export class DepositsListMiniComponent implements OnInit, OnDestroy {
  public depositErrorMessage: string;
  public deposits: Array<Deposit> = [];
  public isDepositsLoading: boolean;

  private subs = new Subscription();

  constructor(private depositsService: DepositsService) {}

  ngOnInit(): void {
    this.depositsService.fetchDeposits();
    this.subscribe();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private subscribe(): void {
    this.subToDeposits();
    this.subToDepositsError();
    this.subToDepositsLoading();
  }

  private subToDepositsError(): void {
    this.subs.add(
      this.depositsService.depositsError.subscribe((error: string) => {
        this.depositErrorMessage = error;
      })
    );
  }
  private subToDeposits(): void {
    this.subs.add(
      this.depositsService.deposits.subscribe((deposits: Array<Deposit>) => {
        this.deposits = deposits;
      })
    );
  }
  private subToDepositsLoading(): void {
    this.subs.add(
      this.depositsService.depositsLoading.subscribe((isLoading: boolean) => {
        this.isDepositsLoading = isLoading;
      })
    );
  }
}
