<div class="credo-page">
    <main>
        <div class="card transfers-card" [formGroup]="mobileOrPnform">
            <div class="card-header">
                <h1 class="card-title">
                    {{ "TRANSFERS.TO_SOMEONE_BY_MOBILE_ID" | translate }}
                </h1>
            </div>
            <div class="back-btn-wrap">
                <a [routerLink]="['/transfer']" class="back-btn">
                    <span class="icon i-dropdown-arrow"></span> {{ "GENERAL.BACK" | translate }}
                </a>
            </div>

            <div class="card-body preloader-parent">
                <div class="card-block accounts-wrap">
                    <div class="row-sm fieldset">
                        <div class="col-12 col-md-6">
                            <div class="form-label">
                                {{ "TRANSFERS.FROM_ACC" | translate }}
                            </div>
                            <app-select [loadingtext]="'GENERAL.SELECT_ACC' | translate" [loading]="
                  (prepareTransferLoading || step !== 1) && !errorMessage
                " [newItem]="false" [choseElement]="selectedAccountFrom" [inputArray]="accountsFrom" (selectedItem)="selectAccountFrom($event)"></app-select>
                        </div>
                        <div class="col-12 col-md-6" *ngIf="selectedAccountFrom">
                            <div class="form-label">
                                {{ "TRANSFERS.TO_ACC" | translate }}
                            </div>
                            <app-select [loadingtext]="'GENERAL.SELECT_ADD_BENEF' | translate" [loading]="templatesLoading || step !== 1" [newItem]="true" [choseElement]="selectedTemplateTo" [inputArray]="templateArr" (selectedItem)="selectTemplateTo($event)" (newItemClick)="prepNewTemplate()"></app-select>
                        </div>
                    </div>
                    <h3 class="error-text" *ngIf="errorMessage">
                        {{ "ERROR_CODES." + errorMessage | translate }}
                    </h3>
                </div>

                <ng-container *ngIf="
            ((selectedAccountFrom && selectedTemplate) ||
              (selectedAccountFrom && showAddTemplateBlock)) &&
            !errorMessage
          ">
                    <div class="card-block details-wrap">
                        <h4 class="card-block-title">
                            {{ "GENERAL.DETAILS" | translate }}
                        </h4>

                        <div class="row-sm fieldset">
                            <div class="col-12 col-md-6 col-xl-auto" [ngClass]="{ disabled: step !== 1 }">
                                <input class="form-input" appCurrency type="text" [placeholder]="'GENERAL.AMOUNT' | translate" formControlName="amount" [ngClass]="giveInputClasses('amount')" />
                            </div>
                            <div class="col-12 col-md-6" [ngClass]="{ disabled: step !== 1 }">
                                <input class="form-input" type="text" [placeholder]="'GENERAL.DESCRIPTION' | translate" formControlName="description" [ngClass]="giveInputClasses('description')" />
                            </div>
                        </div>
                        <div class="row-sm transfer-amount-validation-error">
                            <p *ngIf="
                  mobileOrPnform.controls.amount.dirty &&
                  mobileOrPnform.controls.amount.invalid &&
                  mobileOrPnform.controls.amount.errors.max &&
                  mobileOrPnform.controls.amount.value < 500000
                ">
                                {{ "ERROR_CODES.INSUFFICIENT_FUNDS" | translate }}
                            </p>
                            <p *ngIf="
                  mobileOrPnform.controls.amount.dirty &&
                  mobileOrPnform.controls.amount.invalid &&
                  mobileOrPnform.controls.amount.errors.max &&
                  mobileOrPnform.controls.amount.value >= 100000
                ">
                                Please Note That You Can Only Transfer An Amount of Up to ZMW {{ "GENERAL.AMOUNT" | translate }}:{{ 100000 | moneyFormat }} , For All Transfers Above ZMW 100,000.00, and International Transfers, Kindly Visit Your Nearest Branch
                            </p>
                        </div>
                    </div>
                    <div class="card-block user-wrap">
                        <div class="row-sm fieldset">
                            <div class="col-12 col-md-auto form-group" *ngIf="showAddTemplateBlock && !selectedTemplate" [ngClass]="{ disabled: step !== 1 }">
                                <input class="form-input" type="text" [placeholder]="'TEMPLATES_C.PNS' | translate" formControlName="mobileOrPn" (blur)="checkAccountsOnBlur()" [ngClass]="giveInputClasses('mobileOrPn')" />
                            </div>
                            <div class="col-12 col-md-auto form-group" [ngClass]="{ disabled: step !== 1 }">
                                <input class="form-input" type="text" [placeholder]="'FORM_INPUTS.NAME' | translate" formControlName="name" [ngClass]="giveInputClasses('name')" />
                            </div>
                            <div class="col-12 col-md-4 form-group">
                                <app-select [loadingtext]="
                    'TRANSFERS.SELEC_ACC_BENEF_PLACEHOLDER' | translate
                  " [loading]="accountsToLoading || step !== 1" [newItem]="false" [choseElement]="selectedAccountTo" [inputArray]="accountsTo" (selectedItem)="selectAccountTo($event)"></app-select>
                            </div>
                            <div class="col-12 col-md-auto form-group" *ngIf="showAddTemplateBlock && !selectedTemplate">
                                <button class="btn-icon btn-heart" [ngClass]="{
                    selected: makeTemplateFavourite,
                    disabled: step !== 1
                  }" (click)="clickMakeTemplateFavourite()">
                  <i class="icon i-heart"></i>
                  <span>{{ "TEMPLATES_C.ADD_TO_FAV" | translate }}</span>
                </button>
                            </div>
                        </div>
                        <h3 class="error-text" *ngIf="accountErrorMessage">
                            {{ "ERROR_CODES." + accountErrorMessage | translate }}
                        </h3>
                    </div>
                </ng-container>

                <ng-container *ngIf="step === 2">
                    <div class="card-block">
                        <p class="tax-wrap">
                            {{ "GENERAL.FEE" | translate }}
                            <span>{{ prepareTransferData?.fee | moneyFormat }}
                {{ prepareTransferData?.currency }}</span
              >
            </p>
          </div>
          <div class="card-block user-wrap">
            <div
              class="row-sm fieldset"
              *ngIf="prepareTransferData?.authType === 'SMS' && step === 2"
            >
              <div class="col-12 col-md-6 form-group">
                <div class="sms-wrap">
                  <app-sms-request
                    [valid]="
                      mobileOrPnform.controls.otpCode.valid ||
                      mobileOrPnform.controls.otpCode.touched
                        ? true
                        : false
                    "
                    [disabled]="loading"
                    [resendBtnHovered]="false"
                    [loading]="loading"
                    (giveCode)="getSmsCode($event)"
                    (resend)="resendSMSCode($event)"
                  >
                  </app-sms-request>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="preloader full-screen double-circles" *ngIf="loading"></div>
      </div>

      <div
        class="card-footer"
        [ngClass]="{
          disabled: loading || accountsToLoading
        }"
      >
        <div class="row-sm btn-row">
          <div class="col-12 col-md-auto" *ngIf="step !== 1">
            <button class="btn btn-primary" (click)="goBack()">
              {{ "GENERAL.BACK" | translate }}
            </button>
          </div>
          <div class="col-12 col-md-auto" *ngIf="step !== 2">
            <button
              class="btn btn-primary btn-link"
              [ngClass]="{
                disabled: !(
                  mobileOrPnform.controls.template.value &&
                  mobileOrPnform.controls.accountFrom.value &&
                  mobileOrPnform.controls.amount.value &&
                  mobileOrPnform.controls.description.value &&
                  mobileOrPnform.controls.name.value &&
                  mobileOrPnform.controls.mobileOrPn.value &&
                  mobileOrPnform.controls.accountTo.value
                )
              }"
              (click)="goToNextStep()"
            >
              {{ "GENERAL.NEXT" | translate }}
            </button>
          </div>
          <div class="col-12 col-md-auto" *ngIf="step === 2">
            <button class="btn btn-primary btn-link" (click)="goToNextStep()">
              {{ "TRANSFERS.TRANSFER" | translate }}
            </button>
          </div>
          <div
            class="col-12 col-md-auto"
            *ngIf="
              step === 2 && mobileOrPnform.controls.template.value === 'new'
            "
          >
            <button
              class="btn btn-secondary btn-link"
              (click)="makeTransferAndSaveTemplate()"
            >
              {{ "TRANSFERS.SAVE_AND_TRANSFER" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>