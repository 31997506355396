<div class="credo-page" [formGroup]="editEventForm">
  <main>
    <div class="card event-card">
      <div class="card-header">
        <h1 class="card-title">{{ "CALENDAR.EDIT_EVENT" | translate }}</h1>
      </div>
      <div class="back-btn-wrap">
        <a [routerLink]="['/calendar']" class="back-btn">
          <span class="icon i-dropdown-arrow"></span>
          {{ "GENERAL.BACK" | translate }}
        </a>
      </div>

      <div class="card-body preloader-parent">
        <div class="card-block">
          <div class="card-block-title">
            {{ "CALENDAR.CHOOSE_LABEL" | translate }}
          </div>

          <div class="row-sm event-btn-row">
            <!-- <div class="col-6 col-md-auto">
              <button
                class="btn add-bill-btn"
                [ngClass]="{
                  selected: editEventForm.controls.eventType.value === 1
                }"
                (click)="pickEventType(1)"
              >
                <span>{{ "CALENDAR.UTILITY" | translate }}</span>
              </button>
            </div> -->
            <div class="col-6 col-md-auto">
              <button
                class="btn add-income-btn"
                [ngClass]="{
                  selected: editEventForm.controls.eventType.value === 4
                }"
                (click)="pickEventType(4)"
              >
                <span>{{ "CALENDAR.CUSTOM" | translate }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="card-block">
          <div class="card-block-title">
            {{ "GENERAL.DETAILS" | translate }}
          </div>

          <div class="row-sm fieldset">
            <div class="col-12 col-md-3 form-group">
              <input
                class="form-input"
                type="text"
                [placeholder]="'CALENDAR.EVENT_NAME'|translate"
                formControlName="eventName"
              />
            </div>
            <div class="col-12 col-md-3 form-group">

              <app-full-date-picker
                [pickedDate]="currentDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [placeholder]="'CALENDAR.CHOOSE_DATE'|translate"
                [valid]="true"
                [disabled]="false"
                (giveDate)="pickDate($event)"
              >
              </app-full-date-picker>
            </div>
            <div class="col-12 col-md-3 form-group">
              <input
                class="form-input"
                type="text"
                [placeholder]="'GENERAL.AMOUNT'|translate"
                formControlName="amount"
                digitOnly
              />
            </div>

          </div>
        </div>

        <div class="preloader full-screen double-circles" *ngIf="loading"></div>
      </div>

      <div class="card-footer">
        <div class="row-sm btn-row">
          <div
            class="col-12 col-md-auto"
            [ngClass]="{ disabled: editEventForm.invalid || loading }"
          >
            <button class="btn btn-primary btn-link" (click)="onSubmit()">
              {{ "GENERAL.EDIT" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
