import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { GraphQLRequestService } from 'src/app/graph-ql-request.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarDataService {
  currentDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  weekDayNames: string[] = [
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
    "SUN",
  ];
  monthNames = [
    { value: 0, label: "CALENDAR.MONTHS.JANUARY" },
    { value: 1, label: "CALENDAR.MONTHS.FEBRUARY" },
    { value: 2, label: "CALENDAR.MONTHS.MARCH" },
    { value: 3, label: "CALENDAR.MONTHS.APRIL" },
    { value: 4, label: "CALENDAR.MONTHS.MAY" },
    { value: 5, label: "CALENDAR.MONTHS.JUNE" },
    { value: 6, label: "CALENDAR.MONTHS.JULY" },
    { value: 7, label: "CALENDAR.MONTHS.AUGUST" },
    { value: 8, label: "CALENDAR.MONTHS.SEPTEMBER" },
    { value: 9, label: "CALENDAR.MONTHS.OCTOBER" },
    {
      value: 10,
      label: "CALENDAR.MONTHS.NOVEMBER",
    },
    { value: 11, label: "CALENDAR.MONTHS.DECEMBER" },
  ];
  yearsList = [
    { value: 2010, label: "2010" },
    { value: 2011, label: "2011" },
    { value: 2012, label: "2012" },
    { value: 2013, label: "2013" },
    { value: 2014, label: "2014" },
    { value: 2015, label: "2015" },
    { value: 2016, label: "2016" },
    { value: 2017, label: "2017" },
    { value: 2018, label: "2018" },
    { value: 2019, label: "2019" },
  ];

  constructor(private graphQL: GraphQLRequestService) {}

  getCurrentDate() {
    return this.currentDate;
  }

  getWeekDayNames() {
    return this.weekDayNames;
  }

  getMonthNames() {
    return this.monthNames;
  }

  getYears() {
    return this.yearsList;
  }

  getDatesArray(year, month) {
    const lastDayOfMonth: number = new Date(year, month + 1, 0).getDate();
    const datesArray: number[] = [];
    for (let i = 1; i <= lastDayOfMonth; i++) {
      datesArray.push(i);
    }
    return datesArray;
  }

  getPadding(year, month) {
    let firstDayOfMonth: number = new Date(year, month, 1).getDay();
    if (firstDayOfMonth === 0) {
      firstDayOfMonth = 6;
    } else {
      firstDayOfMonth = firstDayOfMonth - 1;
    }
    const paddingArray: number[] = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      paddingArray.push(i);
    }
    return paddingArray;
  }

  getPrevMonthPadding(year, month) {
    let firstDayOfMonth: number = new Date(year, month, 1).getDay();
    const lastDayOfPrevMonth: number = new Date(year, month, 0).getDate();
    if (firstDayOfMonth === 0) {
      firstDayOfMonth = 6;
    } else {
      firstDayOfMonth = firstDayOfMonth - 1;
    }
    const paddingArray: number[] = [];
    for (
      let i = lastDayOfPrevMonth - firstDayOfMonth + 1;
      i <= lastDayOfPrevMonth;
      i++
    ) {
      paddingArray.push(i);
    }
    return paddingArray;
  }

  getNextMonthPadding(year, month) {
    const lastDayOfMonth: number = new Date(year, month + 1, 0).getDate();
    const prevMonthPadding = this.getPrevMonthPadding(year, month).length;
    const daysInTotal = lastDayOfMonth + prevMonthPadding;

    let paddingCount: number;
    const paddingArray: number[] = [];

    if (daysInTotal % 7 === 0) {
      paddingCount = 0;
    } else {
      paddingCount = 7 - (daysInTotal % 7);
    }

    for (let i = 1; i <= paddingCount; i++) {
      paddingArray.push(i);
    }

    return paddingArray;
  }

  getDatesArrayWeekly(year, month, date) {
    const daysArray: number[] = [];
    const prevMonthDaysArray: number[] = [];
    const nextMonthDaysArray: number[] = [];

    const lastDayOfMonth: number = new Date(year, month + 1, 0).getDate();
    const lastDayOfPrevMonth: number = new Date(year, month, 0).getDate();

    let weekDay: number = new Date(year, month, date).getDay();
    if (weekDay === 0) {
      weekDay = 6;
    } else {
      weekDay = weekDay - 1;
    }

    for (let i = date; i < date + (7 - weekDay); i++) {
      if (i <= lastDayOfMonth) {
        daysArray.push(i);
      } else {
        nextMonthDaysArray.push(i - lastDayOfMonth);
      }
    }

    for (let i = date - 1; i > date - 1 - weekDay; i--) {
      if (i > 0) {
        daysArray.splice(0, 0, i);
      } else {
        prevMonthDaysArray.splice(0, 0, lastDayOfPrevMonth + i);
      }
    }

    return {
      current: daysArray,
      previous: prevMonthDaysArray,
      next: nextMonthDaysArray,
    };
  }

  getNextWeekDate(currentYear, currentMonth, currentDate) {
    const lastDayOfMonth: number = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();

    if (currentDate + 7 < lastDayOfMonth) {
      currentDate += 7;
    } else {
      currentDate = 7 - (lastDayOfMonth - currentDate);

      if (currentMonth < 12) {
        currentMonth += 1;
      } else {
        currentMonth = 0;
      }
    }

    return new Date(currentYear, currentMonth, currentDate);
  }

  getPrevWeekDate(currentYear, currentMonth, currentDate) {
    const lastDayOfPrevMonth = new Date(currentYear, currentMonth, 0).getDate();

    if (currentDate - 7 > 0) {
      currentDate -= 7;
    } else {
      currentDate = lastDayOfPrevMonth - (7 - currentDate);

      if (currentMonth > 0) {
        currentMonth -= 1;
      } else {
        currentMonth = 11;
      }
    }

    return new Date(currentYear, currentMonth, currentDate);
  }

  checkDateValidation(
    minDate: Date,
    maxDate: Date,
    year?: number,
    month?: string,
    day?: number
  ) {
    if (year && !month && !day) {
      if (year <= maxDate.getFullYear() && year >= minDate.getFullYear()) {
        return true;
      } else {
        return false;
      }
    }
    let tempMonth = Number(month);
    if (year && tempMonth >= 0 && !day) {
      if (year < maxDate.getFullYear() && year > minDate.getFullYear()) {
        return true;
      }
      if (
        (year === maxDate.getFullYear() && tempMonth <= maxDate.getMonth()) ||
        (year === minDate.getFullYear() && tempMonth >= minDate.getMonth())
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (year && tempMonth >= 0 && day) {
      if (year < maxDate.getFullYear() && year > minDate.getFullYear()) {
        return true;
      }
      if (
        (year === maxDate.getFullYear() && tempMonth < maxDate.getMonth()) ||
        (year === minDate.getFullYear() && tempMonth > minDate.getMonth())
      ) {
        return true;
      }
      if (
        (year === maxDate.getFullYear() &&
          tempMonth === maxDate.getMonth() &&
          day <= maxDate.getDate()) ||
        (year === minDate.getFullYear() &&
          tempMonth === minDate.getMonth() &&
          day >= minDate.getDate())
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  fetchNotifications() {
    const currentYear = new Date().getFullYear();
    return this.graphQL.request(gql`
      {
        calendarEvents(startDate: "${currentYear}-01-01", endDate: "${
      currentYear + 1
    }-01-01") {
          amount
          createdDate
          currency
          eventDate
          eventName
          eventTypeId
          identifier
          label
          loanId
          providerId
          repeatType
          id
        }
      }
    `);
  }
}
