<div
  [ngClass]="{ loading: isOtpLoading }"
  *ngIf="isStarted"
  class="col-12 form-group"
>
  <!-- [resendBtnHovered]="resendBtnHovered" -->

  <app-sms-request
    [valid]="otpCode"
    [disabled]="!isOtpSent"
    [loading]="isOtpLoading"
    (giveCode)="getSmsCode($event)"
    (resend)="resendSMSCode()"
  >
  </app-sms-request>
</div>
