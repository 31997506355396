<div class="credo-page">
  <main>
    <div class="card templates-card templates-grid-card preloader-parent">
      <div class="card-header with-controls">
        <h1 class="card-title">{{ "TEMPLATES_C.TEMPLATES" | translate }}</h1>

        <div class="back-btn-wrap">
          <a class="back-btn" [routerLink]="['/templates']">
            <span class="icon i-dropdown-arrow"></span>
            {{ "TEMPLATES_C.BACK" | translate }}
          </a>
        </div>
      </div>

      <div class="card-body">
        <div class="card-block fieldset template-edit-wrap">
          <form class="row-sm" [formGroup]="form">
            <div class="col">
              <div class="row-sm">
                <div class="col-12 col-md-4 col-xl-5 form-group">
                  <input
                    class="form-input"
                    type="text"
                    [placeholder]="'TEMPLATES_C.PNS'|translate"
                    maxlength="11"
                    formControlName="mobileOrPn"
                    [ngClass]="giveInputClasses('mobileOrPn')"
                  />
                </div>
                <div class="col-12 col-md-3" *ngIf="step === 2">
                  <input
                    class="form-input"
                    type="text"
                    [placeholder]="'GENERAL.NAME'|translate"
                    formControlName="name"
                    [ngClass]="giveInputClasses('name')"
                  />
                </div>
                <div class="col-12 col-md-4" *ngIf="step === 1">
                  <button
                    class="btn btn-secondary w-fluid"
                    type="submit"
                    (click)="checkIdentifier()"
                  >
                  {{ "TEMPLATES_C.CHECK" | translate }}
                  </button>
                </div>
                <div class="col-12 col-md-4" *ngIf="step === 2">
                  <button
                    class="btn btn-secondary w-fluid"
                    type="submit"
                    (click)="submitForm()"
                  >
                  {{ "TEMPLATES_C.SAVE" | translate }}
                  </button>
                </div>
              </div>

              <div class="row-sm btn-row">
                <div class="col-auto" *ngIf="step === 2">
                  <button
                    class="btn-icon btn-heart"
                    [ngClass]="{
                      selected: form.controls.isFavorite.value
                    }"
                    (click)="toggleFavourite()"
                  >
                    <i class="icon i-heart"></i>
                    <span *ngIf="form.controls.isFavorite.value">
                      {{ "TEMPLATES_C.REMOVE_FROM_FAV" | translate }}
                    </span>
                    <span *ngIf="!form.controls.isFavorite.value">
                      {{ "TEMPLATES_C.ADD_TO_FAV" | translate }}
                    </span>
                  </button>
                </div>
                <div class="col-auto">
                  <button class="btn-icon" (click)="deleteTemplatePrompt()">
                    <i class="icon i-trash"></i>
                    <span>{{ "TEMPLATES_C.DELETE" | translate }}</span>
                  </button>
                </div>
                <div class="col-auto" *ngIf="errorMessage">
                  <h4>{{ errorMessage }}</h4>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="preloader full-screen double-circles" *ngIf="loading"></div>
    </div>
  </main>
</div>

<app-are-you-sure
  [questionText]="questionText"
  *ngIf="showDeletePopup"
  (cancel)="showDeletePopup = false"
  (confirm)="deleteTemplate()"
  [loading]="false"
></app-are-you-sure>
