import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  today: Date = new Date();
  constructor() {}

  ngOnInit(): void {}
  public openPdf(fileName): void {
    let baseUrl = "../../assets/files/";
    if (fileName === "FAQ") {
      window.open(baseUrl+"FAQ.pdf", '_blank');
    } else if(fileName=="TERMS") {
      window.open(baseUrl+"Terms and Conditions.pdf",'_blank');
    }
  }
}
