import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { CoreComponent } from "./core.component";
import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./header/header.component";
import { MenuComponent } from "./menu/menu.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeModule } from "./home/home.module";
import { SharedModule } from "../shared/shared.module";
import { SettingsComponent } from "./settings/settings.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ProductsComponent } from './products/products.component';
import { ProductMenuComponent } from './products/product-menu/product-menu.component';
import { ProductListLoansComponent } from './products/product-lists/product-list-loans/product-list-loans.component';
import { ProductsModule } from './products/products.module';
import { TransfersComponent } from './transfers/transfers.component';
import { TransferToOwnComponent } from './transfers/transfer-to-own/transfer-to-own.component';
import { TransferToSomeoneComponent } from './transfers/transfer-to-someone/transfer-to-someone.component';
import { TransferWithMobileOrPnComponent } from './transfers/transfer-with-mobile-or-pn/transfer-with-mobile-or-pn.component';
import { TemplatesModule } from './templates/templates.module';
import { CalendarComponent } from './calendar/calendar.component';
import { TimerComponent } from './timer/timer.component';
import { CalendarModule } from './calendar/calendar.module';
import { TakeALoanModule } from './take-a-loan/take-a-loan.module';
import { UtilityComponent } from './transfers/utility/utility.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { CoreRoutingModule } from "./core-routing.module";
import { PedingTransferComponent } from './transfers/peding-transfer/peding-transfer.component';
import { ResendTransferComponent } from './transfers/resend-transfer/resend-transfer.component';

@NgModule({
  declarations: [
    CoreComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    SettingsComponent,
    TransfersComponent,
    TransferToOwnComponent,
    TransferToSomeoneComponent,
    TransferWithMobileOrPnComponent,
    TimerComponent,
    UtilityComponent,
    TransactionsComponent,
    PedingTransferComponent,
    ResendTransferComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    HomeModule,
    SharedModule,
    ReactiveFormsModule,
    ProductsModule,
    TemplatesModule,
    CalendarModule,
    TakeALoanModule
  ],
  providers:[DatePipe]
})
export class CoreModule {}
