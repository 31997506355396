<div
  class="dropdown-container"
  [ngClass]="{
    invalid: invalid,
    closed: selectState === 'closed',
    open: selectState === 'open',
    disabled: disableSelect()
  }"
>
  <div class="shade-wrapper">
    <div
      class="selected-wrapper"
      (click)="toggleState()"
      (clickOutside)="close()"
    >
      <div class="selected loading" >
        <ng-container>
          <div class="chosen-account" *ngIf="displayItem">
            <div class="wrapper">
              <h3>{{ displayItem.mainTitle | translate }}</h3>
            </div>
            <h2>
              {{ displayItem.rightInfo | translate }}
            </h2>
          </div>
          <div class="chosen-account" *ngIf="!displayItem">
            <h2>
              {{ loadingtext | translate }}
            </h2>
          </div>
          
        </ng-container>
        <div class="right-icon" *ngIf="(!displayItem && !loading) || showDropIcon">
          <img src="../../../../assets/images/icons/arrow_dropdown_light_gray.svg">
        </div>
        <div class="preloader right-cornered dual-ring" *ngIf="!displayItem && loading"></div>
      </div>
    </div>

    <ul class="selection" *ngIf="selectState === 'open' && !loading">
      <div style="max-height: 200px; overflow-y: scroll">
        <li class="new-item" *ngIf="newItem" (click)="newItemClick.emit(true)">
          <div class="wrapper">
            <div class="titles">
              <h2>{{"GENERAL.NEW_BENEFICIARY" | translate }}</h2>
            </div>
            <div class="right-info">
              <img src="../../../../assets/images/icons/plus_white.svg" alt="plus" />
            </div>
          </div>
        </li>
        <li *ngFor="let item of inputArray" (click)="select(item)">

          <div class="wrapper">
            <div class="titles">
              <p>
                {{ item.aboveTitle | translate }}
              </p>
              <h2>{{ item.mainTitle | translate }}</h2>
              <h3>{{ item.underTitle | translate }}</h3>
            </div>
            <div class="right-info">
              <h3>{{ item.rightInfo | translate }}</h3>
            </div>
          </div>
          
        </li>
      </div>
    </ul>
  </div>
</div>
