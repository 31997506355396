import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RegisterService } from "./register.service";
import {
  registrationCheckCustomerResponse,
  validateRegistrationOTPResponse,
} from "src/app/shared/models/models";
import { clientType } from "src/app/shared/enums/enums";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { Guid } from "guid-typescript";
import { DateFormatPipe } from "src/app/shared/pipes/date-format.pipe";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { LandingService } from "../landing.service";
import { HelperService } from "./helper.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {


  // ------------------------------------------
  clientTypeEnum = clientType;
  clientType: string = "";

  registrationStep: number = 1;

  registrationForm: FormGroup;

  disabledClass: boolean = false;

  // for datepicker
  minDate: Date;
  maxDate: Date;
  pickedDate: Date;

  registrationCheckCustomerSub: Subscription;

  errorMessage: string = "";
  loading: boolean = false;

  otpLoading: boolean = false;
  resendBtnHovered: boolean = false;
  generateOtpSub: Subscription;
  validateOtpSub: Subscription;
  otpCode: string = "";
  guid: Guid;

  checkingUsername: boolean = false;
  checkUserNameSub: Subscription;
  inValidUsernames: string[] = [];
  invalidAgreement: boolean = false;
  invalidPassword: string;

  registerCustomerSub: Subscription;
  modalClosedSub: Subscription;

  constructor(
    public helper:HelperService,
    private router: Router,
    private functionsService: FunctionsService,
    private registerService: RegisterService,
    private dateFormat: DateFormatPipe,
    private modalService: ModalWithTextService,
    private landingService: LandingService
  ) {}

  ngOnInit(): void {
    this.initParametersForDatePicker();
    this.initializeForm();
    this.modalClosedSub = this.modalService.modalClosed.subscribe(
      (res: boolean) => {
        if (res === true) {
          // this.finishRegistration();
        }
      }
    );
  }

  pickClientType(type: clientType) {
    this.clientType = type;
  }
  finishRegistration() {
    this.loading = true;
    this.landingService
      .authorizeUser(
        this.registrationForm.controls.username.value,
        this.registrationForm.controls.password.value
      )
      .subscribe((res: any) => {
        this.loading = false;
        if (res.token && res.token != undefined && res.token != "undefined") {
          this.landingService.setToken(res.token);
          this.router.navigateByUrl("/");
        }
      },(err:any)=>{
        this.loading = false;
        this.modalService.generateErrorModal(err.error.errorCode??"ERROR_CODES.AUTH_ERROR");
      });
  }

  goBack() {
    if (!this.clientType) {
      this.router.navigateByUrl("/landing");
    } else if (this.registrationStep === 1 || this.registrationStep === 2) {
      this.clientType = "";
      this.initializeForm();
      this.otpLoading = false;
      this.resendBtnHovered = false;
      this.otpCode = "";
      this.registrationStep = 1;
    } else if (this.registrationStep === 3) {
      this.registrationStep = 1;
      this.registrationForm.get("smsCode").setValue("");
      this.registrationForm.get("username").setValue("");
      this.registrationForm.get("password").setValue("");
      this.registrationForm.get("name").setValue("");
      this.registrationForm.get("address").setValue("");
      this.registrationForm.get("email").setValue("");
      this.registrationForm.get("agreement").setValue("");
    }
  }

  nextStep() {
    if (this.registrationStep === 1) {
      this.submitForm();
    } else if (this.registrationStep === 2) {
      this.registrationForm.get("smsCode").markAsTouched();
      if (this.registrationForm.get("smsCode").valid) {
        this.validateOtp();
      }

    }
  }

  initializeForm() {
    this.registrationForm = new FormGroup({
      // Validators.pattern("[0-9]*")
      personalN: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(12)
      ]),
      // this.mobileCheck
      mobile: new FormControl("", [Validators.required]),
      birthDate: new FormControl("", Validators.required),
      fiscalNumber: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(11),
      ]),
      companyName: new FormControl("", Validators.required),
      // statNumber: new FormControl("", Validators.required),
      smsCode: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]*"),
        Validators.maxLength(4),
        Validators.minLength(4),
      ]),
      username: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(32),
        this.usernameValidation(this.inValidUsernames),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "^(?=.*[A-Z])(?=.*[!@#^$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
        ),
      ]),
      name: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      email: new FormControl(""),
      agreement: new FormControl(false, Validators.required),
    });
    this.errorMessage = "";
    this.pickedDate = undefined;
  }

  usernameValidation(array: string[]) {
    return (input: FormControl) => {
      const value = input.value;
      return array.includes(value) ? { usernameExists: true } : null;
    };
  }

  mobileCheck(c: FormControl) {
    let value = c.value;
    if (value.substring(0, 3) == "261" && value.length === 12) {
      return;
    } else if (value.substring(0, 1) == "0" && value.length === 10) {
      return;
    }
    return { mobileCheck: false };
  }

  initParametersForDatePicker() {
    this.minDate = new Date(
      new Date().getFullYear() - 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    this.maxDate = new Date(
      new Date().getFullYear() - 5,
      new Date().getMonth(),
      new Date().getDate()
    );
  }

  pickDate(date: Date) {
    this.registrationForm
      .get("birthDate")
      .setValue(this.dateFormat.transform(date));
    this.pickedDate = date;
  }

  giveClassesToCustomInputs(input: AbstractControl) {
    if (this.registrationStep === 2) {
      return "disabled";
    } else {
      return this.functionsService.giveClassesToCustomInputs(input);
    }
  }

  // if we got name, address and email value than disable
  checkInputToGiveClass(input) {
    if (this.registrationForm.controls[input].value) {
      return "disabled";
    }
  }

  submitForm() {
    this.markFormGroupAsTouched();
    this.registrationCheckCustomer();
  }

  markFormGroupAsTouched() {
    this.registrationForm.get("birthDate").markAsTouched();
    this.registrationForm.get("personalN").markAsTouched();
    this.registrationForm.get("mobile").markAsTouched();

    if (this.clientType === this.clientTypeEnum.Entity) {
      this.registrationForm.get("fiscalNumber").markAsTouched();
      // this.registrationForm.get("statNumber").markAsTouched();
      this.registrationForm.get("companyName").markAsTouched();
    }
  }

  registrationCheckCustomer() {
    this.invalidAgreement = false;
    if (this.formIsValidForCustomerChcking()) {
      this.loading = true;
      this.registrationCheckCustomerSub = this.registerService
        .checkPerson(
          this.clientType === this.clientTypeEnum.Entity ? true : false,
          this.registrationForm.get("personalN").value,
          this.registrationForm.get("birthDate").value,
          this.registrationForm.get("mobile").value,
          this.clientType === this.clientTypeEnum.Entity
            ? this.registrationForm.get("fiscalNumber").value
            : null,
          // this.clientType === this.clientTypeEnum.Entity
          //   ? this.registrationForm.get("statNumber").value
          //   : null,
          this.clientType === this.clientTypeEnum.Entity
            ? this.registrationForm.get("companyName").value
            : null
        )
        .subscribe((res: registrationCheckCustomerResponse) => {
          this.loading = false;
          if (res.errors) {
            if (res.errors[0].extensions.code === "NOT_FOUND") {
              this.errorMessage = "NOT_FOUND_USERNAME";
            } else {
              this.errorMessage = res.errors[0].extensions.code;
            }
          } else {
            this.setDetailValues(res);
          }
        });
    } else {
      this.errorMessage = "INVALID_INPUT";
      if (!this.registrationForm.get("agreement").value) {
        this.invalidAgreement = true;
      }
    }
  }

  setDetailValues(values: registrationCheckCustomerResponse) {
    this.registrationForm
      .get("name")
      .setValue(
        values.data.registrationCheckCustomer.firstName.toString() +
          " " +
          values.data.registrationCheckCustomer.lastName.toString()
      );
    if (values.data.registrationCheckCustomer.address) {
      this.registrationForm
        .get("address")
        .setValue(values.data.registrationCheckCustomer.address);
      this.generateOtp();
    } else {
      this.errorMessage = "ADDRESS_NOT_FOUND";
    }

    if (values.data.registrationCheckCustomer.email) {
      this.registrationForm
        .get("email")
        .setValue(values.data.registrationCheckCustomer.email);
    }
  }

  formIsValidForCustomerChcking() {
    if (
      this.registrationForm.get("birthDate").valid &&
      this.registrationForm.get("personalN").valid &&
      this.registrationForm.get("mobile").valid
    ) {
      if (this.clientType === this.clientTypeEnum.Entity) {
        if (
          this.registrationForm.get("fiscalNumber").valid &&
          // this.registrationForm.get("statNumber").valid &&
          this.registrationForm.get("companyName").valid
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  getSmsCode(code) {
    this.registrationForm.get("smsCode").setValue(code);
  }

  generateGUID() {
    this.guid = Guid.create();
  }

  generateOtp() {
    this.errorMessage = "";
    this.generateGUID();
    this.loading = true;
    this.otpLoading = false;

    let data = {
      personalN: encodeURIComponent(this.registrationForm.get("personalN").value),
      birthDate: this.registrationForm.get("birthDate").value,
      mobile: this.registrationForm.get("mobile").value,
      sessionId: this.guid.toString(),
      fiscalNumber:
        this.clientType === this.clientTypeEnum.Entity
          ? this.registrationForm.get("fiscalNumber").value
          : "",
      companyName:
        this.clientType === this.clientTypeEnum.Entity
          ? this.registrationForm.get("companyName").value
          : "",
      isLegalEntity:
        this.clientType === this.clientTypeEnum.Entity ? true : false,
    };

      // statNumber:
      //   this.clientType === this.clientTypeEnum.Entity
      //     ? this.registrationForm.get("statNumber").value
      //     : "",

    this.generateOtpSub = this.registerService
      .generateRegistrationOTP({ data })
      .subscribe((res: any) => {
        this.loading = false;
        this.otpLoading = false;
        if (res.errors) {
          this.registrationStep = 2;
          this.errorMessage = "RESEND_CODE";
          this.otpLoading = true;
          this.resendBtnHovered = true;
          this.registrationForm.controls.smsCode.markAsUntouched();
        } else {
          this.registrationStep = 2;
        }
      });
  }

  validateOtp() {
    this.errorMessage = "";
    this.loading = true;
    this.otpLoading = true;

    let data = {
      personalN: this.registrationForm.get("personalN").value,
      birthDate: this.registrationForm.get("birthDate").value,
      mobile: this.registrationForm.get("mobile").value,
      sessionId: this.guid.toString(),
      otpCode: this.registrationForm.get("smsCode").value,
      fiscalNumber:
        this.clientType === this.clientTypeEnum.Entity
          ? this.registrationForm.get("fiscalNumber").value
          : "",
      companyName:
        this.clientType === this.clientTypeEnum.Entity
          ? this.registrationForm.get("companyName").value
          : "",
      isLegalEntity:
        this.clientType === this.clientTypeEnum.Entity ? true : false,
    };

    // statNumber:
    // this.clientType === this.clientTypeEnum.Entity
    //   ? this.registrationForm.get("statNumber").value
    //   : "",

    this.validateOtpSub = this.registerService
      .validateRegistrationOTP({ data })
      .subscribe((res: validateRegistrationOTPResponse) => {
        this.otpLoading = false;
        this.loading = false;

        if (res.data.validateRegistrationOTP) {
          this.registrationStep = 3;
        } else {
          this.registrationForm.controls.smsCode.markAsUntouched();
          this.errorMessage = "WRONG_OTP";
        }
      });
  }

  checkUsernameValidity() {
    this.errorMessage = "";
    const username = this.registrationForm.get("username");
    if (username.value && username.valid) {
      this.checkingUsername = true;
      this.registrationForm.get("username").disable();
      this.checkUserNameSub = this.registerService
        .checkExistingUsername(username.value)
        .subscribe((res: any) => {
          if (res.errors) {
            this.errorMessage = res.errors[0].extensions.code;
            this.inValidUsernames.push(
              this.registrationForm.controls.username.value
            );
          } else {
            this.errorMessage = "";
          }
          this.checkingUsername = false;

          this.registrationForm.get("username").enable();
        });
    }
  }

  checkPasswordValidity() {
    this.errorMessage = "";
    if (this.registrationForm.get("password").errors) {
      let errors = this.registrationForm.get("password").errors;
      if (errors["minlength"]) {
        this.errorMessage = "INVALID_PASSWORD_LENGTH";
      } else if (errors["pattern"]) {
        this.errorMessage = "INVALID_PASSWORD_SYMBOLS";
      } else {
        this.errorMessage = "INVALID_PASSWORD_FORMAT";
      }
    }
  }

  registerNewUser() {
    if (!this.loading) {
      this.errorMessage = "";

      this.registrationForm.get("username").markAsTouched();
      this.registrationForm.get("password").markAsTouched();
      this.registrationForm.get("address").markAsTouched();
      this.registrationForm.get("agreement").markAsTouched();

      if (this.checkRegistrationForm()) {
        this.loading = true;

        let data = {
          isLegalEntity:
            this.clientType === this.clientTypeEnum.Entity ? true : false,
          companyName:
            this.clientType === this.clientTypeEnum.Entity
              ? this.registrationForm.get("companyName").value
              : "",
          fiscalNumber:
            this.clientType === this.clientTypeEnum.Entity
              ? this.registrationForm.get("fiscalNumber").value
              : "",
          personalN: encodeURIComponent(this.registrationForm.get("personalN").value),
          birthDate: this.registrationForm.get("birthDate").value,
          mobile: this.registrationForm.get("mobile").value,
          username: this.registrationForm.get("username").value,
          password: this.registrationForm.get("password").value,
        };

        // statNumber:
        // this.clientType === this.clientTypeEnum.Entity
        //   ? this.registrationForm.get("statNumber").value
        //   : "",

        this.registerCustomerSub = this.registerService
          .registerCustomer({ data })
          .subscribe((res: any) => {
            this.loading = false;
            if (res.errors) {
              this.errorMessage = res.errors[0].extensions.code;
            } else {
              this.modalService.generateModal(
                "LANDING.REGISTER.REGISTRATION_COMPLETE"
              );
            }
          });
      }
    }
  }

  checkRegistrationForm(){
    if(this.clientType === this.clientTypeEnum.Entity){
      if(
        this.registrationForm.get("companyName").valid &&
        this.registrationForm.get("fiscalNumber").valid &&
        // this.registrationForm.get("statNumber").valid &&
        this.registrationForm.get("personalN").valid &&
        this.registrationForm.get("birthDate").valid &&
        this.registrationForm.get("mobile").valid &&
        this.registrationForm.get("username").valid &&
        this.registrationForm.get("password").valid &&
        this.registrationForm.get("agreement").value
      ){
        return true;
      }else{
        return false;
      }
    } else{
      if(
        this.registrationForm.get("personalN").valid &&
        this.registrationForm.get("birthDate").valid &&
        this.registrationForm.get("mobile").valid &&
        this.registrationForm.get("username").valid &&
        this.registrationForm.get("password").valid &&
        this.registrationForm.get("agreement").value
      ){
        return true;
      }else{
        return false;
      }
    }
  }


  resendSMSCode() {
    this.resendBtnHovered = false;
    this.generateOtp();
  }

  openAgreementOnNewTab() {
    window.open(
      "../../../assets/files/Terms and Conditions.pdf",
      "_blank"
    );
  }
  checkAgreementBox(e) {
    e.preventDefault();
    this.invalidAgreement = false;
    this.registrationForm
      .get("agreement")
      .setValue(!this.registrationForm.get("agreement").value);
  }

  returnAddressSpan() {
    if (this.clientType === this.clientTypeEnum.Entity) {
      return "LANDING.COMPANY_ADDRESS";
    } else {
      return "LANDING.ADDRESS";
    }
  }

  ngOnDestroy(): void {
    if (this.registrationCheckCustomerSub) {
      this.registrationCheckCustomerSub.unsubscribe();
    }
    if (this.generateOtpSub) {
      this.generateOtpSub.unsubscribe();
    }
    if (this.validateOtpSub) {
      this.validateOtpSub.unsubscribe();
    }
    if (this.checkUserNameSub) {
      this.checkUserNameSub.unsubscribe();
    }
    if (this.registerCustomerSub) {
      this.registerCustomerSub.unsubscribe();
    }
  }
}
