import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mobileOrPnTemplatesFilter",
})
export class MobileOrPnTemplatesFilterPipe implements PipeTransform {
  transform(array: any, searchQuerry: any): any {
    if (searchQuerry === undefined) {
      return array;
    }

    return array.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
        item.documentNumber.toLowerCase().includes(searchQuerry.toLowerCase())
    );
  }
}
