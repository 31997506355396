<div class="days">
  <div
    class="padding"
    *ngFor="let day of prevMonthPadding"
    (click)="pickDate('previous', day)"
  >
    {{ day }}
  </div>

  <div
    class="day"
    *ngFor="let day of selectedMonthDates"
    (click)="pickDate('current', day)"
    [ngClass]="pickedDateClass(day)"
  >
    {{ day }}
    <div class="notification-dots" [ngClass]="showHideDot(day)">
      <div class="dot bills"></div>
      <div class="dot loan"></div>
      <div class="dot income"></div>
      <div class="dot custom"></div>
    </div>

    <div class="notification" [ngClass]="firstNotificationClass(day)">
      {{ showFirstNotification(day) }}
    </div>

    <button class="add-new-event" (click)="goToAddNotificationPage()">+</button>
  </div>

  <div
    class="padding"
    *ngFor="let day of nextMonthPadding"
    (click)="pickDate('next', day)"
  >
    {{ day }}
  </div>
</div>
