export const environment = {
  production: false,

  //Credo Test Environment For Development.
  // apiUrl: "http://10.195.105.201:8051/graphql",
  // loginApiUrl: "http://10.195.105.201:8051/api/Auth",

  //ABZ Test Environment
  // apiUrl: "http://172.17.11.166:8081/graphql",
  // loginApiUrl: "http://172.17.11.166:8081/api/Auth",

 // UAT SSL
  //apiUrl: "https://uatob.abbank.co.zm:3443/graphql",
  //loginApiUrl: "https://uatob.abbank.co.zm:3443/api/Auth",

  // apiUrl: "https://172.17.151.166:3443/graphql",
  // loginApiUrl: "https://172.17.151.166:3443/api/Auth",


  // apiUrl: "https://ob.abbank.co.zm:3443/graphql",
  // loginApiUrl: "https://ob.abbank.co.zm:3443/api/Auth",


  //live
    apiUrl: "https://ob.abbank.co.zm:3443/graphql",
   loginApiUrl: "https://ob.abbank.co.zm:3443/api/Auth",




};
