import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  constructor(private graphQL: GraphQLRequestService) {}

  createCalendarEvent(event) {
    let {eventName, amount, eventDate, currency, eventTypeId, eventTriggeringQuantity} = event.calendarEvent;
    return this.graphQL.mutate(gql`
    mutation($eventName:String!,
          $amount:Decimal,
          $eventDate:DateTime!,
          $currency:String,
          $eventTypeId:Int!,
          $eventTriggeringQuantity:Int!
          ){
      createCalendarEvent(calendarEvent:
        {
          eventName: $eventName,
          amount: $amount,
          eventDate: $eventDate,
          currency: $currency,
          eventTypeId: $eventTypeId,
          eventTriggeringQuantity:$eventTriggeringQuantity
        })
      {
        id
        eventName
        eventDate
        eventTypeId
      }
  }`,{eventName, amount, eventDate, currency, eventTypeId, eventTriggeringQuantity});
  }

  f(event, property) {
    return typeof event[property] !== null ? property : "";
  }
  editCalendarEvent(event) {
    const loanId = typeof event.loanId !== null ? "loanId," : "";
    return this.graphQL.mutate(
      gql`
    mutation($updateEvent: CalendarEventInput!)
    {
      updateCalendarEvent(calendarEvent: $updateEvent)
      {
        id,
        currency,
        eventName,
        amount,
        eventDate,
        ${this.f(event, "identifier")}
        ${this.f(event, "label")}
        ${this.f(event, "loanId")}
        ${this.f(event, "providerId")}
        ${this.f(event, "repeatType")}
        eventTypeId,
        eventTriggeringQuantity
      }
    }
    `,
      event
    );
  }

  deleteCalendarEvent(event) {
    return this.graphQL.mutate(
      gql`
        mutation($calendarEventId: Int!) {
          deleteCalendarEvent(calendarEventId: $calendarEventId)
        }
      `,
      event
    );
  }
}
