<div class="days">
  <div
    class="padding"
    *ngFor="let padding of selectedMonthDates.previous"
    (click)="pickDate('previous', padding)"
  >
    {{ padding }}
  </div>

  <div
    class="day"
    *ngFor="let day of selectedMonthDates.current"
    [ngClass]="pickedDateClass(day)"
    (click)="pickDate('current', day)"
  >
    <h3>{{ day }}</h3>
    <div class="notification-dots" [ngClass]="showHideDot(day)">
      <div class="dot bills"></div>
      <div class="dot loan"></div>
      <div class="dot income"></div>
      <div class="dot custom"></div>
    </div>

    <div class="notification" [ngClass]="firstNotificationClass(day)">
      {{ showFirstNotification(day) }}
    </div>

    <button
      class="add-new-event"
      [routerLink]="['/calendar/add-event']"
      [queryParams]="{ date: currentDate }"
    >
      +
    </button>
  </div>

  <div
    class="padding"
    *ngFor="let padding of selectedMonthDates.next"
    (click)="pickDate('next', padding)"
  >
    {{ padding }}
  </div>
</div>
