<div class="three-dot-loader" *ngIf="isDepositsLoading"></div>
<h2 class="error-message" *ngIf="!isDepositsLoading && depositErrorMessage">
  {{ "ERROR_CODES." + depositErrorMessage | translate }}
</h2>
<div class="deposits-list" *ngIf="!isDepositsLoading && deposits.length">
  <ul class="table">
    <li class="t-row t-head">
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.DEPOSITS.ACCOUNT_NUMBER" | translate }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.DEPOSITS.AVAILABLE_AMOUNT" | translate }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.DEPOSITS.CURRENCY" | translate }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ "PRODUCTS.DEPOSITS.CATEGORY" | translate }}</span>
      </div>
    </li>
    <li class="t-row" *ngFor="let deposit of deposits">
      <div class="t-cell card-cell">
        <span>{{ deposit.accountNumber }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ deposit.availableBalance | moneyFormat }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ deposit.currency }}</span>
      </div>
      <div class="t-cell card-cell">
        <span>{{ deposit.category }}</span>
      </div>
    </li>
  </ul>
</div>
