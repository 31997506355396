<div class="card transactions-card">
  <div class="card-block-header">
    <h4 class="card-title">
      {{ "TRANSACTIONS.RECENT_TRANSACTIONS" | translate }}
    </h4>
  </div>
  <app-transaction-list
    *ngIf="accountNumber"
    [startDate]="startDate"
    [endDate]="endDate"
    [accountNumber]="''"
  ></app-transaction-list>
</div>
