import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { TemplatesService } from "../../templates.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-account-number-template",
  templateUrl: "./add-account-number-template.component.html",
  styleUrls: ["./add-account-number-template.component.scss"],
})
export class AddAccountNumberTemplateComponent implements OnInit, OnDestroy {
  accountTemplateForm: FormGroup;

  formSub: Subscription;

  checkAccountSub: Subscription;
  addTemplateSub: Subscription;

  loading = false;

  wrongAccountNumbersList = [];

  errorMessage = "";

  constructor(
    private formBuilder: FormBuilder,
    private templateService: TemplatesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeForm();
    // this.subscribeToForm();
  }

  initializeForm() {
    this.accountTemplateForm = this.formBuilder.group({
      accountNumber: new FormControl("", {
        validators: [
          Validators.required,
          this.accountNumberValidation(this.wrongAccountNumbersList),
        ],
      }),
      name: new FormControl("", { validators: [Validators.required] }),
      bank: new FormControl("", { validators: [Validators.required] }),
    });
  }

  // subscribeToForm() {
  //   this.formSub = this.accountTemplateForm
  //     .get("accountNumber")
  //     .valueChanges.subscribe((accNumber) => {
  //       this.errorMessage = "";
  //       this.checkAccountNumber(accNumber);
  //     });
  // }

  // checkAccountNumber(accNumber) {
  //   if (!this.wrongAccountNumbersList.includes(accNumber)) {
  //     this.loading = true;
  //     this.checkAccountSub = this.templateService
  //       .checkAccountNumberInfo(accNumber)
  //       .subscribe((res) => {
  //         this.loading = false;

  //         if (
  //           res.data["customer"] &&
  //           (res.data["customer"]["firstNameLat"] ||
  //             res.data["customer"]["lastNameLat"])
  //         ) {
  //           const name =
  //             res.data["customer"]["firstNameLat"] +
  //             " " +
  //             res.data["customer"]["lastNameLat"];
  //           this.accountTemplateForm.controls.name.setValue(name);
  //         } else if (res.errors) {
  //           this.accountTemplateForm.controls.name.setValue("");
  //           if (res.errors[0].extensions.code === "BAD_REQUEST") {
  //             this.errorMessage = "WRONG_IBAN";
  //             if (!this.wrongAccountNumbersList.includes(accNumber)) {
  //               this.wrongAccountNumbersList.push(accNumber);
  //               this.accountTemplateForm.controls.accountNumber.setValue("");
  //               this.accountTemplateForm.controls.accountNumber.setValue(
  //                 accNumber
  //               );
  //             }
  //           }
  //           if (res.errors[0].extensions.code === "NOT_FOUND") {
  //             this.errorMessage = "NOT_FOUND";
  //           }
  //         }
  //       });
  //   }
  // }
  getEmittedIndustryNumber(event) {
    this.accountTemplateForm.controls.bank.setValue(event);
  }
  accountNumberValidation(array: string[]) {
    return (input: FormControl) => {
      const value = input.value;
      return array.includes(value) ? { wrongAccountNumber: true } : null;
    };
  }

  submitForm() {
    this.markFormGroupTouched(this.accountTemplateForm);
    if (this.accountTemplateForm.valid) {
      this.addTemplate();
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  addTemplate() {
    this.loading = true;
    const template = {
      template: {
        beneficiaryAccountNumber:this.accountTemplateForm.controls.accountNumber.value,
        name: this.accountTemplateForm.controls.name.value,
        isFavorite: false,
        bankBicCode: this.accountTemplateForm.controls.bank.value,
      },
    };

    this.addTemplateSub = this.templateService
      .addTransferTemplate(template)
      .subscribe((res) => {
        this.loading = false;
        if (!res.errors) {
          this.router.navigateByUrl("/templates");
        } else {
          this.errorMessage = res.errors[0]["extensions"]["code"];
        }
      });
  }

  giveInputClasses(input) {
    if (
      this.accountTemplateForm.get(input).invalid &&
      this.accountTemplateForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  ngOnDestroy(): void {
    // if(this.formSub){
    //   this.formSub.unsubscribe();
    // }
    if (this.checkAccountSub) {
      this.checkAccountSub.unsubscribe();
    }
    if (this.addTemplateSub) {
      this.addTemplateSub.unsubscribe();
    }
  }
}
