<app-landing-header></app-landing-header>

<div class="landing-page">
  <div class="landing-container">
    <div class="landing-head">
      <h1>{{ "LANDING.MAIN_HEADING" | translate }}</h1>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>

<app-landing-footer></app-landing-footer>

<section
  class="popup"
  *ngIf="landingService.showPopupOnLanding"
  (click)="closeInactivityPopup()"
>
  <div class="modalContent">
    <div class="content">
      <p>
        {{ "POPUPS.INACTIVITY_TEXT_3" | translate }}
      </p>
    </div>
  </div>
</section>

