<div class="credo-page">
  <main>
    <div class="card transfers-card" [formGroup]="utilityForm">
      <div class="card-header">
        <h1 class="card-title">
          {{ "TRANSFERS.UTILITY" | translate }}
        </h1>
      </div>
      <div class="back-btn-wrap">
        <a [routerLink]="['/transfer']" class="back-btn">
          <span class="icon i-dropdown-arrow"></span>
          {{ "GENERAL.BACK" | translate }}
        </a>
      </div>

      <div class="card-body preloader-parent">
        <div class="card-block accounts-wrap">
          <div class="row-sm fieldset">
            <div class="col-12 col-md-6" [ngClass]="{ disabled: step !== 1 }">
              <div class="form-label">
                {{ "TRANSFERS.COMPTE_ENC" | translate }}
              </div>
              <input
                class="form-input"
                type="text"
                digitOnly
                [placeholder]="'TRANSFERS.COMPTENC'|translate"
                formControlName="compteEnc"
                [ngClass]="giveInputClasses('compteEnc')"
              />
              <div class="form-label">
                {{ "TRANSFERS.NUMERO_FACTURE" | translate }}
              </div>
              <input
                class="form-input"
                type="text"
                digitOnly
                [placeholder]="'TRANSFERS.NUMEROFACTURE'|translate"
                formControlName="numeroFacture"
                [ngClass]="giveInputClasses('numeroFacture')"
              />
            </div>
          </div>
        </div>

        <div class="card-block info-wrap" *ngIf="jiramaDetails && step >= 2">
          <div class="row-lg">
            <div class="col-auto">
              <span class="name">{{
                "TRANSFERS.NUMERO_FACTURE" | translate
              }}</span>
              <span class="description">
                {{ jiramaDetails.numeroFacture }}
              </span>
            </div>
            <div class="col-auto">
              <span class="name">{{ "TRANSFERS.NOM_CLIENT" | translate }}</span>
              <span class="description">{{ jiramaDetails.nomClient }}</span>
            </div>
            <div class="col-auto">
              <span class="name">{{ "TRANSFERS.MONTANT" | translate }}</span>
              <span class="description">
                {{ jiramaDetails.montant }}
              </span>
            </div>
            <div class="col-auto">
              <span class="name">{{
                "TRANSFERS.JIRAMA_MONTH" | translate
              }}</span>
              <span class="description">
                {{ monthNames[jiramaMonth - 1].label | translate }}
              </span>
            </div>
          </div>
        </div>

        <div class="card-block user-wrap" *ngIf="jiramaDetails && step >= 2">
          <div class="card-block select-card-block">
            <p class="tax-wrap">
              {{ "TRANSFERS.SELECT_ACC_JIRAMA" | translate }}
            </p>
          </div>

          <div class="row-sm fieldset">
            <div
              class="col-12 col-md-auto select-col"
              [ngClass]="{ disabled: step !== 2 }"
            >
              <app-select
                [loadingtext]="loadingSelectText"
                [loading]="prepareTransferLoading && !errorMessage"
                [newItem]="false"
                [choseElement]="selectedAccountFrom"
                [inputArray]="accountsArray"
                (selectedItem)="selectAccount($event)"
              ></app-select>
            </div>
          </div>
        </div>

        <ng-container *ngIf="step >= 3">
          <div class="card-block user-wrap">
            <div class="row-sm fieldset">
              <div class="col-12 col-md-6 form-group">
                <div class="sms-wrap">
                  <app-sms-request
                    [valid]="
                      utilityForm.controls.otpCode.valid ||
                      utilityForm.controls.otpCode.touched
                        ? true
                        : false
                    "
                    [disabled]="loading"
                    [resendBtnHovered]="false"
                    [loading]="loading"
                    (giveCode)="getSmsCode($event)"
                    (resend)="resendSMSCode($event)"
                  >
                  </app-sms-request>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="preloader full-screen double-circles" *ngIf="loading"></div>
      </div>

      <div class="card-footer">
        <div class="row-sm btn-row">
          <div class="col-12 col-md-auto" *ngIf="step >= 2">
            <button
              class="btn btn-primary"
              (click)="back()"
              [disabled]="loading"
            >
              {{ "GENERAL.BACK" | translate }}
            </button>
          </div>
          <div class="col-12 col-md-auto" *ngIf="step === 1">
            <button
              class="btn btn-primary btn-link"
              (click)="next()"
              [ngClass]="{
                disabled: !(
                  utilityForm.get('compteEnc').valid &&
                  utilityForm.get('numeroFacture').valid
                )
              }"
              [disabled]="
                !(
                  utilityForm.get('compteEnc').valid &&
                  utilityForm.get('numeroFacture').valid
                )
              "
            >
              {{ "GENERAL.CHECK" | translate }}
            </button>
          </div>
          <div class="col-12 col-md-auto" *ngIf="step === 2">
            <button
              class="btn btn-primary btn-link"
              (click)="next()"
              [ngClass]="{
                disabled: !selectedAccountFrom
              }"
              [disabled]="!selectedAccountFrom"
            >
              {{ "GENERAL.NEXT" | translate }}
            </button>
          </div>
          <div class="col-12 col-md-auto" *ngIf="step === 3">
            <button
              class="btn btn-primary btn-link"
              (click)="next()"
              [ngClass]="{
                disabled: !this.utilityForm.get('otpCode').value
              }"
              [disabled]="!this.utilityForm.get('otpCode').value"
            >
              {{ "GENERAL.PAY" | translate }}
            </button>
          </div>
          <div class="col-12 col-md-auto">
            <h4 style="color: #ea4c36" *ngIf="errorMessage">
              {{ "ERROR_CODES." + errorMessage | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

