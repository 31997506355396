import { Injectable } from "@angular/core";
import { GraphQLRequestService } from "src/app/graph-ql-request.service";
import gql from "graphql-tag";
import { Subscription, BehaviorSubject } from "rxjs";
import { accountsInfo, accountsInfoResponse } from "../models/models";

@Injectable({
  providedIn: "root",
})
export class AccountsInfoService {
  // Custmer Info variables
  accountsInfoSub: Subscription;
  accountsInfoLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  accountsInfoErrorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  accountsInfo: BehaviorSubject<accountsInfo> = new BehaviorSubject<accountsInfo>(
    undefined
  );
  defaultAccountInfo: BehaviorSubject<accountsInfo> = new BehaviorSubject<accountsInfo>(
    undefined
  );

  constructor(private graphQL: GraphQLRequestService) {}

  accountsInfoQuery() {
    return this.graphQL.request(gql`
      {
        customer {
          accounts {
            account
            accountId
            accountNumber
            availableBalance
            availableBalanceEqu
            balance
            blockedAmount
            category
            categoryId
            currency
            currencyPriority
            hasCard
            status
            statusId
          }
        }
      }
    `);
  }

  fetchAccountsInfo() {
    this.accountsInfoLoading.next(true);
    this.accountsInfoSub = this.accountsInfoQuery().subscribe(
      (res: accountsInfoResponse): any => {
        this.accountsInfoLoading.next(false);
        if (res.errors) {
          this.accountsInfoErrorMessage.next(res.errors[0].extensions.code);
        } else {
          this.accountsInfoErrorMessage.next("");
          this.accountsInfo.next(res.data.customer.accounts);
          let temp: any = res.data.customer.accounts;

          this.defaultAccountInfo.next(temp.filter((d: accountsInfo) => false));
        }
      }
    );
  }

  clearAccountsInfo() {
    if (this.accountsInfo) {
      this.accountsInfo = null;
      this.accountsInfo = new BehaviorSubject<accountsInfo>(undefined);
      this.defaultAccountInfo = null;
      this.defaultAccountInfo = new BehaviorSubject<accountsInfo>(undefined);
    }
    if (this.accountsInfoErrorMessage) {
      this.accountsInfoErrorMessage.next("");
    }
  }
}
