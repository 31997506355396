import { Component, OnInit } from "@angular/core";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { accountsInfoBase } from "../../shared/component-bases/accounts-info-base";

@Component({
  selector: "app-transfers",
  templateUrl: "./transfers.component.html",
  styleUrls: ["./transfers.component.scss"],
})
export class TransfersComponent extends accountsInfoBase implements OnInit {
  constructor(private accountsInfoService: AccountsInfoService,private modalService:ModalWithTextService) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.accountsInfoService.fetchAccountsInfo();
    });
    super.subscribeToAccountsInfo(this.accountsInfoService);
  }

  checkIfAccountsMoreThanOne(): boolean {
    let temp: any = this.accountsInfo;
    if (temp) {
      if (temp.length === 1) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
