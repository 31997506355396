<div class="card exchange-widget">
  <div class="card-block">
    <div class="currency-select-row">
      <div>
      </div>
      <button class="btn btn-circle btn-outline-primary">
        <i class="icon i-exchange i-lg"></i>
      </button>
      <div>
      </div>
    </div>

    <div class="currency-input-row">
      <div>
        <span class="symbol">$</span>
        <input type="text" value="3,444" />
      </div>
      <div>
        <span class="symbol">₾</span>
        <span class="value">3,444</span>
      </div>
    </div>

    <div class="row-sm currency-rates-row">
      <div class="col-50">
        <span>1 EUR = 1.13266 USD</span>
      </div>
      <div class="col-50">
        <span>1 USD = 0.882878 EUR</span>
      </div>
    </div>

    <a class="btn btn-secondary w-fluid btn-link" href="#">Currency List</a>
  </div>
</div>
