<div class="language-switcher">
  <div class="current">
    <span class="language-item" (click)="toggle()"
      ><p>{{ currentLanguage.code.toUpperCase()}}</p><img src="{{ currentLanguage.image }}" /></span
    >
  </div>
  <div class="dropdown-wrapper" *ngIf="dropdown">
    <div class="dropdown">
      <div *ngFor="let item of languageList" class="dropdown-item">
        <span *ngIf="item.code!=currentLanguage.code"class="language-item" (click)="selectLanguage(item)"
          ><img src="{{ item.image }}" />
          <p>{{ item.name }}</p></span
        >
      </div>
    </div>
  </div>
</div>
