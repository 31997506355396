import {  Component, OnInit } from "@angular/core";
import { CustomerInfoService } from "../shared/services/customer-info.service";
@Component({
  selector: "app-core",
  templateUrl: "./core.component.html",
  styleUrls: ["./core.component.scss"],
})
export class CoreComponent implements OnInit {
  menuIsOpen: boolean = false;

  constructor(
    private customerInfoService: CustomerInfoService
  ) {}

  ngOnInit(): void {
    this.customerInfoService.fetchCustomerInfo();
  }z
}
