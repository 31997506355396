import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Guid } from "guid-typescript";
import { UserInfo, userInfo } from "os";
import { Subscription } from "rxjs";
import { accountsInfoBase } from "src/app/shared/component-bases/accounts-info-base";
import { accountsInfo, customerInfo, selectType } from "src/app/shared/models/models";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import { CalendarDataService } from "src/app/shared/services/calendar/calendarData.service";
import { CustomerInfoService } from "src/app/shared/services/customer-info.service";
import { OtpService } from "src/app/shared/services/otp.service";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { TransferBase } from "../transfers-base";
import { TransfersService } from "../transfers.service";

@Component({
  selector: "app-utility",
  templateUrl: "./utility.component.html",
  styleUrls: ["./utility.component.scss"],
})
export class UtilityComponent
  extends TransferBase
  implements OnInit, OnDestroy {
  loading: boolean = false;
  step: number = 1;
  errorMessage: string = "";

  customerInfoSub:Subscription;
  customerInfo: customerInfo;

  utilityForm: FormGroup;

  monthNames;

  checkJiramaBillSub: Subscription;
  jiramaDetails: any;
  jiramaMonth: number;

  payJiramaSub: Subscription;

  // select
  loadingSelectText: string = "Select account number";
  loadinfSelect: boolean = true;
  selectedAccountFrom?: selectType;
  accountsArray: selectType;
  defaultAccount: selectType;


  guid: Guid;

  constructor(
    private formBuilder: FormBuilder,
    private transfersService: TransfersService,
    private calendarDataService: CalendarDataService,
    private accountsInfoService: AccountsInfoService,
    private router: Router,
    private otpService: OtpService,
    private customerInfoService: CustomerInfoService,
    private modalService: ModalWithTextService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();

    this.monthNames = this.calendarDataService.getMonthNames();
    this.modalService.modalClosed.subscribe((res:boolean)=>{
      if(res===true){
        this.onPopupClosed();
      }
    })
    this.getCustomerInfo();
  }

  getCustomerInfo(){
    this.customerInfoSub = this.customerInfoService.customerInfo.subscribe(
      (res: customerInfo) => {
        this.customerInfo = res;
      }
    )}

  initializeForm() {
    this.utilityForm = this.formBuilder.group({
      compteEnc: new FormControl("", [
        Validators.required,
        Validators.maxLength(6),
        Validators.pattern(
          "[0-9]*"
        ),
      ]),
      numeroFacture: new FormControl("", [
        Validators.required,
        Validators.minLength(14),
        Validators.pattern(
          "[0-9]*"
        ),
      ]),
      account: new FormControl("", Validators.required),
      otpCode: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });
  }

  back() {
    if (this.step === 2) {
      this.step = 1;
      this.emptyInputs();
    } else if (this.step === 3) {
      this.step = 2;
      this.selectedAccountFrom = null;
      this.utilityForm.get("account").setValue("");
      this.utilityForm.get("otpCode").setValue("");
    }
  }

  emptyInputs() {
    this.utilityForm.get("compteEnc").setValue("");
    this.utilityForm.get("numeroFacture").setValue("");
    this.utilityForm.get("account").setValue("");
    this.utilityForm.get("otpCode").setValue("");
  }

  next() {
    if (this.step === 1) {
      this.utilityForm.get("compteEnc").markAllAsTouched();
      this.utilityForm.get("numeroFacture").markAllAsTouched();
      this.checkUtility();
    } else if (this.step === 2) {
      this.utilityForm.get("account").markAllAsTouched();
      this.step = 3;
    } else if (this.step === 3) {
      this.utilityForm.get("otpCode").markAllAsTouched();

      if (this.utilityForm.get("otpCode").valid) {
        this.payJirama();
      }
    }
  }

  giveInputClasses(input) {
    if (
      this.utilityForm.get(input).invalid &&
      this.utilityForm.get(input).touched
    ) {
      return "has-error";
    }
  }

  checkUtility() {
    this.loading = true;
    this.errorMessage = "";
    this.guid = Guid.create();

    this.checkJiramaBillSub = this.transfersService
      .checkJiramaBill(
        this.utilityForm.get("compteEnc").value,
        this.utilityForm.get("numeroFacture").value,
        this.guid.toString()
      )
      .subscribe((res: any) => {
        let data = res.data.checkJiramaBill;
        this.loading = false;

        if (res.errors) {
          this.errorMessage = res.errors[0].extensions.code;
        } else {
          if (data) {
            if (!data.code && !data.message) {
              this.jiramaDetails = data;
              this.jiramaMonth = parseInt(
                this.jiramaDetails.moisFacture.substring(5, 7),
                10
              );
              this.prepareAccounts();
              this.errorMessage = "";
              this.step = 2;
            } else {
              if(data.message === "Facture introuvable chez Jirama."){
                this.errorMessage = "INVOICE_NOT_FOUND"
              }else if(data.message === "Ce site n'est pas encore disponible."){
                this.errorMessage = "SITE_NOT_FOUND"
              }
              else{
                this.errorMessage = "ALREADY_PAID";
              }

            }
          }
        }
      });
  }

  prepareAccounts() {
    if (!this.accountsInfo) {
      setTimeout(() => {
        this.accountsInfoService.fetchAccountsInfo();
      });
    }
    super.subscribeToAccountsInfo(this.accountsInfoService);
    this.accountsInfoService.accountsInfoErrorMessage.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = true;
        this.errorMessage = "SOMETHING_WENT_WRONG";
      }
    });
    this.accountsInfoService.accountsInfo.subscribe((res: any) => {
      if (res) {
        this.prepareTransferLoading = false;
        this.prepareAccountsForSelect();
      }
    });
  }

  prepareAccountsForSelect() {
    let temp: any = this.accountsInfo;

    if (temp) {
      this.accountsArray = temp.map((x: accountsInfo) => ({
        id: x.accountId,
        mainTitle: x.accountNumber,
        rightInfo: x.balance.toString() + " " + x.currency,
      }));
    }

    let defTemp: any = this.defaultAccountInfo;
    if (defTemp) {
      if (defTemp.length !== 0) {
        this.defaultAccount = {
          id: this.defaultAccountInfo.accountId,
          imgId: null,
          imgName: "",
          mainTitle: this.defaultAccountInfo.accountNumber,
          aboveTitle: "",
          underTitle: "",
          rightInfo:
            this.defaultAccountInfo.balance.toString() +
            " " +
            this.defaultAccountInfo.currency,
        };
      }
    }
  }

  selectAccount(acc: selectType) {
    this.selectedAccountFrom = acc;
    this.utilityForm.get("account").setValue(acc.mainTitle);
  }

  payJirama() {
    this.loading = true;
    this.errorMessage = "";

    this.payJiramaSub = this.transfersService
      .payJiramaBill(
        this.utilityForm.get("account").value,
        this.utilityForm.get("compteEnc").value,
        this.utilityForm.get("numeroFacture").value,
        this.guid.toString(),
        this.utilityForm.get("otpCode").value
      )
      .subscribe((res: any) => {
        this.loading = false;

        if (res.errors) {
          this.modalService.generateErrorModal("ERROR_CODES."+res.errors[0].extensions.code);
        } else {
          if (res.data.payJiramaBill.code == "200") {
            this.modalService.generateModal("GENERAL.SUCC_TRANSFER");
          } else {
            this.modalService.generateErrorModal("ERROR_CODES."+res.errors[0].extensions.code);
          }
        }
      });
  }


  getSmsCode(event) {
    this.utilityForm.get("otpCode").setValue(event);
  }
  resendSMSCode(event) {
    this.checkUtility();
  }

  ngOnDestroy() {
    if (this.checkJiramaBillSub) {
      this.checkJiramaBillSub.unsubscribe;
    }
    if (this.payJiramaSub) {
      this.payJiramaSub.unsubscribe;
    }

  }

  // popup
  closeClick() {
    this.router.navigateByUrl("/");
  }

  onPopupClosed() {
    this.closeClick();
  }
}
