<div class="utility-mini-box" [formGroup]="utilityForm">
  <div class="utility-inputs" [ngClass]="{ disabled: step !== 1 }">
    <div class="card-row">
      <input
        class="form-input"
        type="text"
        digitOnly
        [placeholder]="'TRANSFERS.COMPTENC'|translate"
        formControlName="compteEnc"
        [ngClass]="giveInputClasses('compteEnc')"
      />
    </div>
    <div class="card-row">
      <input
        class="form-input"
        type="text"
        digitOnly
        [placeholder]="'TRANSFERS.NUMEROFACTURE'|translate"
        formControlName="numeroFacture"
        [ngClass]="giveInputClasses('numeroFacture')"
      />
    </div>
  </div>
  <div class="card-block info-wrap" *ngIf="jiramaDetails && step >= 2">
    <div class="row-lg utility-details">
      <div class="col-auto">
        <p class="name">
          {{"TRANSFERS.NUMERO_FACTURE" | translate}}
          <span class="description">
            {{ jiramaDetails.numeroFacture }}
          </span>
        </p>
      </div>
      <div class="col-auto">
        <p class="name">
          {{"TRANSFERS.NOM_CLIENT" | translate}}
          <span class="description">{{ jiramaDetails.nomClient }}</span>
        </p>
      </div>
      <div class="col-auto">
        <p class="name">
          {{"TRANSFERS.MONTANT" | translate}}
          <span class="description"> {{ jiramaDetails.montant }} </span>
        </p>
      </div>
      <div class="col-auto">
        <p class="name">
          {{"TRANSFERS.JIRAMA_MONTH" | translate}}
          <span class="description">
            {{ monthNames[jiramaMonth - 1].label | translate }}
          </span>
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="jiramaDetails && step >= 2">
    <div class="row-sm fieldset">
      <div
        class="col-12 col-md-auto select-col"
        [ngClass]="{ disabled: step !== 2 }"
      >
        <app-select
          [loadingtext]="'TRANSFERS.SELECT_ACC_JIRAMA' | translate"
          [loading]="prepareTransferLoading && !errorMessage"
          [newItem]="false"
          [choseElement]="selectedAccountFrom"
          [inputArray]="accountsArray"
          (selectedItem)="selectAccount($event)"
        ></app-select>
      </div>
    </div>
  </div>

  <ng-container *ngIf="step >= 3">
    <div class="row-sm fieldset">
      <div class="col-12 col-md-auto select-col">
        <div class="sms-wrap">
          <app-sms-request
            [valid]="
              utilityForm.controls.otpCode.valid ||
              utilityForm.controls.otpCode.touched
                ? true
                : false
            "
            [disabled]="loading"
            [resendBtnHovered]="false"
            [loading]="loading"
            (giveCode)="getSmsCode($event)"
            (resend)="resendSMSCode($event)"
          >
          </app-sms-request>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="preloader full-screen double-circles" *ngIf="loading"></div>
  <div class="utility-buttons">
    <div class="btn-wraper left">
      <button
        class="btn btn-secondary utility-button"
        (click)="back()"
        [disabled]="step === 1"
        [ngClass]="{ disabled: step === 1 }"
      >
        {{"GENERAL.BACK" | translate}}
      </button>
    </div>
    <div class="btn-wraper right" *ngIf="step === 1">
      <button
        class="btn btn-secondary utility-button"
        (click)="next()"
        [ngClass]="{
          disabled: !(
            utilityForm.get('compteEnc').value &&
            utilityForm.get('numeroFacture').value
          )
        }"
        [disabled]="
          !(
            utilityForm.get('compteEnc').value &&
            utilityForm.get('numeroFacture').value
          )
        "
      >
      {{"GENERAL.CHECK" | translate}}
      </button>
    </div>
    <div class="btn-wraper right" *ngIf="step === 2">
      <button
        class="btn btn-secondary utility-button"
        (click)="next()"
        [ngClass]="{
          disabled: !selectedAccountFrom
        }"
        [disabled]="!selectedAccountFrom"
      >
      {{"GENERAL.NEXT" | translate}}
      </button>
    </div>
    <div class="btn-wraper right" *ngIf="step === 3">
      <button
        class="btn btn-secondary utility-button"
        (click)="next()"
        [ngClass]="{
          disabled: !this.utilityForm.get('otpCode').value
        }"
        [disabled]="!this.utilityForm.get('otpCode').value"
      >
      {{"GENERAL.PAY" | translate}}
      </button>
    </div>
  </div>
  <div class="utility-error-message">
    <h4 style="color: #ea4c36" *ngIf="errorMessage">
      {{ "ERROR_CODES." + errorMessage | translate }}
    </h4>
  </div>
</div>

