<div class="sms-request-container">
  <div
    class="sms-input-wrapper"
    [ngClass]="{
      invalid: !valid,
      disabled: disabled || loading,
      loading: loading
    }"
  >
    <div class="sms-input">
      <p>SMS</p>
      <input
        type="text"
        digitOnly
        maxlength="4"
        [(ngModel)]="smsCode"
        (select)="disableSelect($event)"
        (keydown)="disableArrows($event)"
        (input)="smsOnInput()"
        name="new-password"
        autocomplete="off"
      />
      <div class="code">
        <h4
          [ngClass]="{
            focused: smsCode.length === 0,
            finished: smsCode.length === 4
          }"
        >
          {{ smsCode.charAt(0) }}
        </h4>
        <h4
          [ngClass]="{
            focused: smsCode.length === 1,
            finished: smsCode.length === 4
          }"
        >
          {{ smsCode.charAt(1) }}
        </h4>
        <h4
          [ngClass]="{
            focused: smsCode.length === 2,
            finished: smsCode.length === 4
          }"
        >
          {{ smsCode.charAt(2) }}
        </h4>
        <h4
          [ngClass]="{
            focused: smsCode.length === 3,
            finished: smsCode.length === 4
          }"
        >
          {{ smsCode.charAt(3) }}
        </h4>
      </div>
    </div>
    <button
      class="resend"
      type="button"
      (mouseover)="onResendHover()"
      (mouseleave)="onResendBlur()"
      (click)="resendCode()"
    ></button>
  </div>

  <div class="countdown">
    <h2 *ngIf="!resendBtnHovered && time > 0 && !loading">
      {{ "GENERAL.CODE_IS_ACTIVE" | translate }}
      <span
        >{{ Math.floor(time / 60) }}
        {{ "GENERAL.MINUTES" | translate }}
        {{ time % 60 }}
        {{ "GENERAL.SECONDS" | translate }}</span
      >
    </h2>
    <h2
      class="blue"
      *ngIf="resendBtnHovered || time <= 0"
      (click)="resendCode()"
    >
      {{ "GENERAL.RESEND_CODE" | translate }}
    </h2>
  </div>
</div>
