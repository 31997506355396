<div class="credo-page">
  <main>
    <div class="card templates-card">
      <div class="card-header">
        <h1 class="card-title">{{ "TEMPLATES_C.ADD_NEW_TEMP" | translate }}</h1>

        <div class="back-btn-wrap">
          <a [routerLink]="['/templates']" class="back-btn">
            <span class="icon i-dropdown-arrow"></span>
            {{ "GENERAL.BACK" | translate }}
          </a>
        </div>
      </div>

      <div class="card-body">
        <div class="items-grid">
          <div
            class="grid-item grid-item-button"
            [ngClass]="giveActiveTabClass('account')"
            (click)="activeTab = 'account'"
          >
            <i class="icon-circle i-user"></i>
            <span class="name">{{
              "TEMPLATES_C.ADD_ACCOUNT_NUMBER_TEMP" | translate
            }}</span>
          </div>
          <div
            class="grid-item grid-item-button"
            [ngClass]="giveActiveTabClass('mobileOrPn')"
            (click)="activeTab = 'mobileOrPn'"
          >
            <i class="icon-circle i-mobile-pn"></i>
            <span class="name">
              {{ "TEMPLATES_C.ADD_PN_TEMP" | translate }}</span
            >
          </div>
        </div>

        <div [ngSwitch]="activeTab">
          <div *ngSwitchCase="ADD_ACCOUNT">
            <app-add-account-number-template></app-add-account-number-template>
          </div>

          <div *ngSwitchCase="ADD_MOBILE_OR_PN">
            <app-add-mobile-or-pn-template></app-add-mobile-or-pn-template>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
