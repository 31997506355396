import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { TransactionListComponent } from "./shared-components/transaction-list/transaction-list.component";
import { LanguageSwitcherComponent } from "./shared-components/language-switcher/language-switcher.component";
import { ModalComponent } from "./shared-components/modal/modal.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { SelectComponent } from "./shared-components/select/select.component";
import { FullDatePickerComponent } from "./shared-components/full-date-picker/full-date-picker.component";
import { PickDateComponent } from "./shared-components/full-date-picker/pick-date/pick-date.component";
import { PickMonthComponent } from "./shared-components/full-date-picker/pick-month/pick-month.component";
import { PickYearComponent } from "./shared-components/full-date-picker/pick-year/pick-year.component";
import { SmsRequestComponent } from "./shared-components/sms-request/sms-request.component";
import { FormsModule } from "@angular/forms";
import { DigitOnlyDirective } from "./directives/digit-only.directive";
import { UsernamePatternDirective } from "./directives/username-pattern.directive";
import { CurrencyDirective } from "./directives/currency.directive";
import { AreYouSureComponent } from "./shared-components/popups/are-you-sure/are-you-sure.component";
import { MobileTemplatesFilterPipe } from "./pipes/mobile-templates-filter.pipe";
import { MobileOrPnTemplatesFilterPipe } from "./pipes/mobile-or-pn-templates-filter.pipe";
import { AccountTemplatesFilterPipe } from "./pipes/account-templates-filter.pipe";
import { MoneyFormatPipe } from './pipes/money-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { RangePickerComponent } from './shared-components/range-picker/range-picker.component';
import { ModalWithTextComponent } from './shared-components/popups/modal-with-text/modal-with-text.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { AdSliderComponent } from './shared-components/ad-slider/ad-slider.component';
import { AccountOverdraftComponent } from './shared-components/account-overdraft/account-overdraft.component';
import { BankSelectForTransfersComponent } from './shared-components/bank-select-for-transfers/bank-select-for-transfers.component';

@NgModule({
  declarations: [
    LanguageSwitcherComponent,
    TransactionListComponent,
    ModalComponent,
    ClickOutsideDirective,
    SelectComponent,
    FullDatePickerComponent,
    PickDateComponent,
    PickMonthComponent,
    PickYearComponent,
    SmsRequestComponent,
    DigitOnlyDirective,
    UsernamePatternDirective,
    CurrencyDirective,
    AreYouSureComponent,
    MobileTemplatesFilterPipe,
    MobileOrPnTemplatesFilterPipe,
    AccountTemplatesFilterPipe,
    MoneyFormatPipe,
    DateFormatPipe,
    RangePickerComponent,
    ModalWithTextComponent,
    TooltipComponent,
    AdSliderComponent,
    AccountOverdraftComponent,
    BankSelectForTransfersComponent
  ],
  imports: [CommonModule, FormsModule, TranslateModule],
  exports: [
    TranslateModule,
    LanguageSwitcherComponent,
    TransactionListComponent,
    ModalComponent,
    ClickOutsideDirective,
    SelectComponent,
    FullDatePickerComponent,
    SmsRequestComponent,
    DigitOnlyDirective,
    CurrencyDirective,
    MobileTemplatesFilterPipe,
    MobileOrPnTemplatesFilterPipe,
    AccountTemplatesFilterPipe,
    AreYouSureComponent,
    MoneyFormatPipe,
    DateFormatPipe,
    RangePickerComponent,
    ModalWithTextComponent,
    TooltipComponent,
    AdSliderComponent,
    AccountOverdraftComponent,
    BankSelectForTransfersComponent
  ],
})
export class SharedModule {}
