import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class GraphQLRequestService {
  constructor(private apollo: Apollo, private router: Router) {}

  responseMiddleware(res) {
    let { errors } = res;
    if (errors) {
      let unauthorized = errors.some(
        (err) => err.extensions && err.extensions.code === "UNAUTHORIZED"
      );
      let errorModal = errors.some(
        (err) => err.extensions && err.extensions.code.includes("MODAL")
      );

      if (unauthorized) {
        sessionStorage.removeItem("token");
        this.router.navigate(["/landing/auth"]);
      }
    }

    return { errors };
  }

  request(query, variables?) {
    return this.apollo
      .watchQuery({ query, errorPolicy: "all", variables })
      .valueChanges.pipe(
        map((res) => {
          const { errors } = this.responseMiddleware(res);
          return { data: res.data, errors };
        })
      );
  }

  mutate(mutation, variables) {
    return this.apollo
      .mutate({
        mutation,
        variables,
        errorPolicy: "all",
      })
      .pipe(
        map((res) => {
          const { errors } = this.responseMiddleware(res);
          return { data: res.data, errors };
        })
      );
  }
}
