<div *ngIf="modalText || modalErrorText">
  <section class="modalContainer">
    <div class="modalContent">
      <div class="closeButton" (click)="closeModal()">X</div>
      <div class="content">
        <h3 *ngIf="modalText">{{modalText}}</h3>
        <h3 *ngIf="modalErrorText">{{modalErrorText}}</h3>
      </div>
      <button class="btn btn-primary submit-btn" (click)="closeModal()">
        OK
      </button>
    </div>
  </section>
</div>
