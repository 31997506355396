import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TakeALoanComponent } from "./take-a-loan.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { TakeALoanService } from "./take-a-loan.service";

@NgModule({
  declarations: [TakeALoanComponent],
  imports: [CommonModule, RouterModule, SharedModule, ReactiveFormsModule],
  providers: [TakeALoanService],
})
export class TakeALoanModule {}
