import { Injectable } from "@angular/core";
import { FormControl, FormGroup, AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class FunctionsService {
  constructor(private translateService:TranslateService){

  }
  //#region [OtherPrimary] Inputs
  //Gives classes to costum-inputs. EXAMPLE : <div class="custom-input" [ngClass]="giveCustomClasses(formControl)">
  public giveClassesToCustomInputs(input: AbstractControl): string {
    let classes = "";
    if (!input.value) {
      classes += "empty ";
    }

    if (input.touched && input.invalid) {
      classes += "invalid ";
    }

    if (input.disabled) {
      classes += "disabled ";
    }
    return classes;
  }

  // Check input errors and return message depending on what was invalid
  public giveValidationMessagesToCustomInputs(input: AbstractControl): string {
    let validationMessage = "";
    if (input.errors) {
      if (input.errors.minlength) {
        validationMessage = this.translateService.instant("SETTINGS_P.CHANGE_P.MINIAL_LENGTH")+" "+ input.errors.minlength.requiredLength;
      } else if (input.errors.pattern) {
        validationMessage = this.translateService.instant("SETTINGS_P.CHANGE_P.PASS_UPPERCASE");
      } else if(input.errors.passwordsDontMatch){
        validationMessage = this.translateService.instant("SETTINGS_P.CHANGE_P.PASS_DONT_MATCH");
      }
    }
    return validationMessage;
  }

  //#endregion

}
