import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent implements OnInit, OnDestroy {
  ADD_ACCOUNT = "account";
  ADD_MOBILE_OR_PN = "mobileOrPn";
  ADD_PHONE = "mobile";
  ADD_UTILITY_BILLS = "utility";

  activeTab: string;
  routeSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((data) => {
      switch (data.tab) {
        case this.ADD_ACCOUNT:
          this.activeTab = this.ADD_ACCOUNT;
          break;
        case this.ADD_PHONE:
          this.activeTab = this.ADD_PHONE;
          break;
        case this.ADD_UTILITY_BILLS:
          this.activeTab = this.ADD_UTILITY_BILLS;
          break;
      }
    });
  }


  giveActiveTabClass(type) {
    return type === this.activeTab ? "active" : "";
  }

  isActiveMenuElement(tab: string) {
    return this.activeTab === tab;
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }
}
