<section class="popup" *ngIf="popupWaitingLimitInMS">
  <div class="modalContent">
    <div class="content">
      <p>
        {{ "POPUPS.INACTIVITY_TEXT_1" | translate
        }}<span>{{ popupWaitingLimitInMS / 1000 }}</span
        >{{ "POPUPS.INACTIVITY_TEXT_2" | translate }}
      </p>
    </div>
  </div>
</section>
