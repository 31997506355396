import { Subscription } from "rxjs";
import { accountsInfo } from "../models/models";
import { AccountsInfoService } from "../services/accounts-info.service";

export class accountsInfoBase {
  accountsInfoSub: Subscription;
  accountsInfo: accountsInfo;

  defaultAccountInfo: accountsInfo;
  defaultAccountInfoSub: Subscription;

  accountsInfoLoadingSub: Subscription;
  accountsInfoLoading: boolean;

  accountsInfoErrorMessageSub: Subscription;
  accountsInfoErrorMessage: string;

  subscribeToAccountsInfo(accountsInfoService: AccountsInfoService) {
    this.accountsInfoSub = accountsInfoService.accountsInfo.subscribe(
      (res: accountsInfo): any => {
        this.accountsInfo = res;
      }
    );

    this.defaultAccountInfoSub = accountsInfoService.defaultAccountInfo.subscribe(
      (res: accountsInfo): any => {
        this.defaultAccountInfo = res;
      }
    )

    this.accountsInfoLoadingSub = accountsInfoService.accountsInfoLoading.subscribe(
      (res: boolean): any => {
        this.accountsInfoLoading = res;
      }
    );

    this.accountsInfoErrorMessageSub = accountsInfoService.accountsInfoErrorMessage.subscribe(
      (res: string): any => {
        this.accountsInfoErrorMessage = res;
      }
    );
  }

  unsubscribeAccountsInfo() {
    if (this.accountsInfoSub) {
      this.accountsInfoSub.unsubscribe();
    }
    if (this.accountsInfoLoadingSub) {
      this.accountsInfoLoadingSub.unsubscribe();
    }
    if (this.accountsInfoErrorMessageSub) {
      this.accountsInfoErrorMessageSub.unsubscribe();
    }
  }
}
