import { Component, OnInit, Input } from "@angular/core";
import { Subscription } from "rxjs";
import {
  loanInfo,
  LoanScheduleInfo,
  loanStatementInfo,
  loanDetailsInfo,
} from "src/app/shared/models/models";
import { LoanInfoService } from "src/app/shared/services/loan-info.service";

@Component({
  selector: "app-product-list-loans",
  templateUrl: "./product-list-loans.component.html",
  styleUrls: ["./product-list-loans.component.scss"],
})
export class ProductListLoansComponent implements OnInit {

  @Input() loans;
  @Input() currentItem?: loanInfo;
  @Input() loading;

  localLoans;
  localCurrentItem;
  currentLoan;

  SCHEDULE = "SCHEDULE";
  HISTORY = "HISTORY";
  DETAILS = "DETAILS";
  activeTab = "";

  schedulesLoading = false;
  schedules$: Subscription;
  schedulesErrors: any;
  schedules: Array<LoanScheduleInfo>;

  detailsLoading = false;
  details$: Subscription;
  detailsError: any;
  details: loanDetailsInfo;
  statements: Array<loanStatementInfo>;


  constructor(private loanInfoService: LoanInfoService) {}

  ngOnInit(): void {
    this.setStatus(this.SCHEDULE);
  }

  ngOnChanges(changes: any) {
    if (changes.currentItem) {
      this.localCurrentItem = changes.currentItem.currentValue;
    }
    if (changes.loans) {
      this.localLoans = changes.loans.currentValue;
    }

    if (this.localCurrentItem && this.localLoans) {
      this.setCurentLoan(this.localCurrentItem, this.localLoans);
    }
  }

  setCurentLoan(item: any, loans) {
    if (item && loans) {
      let tempLoans: any = this.loans;
      this.currentLoan = tempLoans.filter((loan) => loan.id === item)[0];
      this.fetchLoanScheduleInfo(this.currentLoan.contractN);
      this.fetchloanDetailsInfo(this.currentLoan.contractN);
    }

  }

  fetchLoanScheduleInfo(contractN: string) {
    this.schedules = [];
    this.schedulesLoading = true;
    this.schedules$ = this.loanInfoService
      .loanScheduleInfoQuery(contractN)
      .subscribe((res) => {
        this.schedules =
          res["data"] && res["data"]["loans"]
            ? res["data"]["loans"][0]["schedule"]
            : [];
        this.schedulesErrors = res["errors"];
        this.schedulesLoading = false;
        this.schedules = null ? [] : this.schedules;
      });
  }

  fetchloanDetailsInfo(contractN: string) {
    this.statements = [];


    this.detailsLoading = true;
    this.details$ = this.loanInfoService
      .loanDetailsInfoQuery(contractN)
      .subscribe((res) => {

        this.details =
          res["data"] && res["data"]["loans"] ? res["data"]["loans"][0] : [];
        this.statements =
          res["data"] && res["data"]["loans"]
            ? res["data"]["loans"][0]["statement"]
            : [];
        this.detailsError = res["errors"];

        this.detailsLoading = false;
        this.statements = null ? [] : this.statements;

      });
  }

  getDate(date) {
    return new Date(date);
  }

  isActive(loan: any) {
    if (this.currentLoan) {
      if (loan.id === this.currentLoan.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  clickLoan(loan) {
    if(this.currentLoan === loan){

        this.currentLoan = null;

    }
    this.currentLoan = loan;
    this.fetchLoanScheduleInfo(this.currentLoan.contractN);
    this.fetchloanDetailsInfo(this.currentLoan.contractN);
    this.setStatus(this.SCHEDULE);

  }

  setStatus(status: string) {
    this.activeTab = status;

  }

}
