import { Component, Input, OnInit } from "@angular/core";
import { Overdraft } from "../../models/models";
import { OverdraftService } from "../../services/overdraft.service";

@Component({
  selector: "app-account-overdraft",
  templateUrl: "./account-overdraft.component.html",
  styleUrls: ["./account-overdraft.component.scss"],
})
export class AccountOverdraftComponent implements OnInit {
  // @Input() accountNumber: string;
  @Input() public overdraft: Overdraft;
  // public loading: boolean;

  constructor(private overdraftService: OverdraftService) {}

  ngOnInit(): void {
    // this.fetchOverdraft();
  }

  // public fetchOverdraft(): void {
  //   this.overdraft = null;
  //   this.loading = true;
  //   this.overdraftService.fetchAccountOverdraft(this.accountNumber).subscribe(
  //     (res: any) => {
  //       this.loading = false;
  //       this.overdraft = res?.data?.overdrafts[0];
  //     },
  //     (err: any) => {
  //       this.loading = false;
  //     }
  //   );
  // }
}
