import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LandingService } from "../landing.service";
import { Router } from "@angular/router";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { AdSlider } from "src/app/shared/models/models";
import { OtpService } from "src/app/shared/services/otp.service";
import { Subject } from "rxjs";
// i want to import a 2fa component


import { TwoFaService } from "./two-fa/two-fa.service";


@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  authForm: FormGroup;
  sliderData: AdSlider[];
  auth: any;

  constructor(
    public sharedFunctions: FunctionsService,
    private landingService: LandingService,
    private router: Router,
    private otpService: OtpService,
    private twoFaService: TwoFaService
  ) {}

  loadingAuthentication = false;
  errorMessage: string = "";

  passwordInputType: string = "password";

  isTwoFaStarted = new Subject<boolean>();

  ngOnInit(): void {
    this.initializeForm();
    this.checkIfUsernameIsRemembered();
    this.fillSliderData();
  }

  private fillSliderData(): void {
    this.sliderData = [
      {
        background: "../../../assets/images/banners/landing-1.jpg",
        // background: "../../../assets/images/logo.png"
      },
      {
        background: "../../../assets/images/banners/landing-2.jpg",
        // background: "../../../assets/images/logo.png"
      },
      {
        background: "../../../assets/images/banners/landing-3.jpg",
        // background: "../../../assets/images/logo.png"
      },
      {
        background: "../../../assets/images/banners/landing-4.jpg",
        // background: "../../../assets/images/logo.png"
      },
    ];
  }
  initializeForm() {
    this.authForm = new FormGroup({
      username: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
      remembeUsername: new FormControl(false),
    });
  }

  checkIfUsernameIsRemembered() {
    const username = localStorage.getItem("username");

    if (username) {
      this.authForm.get("username").setValue(username);
      this.authForm.get("remembeUsername").setValue(true);
    } else {
      this.authForm.get("username").setValue("");
    }
  }

  submitForm($event) {
    if (!this.loadingAuthentication) {
      this.checkFormValidity($event);
      this.errorMessage = "";
    }
  }

  checkFormValidity($event) {
    this.markFormGroupTouched(this.authForm);

    if (
      this.authForm.controls.username.valid &&
      this.authForm.controls.password.valid
    ) {
      this.authorizeUser($event);
    }
  }

  markFormGroupTouched(authForm: FormGroup) {
    (Object as any).values(authForm.controls).forEach((control) => {
      control.markAsTouched();
    });
  }
  
  initiateTwoFaAuth() {

    const {
      value: { username, password },
    } = this.authForm;

    this.isTwoFaStarted.next(true);


   




   
  }
  getErrorFromTwoFa(errorMessage) {
    this.errorMessage = errorMessage;
  }
  authorizeUser($event) {
    this.loadingAuthentication = true;
    const {
      value: { username, password },
    } = this.authForm;

    this.auth = this.landingService
      .authorizeUser(username, password, $event.sessionId, $event.code)
      .subscribe(
        (res: any) => {
          this.loadingAuthentication = false;
          // console.log(res)

          if (res.token && res.token != undefined && res.token != "undefined") {
            if (this.authForm.get("remembeUsername")) {
              this.landingService.rememberUsername(
                this.authForm.get("username").value
              );
            }

            this.landingService.setToken(res.token);

            this.router.navigateByUrl("/");
          }
        },
        (err) => {
          this.loadingAuthentication = false;
          this.errorMessage = err.error.errorCode;
        }
      );
  }

  togglePasswordInputType() {
    if (this.passwordInputType === "text") {
      this.passwordInputType = "password";
    } else {
      this.passwordInputType = "text";
    }
  }

  public mouseup() {
    this.passwordInputType = "password";
  }

  public mousedown() {
    this.passwordInputType = "text";
  }

  public openLocationMap() {
    const url =
      "https://www.google.com/maps/place/AB+Bank+Zambia+Limited/@-15.4225643,28.2757211,15z/data=!4m5!3m4!1s0x0:0xbfbbc2d8259f3413!8m2!3d-15.4229159!4d28.2835695";
    window.open(url, "_blank");
  }

  ngOnDestroy(): void {
    if (this.auth) {
      this.auth.unsubscribe();
    }
  }

  public getCustomerMobile(username: string, password: string) {
    this.isTwoFaStarted.next(true);




   
  }



}
