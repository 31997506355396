import { Directive, OnInit, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appCurrency]",
})
export class CurrencyDirective implements OnInit {

  constructor(private el: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.el.nativeElement.value = this.transform(this.el.nativeElement.value);
    }, 0);
  }

  transform(value: any, args?: any): any {
    if (typeof value === "number") {
      const step1 = value.toFixed(2);

      if (step1.split(".")[1]) {
        return this.addCommas(step1.split(".")[0] + "." + step1.split(".")[1]);
      } else {
        return this.addCommas(step1.split(".")[0]);
      }
    } else {
      return "";
    }
  }

  @HostListener("blur", ["$event.target.value", "$event"])
  onBlur(value) {
    if (Number(value)) {
      this.el.nativeElement.value = this.transform(Number(value));
    } else {
      return "";
    }
  }

  @HostListener("focus", ["$event.target.value", "$event"])
  onFocus(value, event) {
    const whole = value.replace(/\,/g, "").split(".")[0];
    const decimal = value.replace(/\,/g, "").split(".")[1];
    if (Number(whole) > 0 || Number(decimal) > 0) {
      if (Number(decimal) > 0) {
        this.el.nativeElement.value = String(whole) + "." + String(decimal);
      } else {
        this.el.nativeElement.value = whole;
      }
    } else {
      this.el.nativeElement.value = "";
    }
  }

  @HostListener("keydown", ["$event"]) onKeyDown(e) {
    if (
      e.key === "." &&
      e.target.value.split("").filter((x) => x === ".").length > 0
    ) {
      e.preventDefault();
    }

    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  addCommas(nStr) {
    nStr += "";
    const x = nStr.split(".");
    let x1 = x[0];
    const x2 = x.length > 1 ? "." + x[1] : "";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }
}
