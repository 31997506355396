import { Component, OnInit, OnDestroy, SimpleChanges } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { Guid } from "guid-typescript";
import { Router } from "@angular/router";
import { TransferInfo } from "../../../../app/shared/models/models";
import { accountsInfo, selectType } from "../../../../app/shared/models/models";
import { AccountsInfoService } from "../../../../app/shared/services/accounts-info.service";
import { accountsInfoBase } from "../../../../app/shared/component-bases/accounts-info-base";
import { FunctionsService } from "../../../../app/shared/functions/functions.service";
import { TransfersService } from "../transfers.service";
import { TransferBase } from "../transfers-base";
import { loadavg } from "os";
import { SelectService } from "src/app/shared/shared-components/select/select.service";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-transfer-to-own",
  templateUrl: "./transfer-to-own.component.html",
  styleUrls: ["./transfer-to-own.component.scss"],
})
export class TransferToOwnComponent
  extends TransferBase
  implements OnInit, OnDestroy
{
  form: FormGroup;

  step: number = 1;
  guid: Guid;

  amount: number;
  description: string = "";

  loading: boolean = true;

  loadingFromSelect: boolean = true;
  loadingToSelect: boolean = true;
  defaultAccount: selectType;

  accountsFrom: Array<selectType>;
  accountFromLoadingText = "TRANSFERS.FROM_ACC_PLACEHOLDER";
  accountsTo: selectType;
  accountToLoadingText = "TRANSFERS.TO_ACC_PLACEHOLDER";
  selectedAccountTo?: selectType;
  selectedAccountFrom?: selectType;

  transferConfirmation$: any;
  transferConfirmationLoading = false;
  transferConfirmationError: any;
  transferConfirmation: TransferInfo;

  showTransferInfoModal = false;

  confirmErrorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountsInfoService: AccountsInfoService,
    private functionsService: FunctionsService,
    private transfersService: TransfersService,
    private modalService: ModalWithTextService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedAccountFrom = null;
    this.selectedAccountTo = null;
    this.initializeForm();
    setTimeout(() => {
      this.accountsInfoService.fetchAccountsInfo();
    });
    this.modalService.modalClosed.subscribe((res: boolean) => {
      if (res === true) {
        this.onPopupClosed();
      }
    });
    super.subscribeToAccountsInfo(this.accountsInfoService);
    this.accountsInfoService.accountsInfoErrorMessage.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = false;
        this.errorMessage = res;
      }
    });
    this.accountsInfoService.accountsInfo.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = false;
        this.prepareAccountsForFromSelect();
      }
    });
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      accountFrom: new FormControl("", [Validators.required]),
      accountTo: new FormControl("", [Validators.required]),
      amount: new FormControl("", [
        Validators.required,
        Validators.max(100000),
      ]),
      description: new FormControl("", [Validators.required]),
    });
  }

  prepareAccountsForFromSelect() {
    let temp: any = this.accountsInfo;

    this.accountsFrom = temp.map((x: accountsInfo) => ({
      id: x.accountId,
      mainTitle: x.accountNumber,
      aboveTitle: x.category,
      rightInfo: x.balance.toString() + " " + x.currency,
    }));

    let defTemp: any = this.defaultAccountInfo;
    if (defTemp) {
      if (defTemp.length !== 0) {
        this.defaultAccount = {
          id: this.defaultAccountInfo.accountId,
          imgId: null,
          imgName: "",
          mainTitle: this.defaultAccountInfo.accountNumber,
          aboveTitle: "",
          underTitle: "",
          rightInfo:
            this.defaultAccountInfo.balance.toString() +
            " " +
            this.defaultAccountInfo.currency,
        };
      }
    }
  }

  prepareAccountsForToSelect(accountFrom: selectType) {
    let accounts: any = this.accountsInfo;
    let choosenAccount = accounts.find(
      (x: accountsInfo) => x.accountId === accountFrom.id
    );
    accounts = accounts.filter(
      (x: accountsInfo) => x.accountId !== choosenAccount.accountId
    );
    accounts = accounts.filter(
      (x: accountsInfo) => x.currency === choosenAccount.currency
    );
    this.accountsTo = accounts.map((x: accountsInfo) => ({
      id: x.accountId,
      mainTitle: x.accountNumber,
      aboveTitle: x.category,
      rightInfo: x.balance.toString() + " " + x.currency,
    }));
    this.loadingToSelect = false;
  }
  selectAccountFrom(accFrom: selectType) {
    this.selectedAccountFrom = accFrom;
    this.form.get("accountFrom").setValue(this.selectedAccountFrom.mainTitle);
    this.setMaxAmountBasedOnChoosenAccount(this.selectedAccountFrom);
    this.prepareAccountsForToSelect(this.selectedAccountFrom);
  }
  setMaxAmountBasedOnChoosenAccount(account) {
    const accounts:any = this.accountsInfo;
    const choosenAccount = accounts.find((x)=> x.accountNumber === account.mainTitle);
    let maxAmount = choosenAccount.balance;
    if(maxAmount > 100000) maxAmount = 100000;
    this.form.controls.amount.setValidators([
      Validators.required,
      Validators.max(maxAmount),
    ]);
  }

  selectAccountTo(accTo: selectType) {
    this.selectedAccountTo = accTo;
    this.form.get("accountTo").setValue(this.selectedAccountTo.mainTitle);
    this.step = 2;
  }

  prepareForTransfer() {
    this.markFormGroupTouched(this.form);
    if (this.form.valid) {
      this.guid = Guid.create();
      super.prepareForTransfer(
        this.transfersService,
        this.form.controls.amount.value,
        this.selectedAccountFrom.rightInfo.substring(
          this.selectedAccountFrom.rightInfo.length - 3
        ),
        this.guid.toString(),
        "TRANSFER_BETWEEN_OWN_ACCOUNTS",
        () => (this.step = 3)
      );
      this.loadingFromSelect = true;
      this.loadingToSelect = true;
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  isDisabledFirstStep() {
    return this.step === 3;
  }

  gotoFirstStep() {
    this.step = 1;
    this.loadingFromSelect = false;
    this.loadingToSelect = false;
    this.prepareTransfer = false;
    this.selectedAccountTo = null;
    this.form.get("amount").setValue(null);
    this.form.get("description").setValue(null);
  }

  transferToOwnAccountConfirm() {
    this.unsubscribeTransferConfirmation();
    this.transferConfirmationLoading = true;
    this.transferConfirmation = undefined;
    this.loading = true;

    this.transferConfirmation$ = this.transfersService
      .transferToOwnAccountConfirm(
        this.selectedAccountFrom.mainTitle,
        this.selectedAccountTo.mainTitle,
        this.selectedAccountFrom.rightInfo.substring(
          this.selectedAccountFrom.rightInfo.length - 3
        ),
        +this.form.get("amount").value,
        this.form.get("description").value,
        this.prepareTransfer.fee,
        this.guid.toString()
      )
      .subscribe((res: any) => {
        this.showTransferInfoModal = true;
        this.transferConfirmationLoading = false;
        this.loading = false;
        if (res.data?.addInternalTransfer) {
          if (
            res.data.addInternalTransfer.status === "SUCCESS" &&
            res.data.addInternalTransfer.id
          ) {
            this.transferConfirmationError = undefined;
            const voucherNumber = res.data.addInternalTransfer.id;
            this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
          } else {
            this.modalService.generateErrorModal("ERROR_CODES.GENERAL_ERROR");
          }
        } else {
          this.modalService.generateErrorModal(
            "ERROR_CODES." + res.errors[0].extensions.code
          );
        }

        this.transferConfirmation =
          res["data"] && res["data"]["addInternalTransfer"]
            ? res["data"]["addInternalTransfer"]
            : {};
      });
  }

  unsubscribeTransferConfirmation() {
    if (this.transferConfirmation$) {
      this.transferConfirmation$.unsubscribe();
    }
  }

  giveInputClasses(input: AbstractControl) {
    if (this.step !== 2) {
      return "disabled";
    } else {
      return this.functionsService.giveClassesToCustomInputs(input);
    }
  }

  ngOnDestroy(): void {
    super.unsubscribeAccountsInfo();
  }

  closeClick() {
    this.router.navigateByUrl(
      "/products/accounts/" + this.selectedAccountFrom.mainTitle
    );
  }

  onPopupClosed() {
    this.closeClick();
  }
}
