<div class="accounts-list">
    <ul class="table">
        <li class="t-row t-head">
            <div class="t-cell card-cell">
                <span>{{ "PRODUCTS.ACCOUNT_NUMBER" | translate }}</span>
            </div>
            <div class="t-cell card-cell">
                <span>{{ "PRODUCTS.BALANCE" | translate }}</span>
            </div>
            <div class="t-cell card-cell">
                <span>{{ "PRODUCTS.BLOCKED" | translate }}</span>
            </div>
            <div class="t-cell card-cell">
                <span>{{ "PRODUCTS.AVAILABLE" | translate }}</span>
            </div>

            <div class="t-cell arrow-cell arrow-for-mini"></div>

        </li>

        <div class="t-row pointer" *ngFor="let account of accounts" [ngClass]="{ active: isActive(account) }">
            <li class="pointer" (click)="clickAccount(account)">
                <div class="t-cell card-cell">
                    <div class="cell-name">
                        {{ "PRODUCTS.ACCOUNT_NUMBER" | translate }}
                    </div>
                    <div>
                        <div>
                            <span class="name">{{ account.category }}</span>
                            <span class="description">{{ account.accountNumber }}</span>
                        </div>
                    </div>
                </div>
                <div class="t-cell card-cell">
                    <div class="cell-name">{{ "PRODUCTS.BALANCE" | translate }}</div>
                    <span class="span-balance">{{ (account.balance | moneyFormat) + " " + account.currency }}
          </span>
                </div>
                <div class="t-cell card-cell">
                    <div class="cell-name">{{ "PRODUCTS.BLOCKED" | translate }}</div>
                    <span class="span-balance">{{
              (account.blockedAmount | moneyFormat) + " " + account.currency
            }}
          </span>
                </div>
                <div class="t-cell card-cell">
                    <div class="cell-name">{{ "PRODUCTS.AVAILABLE" | translate }}</div>
                    <span class="span-balance">{{
            (account.availableBalanceEqu | moneyFormat) + " " + account.currency
          }}</span>
                </div>
                <div class="t-cell arrow-cell arrow-for-mini">
                    <Label>Show</Label>
                    <!-- <i class="icon i-arrow--blue"></i> -->
                </div>
            </li>





            <li *ngIf="currentAccount">
                <div class="card" *ngIf="currentAccount.accountId == account.accountId">
                    <div class="nav-secondary">
                        <ul>
                            <li class="pointer">
                                <a [ngClass]="{ active: isTransactions }" (click)="onClickMenu(true)">{{ "PRODUCTS.TRANSACTIONS" | translate }}</a
                >
              </li>
              <!-- <li class="pointer">
                <a
                  [ngClass]="{ active: !isTransactions }"
                  (click)="onClickMenu(false)"
                  >{{ "PRODUCTS.BLOCKED" | translate }}</a
                >
              </li> -->

              <!-- account.balance<=0 -->
              <li class="pointer" *ngIf="overdraft">
                <a
                  [ngClass]="{ active: !isTransactions }"
                  (click)="onClickMenu(false)"
                  >{{ "PRODUCTS.OVERDRAFT.OVERDRAFT" | translate }}</a
                >
              </li>
            </ul>
          </div>

          <div *ngIf="isTransactions && (!accountsLoading || !overdraftLoading)">
            <h4 style="padding: 15px 30px">
              {{ "PRODUCTS.RECENT_TRANSACTIONS" | translate }}
            </h4>
            <div style="background-color: yello;" class="card-body">
              <app-transaction-list
                [startDate]="null"
                [endDate]="null"
                [accountNumber]="account.accountNumber"
              ></app-transaction-list>
            </div>
          </div>

          <div *ngIf="!isTransactions && (!accountsLoading || !overdraftLoading)">
            <h4 style="padding: 15px 30px">
              {{ "PRODUCTS.OVERDRAFT.OVERDRAFT" | translate }}
            </h4>
            <div class="card-body">
              <app-account-overdraft
                [overdraft]="overdraft"
              ></app-account-overdraft>
            </div>
          </div>
        </div>
      </li>


    </div>
  </ul>
  <h2 class="no-data" *ngIf="!accounts && (!accountsLoading || !overdraftLoading)">
    {{ "GENERAL.NO_DATA" | translate }}
  </h2>
</div>