import { Component, OnInit } from '@angular/core';
import { CustomerInfoService } from 'src/app/shared/services/customer-info.service';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-peding-transfer',
  templateUrl: './peding-transfer.component.html',
  styleUrls: ['./peding-transfer.component.scss']
})
export class PedingTransferComponent implements OnInit {

  constructor(
    private fetchpending: CustomerInfoService,
    private router: Router,
    private shared: SharedService
    ) {
    // this.senderPerson= localStorage.getItem('fullname');
    this.gettransfers();

   }

  transfers =<any>[];
  // senderPerson:String='';

  ngOnInit(): void {

  }


  getData(
    // id,
    // amount,
    // channelCode,
    // branch,
    // comment,
    // currency,
    // externalId,
    // fee,
    // ownersCount,
    // provider,
    // receiverAccountNumber,
    // receiverBank,
    // receiverBankSwift,
    // receiverPerson,
    // rejectCode,
    // rejectReason,
    // senderAccountNumber,
    // senderPerson,
    // status,
    // transferType,
    // transmissionDateTime,
    // userId
    data
    ){
      this.shared.setData(data)
      this.router.navigate(['transfer/approve'])

      // this.router.navigate(['transfer/approve'], { queryParams: {
      //   id: id,
      //   amount: amount,
      //   channelCode: channelCode,
      //   branch: branch,
      //   comment: comment,
      //   currency: currency,
      //   externalId: externalId,
      //   fee: fee,
      //   ownersCount: ownersCount,
      //   provider: provider,
      //   receiverAccountNumber: receiverAccountNumber,
      //   receiverBank: receiverBank,
      //   receiverBankSwift: receiverBankSwift,
      //   receiverPerson: receiverPerson,
      //   rejectCode: rejectCode,
      //   rejectReason: rejectReason,
      //   senderAccountNumber: senderAccountNumber,
      //   senderPerson: senderPerson,
      //   status: status,
      //   transferType: transferType,
      //   transmissionDateTime: transmissionDateTime,
      //   userId: userId


      //    } });



  }

  gettransfers(){
    var sendPerson = localStorage.getItem('fullname');
    this.fetchpending.PendingTransfers(sendPerson).subscribe(
      (response)=>{
        // console.log(response.data['pendingTransfer'])
        this.transfers = response.data['pendingTransfer'];

      },
      (error)=>{
        console.log(error);
      }
          );
     }

}
