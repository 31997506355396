<div class="weekly-view">
  <button class="arrow left" (click)="showPrevWeek()"><</button>

  <div class="wrapper">
    <ul class="week-days">
      <li *ngFor="let weekDayName of weekDayNames">{{ ('CALENDAR.DAYS.' + weekDayName | translate).charAt(0)  }}</li>
    </ul>


    <div class="days">
      <div class="padding" *ngFor="let padding of selectedMonthDates.previous">{{ padding }}</div>
      <div  class="day" 
            *ngFor="let date of selectedMonthDates.current" 
            (click)="pickDate(date)" 
            [ngClass]="getCurrentDateClass(date)">
            {{ date }}
            <div class="notification-dots" [ngClass]="showHideDot(date)">
              <div class="dot bills" ></div>
              <div class="dot loan" ></div>
              <div class="dot income" ></div>
              <div class="dot custom" ></div>
            </div>
      </div>
      <div class="padding" *ngFor="let padding of selectedMonthDates.next">{{ padding }}</div>
    </div>
  </div>

  <button class="arrow right" (click)="showNextWeek()">></button>
</div>