import { Component, Input, OnInit } from '@angular/core';
import { AdSlider } from '../../models/models';

@Component({
  selector: 'app-ad-slider',
  templateUrl: './ad-slider.component.html',
  styleUrls: ['./ad-slider.component.scss']
})
export class AdSliderComponent implements OnInit {

  @Input()sliderContentArray:AdSlider[];
  
  // slider
  sliderTimeInterval;
  time: number = 0;
  activeSlide: number = 1;

  constructor() { }

  ngOnInit(): void {
    this.startSlider();
  }


  startSlider() {
    this.sliderTimeInterval = setInterval(() => {
      this.time++;

      if (this.time > 4) {
        if (this.activeSlide !== this.sliderContentArray.length) {
          this.activeSlide++;
        } else {
          this.activeSlide = 1;
        }

        this.time = 0;
      }
    }, 1000);
  }

}

