export enum productsBoxActiveTab {
  Accounts = "accounts",
  Loans = "Loans",
  Deposits = "Deposits",
}

export enum clientType {
  Retail = "Retail client",
  Entity = "Entity client",
}

// TemplateType for tempaltes
export enum TemplateTypeEnum {
  TRANSFER = "TRANSFER",
  MOBILE = "MOBILE",
  UTILITY = "UTILITY",
  DOCUMENT = "DOCUMENT",
}
