import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { ProductMenuComponent } from './product-menu/product-menu.component';
import { ProductListLoansComponent } from './product-lists/product-list-loans/product-list-loans.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ProductListAccountsComponent } from './product-lists/product-list-accounts/product-list-accounts.component';
import { ProductListDepositsComponent } from './product-lists/product-list-deposits/product-list-deposits.component';



@NgModule({
  declarations: [
    ProductsComponent,
    ProductMenuComponent,
    ProductListLoansComponent,
    ProductListAccountsComponent,
    ProductListDepositsComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, FormsModule],
  exports: [ProductListLoansComponent],
})
export class ProductsModule { }
