import { Branch } from './../../../../shared/models/models';
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import {
  selectType,
  accountsInfo,
  AccountTemplate,
  TransferInfo,
  Bank,
} from "src/app/shared/models/models";
import { AccountsInfoService } from "src/app/shared/services/accounts-info.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Guid } from "guid-typescript";
import { TransferBase } from "../../../transfers/transfers-base";
import { TransfersService } from "src/app/core/transfers/transfers.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalWithTextService } from "src/app/shared/shared-components/popups/modal-with-text/modal-with-text.service";
import { DictionaryService } from "src/app/shared/services/dictionary.service";

@Component({
  selector: "app-to-someone-mini",
  templateUrl: "./to-someone-mini.component.html",
  styleUrls: ["./to-someone-mini.component.scss"],
})
export class ToSomeoneMiniComponent
  extends TransferBase
  implements OnInit, OnDestroy
{
  guid: Guid;
  form: FormGroup;
  step: number = 1;

  @Input() templates: AccountTemplate;
  @Input() templatesLoading = false;
  @Input() templatesErrors: any;

  loadingTemplateText: string = "GENERAL.SELECT_ADD_BENEF";

  selectedAccountFrom: accountsInfo;
  selectedAccountTo: AccountTemplate;
  transactionType: string = "EXTERNAL_TRANSFER";

  accountsFrom: selectType;
  templatesToArr: selectType;
  templateTo: selectType;
  defaultAccount: selectType;
  loadingSelectFromText: string = "GENERAL.SELECT_ACC";
  branchName: string="";
  receiverBank: string = "";
  senderPerson: string = "";

  otpCode = "";
  smsCodeValidity = false;
  resendBtnHovered: boolean = false;
  ownersCount:number=0;

  isBetween: boolean = false;

  loading: boolean = true;
  prepareTransferLoading: boolean = false;

  transferConfirmationSub: Subscription;
  transferConfirmationLoading = false;
  transferConfirmationError: any;
  transferConfirmation: TransferInfo;
  modalClosedSub: Subscription;
  errorMessage: string;

  banksLoadingText = "GENERAL.SELECT_BANKS";
  banksForSelect: Array<selectType>;
  choosenBank: selectType;

  isTransferTemplateSelected: boolean;

  constructor(
    public dictionaryService: DictionaryService,
    private accountsInfoService: AccountsInfoService,
    private transferService: TransfersService,
    private formBuilder: FormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private modalService: ModalWithTextService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.modalClosedSub = this.modalService.modalClosed.subscribe(
      (res: boolean) => {
        if (res === true) {
          this.onPopupClosed();
        }
      }
    );

    super.subscribeToAccountsInfo(this.accountsInfoService);
    this.accountsInfoService.accountsInfoErrorMessage.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = false;
        this.errorMessage = res;
      }
    });
    this.accountsInfoService.accountsInfo.subscribe((res: any) => {
      this.loading = false;
      if (res) {
        this.prepareTransferLoading = false;
        this.prepareAccountsForFromSelect();
      }
    });
  }

  initializeForm() {
    // Validators.minLength(23),
    // Validators.maxLength(23),
    this.form = this.formBuilder.group({
      accountFrom: new FormControl("", [Validators.required]),
      accountTo: new FormControl("", [Validators.required]),
      amount: new FormControl(
        {
          value: null,
          disabled: this.isDisabledFirstStep(),
        },
        [Validators.required, Validators.max(100000)]
      ),
      bank: new FormControl("", [Validators.required]),
      branchName: new FormControl("", [Validators.required]),
      // branch: new FormControl("", [Validators.required]),
      description: new FormControl(
        {
          value: "",
          disabled: this.isDisabledFirstStep(),
        },
        [Validators.required]
      ),
      otpCode: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });
  }

  isDisabledFirstStep() {
    return this.step === 2;
  }
  isfirstStepValid() {
    const { accountFrom, accountTo, amount, bank, description } =
      this.form.controls;
    return (
      accountFrom.valid &&
      accountTo.valid &&
      amount.valid &&
      bank.valid &&
      description.valid
    );
  }
  selectAccountFrom(account: selectType) {
    let temp: any = this.accountsInfo;
    this.selectedAccountFrom = temp.filter(
      (item: accountsInfo) => item.accountId === account.id
    )[0];
    this.form
      .get("accountFrom")
      .setValue(this.selectedAccountFrom.accountNumber);
    this.setMaxAmountBasedOnChoosenAccount(this.selectedAccountFrom);
  }
  setMaxAmountBasedOnChoosenAccount(account) {
    let maxAmount = account.balance;
    if (maxAmount > 100000) maxAmount = 100000;
    this.form.controls.amount.setValidators([
      Validators.required,
      Validators.max(maxAmount),
    ]);
  }
  newItemClick() {
    this.router.navigateByUrl("/transfer/to-someones-account", {
      state: { addNewTemplate: true },
    });
  }

  selectTemplateTo(template: selectType) {
    let temp: any = this.templates;
    this.templateTo = template;
    this.selectedAccountTo = temp.filter(
      (item: AccountTemplate) => item.id.toString() === template.id.toString()
    )[0];
    if (this.isTemplateIsSelected(this.selectedAccountTo)) {
      this.setTemplateAsSelected(this.selectedAccountTo.bankBicCode);
    }

    this.form.get("accountTo").setValue(this.templateTo.rightInfo);
  }
  isTemplateIsSelected(template): boolean {
    if (
      template &&
      template.beneficiaryAccountNumber &&
      template.bankBicCode &&
      template.type == "TRANSFER"
    ) {
      return true;
    }
    return false;
  }
  setTemplateAsSelected(industryNumber): void {
    this.isTransferTemplateSelected = true;
    this.form.controls.bank.setValue(industryNumber);
  }

  // getEmittedBranchCode(branchCode: string) {
  //   if (branchCode) {
  //     this.form.get("branch").setValue(branchCode);
  //   } else {
  //     this.form.get("branch").setValue(null);
  //   }
  // }
  getEmittedIndustryNumber(bankCode: string) {
    this.form.get("bank").setValue(bankCode);
  }
  prepareAccountsForFromSelect() {
    let temp: any = this.accountsInfo;

    if (temp) {
      this.accountsFrom = temp.map((x: accountsInfo) => ({
        id: x.accountId,
        mainTitle: x.accountNumber,
        aboveTitle: x.category,
        rightInfo: x.balance.toString() + " " + x.currency,
      }));
    }

    let defTemp: any = this.defaultAccountInfo;
    if (defTemp) {
      if (defTemp.length !== 0) {
        this.defaultAccount = {
          id: this.defaultAccountInfo.accountId,
          imgId: null,
          imgName: "",
          mainTitle: this.defaultAccountInfo.accountNumber,
          aboveTitle: "",
          underTitle: "",
          rightInfo:
            this.defaultAccountInfo.balance.toString() +
            " " +
            this.defaultAccountInfo.currency,
        };
      }
    }
  }

  waitforTemplates() {
    let temp: any = this.templates;
    if (temp) {
      if (temp.length >= 0) {
        this.prepareTemplatesForToSelect();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  prepareTemplatesForToSelect() {
    let temp: any = this.templates;
    if (temp) {
      this.templatesToArr = temp.map((x: AccountTemplate) => ({
        id: x.id,
        mainTitle: x.name,
        rightInfo: x.beneficiaryAccountNumber,
      }));
    }
  }

  prepare() {
    this.markFormGroupTouched(this.form);

    if (this.checkFirstStepValidity()) {
      this.checkIfInternalAccount();

      this.checkIfInternalIsBetweenOwn();
      if (this.isBetween) {
        this.transactionType = "TRANSFER_BETWEEN_OWN_ACCOUNTS";
      }

      this.guid = Guid.create();
      super.prepareForTransfer(
        this.transferService,
        +this.form.get("amount").value,
        this.selectedAccountFrom.currency,
        this.guid.toString(),
        this.transactionType,
        () => (this.step = 2)
      );
    }
  }

  checkIfInternalAccount() {
    const choosenBankCode = this.form.controls.bank.value;
    const zambiaBankCode = "ABBAK";
    if (choosenBankCode === zambiaBankCode) {
      this.transactionType = "INTERNAL_TRANSFER";
    } else {
      this.transactionType = "EXTERNAL_TRANSFER";
    }
  }

  markFormGroupTouched(form: FormGroup) {
    (Object as any).values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  checkFirstStepValidity() {
    if (
      this.form.get("accountFrom").valid &&
      this.form.get("accountTo").valid &&
      this.form.get("description").valid &&
      this.form.get("amount").valid
    ) {
      return true;
    } else {
      return false;
    }
  }

  transferToSomeoneAccountConfirm() {
    if (this.form.valid) {
      this.unsubscribeTransferConfirmation();
      this.transferConfirmation = undefined;
      this.errorMessage = "";

      this.transferConfirmationLoading = true;
      this.loading = true;

      if (
        this.transactionType === "INTERNAL_TRANSFER" ||
        this.transactionType === "TRANSFER_BETWEEN_OWN_ACCOUNTS"
      ) {
        this.checkIfInternalIsBetweenOwn();

        this.transferConfirmationSub = this.transferService
          .transferToSomeoneInternalAccountConfirm(
            this.selectedAccountFrom.accountNumber,
            this.selectedAccountFrom.currency,
            +this.form.get("amount").value,
            this.prepareTransfer.fee,
            this.guid.toString(),
            this.form.get("otpCode").value,
            this.isBetween,
            null,
            this.form.get("accountTo").value,
            this.selectedAccountTo ? this.selectedAccountTo.name : null,
            this.form.get("description").value
          )
          .subscribe((res: any) => {
            this.transferConfirmationLoading = false;
            this.loading = false;

            if (res?.data?.addInternalTransfer) {
              if (
                res.data.addInternalTransfer.status === "SUCESS" &&
                res.data.addInternalTransfer.id
              ) {
                this.errorMessage = "";
                const voucherNumber = res?.data?.addInternalTransfer?.id;
                this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
              } else {
                this.errorMessage = "GENERAL_ERROR";
              }
            }
            if (res.errors) {
              this.errorMessage = res.errors[0].extensions.code;
              this.gotoFirstStep();
            }
            this.transferConfirmation =
              res["data"] && res["data"]["addExternalTransfer"]
                ? res["data"]["addExternalTransfer"]
                : {};
          });
      } else if (this.transactionType === "EXTERNAL_TRANSFER") {
        this.transferConfirmationSub = this.transferService
          .transferToSomeoneExternalAccountConfirm(
            this.selectedAccountFrom.accountNumber,
            this.form.controls.accountTo.value,
            this.selectedAccountFrom.currency,
            +this.form.controls.amount.value,
            this.form.controls.description.value,
            this.prepareTransfer.fee,
            this.selectedAccountTo ? this.selectedAccountTo.name : null,
            this.guid.toString(),
            this.form.controls.otpCode.value,
            this.form.controls.bank.value,
            this.form.controls.branchName.value,
            this.senderPerson,
            this.receiverBank,
            this.ownersCount
          )
          .subscribe((res: any) => {
            this.transferConfirmationLoading = false;
            this.loading = false;

            if (res.errors) {
              this.errorMessage = res.errors[0].extensions.code;
              this.gotoFirstStep();
            } else if (res.data) {
              if (
                res.data.addExternalTransfer.status === "SUCCESS" &&
                res.data.addExternalTransfer.id
              ) {
                this.errorMessage = "";
                const voucherNumber = res?.data?.addExternalTransfer?.id;
                this.modalService.generateModal(`Successful Transfer! Voucher Number : ${voucherNumber}`);
              } else {
                this.errorMessage = "GENERAL_ERROR";
              }
            }
            this.transferConfirmation =
              res["data"] && res["data"]["addExternalTransfer"]
                ? res["data"]["addExternalTransfer"]
                : {};
          });
      }

      this.emptySmsCode();
    }
  }

  checkIfInternalIsBetweenOwn() {
    let temp: any = this.accountsFrom;
    if (this.form.controls.accountTo.value) {
      temp.forEach((element) => {
        if (element.id === this.form.controls.accountTo.value) {
          this.isBetween = true;
        }
      });
    }
  }

  getSmsCode(event) {
    this.form.controls.otpCode.setValue(event);
  }

  resendSms() {
    this.guid = Guid.create();
    super.prepareForTransfer(
      this.transferService,
      +this.form.get("amount").value,
      this.selectedAccountFrom.currency,
      this.guid.toString(),
      this.transactionType,
      () => (this.step = 2)
    );
  }

  gotoFirstStep() {
    this.step = 1;
    this.emptySmsCode();
  }

  emptySmsCode() {
    this.smsCodeValidity = false;
    this.otpCode = "";
  }

  giveInputClasses(input) {
    if (this.form.get(input).invalid && this.form.get(input).touched) {
      return "has-error";
    }
  }

  unsubscribeTransferConfirmation() {
    if (this.transferConfirmationSub) {
      this.transferConfirmationSub.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    super.unsubscribeAccountsInfo();
  }

  closeClick() {
    this.router.navigateByUrl(
      "/products/accounts/" + this.selectedAccountFrom?.accountNumber
    );
  }

  onPopupClosed() {
    this.closeClick();
  }
}
