<div class="credo-sidebar-container active" [ngClass]="{ collapsed: !menuIsOpen, active: menuIsOpen }">
    <div class="credo-sidebar">
        <div>
            <div class="head">
                <a class="user-wrap" href="#">
                    <div *ngIf="customerInfo">
                        <span>{{ "GENERAL.HELLO" | translate }},</span>
                        <b>{{ customerInfo.firstNameLat }}</b>
                    </div>
                </a>
                <div class="btn-wrap">


                    <a routerLink="transfer/pending" class="btn btn-circle btn-outline-secondary" (click)="toggleMenuState()" [routerLink]="['/transfer/pending']">
                        <i class="icon i-bell"></i>
                    </a>
                    <a class="btn btn-circle btn-outline-secondary" [routerLink]="['/settings']" (click)="toggleMenuState()"><i class="icon i-cog"></i
          ></a>
                    <a class="btn btn-circle btn-outline-secondary" (click)="logOut()"><i class="icon i-logout"></i
          ></a>
                </div>
            </div>

            <nav>
                <ul>
                    <li [ngClass]="giveClasses('products', true)">
                        <a class="nav-link" [routerLink]="['/products/accounts']" (click)="closeMenu()">
                            <i class="icon i-myProducts"></i>
                            <span>{{ "MENU.MY_PRODUCTS" | translate }}</span>
                        </a>
                    </li>
                    <li [ngClass]="giveClasses('transfer', true)">
                        <a class="nav-link" [routerLink]="['/transfer']" (click)="closeMenu()">
                            <i class="icon i-transfers"></i>
                            <span>{{ "MENU.TRANSFERS" | translate }}</span>
                        </a>
                    </li>
                    <li [ngClass]="giveClasses('transactions', true)">
                        <a class="nav-link" [routerLink]="['/transactions']" (click)="closeMenu()">
                            <i class="icon i-transactions"></i>
                            <span>{{ "MENU.TRANSACTIONS" | translate }}</span>
                        </a>
                    </li>

                    <li [ngClass]="giveClasses('take-a-loan', true)">
                        <a class="nav-link" [routerLink]="['/take-a-loan']" (click)="closeMenu()">
                            <i class="icon i-take-a-loan"></i>
                            <span>{{ "MENU.TAKE_A_LOAN" | translate }}</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
