import { Component, OnDestroy, OnInit } from "@angular/core";
import { LandingService } from "../../landing/landing.service";
import { CoreService } from "../../shared/services/core.service";

@Component({
  selector: "app-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnInit, OnDestroy {

  timeOfLastActivity: Date;
  newActivity: number;
  inactivityLimitInMS: number = 180000;
  popupWaitingLimitInMS: number;
  timeOut: any;
  interval: any;

  constructor(
    private coreService: CoreService,
    private landingService: LandingService
  ) {}

  ngOnInit(): void {
    this.startIntervals();

    document.addEventListener("mousemove", this.userMoved);
    document.addEventListener("touchstart", this.userMoved);
  }

  userMoved = () => {
    this.clearIntervals();
    this.popupWaitingLimitInMS = null;
    this.startIntervals();
  };

  startIntervals() {
    this.timeOut = setTimeout(() => {
      this.interval = setInterval(() => {
        this.popupWaitingLimitInMS = this.popupWaitingLimitInMS
          ? this.popupWaitingLimitInMS - 1000
          : 20000;
        if (this.popupWaitingLimitInMS <= 0) {
          this.popupWaitingLimitInMS = 0;
          this.clearIntervals();
          this.logout();
        }
      }, 1000);
    }, this.inactivityLimitInMS);
  }

  updatateSessionTime() {
    const date = new Date();

    if (
      this.timeOfLastActivity &&
      date.getTime() - this.timeOfLastActivity.getTime() >
        this.inactivityLimitInMS + 20000
    ) {
      this.logout();
    }
    this.timeOfLastActivity = date;
  }

  logout() {
    this.coreService.logOut();
    this.landingService.showPopupOnLanding = true;
  }

  clearIntervals() {
    clearInterval(this.interval);
    clearTimeout(this.timeOut);
  }

  ngOnDestroy(): void {
    this.clearIntervals();

    document.removeEventListener("mousemove", this.userMoved);
    document.removeEventListener("touchstart", this.userMoved);
  }
}
