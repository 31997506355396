<div class="slider">
  <ng-container *ngFor="let slide of sliderContentArray; let i = index">
    <div
      class="slide-content"
      [ngClass]="{
        active: i + 1 === activeSlide,
        prev: i === activeSlide - 2
      }"
    >
      <span
        class="slide-img"
        [style.background-image]="'url(' + slide.background + ')'"
      ></span>
      <div class="slide-caption">
        <p>{{ slide.text | translate }}</p>

      </div>
    </div>
  </ng-container>

  <div class="dots" *ngIf="sliderContentArray && sliderContentArray.length">
    <div
      class="dot"
      *ngFor="let dot of sliderContentArray; let i = index"
      [ngClass]="{ active: i + 1 === activeSlide }"
      (click)="activeSlide = i + 1; time = 0"
    ></div>
  </div>
</div>
