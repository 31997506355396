<div class="card ads-card">
  <div
    class="bg-img bg-img--cover"
    style="background-image: url('../../../../assets/images/0/ads1.png');"
  ></div>
  <div class="card-body">
    <div>
      <span>განვადება</span>
      <h5>გააკეთე განაცხადი</h5>
    </div>
    <a class="btn btn-circle btn-primary" href="#"
      ><i class="icon i-arrow--white"></i
    ></a>
  </div>
</div>
