import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-are-you-sure",
  templateUrl: "./are-you-sure.component.html",
  styleUrls: ["./are-you-sure.component.scss"],
})
export class AreYouSureComponent implements OnInit {
  @Input() questionText: string;
  @Input() loading: boolean;
  @Input() error: string;

  @Output() cancel = new EventEmitter<any>();
  @Output() confirm = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
  }

  closePopup(event) {
    if (!this.loading) {
      if (
        event.target.className === "cancel" ||
        event.target.className === "are-you-sure-container"
      ) {
        this.cancel.emit();
      }
    }
  }

  confirmPopup() {
    this.confirm.emit();
  }
}
