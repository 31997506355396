<style>
    .wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .button {
        width: 600px;
        height: 45px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        color: rgb(92, 90, 90);
        background-color: #fff;
        border: none;
        font-weight: normal;
        border-radius: 45px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
    }
    
    .button:hover {
        background-color: #063453;
        box-shadow: 0px 15px 20px rgba(46, 8, 99, 0.4);
        color: #fff;
        transform: translateY(-7px);
    }
</style>

<h1 style="color: white ; margin-top:80px;margin-left:0px">Terms and Conditions</h1>
<div>
    <div style="margin-top:90px ; justify-content: center; display:flex">
        <button (click)="modal()" class="button">Agreement and Acceptance</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            This agreement becomes effective between you and AB Bank Zambia Limited ('the bank ', 'we' or 'us') at the time of registering for Online Banking or at the time you access Online Banking, whichever occurs first. For purposes of this agreement 'Online
            Banking' includes, GSM, email, Online Banking, E-statements, debit card, ATM, Banking and transaction notification services.
        </p>
    </div>



    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal1()" class="button">Incorporated terms in addition to this agreement</button>
    </div>

    <div style=" justify-content: center; display:flex" *ngIf="showMe1">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            The terms and conditions of your accounts, AB Bank electronic wallet or facility terms and conditions (other terms) shall apply to all transactions you carry out or instruct us to carry out through bank accounts linked to the account number you select
            when using our Online Banking Platform. In the event of conflict between the provisions of this agreement and the any other term, the provisions of this agreement shall prevail. Where other terms require amendments or additions thereto to
            be reduced to writing and/or signed, your acceptance of this agreement shall be deemed to satisfy such requirements.
        </p>
    </div>

    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal2()" class="button">Defining the device and medium</button>
    </div>

    <div style=" justify-content: center; display:flex" *ngIf="showMe2">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            The device which you select to access our Online Banking Platform may include a computer, cell phone, telephone, television or similar technologies (the device) and the medium through which you access Online Banking Platform may include the Internet,
            wireless application protocol (WAP), wireless Internet gateway (WIG), or similar technologies (the medium). We will refer to the device and the medium collectively as "the communication system ".
        </p>
    </div>

    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal3()" class="button">Amendments</button>
    </div>

    <div style=" justify-content: center; display:flex" *ngIf="showMe3">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We may amend this agreement from time to time and you are bound by the version of this agreement that exists at the time you access Online Banking. The date of the version is recorded above clause 1.
        </p>
    </div>

    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal4()" class="button">Requirements and registration</button>
    </div>

    <div style=" justify-content: center; display:flex" *ngIf="showMe4">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            To access Online Banking follow the steps below:<br/><br/>a. You must first have an active account with AB Bank Zambia; <br/><br/>b. Register for Online Banking by filling in the registration form; You will be issued an OTP (One Time Password)
            via SMS that you will use to move to the next stage of accepting terms and to complete the registration process; <br/><br/>c. You must then create your own password;<br/> You can contact our Contact Center for more information at 888 or you
            can visit an AB Bank Branch.<br/><br> Verification:

            <br/> You are not permitted to create a password using account details belonging to another individual or company. Before we allow you access to Online Banking, we may require additional information from you to verify your identity. We will
            require your prior consent (express or implied), which consent will be deemed to have been granted by you at the time that you sign the registration form referred to above or at the time you accept these terms and conditions, to perform credit
            or other checks on you before we process an instruction.
        </p>
    </div>

    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal5()" class="button">Fees For the use of Online Banking</button>
    </div>

    <div style=" justify-content: center; display:flex" *ngIf="showMe5">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            Access to the online Banking platform is free, you will however be billed standard rates applicable on the type of account you hold and the nature of the transaction you are performing. You may view the applicable tariffs on our website by clicking the
            following link: https://www.abbank.co.zm/tariff-guides/. If you fail to pay the fees or if you have insufficient funds in the accounts which you have selected for this purpose, we reserve the right to refuse you access to the relevant Online
            Banking service and to debit all outstanding fees to any other account you have with the bank.
        </p>
    </div>



    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal6()" class="button">Execution of Instructions that appear to Originate from you</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe6">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            You are responsible for giving correct information and instructions when registering for Online banking and when conducting transactions. You are also responsible for the safety of your access codes and the Bank will not be liable for any loss or damage
            caused by your error.<br/> Instructions with valid credentials will be acted upon. You are required to notify the Bank in the event that you wish to change your access codes.<br/> Use of a communication system means we do not interact
            face-to-face. Unless you notify us before we give effect to an instruction, you authorize us to rely on and perform all instructions that appear to originate from you (even if someone else is impersonating you).

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal7()" class="button">Sending and processing instructions</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe7">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            Your instructions to us will be subject to the same turn-around times and processes that apply to your customer profile, the type of account you have and type of transaction involved. You will not hold us liable if you execute the same instruction more
            than once and neither instruction will be reversible.

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal8()" class="button">Confirmation of receipt of your instructions</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe8">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            An instruction is deemed to be received by us only once we have confirmed we have received it. If we fail to confirm receipt of your instruction, do not re-send the same instruction before checking your statements and contacting our Contact Centre at
            888. This is because the initial instruction may still be processed and re-sending the instruction may lead to a double transaction for which we will not be held liable.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal9()" class="button">Access code protection and irregularities</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe9">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            An "access code" refers to your password and username. You must always protect and keep your access codes confidential. We rely on you to report any compromise of your access codes to us without delay. You may do this by calling our Contact Centre or
            by visiting an AB Bank Branch. We may ask you to verify your identity. Once you ask us to disable an access code, we have the right to:<br/><br/> a. Reject all instructions received after such notification;<br/> b. Suspend the
            processing of all instructions not yet to be executed;<br/> c. Reverse (if possible) all executed transactions from such date and time as we may reasonably determine the unauthorized use to have commenced;<br/> d. Deactivate the access code
            without further notice.<br/><br/> Your access code is confidential and must not be shared with anyone; no AB Bank staff will ask for your access code. Report any irregularities to our Contact Centre or any of our branches.

        </p>
    </div>

    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal10()" class="button">One Time Password (OTP)</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe10">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            A unique and time-sensitive password is used as added security on our Online Banking platform. The OTP does not replace the password you use to log on to Internet banking but provides a second layer of security. The OTP will be sent to you by SMS when
            need arises and is valid such as when adding beneficiaries, a changing your password or your cell phone number.

        </p>
    </div>


    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal11()" class="button">Ending a session</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe11">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            You must ensure that you log off from the Online Banking service once you have finished using the Online Banking Platform. If you do not do this, unauthorized transactions may result, for which we will not be liable for.

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal12()" class="button">Your authority</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe12">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            By accepting these terms and conditions, you permit us to regard all activities you conduct, or instructions sent after you enter your access code as being authorized by you and intended to have legal force and effect.

        </p>
    </div>



    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal13()" class="button">Sufficient notice</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe13">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            You permit us to issue notices required in terms of this agreement, legislation, or regulation by making such notification available via our communication systems or sending such notification by email, SMS or similar future technologies. Any notices so
            issued by us, will as far as they contain contractual terms relating to electronic banking, also form part of this agreement. You permit us to securely process, share and store (via physical or cloud) your personal information to optimize
            your personal experience.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal14()" class="button">No offer, recommendation, or solicitatione</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe14">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            Unless clearly stated, all material on the communication system merely constitutes an invitation to do business with us. It does not constitute an offer or solicitation to buy or sell, or dispose in any way, of any investment, or to enter into any transaction.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal15()" class="button">Nature of information on the communication system</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe15">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            Information on the communication system is intended to provide you with only general information about the bank, its products, services, and objectives. From time to time, we may provide information on:<br/><br/> a. projected revenues, income,
            capital structure or other financial items;<br/> b. our plans, objectives and projections for future operations and services;<br/>
        </p>
    </div>







    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal16()" class="button">Information feeds</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe16">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We may use the services of other organizations to provide information on the communication system. We have no control over this information and make no representations or warranties of any nature as to its accuracy, appropriateness or correctness. You
            agree that such information is provided "as is " and we will not be directly or indirectly liable for any damage that may arise from you relying on it. All quotes, news, market information such as share prices or data shown on the communication
            system by way of live information feeds are delayed unless otherwise stated. You should always select the "refresh " or similar page or screen update function on your Internet browser or handset to ensure that the information you are viewing
            is the most current.
        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal17()" class="button">Links to third parties' communication systems</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe17">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            The communication system may contain links to other communication systems that carry information and material produced by other parties. While we try to provide links only to reputable communication systems, we cannot accept responsibility or liability
            for the information provided on other communication systems. A link from our communication system to any other communication system does not mean that we have scrutinized or endorsed the owners or administrators of such communication systems
            or their business or security practices and operations.
        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal18()" class="button">Our intellectual property</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe18">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We retain all copyright and other intellectual property rights in all material, including logos and other graphics and multimedia works published on or via the communication system. You are authorized to view and download one copy to a local hard drive
            or disk, print and make copies of such printouts provided that:<br/><br/> a. the material is used for considering or using Internet Banking and for no other commercial purposes; and<br/> b. any reproduction of any portion of our
            proprietary material must include our entire copyright notice.<br/> c. future economic performance. These will be only estimates so actual events or results may differ. All information is provided "as is " and should not be treated as professional
            or investment advice of any kind. You should consult your own professional advisers before relying on any information on the communication system.<br/> d. The logos and trademarks shown on our communication system are our registered and unregistered
            trademarks or those of third parties. Nothing contained on our communication system should be construed as granting any license or right to use any trademark or other intellectual property without our prior written permission or that of the
            relevant third parties. Your application to use any content from our communication system must be submitted in writing. On receipt we may respond and enter into further discussions with you. Consider your request as declined if you do not
            get a written response from us within five business days. Irrespective of the existence of copyright, you acknowledge that we are the proprietor of all material on the communication system, whether it constitutes confidential information or
            not, and that you shall have no right, title, or interest in any such material.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal19()" class="button">Software</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe19">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            Your failure to use appropriate software or hardware may result in a higher security risk and cause the communication system not to operate properly or not at all. Software, if any, made available for download on or via the communication system is governed
            by license conditions that establish a legal relationship with the licensor. You indemnify us against any breach of these license conditions. We give no warranty and make no representation, whether expressly or implied, as to the quality or
            fitness for purpose or use of such software. No warranty, whether express or implied is given that any files, downloads, or applications available via this communication system are free of viruses, Trojans, bombs, time-locks or any other data
            or code which could corrupt or affect the operation of your computer, database, network, or other information system.

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal20()" class="button">Transmission of information and security tips</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe20">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            Information transmitted via an unsecured link over a communication system is susceptible to potential unlawful access, distortion, or monitoring. You must comply with the security tips which are published on our website from time to time. As we do not
            have the ability to prevent unlawful activities by unscrupulous persons, you accept that we cannot be held liable for any loss, harm or damage suffered by you as a result thereof. To limit these risks, we may request independent verification
            of any information transmitted by you via our communication system from time to time.

        </p>
    </div>



    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal21()" class="button">Privacy</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe21">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We respect your privacy and your personal information. We will take all reasonable measures to protect your personal information and to keep it confidential, even when you are no longer our customer. If you call our contact center, you permit and hereby
            consent to us recording your calls for security reasons, and for the purposes of customer care

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal22()" class="button">Indemnity</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe22">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            You indemnify us for all losses and costs we may incur on your behalf due to:<br/><br/> a. Non-payment.<br/> b. Any instruction or transaction exceeding the amount available in your bank account; or<br/> c. Unauthorized instructions executed
            by us were not due to our negligence.

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal23()" class="button">Unavailability of Online Banking</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe23">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We will always and for whatever reason, have the sole and exclusive right to suspend or terminate Online Banking without any prior notification or giving any reasons for such termination or suspension. You acknowledge and accept that Online Banking may
            be unavailable from time to time for any reason, including: technical failure or problems with the communication system itself or our communication system underlying banking systems (the bank system); technical failure or problems with a communication
            system directly or indirectly underlying Online Banking that is owned or controlled by other persons (third party system); unavailability of telecommunication or electricity services; or other circumstances beyond our control. You undertake,
            in the event of unavailability of Online Banking, to limit your potential losses by using any other means of communication with us for the duration of the unavailability of Online Banking.

        </p>
    </div>




    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal24()" class="button">Warranties and representations</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe24">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We do not warrant that the communication system or Online Banking will be error-free or will meet any criteria of accuracy, completeness or reliability of information, performance, or quality. We expressly disclaim all implied warranties, including, without
            limitation, warranties of merchantability, title, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal25()" class="button">Disclaimer and limitation of liability</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe25">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            For purposes of this clause "we " or "us " or "our " includes the bank as well as its affiliates, shareholders, employees, consultants, and agents. Although we have taken care to ensure that the content provided on the Online Banking Platform is accurate
            and that you suffer no loss or damage as a result of your using it, the Online Banking Platform is provided "as is ". We are not liable for any damages whatsoever relating to your use of the Online Banking Platform. This includes the information
            contained on the Online Banking Platform or your inability to use the Online Banking Platform, including, without limitation, any direct, indirect, special, incidental or consequential damages, whether arising out of contract, statute, delict
            or otherwise and regardless of whether we were expressly advised of the possibility of such loss or damage. Without derogating from the generality of the foregoing, we are not liable for:<br/><br/> a. any damages which you suffer
            as a result of a compromise of your access codes.<br/> b. any interruption, malfunction, downtime or other failure of the communication system or Online Banking, our banking system, third party system, databases or any component part thereof
            for whatever reason.<br/> c. any loss or damage which arises from your orders, investment decisions, purchases or disposal of goods and services, including financial instruments or currencies, from third parties based upon the information
            provided on the communication system.<br/> d. any loss or damage with regard to your or any other data directly or indirectly caused by malfunction of our bank system, third party systems, power failures, unlawful access to or theft of data,
            computer viruses or destructive code on the bank system or third-party systems; programming defects, negligence on our part.<br/> e. any interruption, malfunction, downtime or other failure of goods or services provided by third parties, including,
            without limitation, third party systems such as the public switched telecommunication service providers, internet service providers, electricity and water suppliers, local authorities and certification authorities<br/> f. any event over which
            we have no direct control.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal26()" class="button">Breach</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe26">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            We may terminate your access to Online Banking if you breach a material term of this agreement and fail to remedy it within five days of you being notified of it. We may do this without detracting from our right to take any other steps available to us
            at law or under this agreement, including the right to obtain an interdict.

        </p>
    </div>





    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal27()" class="button">How disputes will be resolved</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe27">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            All disputes arising as a result of your use of Online Banking, the interpretation of this agreement or any matter which in terms of this agreement requires agreement by the parties will be decided before a Zambian Court of Competent Jurisdiction.

        </p>
    </div>







    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal28()" class="button">Capacity to enter into agreements</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe28">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            You warrant to us that you have the required legal capacity to enter into and be bound by this agreement. Anyone below the age of 18 must be assisted by their legal guardian when reading this agreement. If you are below 18 years of age you have to speak
            to your legal guardian or parents. If you are unsure whether you have the legal capacity to enter into agreements, you have to contact someone able to provide you with this information before you continue to use Online Banking. Our Customer
            Care Centre will be able to help you on 888

        </p>
    </div>






    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal29()" class="button">Notifications To prevent unnecessary disputes</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe29">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            You agree that unless you can prove otherwise, a certificate signed by a manager of the Bank whose appointment/capacity need not be proved or his representative is sufficient proof of the date of publication, withdrawal, transmission and content of:<br/><br/>            a. the current version and all previous versions of this agreement,<br/> b. notices and disclaimers posted on the communication system, and<br/> c. notification sent under this agreement.

        </p>
    </div>






    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal30()" class="button">Our address for notices and service of legal process</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe30">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            For the purpose of service of any legal process we choose the following registered address:<br /><br /> The Legal Counsel<br /> AB Bank Zambia Limited<br /> Plot 7393 Chainda Place<br /> Cairo Road<br /> Lusaka
            <br />

        </p>
    </div>







    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal31()" class="button">Law governing our relationship</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe31">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            This agreement will be governed and construed in accordance with the laws of the Republic of Zambia.

        </p>
    </div>



    <div style="margin-top:10px ; justify-content: center; display:flex">
        <button (click)="modal32()" class="button">General provisions</button>
    </div>
    <div style=" justify-content: center; display:flex" *ngIf="showMe32">
        <p style="font-size: 20px; width:650px;background-color:white; border-radius: 45px;margin-top:10px ; padding:15px">
            The headings of the clauses in this agreement are provided for convenience and ease of reference only and will not be used to interpret, modify, or amplify this agreement. No failure or delay by us to exercise any of our rights is to be construed as a
            waiver of any such right, whether this is done expressly or is implied. It will also not affect the validity of any part of these conditions or prejudice our right to take subsequent action against you. If any of these terms, conditions or
            provisions are held to be invalid, unlawful or unenforceable to any extent; such term, condition or provision will be severed from the remaining terms, conditions and provisions, which will continue to be valid to the full extent permitted
            by law. If you have any questions about this agreement or do not understand anything in this agreement, please call our Customer Care Centre 888.

        </p>
    </div>

</div>